import { IconButton, IconButtonProps } from "@growtherapy/sprout-ui";
import { twMerge } from "tailwind-merge";
import { VisualIndicator } from "./VisualIndicator";
import { useTheme } from "../hooks";
import classNames from "classnames";
import { Theme } from "../state";
import { forwardRef } from "react";

const DARK_THEME_CLASSNAMES =
  "bg-neutral-900 text-neutral-100 active:text-neutral-100 disabled:text-neutral-700 hover:bg-[#000000] active:bg-[#000000]";

const SIZING_AND_SPACING_CLASSNAMES =
  "min-w-12 max-w-12 min-h-12 max-h-12 justify-center p-0";

const mergeButtonClassNames = (
  theme: Theme,
  shouldOverrideClassName: boolean = false,
  className?: string,
) => {
  const classJoinArgs = [
    { [DARK_THEME_CLASSNAMES]: theme === Theme.DARK },
    SIZING_AND_SPACING_CLASSNAMES,
  ];

  if (className && shouldOverrideClassName) {
    return twMerge(classNames(className, classJoinArgs));
  } else {
    return twMerge(classNames(classJoinArgs, className));
  }
};

type ControlPanelButtonProps = IconButtonProps & {
  showVisualIndicator?: boolean;
  containerClassName?: string;
  /**
   * If true, the class names defined and assigned specifically by this
   * component will take precedence over any `className` prop passed when being
   * merged, before being applied to the button. This is useful if the
   * `className` prop is actually coming from another component, for example for
   * the `MenuToggleButton` component in Sprout, when it renders as a
   * `ControlPanelButton`.
   */
  shouldOverrideClassName?: boolean;
  children?: React.ReactNode;
};

/** Wraps IconButton component to ensure consistent color and sizing. **/
export const ControlPanelButton = forwardRef<
  HTMLButtonElement,
  ControlPanelButtonProps
>(function ControlPanelButton(
  {
    className,
    containerClassName,
    showVisualIndicator = false,
    shouldOverrideClassName = false,
    children,
    ...props
  },
  ref,
) {
  const { theme } = useTheme();
  return (
    <div className={twMerge("relative", containerClassName)}>
      <IconButton
        ref={ref}
        use="secondary"
        className={mergeButtonClassNames(
          theme,
          shouldOverrideClassName,
          twMerge("shadow-border-rebrand-neutral-800", className),
        )}
        {...props}
      />
      {showVisualIndicator && (
        <VisualIndicator
          data-testid="visual-indicator"
          className="absolute -top-1 -right-1"
        />
      )}
      {children}
    </div>
  );
});
