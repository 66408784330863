import { Link } from "@growtherapy/sprout-ui";
import Markdown, { Components } from "react-markdown";
import { omit } from "remeda";

const customMarkdownComponents: Components = {
  a: ({ children, href, ...otherLinkProps }) => {
    if (!href) return <>{children}</>;
    return (
      <Link
        className="no-underline"
        target="_blank"
        externalIcon={false}
        href={href}
        external
        {...omit(otherLinkProps, ["contentEditable"])}
      >
        {children}
      </Link>
    );
  },
};

export function AnnouncementContent({ children }: { children: string }) {
  return <Markdown components={customMarkdownComponents}>{children}</Markdown>;
}
