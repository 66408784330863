import { SelectedVisitor } from "../state";
import { Scalars, InputMaybe, Exact } from "../../types";
import { NudgeSchemaTrigger } from "@grow-therapy-team/seedling-components";

export enum AppointmentType {
  Consultation = "consultation",
  FollowUp = "followup",
  Intake = "intake",
}

export enum AppointmentLocation {
  InOffice = "in-office",
  Virtual = "virtual",
}

export type RecurringAppointmentGrouping = {
  __typename?: "RecurringAppointmentGroupingObject";
  indefinite: boolean;
  recurringAppointmentGroupingId: string;
  weeksBetween: number;
  appointments: Array<{
    __typename?: "PublicAppointmentObject";
    id: string;
    timeStart: string;
  }>;
};

export type ScheduledAppointment = {
  __typename?: "AppointmentObject";
  id: string;
  appointmentShortId: string;
  status: string;
  timeStart: string;
  timeEnd: string;
  length?: number | null;
  inOffice: AppointmentLocation;
  appointmentType: AppointmentType;
  videoLink?: string | null;
  patient: {
    __typename?: "PatientObject";
    id: string;
    name: string;
    customerId: string;
    isZocdocPatient?: boolean | null;
    isZocdocConfirmed?: boolean | null;
    preferredFullName?: string | null;
    pronouns?: string | null;
    shortId: string;
    latestIntakeFormResponse?: {
      __typename?: "FormResponseObject";
      id: string;
      reviewedAt?: string | null;
    } | null;
    nudges?: Array<{
      __typename?: "NudgeObject";
      nudgeSchema: {
        __typename?: "NudgeSchemaObject";
        trigger: NudgeSchemaTrigger;
      };
    }>;
  };
  recurringAppointmentGrouping?: RecurringAppointmentGrouping | null;
} | null;

export type ScheduledPatientInformation = SelectedVisitor & {
  appointmentType: AppointmentType;
  appointmentStartTime: string;
  appointmentEndTime: string;
  recurringAppointmentGrouping?: RecurringAppointmentGrouping | null;
  appointmentLocation: AppointmentLocation;
  appointmentId: string;
  appointmentShortId: string;
  patientId: string;
};

export type Appointments = Array<{
  __typename?: "AppointmentObjectEdge";
  node?: ScheduledAppointment;
} | null>;

export type GetUpcomingAppointmentsQuery = {
  __typename?: "Query";
  allAppointments?: {
    __typename?: "AppointmentObjectConnection";
    edges: Appointments;
  } | null;
};

/** Container type for DateTimeRange, mimicking the one previously autogenerated by ``graphene-sqlalchemy-filter``. */
export type DateTimeRange = {
  begin: Scalars["DateTime"]["input"];
  end: Scalars["DateTime"]["input"];
};
export type GetUpcomingAppointmentsQueryVariables = Exact<{
  filters?: InputMaybe<AppointmentsFilter>;
}>;
export type AppointmentsFilter = {
  canceled?: InputMaybe<Scalars["Boolean"]["input"]>;
  conclusiveSchedulingCheck?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeConsultationCalls?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasCheck?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideReviewedChecks?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeArchivedPatients?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeZocdocUnconfirmed?: InputMaybe<Scalars["Boolean"]["input"]>;
  parentPayor?: InputMaybe<Scalars["String"]["input"]>;
  patientIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  patientShortIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  schedulingCheck?: InputMaybe<Scalars["Boolean"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  statusClient?: InputMaybe<Scalars["String"]["input"]>;
  timeStartGte?: InputMaybe<Scalars["DateTime"]["input"]>;
  timeStartLt?: InputMaybe<Scalars["DateTime"]["input"]>;
  timeStartRange?: InputMaybe<DateTimeRange>;
  verified?: InputMaybe<Scalars["Boolean"]["input"]>;
};
