import { Button } from "@growtherapy/sprout-ui";
import classNames from "classnames";
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type Messages = {
  content: string;
  render?: ReactNode;
}[];

export type CannedMessagesProps = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "onSelect"
> & {
  messages: Messages;
  disabled?: boolean;
  onSelect: (message: string) => void;
};

export function CannedMessages({
  messages,
  onSelect,
  disabled,
  className,
  ...otherProps
}: CannedMessagesProps) {
  return (
    <div className={twMerge("flex flex-wrap gap-2", className)} {...otherProps}>
      {messages.map(({ content, render }, index) => (
        <Button
          key={content}
          disabled={disabled}
          className={classNames(
            { "hidden xs:block": index > 0 },
            "text-small m-0 min-w-fit px-3 py-2",
          )}
          use="secondary"
          onClick={() => onSelect(content)}
        >
          {render ?? content}
        </Button>
      ))}
    </div>
  );
}
