import {
  selectedVisitorNotesAtom,
  drawerStateAtom,
  ProviderDrawerState,
} from "../state";
import { NoteResponseType, NoteType, ProgressNoteSectionEnum } from "./types";
import { formatNotes } from "./utils";
import { useAtomValue, useSetAtom } from "jotai";
import { DrawerBody, Card, Icon, Text } from "@growtherapy/sprout-ui";
import { useGetCurrentNoteSchema } from "../useGetCurrentNoteSchema";
import { ClientInformationDrawerHeader } from "./ClientInformationDrawerHeader";
import { gracefullyParseJson } from "../../utils";

import { AppointmentDetails } from "./AppointmentDetails";
import { ClinicalPresentation } from "./ClinicalPresentation";
import { TherapistIntervention } from "./TherapistInterventions";
import { ClientProgress } from "./ClientProgress";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { faSpinnerThird as fadSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

type ClientInformationProgressNotesDrawerProps = {
  selectedVisitorNotes: Record<string, NoteType[]>;
  isLoading: boolean;
  isError: boolean;
};

export function ClientInformationProgressNotesDrawer({
  selectedVisitorNotes,
  isLoading,
  isError,
}: ClientInformationProgressNotesDrawerProps) {
  const setDrawerState = useSetAtom(drawerStateAtom);

  const renderNotesContent = (sectionTitle: string, notes: NoteType[]) => {
    switch (sectionTitle) {
      case ProgressNoteSectionEnum.APPOINTMENT_DETAIL:
        return <AppointmentDetails sectionTitle={sectionTitle} notes={notes} />;
      case ProgressNoteSectionEnum.CLINICAL_PRESENTATION:
        return (
          <ClinicalPresentation sectionTitle={sectionTitle} notes={notes} />
        );
      case ProgressNoteSectionEnum.THERAPIST_INTERVENTIONS:
        return (
          <TherapistIntervention sectionTitle={sectionTitle} notes={notes} />
        );
      case ProgressNoteSectionEnum.CLIENT_PROGRESS:
        return <ClientProgress sectionTitle={sectionTitle} notes={notes} />;
      default:
        return null;
    }
  };

  return (
    <>
      <ClientInformationDrawerHeader
        drawerTitle="Progress Notes"
        onClickBackButton={() => setDrawerState(ProviderDrawerState.CLIENT_INFORMATION)}
      />
      <DrawerBody>
        {isLoading ? (
          <div className="flex justify-center">
            <Icon
              aria-hidden={false}
              aria-label="Loading..."
              size="xl"
              data-testid="client-information.notes.loading"
              className="text-ivy-500 fa-spin"
              icon={fadSpinnerThird}
            />
          </div>
        ) : isError ? (
          <Card
            data-testid="client-information.notes.error"
            className="flex flex-row items-center"
          >
            <Icon
              aria-hidden
              icon={faWarning}
              className="mr-2"
              color="#CC6F52"
            />
            <Text variant="xs" className="text-left fs-exclude">
              Sorry, we’re unable to pull up this client’s information right
              now. If this issue persists, please reach out to support via the
              Provider Portal.
            </Text>
          </Card>
        ) : (
          <>
            {Object.entries(selectedVisitorNotes).map(
              ([sectionTitle, notes]) => (
                <>{renderNotesContent(sectionTitle, notes)}</>
              ),
            )}
          </>
        )}
      </DrawerBody>
    </>
  );
}

export function ClientInformationProgressNotesDrawerWrapper() {
  const selectedVisitorNotes = useAtomValue(selectedVisitorNotesAtom);
  const {
    data: noteSchemaData,
    loading,
    error,
  } = useGetCurrentNoteSchema(
    selectedVisitorNotes.patientId,
    selectedVisitorNotes.noteType,
  );

  const parsedNotes = gracefullyParseJson(
    selectedVisitorNotes.notes,
  ) as NoteResponseType;

  const formattedNotes = formatNotes(parsedNotes, noteSchemaData);

  return (
    <ClientInformationProgressNotesDrawer
      isLoading={loading}
      isError={!!error}
      selectedVisitorNotes={formattedNotes}
    />
  );
}
