import {
  AriaToastProps,
  ToastAria,
  useToast as useReactAriaToast,
} from "@react-aria/toast";
import { ToastState } from "@react-stately/toast";
import { RefObject, useCallback, useMemo, useRef } from "react";
import { isDeepEqual } from "remeda";
import { CustomToast, ModifiedToastAria } from "./types";

/**
 * Custom hook that wraps the `useToast` hook from `@react-aria/toast` that
 * memoizes the props returned by it in order to prevent re-renders when
 * invoking the custom toast render function. It also replaces the `onPress`
 * handler with an `onClick` handler to support the Sprout implementation of a
 * `Button`.
 *
 * ### From the `@react-aria/toast` documentation:
 *
 * Provides the behavior and accessibility implementation for a toast component.
 * Toasts display brief, temporary notifications of actions, errors, or other
 * events in an application.
 *
 * @returns A tuple containing stable references to the toast props and modified
 * aria props.
 */
export function useToast(
  props: AriaToastProps<CustomToast>,
  state: ToastState<CustomToast>,
  ref: RefObject<HTMLDivElement | null>,
): [ToastAria["toastProps"], ModifiedToastAria] {
  const previousToastPropsRef = useRef<ToastAria["toastProps"]>(null!);
  const previousAriaPropsRef = useRef<ModifiedToastAria | null>(null);

  const {
    toastProps,
    // NOTE: @react-aria operates on the assumption that buttons use an
    // `onPress` handler, not an `onClick` handler, but Sprout `Button`s expect
    // an `onClick` handler. @react-aria's event handler returned by this hook
    // does *not* actually use the press event parameter at all, it's only typed
    // as if it does:
    // https://github.com/adobe/react-spectrum/blob/cdba748760b1e30fb15414e8b585d05fd167819e/packages/%40react-aria/toast/src/useToast.ts#L102
    // As such, we can safely replace the event handler with an `onClick`
    // without passing any event through, which we do with the stabilized
    // `closeToast` function below.
    closeButtonProps: { onPress: _, ...closeButtonProps },
    ...rest
  } = useReactAriaToast(props, state, ref);

  const closeToast = useCallback(() => {
    state.close(props.toast.key);
  }, [props.toast.key, state]);

  const memoizedToastProps: [ToastAria["toastProps"], ModifiedToastAria] =
    useMemo(() => {
      if (previousToastPropsRef.current && previousAriaPropsRef.current) {
        const {
          closeButtonProps: { onClick: _, ...prevCloseButtonProps },
          ...prevRest
        } = previousAriaPropsRef.current;

        // If the props haven't changed, return the previous props.
        if (
          isDeepEqual(previousToastPropsRef.current, toastProps) &&
          isDeepEqual(prevRest, rest) &&
          isDeepEqual(prevCloseButtonProps, closeButtonProps)
        ) {
          return [previousToastPropsRef.current, previousAriaPropsRef.current];
        }
      }

      previousToastPropsRef.current = toastProps;
      previousAriaPropsRef.current = {
        ...rest,
        closeButtonProps: {
          ...closeButtonProps,
          onClick: closeToast,
        },
      };

      return [toastProps, previousAriaPropsRef.current];
    }, [rest, closeButtonProps, closeToast, toastProps]);

  return memoizedToastProps;
}
