import { useCallback, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { CSSRSPromptAlert } from "./CSSRSPromptAlert";
import { useAtomValue, useSetAtom } from "jotai";
import { ProviderDrawerState, drawerStateAtom } from "../state";
import { twilioRoomAtom } from "../../twilio";
import {
  MeasureBundleObject,
  shouldPromptForCSSRS,
} from "@grow-therapy-team/seedling-components";

export function useCSSRSPromptToastCallback() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  const twilioRoom = useAtomValue(twilioRoomAtom);
  const toastIdRef = useRef<string | null>(null);

  useEffect(
    function clearToastOnTwilioRoomDisconnect() {
      if (!twilioRoom && toastIdRef.current) {
        toast.remove(toastIdRef.current);
      }
    },
    [twilioRoom],
  );

  return useCallback(
    (
      visitorName: string,
      patientChart?: {
        preferredShortName?: string | null;
        measureBundles?: MeasureBundleObject[];
      } | null,
    ) => {
      if (!patientChart) {
        return;
      }

      if (
        !patientChart.measureBundles ||
        !shouldPromptForCSSRS(patientChart.measureBundles)
      ) {
        return;
      }

      // TODO: TOAST HOT
      toast.custom(
        ({ id: toastId }) => {
          toastIdRef.current = toastId;
          return (
            <CSSRSPromptAlert
              onClose={() => toast.remove(toastId)}
              patientName={patientChart.preferredShortName || visitorName}
              onAdministerCSSRS={() => {
                setDrawerState(ProviderDrawerState.CSSRS_FORM);
                toast.remove(toastId);
              }}
              className="bottom-center-toast max-w-4xl"
            />
          );
        },
        {
          duration: Infinity,
          position: "bottom-center",
        },
      );
    },
    [setDrawerState],
  );
}
