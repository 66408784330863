import {
  Text,
  Menu,
  MenuToggleButton,
  MenuToggleButtonProps,
  MenuItem,
  Icon,
  MenuDivider,
  MenuPlacement,
} from "@growtherapy/sprout-ui";
import { ThumbnailPosition } from "../twilio/state";
import {
  faArrowDownLeft,
  faArrowDownRight,
  faArrowsUpDownLeftRight,
  faArrowUpLeft,
  faArrowUpRight,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { SelfViewControlButton } from "./SelfViewControlButton";

interface PositionAttributes {
  placement: MenuPlacement;
  icon: IconDefinition;
  label: string;
}

const POSITION_ATTRIBUTES_BY_POSITION: Record<
  ThumbnailPosition,
  PositionAttributes
> = {
  [ThumbnailPosition.TOP_RIGHT]: {
    placement: "bottom end",
    icon: faArrowUpRight,
    label: "Top right corner",
  },
  [ThumbnailPosition.TOP_LEFT]: {
    placement: "bottom end",
    icon: faArrowUpLeft,
    label: "Top left corner",
  },
  [ThumbnailPosition.BOTTOM_LEFT]: {
    placement: "top end",
    icon: faArrowDownLeft,
    label: "Bottom left corner",
  },
  [ThumbnailPosition.BOTTOM_RIGHT]: {
    placement: "top end",
    icon: faArrowDownRight,
    label: "Bottom right corner",
  },
};

export function SetPositionMenu({
  position,
  setPosition,
  isMobile,
}: {
  position: ThumbnailPosition;
  setPosition: (thumbnailPosition: ThumbnailPosition) => void;
  isMobile: boolean;
}) {
  const currentPosition = POSITION_ATTRIBUTES_BY_POSITION[position];
  const positionChoices = Object.entries(
    POSITION_ATTRIBUTES_BY_POSITION,
  ).filter(([thumbnailPosition, _]) => thumbnailPosition != position);

  return (
    <Menu
      className="absolute bottom-2 right-2"
      placement={currentPosition.placement}
      toggleButton={
        <SelfViewControlButton<MenuToggleButtonProps>
          as={MenuToggleButton}
          buttonClassName="group/move-button"
          icon={faArrowsUpDownLeftRight}
          label="Move video"
          labelClassName="group-hover/move-button:block group-focus/move-button:block"
          labelIsVisible={!isMobile}
          screenReaderLabel="Change video position"
          visibleCaret={false}
        />
      }
    >
      <Text as="span" className="px-4 pt-1" variant="sm" aria-hidden>
        Move video
      </Text>
      <MenuDivider />
      <>
        {positionChoices.map(([thumbnailPosition, positionAttributes]) => (
          <MenuItem
            key={thumbnailPosition}
            onClick={() => setPosition(thumbnailPosition as ThumbnailPosition)}
            className="flex items-center gap-2"
          >
            <Icon
              aria-hidden
              icon={positionAttributes.icon}
              className="w-4 h-4"
            />
            <span className="sr-only">Move video to</span>
            {positionAttributes.label}
          </MenuItem>
        ))}
      </>
    </Menu>
  );
}
