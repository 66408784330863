import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  ProviderDrawerState,
  drawerStateAtom,
  selectedBundleAtom,
  selectedClientInfoAtom,
  selectedMeasureBundleItemIdAtom,
} from "../state";
import { useMemo, useState } from "react";
import {
  MICMeasureBundleResponses,
  MeasureBundlesListConnected,
  findMostRecentCompletedPHQBundle,
  shouldPromptForCSSRS,
} from "@grow-therapy-team/seedling-components";
import { DrawerBody, toast } from "@growtherapy/sprout-ui";
import { ClientInformationDrawerHeader } from "../client-information/ClientInformationDrawerHeader";
import { useGetPatientByShortId } from "../useGetPatientByShortId";
import { CSSRSPromptAlert } from "./CSSRSPromptAlert";
import { logger } from "../../datadog/logger";

export function MeasureResponses() {
  const measureBundle = useAtomValue(selectedBundleAtom);
  const measureBundleItemId = useAtomValue(selectedMeasureBundleItemIdAtom);
  const selectedClientInfo = useAtomValue(selectedClientInfoAtom);
  const patientShortId = selectedClientInfo?.patientShortId;
  const [cssrsAlertIsDismissed, setCssrsAlertIsDismissed] = useState(false);
  const { data } = useGetPatientByShortId(patientShortId);
  const measureBundles = data?.patientChart?.measureBundles;
  const preferredFirstName =
    selectedClientInfo?.preferredName?.patientFirstName;
  const setDrawerState = useSetAtom(drawerStateAtom);

  const shouldPromptForCssrs = shouldPromptForCSSRS(measureBundles);
  const mostRecentPHQ9bundle = findMostRecentCompletedPHQBundle(
    measureBundles ?? [],
  );

  //only show the alert if the selected bundle is the most recent PHQ9 bundle
  const isMostRecentPHQ9Bundle =
    mostRecentPHQ9bundle?.measureBundleShortId ===
    measureBundle?.measureBundleShortId;

  const shouldShowCssrsAlert =
    !cssrsAlertIsDismissed && shouldPromptForCssrs && isMostRecentPHQ9Bundle;

  const onAdministerCSSRS = () => {
    setDrawerState(ProviderDrawerState.CSSRS_FORM);
  };

  const onNavigateToPaperworkFlow = () => {
    setDrawerState(ProviderDrawerState.PENDING_MEASURES);
  };

  if (!measureBundle) return null;

  return (
    <MICMeasureBundleResponses
      cssrsAlert={
        shouldShowCssrsAlert ? (
          <CSSRSPromptAlert
            onClose={() => setCssrsAlertIsDismissed(true)}
            patientName={preferredFirstName ?? ""}
            onAdministerCSSRS={onAdministerCSSRS}
          />
        ) : undefined
      }
      initialSelectedMeasureBundleItemId={measureBundleItemId}
      measureBundle={measureBundle}
      onNavigateToPaperworkFlow={onNavigateToPaperworkFlow}
    />
  );
}

function MeasureBundlesList() {
  const setSelectedBundle = useSetAtom(selectedBundleAtom);
  const patientShortId = useAtomValue(selectedClientInfoAtom)?.patientShortId;

  if (!patientShortId) return null;

  return (
    <MeasureBundlesListConnected
      patientChartQueryOptions={{
        onError: (error) => {
          logger.error(
            "Unable to fetch measure bundles list",
            { patientShortId },
            error,
          );
          // TODO: TOAST SPROUT
          toast.error("Unable to fetch measures. Please try again later.");
        },
      }}
      cssrsMeasureEnabled
      patientShortId={patientShortId}
      onClickBundle={(measureBundle) => {
        setSelectedBundle(measureBundle);
      }}
    />
  );
}

function DrawerHeader() {
  const preferredFirstName = useAtomValue(selectedClientInfoAtom)?.preferredName
    ?.patientFirstName;
  const [selectedBundle, setSelectedBundle] = useAtom(selectedBundleAtom);
  const setSelectedMeasureBundleItem = useSetAtom(
    selectedMeasureBundleItemIdAtom,
  );
  const setDrawerState = useSetAtom(drawerStateAtom);
  const sentAt = selectedBundle?.sentAt;

  const drawerTitle = useMemo(() => {
    if (sentAt) {
      return `Check-in from ${new Date(sentAt).toLocaleDateString(undefined, {
        month: "long",
        day: "numeric",
        year: "numeric",
      })}`;
    }
    if (preferredFirstName) {
      return `${preferredFirstName}'s Measures`;
    }
    return "Measures";
  }, [sentAt, preferredFirstName]);

  const goToPrevious = () => {
    if (selectedBundle) {
      setSelectedBundle(undefined);
      setSelectedMeasureBundleItem(undefined);
      return;
    }

    setDrawerState((prev) => {
      if (prev === ProviderDrawerState.MEASURE_RESPONSES) {
        return ProviderDrawerState.CLIENT_INFORMATION;
      }

      return ProviderDrawerState.MEASURE_RESPONSES;
    });
  };

  return (
    <ClientInformationDrawerHeader
      drawerTitle={drawerTitle}
      onClickBackButton={goToPrevious}
    />
  );
}

export function MeasureResponsesDrawer() {
  const hasSelectedBundle = !!useAtomValue(selectedBundleAtom);

  return (
    <>
      <DrawerHeader />
      <DrawerBody>
        {hasSelectedBundle ? <MeasureResponses /> : <MeasureBundlesList />}
      </DrawerBody>
    </>
  );
}
