import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@growtherapy/sprout-ui";

export function TakeOverScreenShareModal({
  isModalOpen,
  onCloseModal,
  onConfirmShare,
}: {
  isModalOpen: boolean;
  onCloseModal: () => void;
  onConfirmShare: () => void;
}) {
  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onCloseModal}
      onOutsideClick={onCloseModal}
    >
      <ModalHeader>Stop other’s screen share?</ModalHeader>
      <ModalBody>
        Sharing your screen will stop the other person’s screen share. Do you
        want to continue?
      </ModalBody>
      <ModalFooter
        actions={[
          {
            key: "cancel",
            use: "secondary",
            onClick: onCloseModal,
            actionContent: "Cancel",
          },
          {
            key: "confirm",
            use: "primary",
            onClick: onConfirmShare,
            actionContent: "Yes, share my screen",
          },
        ]}
      />
    </Modal>
  );
}
