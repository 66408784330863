import {
  faPause,
  faPlay,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { LottieRef } from "lottie-react";
import { useCallback } from "react";
import { useLocalStorage } from "usehooks-ts";
import { ActionType } from "./types";

export function getNextAction(currentAction: ActionType): ActionType {
  return currentAction === ActionType.PAUSE
    ? ActionType.PLAY
    : ActionType.PAUSE;
}

export const ANIMATION_ACTION_ICONS: Record<ActionType, IconDefinition> = {
  Play: faPlay,
  Pause: faPause,
};

const DEFAULT_STORED_ANIMATION_STATE = "Unset";
const STORED_ANIMATION_STATE_KEY = "background-animation-state";
type StoredAnimationState = ActionType | typeof DEFAULT_STORED_ANIMATION_STATE;

function isValidAnimationState(action: string): action is ActionType {
  return action === ActionType.PLAY || action === ActionType.PAUSE;
}

const lottieRef: LottieRef = {
  current: null,
};

/**
 * Performs the action on the animation item. Returns `true` if the action was
 * performed.
 */
function doAnimationAction(lottieRef: LottieRef, action: ActionType) {
  if (!lottieRef.current || !lottieRef.current?.animationItem) return false;
  const animationItem = lottieRef.current.animationItem;
  switch (action) {
    case ActionType.PLAY:
      animationItem.play();
      break;
    case ActionType.PAUSE:
      animationItem.pause();
      break;
  }
  return true;
}

const animationStateAtom = atom<ActionType | null>(null);

function getStoredAnimationState(): StoredAnimationState {
  const storedState = localStorage.getItem(
    STORED_ANIMATION_STATE_KEY,
  ) as StoredAnimationState;
  if (!storedState) return "Unset";
  return storedState;
}

function defaultAnimationState(state: StoredAnimationState): ActionType {
  return isValidAnimationState(state) ? state : ActionType.PLAY;
}

export const useAnimationState = () => {
  // We record to local storage when a user toggles the animation state
  // themselves, to respect their preference across sessions and during
  // interactions where we programmatically pause and play the animation.
  const [storedAnimationState, setStoredAnimationState] =
    useLocalStorage<StoredAnimationState>(STORED_ANIMATION_STATE_KEY, "Unset");

  const currentAnimationState = defaultAnimationState(
    useAtomValue(animationStateAtom) ?? storedAnimationState,
  );

  // Used to get the current state of the animation in callbacks
  const getCurrentAnimationState = useAtomCallback(
    useCallback((get) => {
      return defaultAnimationState(
        get(animationStateAtom) ?? getStoredAnimationState(),
      );
    }, []),
  );
  const setAnimationJotaiState = useSetAtom(animationStateAtom);
  const setCurrentAnimationState = useCallback(
    ({ state, shouldStore }: { state: ActionType; shouldStore: boolean }) => {
      setAnimationJotaiState(state);
      if (shouldStore) {
        setStoredAnimationState(state);
      }
    },
    [setAnimationJotaiState, setStoredAnimationState],
  );

  // Toggle is always initiated by the user, so we update the stored state
  // when it is called.
  const toggle = useCallback(() => {
    const currentAnimationState = getCurrentAnimationState();
    const nextAnimationState = getNextAction(currentAnimationState);
    doAnimationAction(lottieRef, nextAnimationState);
    setCurrentAnimationState({ state: nextAnimationState, shouldStore: true });
  }, [getCurrentAnimationState, setCurrentAnimationState]);

  // Pause is only initiated programmatically during other interactions, such as
  // the drawer opening.
  const pause = useCallback(() => {
    doAnimationAction(lottieRef, ActionType.PAUSE);
    setCurrentAnimationState({ state: ActionType.PAUSE, shouldStore: false });
  }, [setCurrentAnimationState]);

  // Play is only initiated programmatically during other interactions, such as
  // the drawer closing.
  const play = useCallback(() => {
    // We will respect the user's preference to pause the animation and not
    // programmatically resume it if they intentionally paused it.
    if (getStoredAnimationState() === ActionType.PLAY) return;
    doAnimationAction(lottieRef, ActionType.PLAY);
    setCurrentAnimationState({ state: ActionType.PLAY, shouldStore: false });
  }, [setCurrentAnimationState]);

  return {
    lottieRef,
    toggle,
    pause,
    play,
    currentState: currentAnimationState,
  };
};
