import { Button } from "@growtherapy/sprout-ui";
import { useSetAtom } from "jotai";
import { useCallback } from "react";
import type { PageComponentProps } from "../../../../components";
import { visitorDrawerStateAtom } from "../../../../visitor/state";
import { useMoodCheckinSelectionsState } from "./useMoodCheckinSelectionsState";
import { useSyncMoodCheckinChoicesCallback } from "./useSyncMoodCheckinChoicesCallback";
import { usePreSessionPromptState } from "../../../waiting-room/usePreSessionPromptState";

export function Actions({
  isFirstPage,
  prevPage,
}: PageComponentProps<string[]>) {
  const [selections] = useMoodCheckinSelectionsState();
  const onSyncChoices = useSyncMoodCheckinChoicesCallback();
  const setDrawerState = useSetAtom(visitorDrawerStateAtom);
  const { clearPreSessionPrompt } = usePreSessionPromptState();

  const onSaveAndCloseDrawer = useCallback(() => {
    onSyncChoices(selections, {
      onCompleted() {
        setDrawerState(null);
        clearPreSessionPrompt();
      },
    });
  }, [onSyncChoices, selections, setDrawerState, clearPreSessionPrompt]);

  return (
    <>
      {!isFirstPage && (
        <Button onClick={prevPage} use="secondary">
          Previous
        </Button>
      )}
      <Button onClick={onSaveAndCloseDrawer}>Save & Close</Button>
    </>
  );
}
