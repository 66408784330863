import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { MenuItem } from "@growtherapy/sprout-ui";
import { ComponentProps, useCallback } from "react";
import { VisitorDrawerState, visitorDrawerStateAtom } from "../state";
import { useAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { IconMenuItem } from "../../components";
import { SettingsDrawerControl } from "../../twilio";

export type VisitorSettingsDrawerMenuItemProps = {
  drawerId: string;
  isSettingsDrawerOpen: boolean;
  className?: string;
} & Pick<ComponentProps<typeof MenuItem>, "onClick">;

export function VisitorSettingsDrawerControlMenuItem({
  className,
  drawerId,
  isSettingsDrawerOpen,
  onClick,
}: VisitorSettingsDrawerMenuItemProps) {
  return (
    <IconMenuItem
      aria-controls={drawerId}
      aria-expanded={isSettingsDrawerOpen}
      onClick={onClick}
      className={className}
      icon={faGear}
    >
      <span className="sr-only">
        {isSettingsDrawerOpen ? "Close" : "Open"} settings
      </span>
      <span aria-hidden>Settings</span>
    </IconMenuItem>
  );
}

export function VisitorSettingsDrawerControlMenuItemWrapper(
  props: Pick<VisitorSettingsDrawerMenuItemProps, "drawerId">,
) {
  const { isSettingsDrawerOpen, toggleDrawerState } =
    useVisitorDrawerControls();
  return (
    <VisitorSettingsDrawerControlMenuItem
      drawerId={props.drawerId}
      isSettingsDrawerOpen={isSettingsDrawerOpen}
      onClick={toggleDrawerState}
    />
  );
}

export function VisitorSettingsDrawerButton(
  props: Pick<VisitorSettingsDrawerMenuItemProps, "drawerId">,
) {
  const { isSettingsDrawerOpen, toggleDrawerState } =
    useVisitorDrawerControls();
  return (
    <SettingsDrawerControl
      forDrawerStates={VisitorDrawerState.SETTINGS}
      isSettingsDrawerOpen={isSettingsDrawerOpen}
      onClick={toggleDrawerState}
      drawerId={props.drawerId}
    />
  );
}

function useVisitorDrawerControls() {
  const [drawerState, setDrawerState] = useAtom(visitorDrawerStateAtom);
  const isSettingsDrawerOpen = drawerState === VisitorDrawerState.SETTINGS;
  const getDrawerState = useAtomCallback(
    useCallback((get) => get(visitorDrawerStateAtom), []),
  );
  const toggleDrawerState = () => {
    const currentDrawerState = getDrawerState();
    if (currentDrawerState === VisitorDrawerState.SETTINGS) {
      setDrawerState(null);
      return;
    }
    setDrawerState(VisitorDrawerState.SETTINGS);
  };

  return {
    isSettingsDrawerOpen,
    toggleDrawerState,
  };
}
