import toast from "react-hot-toast";
import { useAtomValue } from "jotai";
import { useState, useEffect } from "react";
import { RequestingClientConsentToast } from "./RequestingClientConsentToast";
import { patientConsentAtom } from "../../twilio/messages/state";
import { ConsentStatus } from "../../twilio/types";

export function useRequestingClientConsentToast() {
  const [toastId, setToastId] = useState<string | undefined>();
  const patientConsent = useAtomValue(patientConsentAtom).value;
  const requestingClientConsent = patientConsent === ConsentStatus.DECIDING;

  useEffect(() => {
    if (requestingClientConsent && !toastId) {
      // TODO: TOAST HOT
      const id = toast.custom(RequestingClientConsentToast, {
        position: "bottom-center",
        duration: Infinity,
      });
      setToastId(id);
    } else if (!requestingClientConsent && toastId) {
      toast.remove(toastId);
      setToastId(undefined);
    }
  }, [requestingClientConsent, toastId]);
}
