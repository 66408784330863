import { EndSessionButton } from "../components";
import { TrackingEvents, sendLoggingEvents } from "../events";
import { useCompleteTwilioSessionCallback } from "./useCompleteTwilioSessionCallback";
import { hasRecordingAtom } from "./session-recording/state";
import { useShouldDisableTranscriptionForMultipleParticipants } from "../twilio/controls/useShouldDisableTranscriptionForMultipleParticipants";
import { useSetAtom } from "jotai";
import { useSessionSummaryNudgeCount } from "./useSessionSummaryNudgeCount";
import { toast } from "../toasts";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";

export function CompleteSessionButton() {
  /**
   * The shouldDisableTranscription hook needs to run before useCompleteTwilioSessionCallback here
   * because the latter hook will clean up all participants. This would cause it to always
   * resolve to as non-multiparticipant session.
   **/
  const shouldDisableTranscription =
    useShouldDisableTranscriptionForMultipleParticipants();
  const completeSession = useCompleteTwilioSessionCallback();

  const getHasRecording = useAtomCallback(
    useCallback((get) => get(hasRecordingAtom), []),
  );
  const setHasRecording = useSetAtom(hasRecordingAtom);
  const { disable: disableFutureSessionSummaryNudges } =
    useSessionSummaryNudgeCount();
  return (
    <EndSessionButton
      onClick={() => {
        sendLoggingEvents(TrackingEvents.PROVIDER_SESSION_COMPLETE);
        completeSession();
        disableFutureSessionSummaryNudges();
        toast.success(
          getHasRecording() && !shouldDisableTranscription
            ? "Session completed. Transcription processing in progress."
            : "Session completed.",
          { position: "bottom-center", onClose: () => setHasRecording(false) },
        );
      }}
      aria-label="Complete session"
    >
      Complete Session
    </EndSessionButton>
  );
}
