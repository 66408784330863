import React from "react";
import classNames from "classnames";
import { DEFAULT_POSITION, queues } from "./queues";
import {
  BasicToastOptions,
  CustomToast,
  ToastOptions,
  ToastRenderFn,
  ToastVariant,
} from "./types";
import { Toast } from "./Toast";
import { getQueueForWindowSize } from "./util";

/**
 * Enqueues a toast with the provided render function and options. By default, a
 * toast will be added to the `DEFAULT_POSITION` queue, which is
 * `bottom-center`.
 *
 * A toast which is enqueued may not be shown immediately, if there are already
 * `MAX_VISIBLE_TOASTS` visible.
 *
 * When the window is smaller, all bottom toasts will be added to the center
 * queue to ensure they don't overlap.
 *
 * @param renderFn a function that takes the aria props and returns a React node
 * to render the toast
 * @param options the options to pass to the toast
 * @see {@link ToastOptions} for the options available
 * @return the unique key of the toast, or undefined if no new toast was added
 */
function enqueue(
  renderFn: ToastRenderFn,
  _options: ToastOptions & { key: string },
): string | undefined;
function enqueue(renderFn: ToastRenderFn, _options?: ToastOptions): string;
function enqueue(
  renderFn: ToastRenderFn,
  _options?: ToastOptions,
): string | undefined {
  // Deduplicate toasts with the same key.
  if (_options?.key) {
    const visibleKeys = new Set(
      Object.values(queues).flatMap((queue) =>
        queue.visibleToasts.map((toast) => toast.content.key),
      ),
    );
    if (visibleKeys.has(_options?.key)) {
      return;
    }
  }

  const {
    position = DEFAULT_POSITION,
    key,
    tags = new Set<string>(),
    ...options
  } = _options ?? {};

  // @react-aria/toast does not treat `Infinity` as a valid timeout, so we
  // convert it to `undefined` to create the same effect (disable the timeout).
  if (options.timeout === Infinity) options.timeout = undefined;

  const toast: CustomToast = {
    key,
    renderFn,
    tags,
  };

  const queue = getQueueForWindowSize(position);
  return queue.add(toast, options);
}

/**
 * Small, internal utility to enqueue a basic toast with a single content node,
 * using the provided options and variant. Builds a renderFn for the content
 * using the appropriate aria props. By default, the toast will be dismissible.
 *
 * @param content the content to display in the body of the toast, as a title
 * @param options the options to pass to the toast
 * @param variant the visual variant of the toast
 * @returns the key of the resultant toast
 */
function addBasic(
  content: React.ReactNode,
  variant: ToastVariant,
  options: BasicToastOptions,
) {
  const { isDismissible, isMasked, position } = options;
  const title = variant[0].toUpperCase() + variant.slice(1).toLowerCase();
  return enqueue(
    ({
      closeButtonProps,
      contentProps,
      descriptionProps,
      titleProps,
      ...toastAriaProps
    }) => (
      <Toast
        {...toastAriaProps}
        className={classNames({
          "sm:w-md": position !== "top",
          // Toasts at the top position are wider to function more like banners,
          // since they are always and only shown in the middle.
          "sm:w-max sm:min-w-[33rem] sm:max-w-[38rem] md:max-w-[52rem]":
            position === "top",
        })}
        closeButtonProps={isDismissible ? closeButtonProps : undefined}
        variant={variant}
      >
        <div {...contentProps}>
          <span className="sr-only" {...titleProps}>{`${title}:`}</span>
          <span
            {...descriptionProps}
            data-dd-privacy={isMasked ? "mask" : undefined}
            className={classNames({ "fs-exclude": isMasked })}
          >
            {content}
          </span>
        </div>
      </Toast>
    ),
    options,
  );
}

const DEFAULT_BASIC_OPTIONS: Partial<BasicToastOptions> = {
  isDismissible: true,
  isMasked: false,
  position: DEFAULT_POSITION,
  timeout: 10000,
};

function mergeOptions(
  options: ToastOptions = {},
  defaults: Partial<ToastOptions> = DEFAULT_BASIC_OPTIONS,
): ToastOptions {
  return { ...defaults, ...options };
}

/**
 * Enqueues a success-style toast with the provided content and options. By
 * default, the toast will be dismissible, will auto-dismiss after 10s, and will
 * be added to the `DEFAULT_POSITION` queue, which is `bottom-center`.
 *
 * @param content the content to display in the body of the toast, as a title
 * @param options the options to pass to the toast
 * @returns the key of the resultant toast
 */
function success(content: React.ReactNode, options?: BasicToastOptions) {
  return addBasic(content, ToastVariant.Success, mergeOptions(options));
}

/**
 * Enqueues an error-style toast with the provided content and options. By
 * default, the toast will be dismissible, will auto-dismiss after 10s, and will
 * be added to the `DEFAULT_POSITION` queue, which is `bottom-center`.
 *
 * @param content the content to display in the body of the toast, as a title
 * @param options the options to pass to the toast
 * @returns the key of the resultant toast
 */
function error(content: React.ReactNode, options?: BasicToastOptions) {
  return addBasic(content, ToastVariant.Error, mergeOptions(options));
}

/**
 * Enqueues an info-style toast with the provided content and options. By
 * default, the toast will be dismissible, will auto-dismiss after 10s, and will
 * be added to the `DEFAULT_POSITION` queue, which is `bottom-center`.
 *
 * @param content the content to display in the body of the toast, as a title
 * @param options the options to pass to the toast
 * @returns the key of the resultant toast
 */
function info(content: React.ReactNode, options?: BasicToastOptions) {
  return addBasic(content, ToastVariant.Info, mergeOptions(options));
}

/**
 * Closes all toasts that were labeled with the provided tag. No-op if no toasts
 * are found with the provided tag.
 *
 * @param tag the tag to match against
 */
function closeByTag(tag: string) {
  Object.values(queues).forEach((queue) =>
    queue.visibleToasts.forEach((toast) => {
      if (toast.content.tags.has(tag)) {
        queue.close(toast.key);
      }
    }),
  );
}

/**
 * Closes a single toast by its key. No-op if no toast with this key is found.
 *
 * @param key The key of the toast to close. Supports both keys automatically
 * supplied by `react-aria` as well as custom keys provided by the caller in the
 * `ToastOptions`.
 */
function close(key: string) {
  Object.values(queues).forEach((queue) => {
    // Try to close the toast with the provided internal key.
    queue.close(key);
    // Also look for a toast with a custom key.
    const toastWithCustomKey = queue.visibleToasts.find(
      (toast) => toast.content.key === key,
    );
    // If we find one, close it.
    if (toastWithCustomKey) {
      queue.close(toastWithCustomKey.key);
    }
  });
}

const toast = {
  enqueue,
  success,
  error,
  info,
  close,
  closeByTag,
};

export default toast;
