import { Menu, MenuItem, MenuToggleButton } from "@growtherapy/sprout-ui";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { ComponentProps, ReactElement } from "react";
import { ControlPanelButton } from "./ControlPanelButton";

export type MenuChild = ReactElement<ComponentProps<typeof MenuItem>>;

export type AdditionalOptionsMenuProps = {
  shouldShowVisualIndicator: boolean;
  children?: ComponentProps<typeof Menu>["children"];
};

export function AdditionalOptionsMenu({
  children = [],
  shouldShowVisualIndicator,
}: AdditionalOptionsMenuProps) {
  return (
    <Menu
      placement="top"
      toggleButton={
        <MenuToggleButton<typeof ControlPanelButton>
          as={ControlPanelButton}
          visibleCaret={false}
          showVisualIndicator={shouldShowVisualIndicator}
          aria-label="View additional options"
          iconDefinition={faEllipsis}
          containerClassName="md:hidden"
          shouldOverrideClassName
        />
      }
    >
      {children}
    </Menu>
  );
}
