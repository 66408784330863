import { Theme } from "../state";
import {
  Navbar,
  PageContainer,
  PageProviders,
  PushDrawerContainer,
  StageContainer,
  useDetectStaleVersionInterval,
} from "../components";
import RebrandBackground from "../assets/provider-bg.svg?react";
import { ProviderAvatarWrapper as ProviderAvatar } from "./ProviderAvatar";
import { SelfVideoThumbnail } from "./SelfThumbnailVideo";
import { useProviderTwilioRoomListeners } from "./useProviderTwilioRoomListeners";
import { ProviderGreetingWrapper as ProviderGreeting } from "./ProviderGreeting";
import { SchedulePreviewWrapper as SchedulePreview } from "./schedule-preview/SchedulePreview";
import { useAtomValue, useSetAtom } from "jotai";
import {
  isMediaShareModeAtom,
  participantCountAtom,
  twilioRoomAtom,
  useHandleDeviceChanges,
  useInitLocalMediaTracks,
} from "../twilio";
import { useVisitorPresenceMap } from "./useVisitorPresenceMap";
import { StartNewSessionConfirmationModalWrapper as StartNewSessionConfirmationModal } from "./clients-drawer/StartNewSessionConfirmationModal";
import { useRecordUnaccompaniedProviderInterval } from "./useRecordUnaccompaniedProviderInterval";
import { useNetworkQualityLogging } from "../twilio/useNetworkQualityLogging";
import { nonEssentialElementProps, useSessionFocus } from "../hooks";
import { useInitProviderGlobalDDLoggerContext } from "./useInitProviderGlobalDDLoggerContext";
import { useResetVideoOnMobileNavigation } from "../twilio/useResetVideoOnMobileNavigation";
import { useInitProviderUserTracking } from "./useInitProviderUserTracking";
import { InSessionTimerWrapper as InSessionTimer } from "./InSessionTimer";
import { useMaxDurationWarning } from "./useMaxDurationWarning";
import { SessionGrid } from "../twilio/session-grid/SessionGrid";
import { useInitProviderChatClient } from "./chat";
import {
  ProviderDrawerState,
  drawerStateAtom,
  inSessionPatientInformationAtom,
  providerShortIdAtom,
} from "./state";
import { PushDrawerWrapper as PushDrawer } from "./PushDrawer";
import {
  ComponentProps,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { HelpModalWrapper as HelpModal } from "./HelpModal";
import { useShowNotificationInFaviconAndTitle } from "./useUpdateFaviconAndTitle";
import { TrackingEvents, sendLoggingEvents } from "../events";
import { useInitTwilioSyncClient } from "./sync";
import classNames from "classnames";
import { useUpdateTrackStatsInterval } from "../twilio/stats/useUpdateTrackStatsInterval";
import { useAnnouncementToast } from "./useAnnouncementToast";
import { useInactiveTabPerformanceOptimization } from "../hooks/useInactiveTabPerformanceOptimization";
import { usePromptFollowUpToast } from "./schedule-appointment/usePromptFollowUpToast";
import { EventTypeEnum } from "../segment/types";
import { useWaitingRoomTrackingEvent } from "../hooks/useWaitingRoomTrackingEvent";
import { useSyncMoodCheckin } from "./measures/useSyncMoodCheckin";
import { RemoteParticipant } from "./RemoteParticipant";
import { TranscriptionPillWrapper } from "./session-recording/TranscriptionPill";
import { AvsCtaPillWrapper as AvsCtaPill } from "./after-visit-summary/AvsCtaPill";
import { usePeriodicallySendParticipantState } from "../twilio/messages/usePeriodicallySendParticipantState";
import { useSendProviderParticipantStateCallback } from "./messages/useSendProviderParticipantStateCallback";
import { ProviderWhiteboard } from "./ProviderWhiteboard";
import { useObscuredProviderContentProps } from "./a11y";
import { PUSH_DRAWER_ARIA_CONTROLS_ID } from "./constants";
import { ProviderSessionControlsWrapper as SessionControls } from "./controls";

function Background() {
  return (
    <RebrandBackground
      className="pointer-events-none bg-neutral-900 absolute w-screen h-screen"
      aria-hidden
    />
  );
}

function InSessionStageEffects() {
  const sendParticipantState = useSendProviderParticipantStateCallback();
  usePeriodicallySendParticipantState(sendParticipantState);
  return null;
}

export function MobileSelfVideoThumbnail() {
  return (
    <SelfVideoThumbnail
      className="self-center relative my-10 sm:hidden"
      shouldShowHideSelf={false}
      showExpandVideoToggle={false}
      showSetPositionMenu={false}
      videoClassName="min-h-[14rem] max-h-[18rem] min-w-[90vw] max-w-screen-sm"
    />
  );
}

export function DesktopSelfVideoThumbnail(
  props: Partial<ComponentProps<typeof SelfVideoThumbnail>>,
) {
  const inSession = !!useAtomValue(participantCountAtom);
  const baseClassName = "absolute";
  const variableProps = inSession
    ? {
        className: `${baseClassName} bottom-14`,
        showExpandVideoToggle: false,
      }
    : {
        className: `${baseClassName} hidden sm:inline`,
        showExpandVideoToggle: true,
      };
  const isMediaShareMode = useAtomValue(isMediaShareModeAtom);

  if (isMediaShareMode) return null;

  return (
    <SelfVideoThumbnail
      shouldShowHideSelf
      showSetPositionMenu
      {...variableProps}
      {...props}
    />
  );
}

export const Stage = memo(function Stage() {
  const inSession = !!useAtomValue(participantCountAtom);
  const isMediaShareMode = useAtomValue(isMediaShareModeAtom);
  const obscuredContentProps = useObscuredProviderContentProps();

  const thumbnail = useMemo(
    () => (
      <SelfVideoThumbnail
        showExpandVideoToggle={false}
        shouldShowHideSelf={false}
        showSetPositionMenu={false}
        className={classNames({
          "flex-1 max-w-[15rem] min-w-[10rem] sm:min-w-[unset] max-h-[8.5rem] min-h-[7.875rem] w-auto h-full sm:mb-2":
            isMediaShareMode,
        })}
        videoClassName="max-w-[15rem] max-h-[8.5rem] min-w-[unset] w-auto h-full [&>video]:relative mb-2 mr-2 sm:mr-0 flex-1 border rounded-2xl border-neutral-800"
      />
    ),
    [isMediaShareMode],
  );

  return (
    <StageContainer {...obscuredContentProps} className="@container" rounded>
      {inSession ? (
        <>
          <InSessionStageEffects />
          <SessionGrid
            remoteParticipantComponent={RemoteParticipant}
            thumbnail={thumbnail}
            whiteboardComponent={ProviderWhiteboard}
          />
          <InSessionTimer />
          <DesktopSelfVideoThumbnail />
        </>
      ) : (
        <>
          <Background />
          <DesktopSelfVideoThumbnail />
          <div
            className="relative h-full w-full flex flex-col overflow-y-auto overflow-x-hidden p-8"
            // The main page content is a scrollable container that potentially
            // does not contain any interactive elements. The tabindex is added
            // to ensure that the container is focusable and can be scrolled
            // using the keyboard.
            // See:
            // - https://www.a11yproject.com/posts/how-to-use-the-tabindex-attribute/#scrollable-overflow-containers
            // - https://chromestatus.com/feature/5231964663578624
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
          >
            <ProviderAvatar />
            <MobileSelfVideoThumbnail />
            <div className="flex flex-col flex-grow items-center justify-center">
              <ProviderGreeting className="mb-8" />
              <SchedulePreview />
              <AvsCtaPill className="mt-6" />
            </div>
          </div>
        </>
      )}
    </StageContainer>
  );
});

function PageEffects() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  useInitLocalMediaTracks();
  useHandleDeviceChanges();
  useInitTwilioSyncClient();
  useVisitorPresenceMap();
  useSyncMoodCheckin();
  useProviderTwilioRoomListeners();
  useRecordUnaccompaniedProviderInterval();
  useNetworkQualityLogging({
    onOpenSettings: () => setDrawerState(ProviderDrawerState.SETTINGS),
  });
  useSessionFocus();
  useInitProviderGlobalDDLoggerContext();
  useInitProviderUserTracking();
  useResetVideoOnMobileNavigation();
  useMaxDurationWarning();
  useDetectStaleVersionInterval(!useAtomValue(twilioRoomAtom));
  useShowNotificationInFaviconAndTitle();
  useInitProviderChatClient();
  useUpdateTrackStatsInterval();
  useAnnouncementToast();

  useInactiveTabPerformanceOptimization();
  const providerShortId = useAtomValue(providerShortIdAtom);
  useWaitingRoomTrackingEvent(
    EventTypeEnum.PROVIDER_WAITING_ROOM,
    providerShortId,
  );

  return null;
}

export function Page() {
  const inSessionPatientInformation = useAtomValue(
    inSessionPatientInformationAtom,
  );

  const providerShortId = useAtomValue(providerShortIdAtom);
  const inSession = !!useAtomValue(participantCountAtom);
  const obscuredContentProps = useObscuredProviderContentProps();

  usePromptFollowUpToast(
    providerShortId,
    inSessionPatientInformation?.patientShortId,
  );

  useEffect(
    function sendLoggingEventsOnPageLoad() {
      if (inSession) {
        // TODO migrate to segment
        sendLoggingEvents(TrackingEvents.PROVIDER_ENTER_SESSION_ROOM);
      }
    },
    [inSession],
  );

  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const openHelpModal = useCallback(() => {
    sendLoggingEvents(TrackingEvents.ANY_HELP_REQUEST);
    sendLoggingEvents(TrackingEvents.PROVIDER_HELP_REQUEST);
    setIsHelpModalOpen(true);
  }, []);

  return (
    <>
      <PageEffects />
      <PageProviders theme={Theme.DARK}>
        <PageContainer>
          <HelpModal
            isOpen={isHelpModalOpen}
            onClose={() => setIsHelpModalOpen(false)}
          />
          <Navbar
            {...obscuredContentProps}
            onClickNeedHelpButton={openHelpModal}
            TranscriptionPillComponent={TranscriptionPillWrapper}
            {...nonEssentialElementProps}
            className={classNames(
              nonEssentialElementProps.className,
              "order-1",
            )}
          />
          <SessionControls />
          <PushDrawerContainer
            // The push drawer must come _after_ the buttons that can trigger it
            // in the DOM. This is so that a keyboard user can tab forward from
            // the triggering button to the drawer. However, the drawer and
            // stage are meant to be visually positioned _before_ the control
            // buttons, so we reorder them within their flex container.
            className="order-2"
          >
            <Stage />
            <PushDrawer id={PUSH_DRAWER_ARIA_CONTROLS_ID} />
          </PushDrawerContainer>
        </PageContainer>
        <StartNewSessionConfirmationModal />
      </PageProviders>
    </>
  );
}
