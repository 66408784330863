import { ClientInformationProgressWrapper as ClientInformationProgress } from "../ClientInformationProgress";
import { ClientInformationFormsAndQuestionnaires } from "../ClientInformationFormsAndQuestionnaires";
import { useAtomValue, useSetAtom } from "jotai";
import { CombinedFormDataEntriesByFormTitle } from "../types";
import {
  ProviderDrawerState,
  isInSessionWithSelectedClientAtom,
  treatmentPlanContentStatus,
  drawerStateAtom,
  selectedClientInfoAtom,
} from "../../state";
import {
  TreatmentPlansOverviewConnected,
  TreatmentPlanStatus,
  TreatmentPlansOverviewConnectedProps,
} from "@grow-therapy-team/seedling-components";
import { Card } from "@growtherapy/sprout-ui";
import {
  completedFormsForSelectedClientAtom,
  hasNewFormsAtomFamily,
} from "../state";

interface TreatmentTabProps {
  patientShortId: string;
  completedForms?: CombinedFormDataEntriesByFormTitle;
  hasNewForms?: boolean;
  shouldShowFormsSection?: boolean;
  onClickTreatmentPlans: (treatmentPlanStatus: TreatmentPlanStatus) => void;
  treatmentPlansComponent: React.FC<TreatmentPlansOverviewConnectedProps>;
  clientInformationProgress: React.FC;
}

export function TreatmentTab({
  completedForms,
  hasNewForms,
  patientShortId,
  shouldShowFormsSection,
  onClickTreatmentPlans,
  treatmentPlansComponent: TreatmentPlans,
  clientInformationProgress: ClientInformationProgress,
}: TreatmentTabProps) {
  return (
    <>
      <div data-testid="client-info.treatment.progress">
        <ClientInformationProgress />
      </div>
      {shouldShowFormsSection && (
        <Card>
          <ClientInformationFormsAndQuestionnaires
            completedForms={completedForms}
            hasNewForms={hasNewForms}
          />
        </Card>
      )}
      <div className="pt-4" data-testid="client-info.treatment.treatment-plans">
        <TreatmentPlans
          patientShortId={patientShortId}
          onClick={() =>
            onClickTreatmentPlans(TreatmentPlanStatus.CURRENT_GOALS)
          }
        />
      </div>
    </>
  );
}

export function TreatmentTabWrapper({
  selectedPatientShortId,
}: {
  selectedPatientShortId: string;
}) {
  const completedForms = useAtomValue(completedFormsForSelectedClientAtom);
  const hasNewForms = useAtomValue(
    hasNewFormsAtomFamily(selectedPatientShortId ?? ""),
  );
  const patientShortId =
    useAtomValue(selectedClientInfoAtom)?.patientShortId ?? "";
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setTreatmentPlanContentStatus = useSetAtom(treatmentPlanContentStatus);

  const onClickTreatmentPlans = (treatmentPlanStatus: TreatmentPlanStatus) => {
    setTreatmentPlanContentStatus(treatmentPlanStatus);
    setDrawerState(ProviderDrawerState.TREATMENT_PLANS_CONTENT);
  };

  const hasCompletedForms =
    completedForms && Object.values(completedForms).length > 0;

  const isInSessionWithSelectedClient = useAtomValue(
    isInSessionWithSelectedClientAtom,
  );
  const shouldShowFormsSection =
    isInSessionWithSelectedClient || hasCompletedForms;

  return (
    <TreatmentTab
      hasNewForms={hasNewForms}
      completedForms={completedForms}
      patientShortId={patientShortId}
      onClickTreatmentPlans={onClickTreatmentPlans}
      shouldShowFormsSection={shouldShowFormsSection}
      treatmentPlansComponent={TreatmentPlansOverviewConnected}
      clientInformationProgress={ClientInformationProgress}
    />
  );
}
