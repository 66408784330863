import { OldToast, ToastVariant } from "../../components";
import toast from "react-hot-toast";
import { Icon, Text } from "@growtherapy/sprout-ui";
import { faCirclePause } from "@fortawesome/pro-solid-svg-icons";

export function RequestingClientConsentToast({ id: toastId }: { id: string }) {
  return (
    <OldToast
      className="bottom-center-toast"
      variant={ToastVariant.Neutral}
      onClose={() => {
        toast.remove(toastId);
      }}
    >
      <Icon aria-hidden icon={faCirclePause} />
      <Text variant="sm">Requesting client consent for transcription...</Text>
    </OldToast>
  );
}
