import { useAtomValue } from "jotai";
import { useChatDrawerButtonUtils } from "../chat/useChatDrawerButtonUtils";
import { ProviderDrawerState, drawerStateAtom } from "../state";
import { PUSH_DRAWER_ARIA_CONTROLS_ID } from "../constants";
import { ChatDrawerControlButton } from "../../twilio/controls/ChatDrawerControl";
import { getIsAnyChatDrawerOpen } from "../../components/utils";

export function ProviderChatDrawerControlButtonWrapper() {
  const { unreadMessageCount, toggleChatDrawer } = useChatDrawerButtonUtils();
  const drawerState = useAtomValue(drawerStateAtom);
  const isChatDrawerOpen = getIsAnyChatDrawerOpen(drawerState);

  return (
    <ChatDrawerControlButton
      drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID}
      forDrawerStates={[
        ProviderDrawerState.ALL_CLIENTS_CHAT_LIST,
        ProviderDrawerState.PARTICIPANT_DISCONNECTED_CHAT,
        ProviderDrawerState.SESSION_CHAT,
        ProviderDrawerState.WAITING_ROOM_CHAT,
      ]}
      isChatDrawerOpen={isChatDrawerOpen}
      messageCount={unreadMessageCount}
      onClick={toggleChatDrawer}
    />
  );
}
