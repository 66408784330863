import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { Provider } from "../types";

export type GetCurrentProviderQueryData = {
  currentProvider: Required<Provider>;
};

export const GET_CURRENT_PROVIDER_GQL = gql`
  query GetCurrentProvider {
    currentProvider {
      shortId
      name
      image
      pronouns
      isPrescriber
    }
  }
`;

export function useGetCurrentProviderQuery() {
  return useQuery<GetCurrentProviderQueryData>(GET_CURRENT_PROVIDER_GQL);
}

export function useGetCurrentProviderQueryCallback() {
  return useLazyQuery<GetCurrentProviderQueryData>(GET_CURRENT_PROVIDER_GQL);
}
