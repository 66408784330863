import { Icon, Text } from "@growtherapy/sprout-ui";
import BrowserHelp from "../assets/browser-help.svg?react";
import BrowserHelpMobile from "../assets/browser-help-mobile.svg?react";
import { NoAccessVariant } from "../twilio/controls/NoAccessToast";
import { faLock, faVideo } from "@fortawesome/pro-solid-svg-icons";

export function BlockedPermissionDescription({
  isMobile,
  noAccessVariant,
}: {
  isMobile: boolean;
  noAccessVariant?: NoAccessVariant;
}) {
  return isMobile ? (
    <>
      <Text
        className="text-neutral-800"
        data-testid="blocked-permissions.mobile-instructions-section"
        variant="md"
      >
        Your browser doesn&apos;t have access to your{" "}
        {noAccessVariant ? noAccessVariant : "camera and microphone"}. Allow
        access by selecting the <Icon icon={faVideo} size="xs" aria-hidden />
        <span className="sr-only">video</span> icon or{" "}
        <Icon icon={faLock} size="xs" aria-hidden />
        <span className="sr-only">&nbsp;lock</span> icon in the corner.
      </Text>
      <BrowserHelpMobile
        className="shrink-0 justify-self-center w-full h-fit"
        data-testid="blocked-permissions.mobile-help-img"
        title="Highlighting video icon in mobile browser address bar"
      />
      <section data-testid="blocked-permissions.additional-info-section">
        <Text as="h3" className="font-medium">
          If that didn&apos;t work:
        </Text>
        <ul className="list-disc ml-4">
          <li>
            <Text variant="md" className="text-neutral-800 mb-4">
              <Text
                className="text-lilac-700 font-medium"
                as="span"
                variant="md"
              >
                For iPhone:
              </Text>{" "}
              open your device&apos;s Settings {">"} Privacy & Security {">"}{" "}
              Camera and/or Microphone {">"} turn on access for your browser.
            </Text>
          </li>
          <li>
            <Text variant="md" className="text-neutral-800">
              <Text
                className="text-lilac-700 inline font-medium"
                as="span"
                variant="md"
              >
                For Android:
              </Text>{" "}
              open your device&apos;s Settings {">"} Security & Privacy {">"}{" "}
              Privacy {">"} Permission manager {">"} allow camera and microphone
              permissions to the app.
            </Text>
          </li>
        </ul>
      </section>
    </>
  ) : (
    <>
      <Text
        className="text-medium text-neutral-800"
        data-testid="blocked-permissions.instructions-section"
        variant="md"
      >
        Your browser doesn’t have access to your{" "}
        {noAccessVariant ? noAccessVariant : "camera and microphone"}. Allow
        access by selecting the <Icon icon={faLock} size="xs" aria-hidden />
        <span className="sr-only">lock</span> icon next to the address bar:
      </Text>
      <BrowserHelp
        className="shrink-0 self-center w-full h-fit"
        data-testid="blocked-permissions.help-img"
        title="Highlighting lock icon in browser address bar"
      />
    </>
  );
}
