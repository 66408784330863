import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { DEFAULT_LOCAL_TRACK_VALUE, localDataTrackAtom } from "../state";

/**
 * Clears the local data track stored in Jotai state.
 */
export function useClearLocalDataTrackCallback() {
  const setLocalDataTrack = useSetAtom(localDataTrackAtom);

  return useCallback(() => {
    setLocalDataTrack(DEFAULT_LOCAL_TRACK_VALUE);
  }, [setLocalDataTrack]);
}
