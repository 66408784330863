import { IconBadge, IconBadgeUse, Text } from "@growtherapy/sprout-ui";
import { noop } from "../utils";
import { useAtomValue } from "jotai";
import {
  inSessionPatientInformationAtom,
  joiningVisitorIdFamily,
  providerShortIdAtom,
  waitingVisitorsAtom,
} from "./state";
import { useEffect } from "react";
import { VisitorPresence } from "../types";
import { useGetTelehealthSessionInfo } from "../hooks";
import {
  AppointmentTime,
  type AppointmentTimeProps,
} from "../components/AppointmentTime";
import { ClientMoodWrapper as ClientMood } from "./client-information/ClientMood";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { ToastRenderFnProps, Toast } from "../toasts";
import { ToastButton } from "../toasts/ToastButton";

export type ClientQueueToastProps = {
  appointment?: AppointmentTimeProps["appointment"];
  buttonIsLoading?: boolean;
  clientMoodComponent?: typeof ClientMood;
  currentSessionPatientShortId: VisitorPresence["patientShortId"] | null;
  isAppointmentLoading?: AppointmentTimeProps["isLoading"];
  onAdmit: () => Promise<void>;
  onOpenQueue: () => void;
  /**
   * unlike onAdmit, this callback is required to avoid a funky edge-case when a
   * client with a different shortId joins the waiting room while the provider
   * is in a session with another client. if this becomes too cumbersome,
   * consider using onOpenQueue when the client is in a session with another
   * client
   */
  onShowStartNewSessionConfirmation: () => void;
  participantName: string;
  patientShortId: VisitorPresence["patientShortId"];
  visitorUuid?: string;
} & ToastRenderFnProps;

export function ClientQueueToast({
  appointment,
  isAppointmentLoading,
  buttonIsLoading,
  clientMoodComponent: ClientMoodComponent,
  currentSessionPatientShortId,
  onAdmit,
  onOpenQueue = noop,
  onShowStartNewSessionConfirmation,
  participantName,
  patientShortId,
  visitorUuid,
  contentProps,
  closeToast,
  descriptionProps,
  titleProps,
  ...toastAriaProps
}: ClientQueueToastProps) {
  const verbVariant = visitorUuid ? "has" : "have";
  const isRequestForNewSession =
    !!currentSessionPatientShortId &&
    currentSessionPatientShortId !== patientShortId;

  const onButtonClick = async () => {
    // if a new session has been requested (e.g., patientShortId is not the
    // same as the current session patientShortId), show a confirmation
    if (isRequestForNewSession) {
      onShowStartNewSessionConfirmation();
      return;
    }

    if (visitorUuid) {
      await onAdmit();
      closeToast();
      return;
    }

    onOpenQueue();
  };

  return (
    <Toast
      className="sm:w-[30rem]"
      data-testid="client-queue-toast"
      closeButtonSize="sm"
      {...toastAriaProps}
    >
      <button
        className="flex items-center flex-1 gap-3"
        onClick={onOpenQueue}
        aria-label="Open client queue"
      >
        <IconBadge
          aria-hidden
          icon={faUser}
          use={IconBadgeUse.Lilac}
          size="lg"
        />
        <div className="flex flex-col" {...contentProps}>
          <Text
            as="span"
            variant="sm"
            className="text-left fs-exclude"
            data-dd-privacy="mask"
            data-dd-action-name="Click on queue toast title"
            {...titleProps}
          >
            {participantName} {verbVariant} joined your waiting room
          </Text>
          <div className="flex flex-col" {...descriptionProps}>
            {isRequestForNewSession && (
              <AppointmentTime
                appointment={appointment}
                data-dd-action-name="appointment time"
                isLoading={isAppointmentLoading}
              />
            )}
            {!!ClientMoodComponent && (
              <ClientMoodComponent visitorUuid={visitorUuid} />
            )}
          </div>
        </div>
      </button>
      <ToastButton loading={buttonIsLoading} onClick={onButtonClick}>
        {visitorUuid ? "Admit" : "View"}
      </ToastButton>
    </Toast>
  );
}

type WrapperProps = Omit<
  ClientQueueToastProps,
  | "appointment"
  | "isAppointmentLoading"
  | "buttonIsLoading"
  | "clientNameAnnotation"
  | "currentSessionPatientShortId"
>;
export function ClientQueueToastWrapper(props: WrapperProps) {
  const { patientShortId, visitorUuid, closeToast } = props;
  const isWaitingForClientToJoin = useAtomValue(
    joiningVisitorIdFamily(visitorUuid ?? ""),
  );
  const visitorPresenceMap = useAtomValue(waitingVisitorsAtom);
  const providerShortId = useAtomValue(providerShortIdAtom);
  const { patientShortId: currentSessionPatientShortId = null } =
    useAtomValue(inSessionPatientInformationAtom) ?? {};

  const { data, loading } = useGetTelehealthSessionInfo(
    providerShortId,
    patientShortId,
  );
  const appointment = data?.telehealthSessionInfo?.appointment;

  useEffect(() => {
    // Dismiss the toast if the visitor has left the waiting room OR in the case of a multi-visitor generic toast, if all visitors have left
    if (
      (visitorUuid && !visitorPresenceMap[visitorUuid]) ||
      (!visitorUuid && !Object.keys(visitorPresenceMap).length)
    ) {
      closeToast();
    }
  }, [closeToast, visitorPresenceMap, visitorUuid]);

  return (
    <ClientQueueToast
      {...props}
      buttonIsLoading={isWaitingForClientToJoin}
      clientMoodComponent={ClientMood}
      currentSessionPatientShortId={currentSessionPatientShortId}
      appointment={appointment}
      isAppointmentLoading={loading}
    />
  );
}
