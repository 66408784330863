import { atom } from "jotai";
import { createDerivedWritableAtom } from "../../utils";

export type VisitorSessionRecordingAtom = {
  otherPatientConsentedInSession: boolean;
};

export const visitorSessionRecordingAtom = atom<VisitorSessionRecordingAtom>({
  otherPatientConsentedInSession: false,
});

export const otherPatientConsentedInSessionAtom = createDerivedWritableAtom(
  visitorSessionRecordingAtom,
  "otherPatientConsentedInSession",
);
