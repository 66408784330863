import { useAtomCallback } from "jotai/utils";
import useScreenShareCallbacks, {
  ShareScreenOptions,
} from "./useScreenShareCallbacks";
import { useWhiteboardCallbacks } from "./useWhiteboardCallbacks";
import { isScreenShareModeAtom } from "..";
import { isWhiteboardModeAtom } from "../messages/state";
import { toast } from "../../toasts";

type SwitchToMode = (
  ...args:
    | ["screenshare", ShareScreenOptions]
    | ["screenshare"]
    | ["whiteboard"]
) => void;

export function useSessionModeCallbacks() {
  const getIsScreenshareMode = useAtomCallback((get) =>
    get(isScreenShareModeAtom),
  );
  const getIsWhiteboardMode = useAtomCallback((get) =>
    get(isWhiteboardModeAtom),
  );

  const { startWhiteboard, stopWhiteboard } = useWhiteboardCallbacks();
  const { triggerBrowserScreenShare, stopScreenShare } =
    useScreenShareCallbacks(() => {
      toast.error("There was an error sharing your screen");
    });

  function stopCurrentMode() {
    if (getIsScreenshareMode()) {
      stopScreenShare();
    } else if (getIsWhiteboardMode().value) {
      stopWhiteboard();
    }
  }

  const switchToMode: SwitchToMode = (mode, options?) => {
    stopCurrentMode();
    switch (mode) {
      case "screenshare": {
        triggerBrowserScreenShare(options);
        break;
      }
      case "whiteboard": {
        startWhiteboard();
        break;
      }
    }
  };

  return {
    switchToMode,
    stopCurrentMode,
  };
}
