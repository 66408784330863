import { MeasureResponseCompletionCampaign } from "@grow-therapy-team/seedling-components";
import { Button } from "@growtherapy/sprout-ui";
import { PageComponentProps } from "../../components";
import { MeasureSchemaAndAnswers } from "./types";
import { useSubmitMeasureResponseCallback } from "../../hooks/measures/useSubmitMeasureResponseCallback";

const SUBMIT_BUTTON = "Submit";
const NEXT_BUTTON = "Next";
const PREVIOUS_BUTTON = "Previous";

export type MeasureActionsProps = Pick<
  PageComponentProps<MeasureSchemaAndAnswers>,
  "isFirstPage" | "isLastPage"
> & {
  isLoading?: boolean;
  onNextPage: () => void;
  onPrevPage: () => void;
};

export function MeasuresActions({
  isFirstPage,
  isLastPage,
  isLoading,
  onNextPage,
  onPrevPage,
}: MeasureActionsProps) {
  return (
    <>
      {!isFirstPage && (
        <Button onClick={onPrevPage} use="secondary" disabled={isLoading}>
          {PREVIOUS_BUTTON}
        </Button>
      )}
      <Button onClick={onNextPage} loading={isLoading} disabled={isLoading}>
        {isLastPage ? SUBMIT_BUTTON : NEXT_BUTTON}
      </Button>
    </>
  );
}

type MeasuresActionsWrapperProps =
  PageComponentProps<MeasureSchemaAndAnswers> & {
    clientUserShortId?: string;
    providerShortId?: string;
  } & {
    completionCampaign: MeasureResponseCompletionCampaign;
    onSubmitMeasureSuccess?: () => void;
  };

export function MeasuresActionsWrapper({
  clientUserShortId,
  completionCampaign,
  page,
  providerShortId,
  isFirstPage,
  isLastPage,
  prevPage,
  nextPage,
  setPage,
  scrollToTop,
  onSubmitMeasureSuccess,
}: MeasuresActionsWrapperProps) {
  const [submitMeasure, { isLoading }] = useSubmitMeasureResponseCallback();

  const goToNextMeasure = () => {
    if (isLastPage) {
      return;
    }
    setPage((prev) => ({ ...prev, hasSubmitted: true }));
    nextPage();
    scrollToTop();
  };

  const handleMeasureSubmission = async () => {
    const { status } = await submitMeasure({
      page,
      clientUserShortId,
      providerShortId,
      completionCampaign,
    });

    if (status !== "success") {
      return;
    }

    onSubmitMeasureSuccess?.();
    goToNextMeasure();
  };

  return (
    <MeasuresActions
      isFirstPage={isFirstPage}
      isLastPage={isLastPage}
      isLoading={isLoading}
      onNextPage={handleMeasureSubmission}
      onPrevPage={prevPage}
    />
  );
}
