import { Modal, ModalBody, ModalHeader } from "@growtherapy/sprout-ui";
import { useSetAtom } from "jotai";
import { visitorDrawerStateAtom } from "../../state";
import { VibesComponentProps } from "./types";
import { UNWIND_WHILE_YOU_WAIT } from "./constants";
import { MusicStateToggleButtonWrapper as MusicStateToggleButton } from "../background-action/MusicStateToggleButton";
export function VerticalVibes({ closePreSessionVibes }: VibesComponentProps) {
  return (
    <Modal isOpen onClose={closePreSessionVibes} className="h-full">
      <ModalHeader>{UNWIND_WHILE_YOU_WAIT}</ModalHeader>
      <ModalBody>
        TODO CARED-2016
        <div className="absolute bottom-4 left-0 w-full flex justify-center">
          <MusicStateToggleButton />
        </div>
      </ModalBody>
    </Modal>
  );
}

export function VerticalVibesWrapper(props: VibesComponentProps) {
  const setVisitorDrawerState = useSetAtom(visitorDrawerStateAtom);
  // Close any other open drawers to prevent weird overlap issues
  setVisitorDrawerState(null);
  return <VerticalVibes {...props} />;
}
