import { Menu, MenuToggleButton, Tooltip } from "@growtherapy/sprout-ui";
import { useAtom, useAtomValue } from "jotai";
import {
  isShowingScreenShareConfirmationAtom,
  localScreenShareTrackAtom,
  participantCountAtom,
} from "../state";
import { deviceIsMobile } from "../../utils";
import { TakeOverScreenShareModal } from "./TakeOverScreenShareModal";
import { IconMenuItem, ControlPanelButton } from "../../components";
import { faAirplay, faSquareXmark } from "@fortawesome/pro-solid-svg-icons";
import { useSessionModeCallbacks } from "./useSessionModeCallbacks";

export function ScreenShareMenuItems({
  isSharing,
  onShare,
  onStopShare,
}: {
  isSharing: boolean;
  onShare: () => void;
  onStopShare: () => void;
}) {
  return (
    <>
      {isSharing ? (
        <>
          <IconMenuItem onClick={onStopShare} destructive icon={faSquareXmark}>
            Stop sharing
          </IconMenuItem>
          <IconMenuItem icon={faAirplay} onClick={onShare}>
            Share different screen
          </IconMenuItem>
        </>
      ) : (
        <>
          <IconMenuItem onClick={onShare} icon={faAirplay}>
            Share screen
          </IconMenuItem>
        </>
      )}
    </>
  );
}

export function ScreenShareControl({
  isLoading,
  isSharing,
  isShowingScreenShareConfirmation,
  onConfirmShare,
  onHideScreenShareConfirmation,
  onShare,
  onStopShare,
}: {
  isLoading?: boolean;
  isSharing: boolean;
  isShowingScreenShareConfirmation: boolean;
  onConfirmShare: () => void;
  onHideScreenShareConfirmation: () => void;
  onShare: () => void;
  onStopShare: () => void;
}) {
  const ariaLabel = isSharing ? "See screenshare options" : "Share your screen";
  const tooltipText = isSharing ? "You are sharing screen" : "Share screen";

  const shareButton = isSharing ? (
    <MenuToggleButton<typeof ControlPanelButton>
      as={ControlPanelButton}
      aria-label={ariaLabel}
      iconDefinition={faAirplay}
      loading={isLoading}
      shouldOverrideClassName
    />
  ) : (
    <ControlPanelButton
      aria-label={ariaLabel}
      loading={isLoading}
      iconDefinition={faAirplay}
      onClick={onShare}
    />
  );

  return (
    <div className="hidden md:block">
      {/** TODO: CARED-1967 The screen-share confirmation modal should be its own component */}
      <TakeOverScreenShareModal
        isModalOpen={isShowingScreenShareConfirmation}
        onCloseModal={onHideScreenShareConfirmation}
        onConfirmShare={onConfirmShare}
      />
      {isSharing ? (
        <Menu
          placement="top"
          toggleButton={shareButton}
          toggleButtonTooltip={tooltipText}
        >
          <ScreenShareMenuItems
            isSharing={isSharing}
            onShare={onShare}
            onStopShare={onStopShare}
          />
        </Menu>
      ) : (
        <Tooltip childIsInteractive text={tooltipText}>
          {shareButton}
        </Tooltip>
      )}
    </div>
  );
}

export function ScreenShareControlWrapper() {
  const inSession = !!useAtomValue(participantCountAtom);
  const isSharing = !!useAtomValue(localScreenShareTrackAtom);
  const { switchToMode, stopCurrentMode } = useSessionModeCallbacks();
  const [
    isShowingScreenShareConfirmation,
    setIsShowingScreenShareConfirmation,
  ] = useAtom(isShowingScreenShareConfirmationAtom);

  if (!inSession || deviceIsMobile()) return;

  return (
    <ScreenShareControl
      isSharing={isSharing}
      isShowingScreenShareConfirmation={isShowingScreenShareConfirmation}
      onConfirmShare={() =>
        switchToMode("screenshare", { shouldTakeOverScreenShare: true })
      }
      onHideScreenShareConfirmation={() =>
        setIsShowingScreenShareConfirmation(false)
      }
      onShare={() =>
        switchToMode("screenshare", { shouldTakeOverScreenShare: false })
      }
      onStopShare={stopCurrentMode}
    />
  );
}

export function ScreenShareMenuItemsWrapper() {
  const isScreenSharing = !!useAtomValue(localScreenShareTrackAtom);
  const { switchToMode, stopCurrentMode: stopSharingScreen } =
    useSessionModeCallbacks();
  const shareScreen = () =>
    switchToMode("screenshare", { shouldTakeOverScreenShare: false });

  return (
    <ScreenShareMenuItems
      isSharing={isScreenSharing}
      onShare={shareScreen}
      onStopShare={stopSharingScreen}
    />
  );
}
