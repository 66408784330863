import { IconButtonProps, Tooltip } from "@growtherapy/sprout-ui";
import { DrawerButton, DrawerButtonProps } from "../../components/DrawerButton";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { ControlPanelButton } from "../../components";

export function SettingsDrawerControl({
  drawerId,
  forDrawerStates,
  isSettingsDrawerOpen,
  onClick,
}: {
  drawerId: string;
  forDrawerStates: DrawerButtonProps<IconButtonProps>["forDrawerStates"];
  isSettingsDrawerOpen: boolean;
  onClick: () => void;
}) {
  const label = isSettingsDrawerOpen ? "Close settings" : "Open settings";
  return (
    <div className="hidden md:block relative">
      {/* NOTE: !w-max encourages the tooltip not to constrain its
          width to the child `DrawerButton`'s width. */}
      <Tooltip childIsInteractive text={label} className="!w-max">
        <DrawerButton
          as={ControlPanelButton}
          forDrawerStates={forDrawerStates}
          className="hidden md:flex"
          aria-label={label}
          aria-expanded={isSettingsDrawerOpen}
          aria-controls={drawerId}
          iconDefinition={faGear}
          onClick={onClick}
        />
      </Tooltip>
    </div>
  );
}
