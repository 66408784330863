import { Alert } from "@growtherapy/sprout-ui";

export function ProviderReadyToSeeYouCard({
  countdown,
}: {
  countdown?: string | null;
}) {
  return (
    countdown && (
      <Alert heading="Your provider is ready to see you!" use="info">
        Automatically transferring in {countdown}
      </Alert>
    )
  );
}
