import {
  GaussianBlurBackgroundProcessor,
  ImageFit,
  VirtualBackgroundProcessor,
  isSupported,
} from "@twilio/video-processors";
import ColorBlack from "../assets/backgrounds/color-black.jpg";
import ColorBlue from "../assets/backgrounds/color-blue.jpg";
import ColorCream from "../assets/backgrounds/color-cream.jpg";
import ColorGray from "../assets/backgrounds/color-gray.jpg";
import ColorGreen from "../assets/backgrounds/color-green.jpg";
import ColorYellow from "../assets/backgrounds/color-yellow.jpg";
import DarkBurst from "../assets/backgrounds/dark-burst.jpg";
import DarkBurstThumb from "../assets/backgrounds/thumb/dark-burst.jpg";
import DarkCircles from "../assets/backgrounds/dark-circles.jpg";
import DarkCirclesThumb from "../assets/backgrounds/thumb/dark-circles.jpg";
import DarkGust from "../assets/backgrounds/dark-gust.jpg";
import DarkGustThumb from "../assets/backgrounds/thumb/dark-gust.jpg";
import Grasses from "../assets/backgrounds/grasses.jpg";
import GrassesThumb from "../assets/backgrounds/thumb/grasses.jpg";
import Cabin from "../assets/backgrounds/cabin.jpg";
import CabinThumb from "../assets/backgrounds/thumb/cabin.jpg";
import Field from "../assets/backgrounds/field.jpg";
import FieldThumb from "../assets/backgrounds/thumb/field.jpg";
import LightBurst from "../assets/backgrounds/light-burst.jpg";
import LightBurstThumb from "../assets/backgrounds/thumb/light-burst.jpg";
import LightCircles from "../assets/backgrounds/light-circles.jpg";
import LightCirclesThumb from "../assets/backgrounds/thumb/light-circles.jpg";
import LightGust from "../assets/backgrounds/light-gust.jpg";
import LightGustThumb from "../assets/backgrounds/thumb/light-gust.jpg";
import Mountains from "../assets/backgrounds/mountains.jpg";
import MountainsThumb from "../assets/backgrounds/thumb/mountains.jpg";
import Office from "../assets/backgrounds/office.jpg";
import OfficeThumb from "../assets/backgrounds/thumb/office.jpg";
import Paint from "../assets/backgrounds/paint.jpg";
import PaintThumb from "../assets/backgrounds/thumb/paint.jpg";
import Sand from "../assets/backgrounds/sand.jpg";
import SandThumb from "../assets/backgrounds/thumb/sand.jpg";
import Wall from "../assets/backgrounds/wall.jpg";
import WallThumb from "../assets/backgrounds/thumb/wall.jpg";
import Water from "../assets/backgrounds/water.jpg";
import WaterThumb from "../assets/backgrounds/thumb/water.jpg";
import { logger } from "../datadog/logger";
import { LocalVideoTrack, VideoProcessor } from "twilio-video";
import { BackgroundType, getStoredAudioVideoSettings } from "./config";

export const WEBGL_EXECUTION_ERROR =
  "Failed to execute 'clientWaitSync' on 'WebGL2RenderingContext'";

export type BackgroundImageType = {
  id: number;
  name: string;
  image: string;
  thumb?: string;
  className?: string;
};

export let blurProcessor: GaussianBlurBackgroundProcessor | undefined =
  undefined;
export let virtualBackgroundProcessor: VirtualBackgroundProcessor | undefined =
  undefined;

export const sceneBackgrounds = [
  {
    id: 5,
    name: "Mountains",
    image: Mountains,
    thumb: MountainsThumb,
  },
  {
    id: 4,
    name: "Field",
    image: Field,
    thumb: FieldThumb,
  },
  {
    id: 7,
    name: "Grasses",
    image: Grasses,
    thumb: GrassesThumb,
  },
  {
    id: 3,
    name: "Cabin",
    image: Cabin,
    thumb: CabinThumb,
  },
  {
    id: 6,
    name: "Office",
    image: Office,
    thumb: OfficeThumb,
  },
  {
    id: 8,
    name: "Wall",
    image: Wall,
    thumb: WallThumb,
  },
  {
    id: 9,
    name: "Water",
    image: Water,
    thumb: WaterThumb,
  },
  {
    id: 10,
    name: "Sand",
    image: Sand,
    thumb: SandThumb,
  },
  {
    id: 11,
    name: "Paint",
    image: Paint,
    thumb: PaintThumb,
  },
];

export const patternBackgrounds = [
  {
    id: 12,
    name: "Light gust",
    image: LightGust,
    thumb: LightGustThumb,
  },
  {
    id: 13,
    name: "Light burst",
    image: LightBurst,
    thumb: LightBurstThumb,
  },
  {
    id: 2,
    name: "Light circles",
    image: LightCircles,
    thumb: LightCirclesThumb,
  },
  {
    id: 14,
    name: "Dark gust",
    image: DarkGust,
    thumb: DarkGustThumb,
  },
  {
    id: 15,
    name: "Dark burst",
    image: DarkBurst,
    thumb: DarkBurstThumb,
  },
  {
    id: 0,
    name: "Dark circles",
    image: DarkCircles,
    thumb: DarkCirclesThumb,
  },
];

export const colorBackgrounds = [
  {
    id: 16,
    name: "Cream",
    image: ColorCream,
    className: "bg-neutral-300",
  },
  {
    id: 17,
    name: "Gray",
    image: ColorGray,
    className: "bg-neutral-400",
  },
  {
    id: 18,
    name: "Black",
    image: ColorBlack,
    className: "bg-neutral-900",
  },
  {
    id: 1,
    name: "Green",
    image: ColorGreen,
    className: "bg-green-100",
  },
  {
    id: 19,
    name: "Yellow",
    image: ColorYellow,
    className: "bg-yellow-100",
  },
  {
    id: 20,
    name: "Blue",
    image: ColorBlue,
    className: "bg-color-6",
  },
];

const sortedBackgrounds = [
  ...sceneBackgrounds,
  ...patternBackgrounds,
  ...colorBackgrounds,
].sort((a, b) => a.id - b.id);

const rawImagePaths = sortedBackgrounds.map((item) => item.image);

const imageElements = new Map();

export const getBackgroundImage = (
  index: number,
): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    if (imageElements.has(index)) {
      return resolve(imageElements.get(index));
    }
    const img = new Image();
    img.onload = () => {
      imageElements.set(index, img);
      resolve(img);
    };
    img.onerror = reject;
    img.src = rawImagePaths[index];
  });
};

//corresponds to a generated directory in the public folder
const virtualBackgroundAssets = "/virtualbackground";

export function removeProcessor(videoTrack: LocalVideoTrack) {
  if (videoTrack && videoTrack.processor) {
    videoTrack.removeProcessor(videoTrack.processor);
  }
}

export function addProcessor(
  videoTrack: LocalVideoTrack,
  processor:
    | GaussianBlurBackgroundProcessor
    | VirtualBackgroundProcessor
    | VideoProcessor,
) {
  if (videoTrack && videoTrack.processor !== processor) {
    removeProcessor(videoTrack);
    videoTrack.addProcessor(processor, {
      inputFrameBufferType: "videoframe",
      outputFrameBufferContextType: "bitmaprenderer",
    });
  }
}

export async function restartProcessor(videoTrack: LocalVideoTrack) {
  const processor = videoTrack.processor;
  if (!processor) return;
  removeProcessor(videoTrack);
  addProcessor(videoTrack, processor);
}

/**
 * Initializes and loads background processor models if not already loaded.
 */
export async function loadProcessorModels() {
  if (!isSupported) return;

  if (!blurProcessor) {
    blurProcessor = new GaussianBlurBackgroundProcessor({
      blurFilterRadius: 15,
      assetsPath: virtualBackgroundAssets,
    });
    try {
      await blurProcessor.loadModel();
    } catch (error) {
      logger.error("Unable to load blur model", {}, error as Error);
    }
  }
  if (!virtualBackgroundProcessor) {
    virtualBackgroundProcessor = new VirtualBackgroundProcessor({
      maskBlurRadius: 5,
      assetsPath: virtualBackgroundAssets,
      backgroundImage: await getBackgroundImage(0),
      fitType: ImageFit.Cover,
    });
    try {
      await virtualBackgroundProcessor.loadModel();
    } catch (error) {
      logger.error(
        "Unable to load virtual background model",
        {},
        error as Error,
      );
    }
  }
}

/**
 * Returns the current background processor based on the stored audio video settings.
 *
 * Assumes the processor is already initialized.
 */
export function getCurrentProcessor():
  | VirtualBackgroundProcessor
  | GaussianBlurBackgroundProcessor
  | undefined {
  const storedAudioVideoSettings = getStoredAudioVideoSettings();
  const backgroundType = storedAudioVideoSettings.backgroundType;
  switch (backgroundType) {
    case BackgroundType.BLUR:
      return blurProcessor;
    case BackgroundType.IMAGE:
      return virtualBackgroundProcessor;
    default:
      return;
  }
}
