import { faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@growtherapy/sprout-ui";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { SelfViewControlButton } from "./SelfViewControlButton";

interface HideSelfViewButtonProps {
  onClick: () => void;
  isVideoEnabled: boolean;
  isSelfViewHidden: boolean;
  position?: string;
  isMobile: boolean;
  isInSession: boolean;
}

export function HideSelfViewButton({
  onClick,
  isVideoEnabled,
  isSelfViewHidden,
  isMobile,
  isInSession,
}: HideSelfViewButtonProps) {
  return (
    <div
      className={twMerge(
        classNames("z-10 flex items-center max-h-8", {
          "absolute top-2 right-2": !isSelfViewHidden,
          "relative m-2": isSelfViewHidden,
        }),
      )}
    >
      <HideSelfViewTooltip
        isSelfViewHidden={isSelfViewHidden}
        isVideoEnabled={isVideoEnabled}
        isInSession={isInSession}
      >
        <SelfViewControlButton
          buttonClassName={classNames("group/hide-self-view-button", {
            "opacity-100 w-auto border-neutral-900": isSelfViewHidden,
          })}
          icon={faEyeSlash}
          label={isSelfViewHidden ? "(You)" : "Hide self view"}
          labelClassName={classNames({
            "group-hover/hide-self-view-button:block group-focus/hide-self-view-button:block":
              !isSelfViewHidden,
            inline: isSelfViewHidden,
          })}
          labelIsVisible={!isMobile || isSelfViewHidden}
          onClick={onClick}
          screenReaderLabel={`${isSelfViewHidden ? "Show" : "Hide"} self view`}
        />
      </HideSelfViewTooltip>
    </div>
  );
}

const IN_SESSION_TOOLTIP_TEXT = "Others can still see your video";
const NOT_IN_SESSION_TOOLTIP_TEXT = "Others will still see your video";

function HideSelfViewTooltip({
  isSelfViewHidden,
  isVideoEnabled,
  isInSession,
  children,
}: Pick<
  HideSelfViewButtonProps,
  "isSelfViewHidden" | "isVideoEnabled" | "isInSession"
> & {
  children: React.ReactElement;
}) {
  // This state starts undefined so that the tooltip operates in an uncontrolled
  // manner (show on hover) until interacted with.
  const [initialShowTooltip, setInitialShowTooltip] = useState<boolean>();
  const tooltipTimeoutRef = useRef<number | null>(null);
  const [srAlert, setSrAlert] = useState<string>("");
  const srAlertTimeoutRef = useRef<number | null>(null);

  // We temporarily take control of the tooltip visibility to show it when the
  // user hides their self view, if they had video enabled. This is to help the
  // user understand that others can still see their video. After 5 seconds, we
  // hide the tooltip again. The tooltip's `afterHide` callback will be
  // immediately invoked when the tooltip is hidden, setting the state back to
  // `undefined` and thus returning the tooltip to its uncontrolled, default
  // (show on hover) behavior.
  useEffect(() => {
    if (isSelfViewHidden && isVideoEnabled) {
      setInitialShowTooltip(true);
      // The SR alert needs to be added to the DOM after the live region, or
      // else it won't be read.
      srAlertTimeoutRef.current = window.setTimeout(() => {
        setSrAlert(
          isInSession ? IN_SESSION_TOOLTIP_TEXT : NOT_IN_SESSION_TOOLTIP_TEXT,
        );
      }, 100);
      tooltipTimeoutRef.current = window.setTimeout(() => {
        setInitialShowTooltip(false);
      }, 5000);
    } else {
      setSrAlert("");
    }
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
      if (srAlertTimeoutRef.current) {
        clearTimeout(srAlertTimeoutRef.current);
      }
    };
  }, [isSelfViewHidden, isVideoEnabled, isInSession]);

  return (
    <>
      {isSelfViewHidden && isVideoEnabled && (
        <span className="sr-only" aria-live="polite">
          {srAlert}
        </span>
      )}
      <Tooltip
        // NOTE: !w-max encourages the tooltip not to constrain its width to the
        // child `Button`'s width.
        className="!w-max"
        text={
          isInSession ? IN_SESSION_TOOLTIP_TEXT : NOT_IN_SESSION_TOOLTIP_TEXT
        }
        childIsInteractive={false}
        disabled={!isSelfViewHidden || !isVideoEnabled}
        isOpen={initialShowTooltip}
        // Reset the state to `undefined` after the tooltip is hidden to return it
        // to uncontrolled mode.
        afterHide={() => setInitialShowTooltip(undefined)}
      >
        {children}
      </Tooltip>
    </>
  );
}
