import { lazy, Suspense } from "react";
import { WhiteboardLoader } from "./WhiteboardLoader";
import type { WhiteBoardCanvasPropsType } from "./WhiteboardCanvas";

const WhiteboardCanvas = lazy(() => import("./WhiteboardCanvas"));
export function LazyWhiteboardCanvas(props: WhiteBoardCanvasPropsType) {
  return (
    <Suspense fallback={<WhiteboardLoader />}>
      <WhiteboardCanvas {...props} />
    </Suspense>
  );
}
