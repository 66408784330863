import {
  CLIENT_CONSENT_PROMPT_TOASTS_FOR_CLIENTS_STORAGE_KEY,
  MAX_CONSENT_PROMPT_TOASTS_PER_CLIENT,
} from "./constants";
import { logger } from "../../datadog";

function isToastsForClients(value: unknown): value is Record<string, string[]> {
  return (
    typeof value === "object" &&
    value !== null &&
    Object.values(value).every(
      (item) =>
        Array.isArray(item) &&
        item.every((subItem) => typeof subItem === "string"),
    )
  );
}

/**
 * Helper function that parses the raw value stored in local storage for the
 * clients and appointments that the provider has seen the consent prompt toast
 * for.
 * @param rawValue The raw value from local storage.
 * @returns The parsed dictionary of client short ids to lists of appointment
 * short ids for which the provider has seen the consent prompt toast.
 */
function parseToastsForClients(rawValue: string): Record<string, string[]> {
  try {
    // Try to parse the value as JSON
    const parsedValue = JSON.parse(rawValue) as Record<string, unknown>;
    // If the parsed value is an object, it's likely a valid format.
    if (isToastsForClients(parsedValue)) {
      return parsedValue;
    }
  } catch (_) {
    // This just means whatever value that was set is invalid, so we ignore it.
    logger.warn(
      "Found invalid value stored for CLIENT_CONSENT_PROMPT_TOASTS_FOR_CLIENTS_STORAGE_KEY:",
      { rawValue },
    );
  }
  return {};
}

/**
 * Determines if the consent prompt toast should be shown for a specific client
 * and appointment pair, based on what the provider has already seen.
 * @param clientShortId The short id of the client.
 * @param appointmentShortId The short id of the appointment.
 * @returns Whether the consent prompt toast should be shown.
 */
export function shouldShowToastForClient(
  clientShortId: string,
  appointmentShortId: string,
) {
  const rawToastsForClients = window.localStorage.getItem(
    CLIENT_CONSENT_PROMPT_TOASTS_FOR_CLIENTS_STORAGE_KEY,
  );
  if (!rawToastsForClients) return true;

  const toastsForClients = parseToastsForClients(rawToastsForClients);
  const toastedAppointments = toastsForClients[clientShortId] ?? [];
  // We show the toast if the provider has not seen it for this client more
  // than MAX_CONSENT_PROMPT_TOASTS_PER_CLIENT times and has not seen it for this specific
  // appointment yet.
  return (
    toastedAppointments.length < MAX_CONSENT_PROMPT_TOASTS_PER_CLIENT &&
    !toastedAppointments.includes(appointmentShortId)
  );
}

/**
 * Records that the provider has seen the consent prompt toast for a specific
 * client and appointment.
 * @param clientShortId The short id of the client.
 * @param appointmentShortId The short id of the appointment.
 */
export function recordToastForClient(
  clientShortId: string,
  appointmentShortId: string,
) {
  const rawToastsForClients = window.localStorage.getItem(
    CLIENT_CONSENT_PROMPT_TOASTS_FOR_CLIENTS_STORAGE_KEY,
  );

  const toastsForClients = parseToastsForClients(rawToastsForClients ?? "{}");
  const toastedAppointments = toastsForClients[clientShortId] ?? [];

  if (!toastedAppointments.includes(appointmentShortId)) {
    const newAppointments = [...toastedAppointments, appointmentShortId];
    window.localStorage.setItem(
      CLIENT_CONSENT_PROMPT_TOASTS_FOR_CLIENTS_STORAGE_KEY,
      JSON.stringify({
        ...toastsForClients,
        [clientShortId]: newAppointments,
      }),
    );
  }
}

/**
 * Hook for managing the consent prompt toasts for clients in local storage.
 * When the provider sees the consent prompt toast for a specific client and
 * appointment, it is recorded to local storage such that:
 * - The provider will not see the toast for the same client more than
 *   MAX_CONSENT_PROMPT_TOASTS_PER_CLIENT times.
 * - The provider will not see the toast for the same appointment more than once
 *   (e.g. if the client leaves and rejoins).
 * @returns An object with two stable callback functions: one to check if the
 * toast should be shown for a client and appointment, and one to record that a
 * toast has been shown.
 */
export function useClientConsentPromptToasts() {
  // NOTE: The following functions must be stable and dependency-free, or else
  // they will be invoked with stale values! This is because they are used in
  // the `useAdmitClientCallback` hook, which explicitly specifies empty
  // dependency arrays and thus will not pick up updated values.
  return {
    shouldShowToastForClient,
    recordToastForClient,
  };
}
