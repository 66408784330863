import { Popover } from "../../components/popover/Popover";
import { faStickyNote } from "@fortawesome/pro-solid-svg-icons";
import {
  PopoverButton,
  PopoverButtonAsProps,
} from "../../components/popover/PopoverButton";
import { FC, forwardRef } from "react";
import { useFeatureGate } from "@statsig/react-bindings";
import { scribblesGate } from "../../statsig/gates";
import { ControlPanelButton } from "../../components";
import { capitalize, noop } from "../../utils";

const PatientNotePopoverButton = forwardRef<
  HTMLButtonElement,
  PopoverButtonAsProps
>(function PatientNotePopoverButton(props, ref) {
  const isOpen = "data-open" in props;
  const actionName = isOpen ? "close" : "open";

  return (
    <ControlPanelButton
      {...props}
      ref={ref}
      aria-label={`${capitalize(actionName)} sticky note. Pressing the N key will also ${actionName} the sticky note.`}
      iconDefinition={faStickyNote}
    />
  );
});

export type PatientNotePopoverControlButtonProps = {
  // TODO: CARED-2024 Update FC with component props
  patientNoteComponent: FC;
};

export function PatientNotePopoverControlButton({
  patientNoteComponent: PatientNoteComponent,
}: PatientNotePopoverControlButtonProps) {
  return (
    <Popover
      popoverButton={
        <PopoverButton
          as={PatientNotePopoverButton}
          keyboardShortcutPredicate="N"
          shouldShowChevron
          renderTooltipText={({ isPopoverOpen }) =>
            `${isPopoverOpen ? "Close" : "Open"} sticky note (shift + n)`
          }
        />
      }
    >
      <PatientNoteComponent />
    </Popover>
  );
}

export function PatientNotePopoverControlButtonWrapper() {
  const { value: isFeatureEnabled } = useFeatureGate(scribblesGate);

  if (!isFeatureEnabled) {
    return null;
  }

  // TODO: CARED-2024 Pass in the connected PatientNote component
  return <PatientNotePopoverControlButton patientNoteComponent={noop} />;
}
