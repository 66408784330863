import { SelfVideoThumbnail } from "../../visitor/SelfVideoThumbnail";
import {
  BackgroundSettings,
  BackgroundType,
  LocalTrackState,
  colorBackgrounds,
  hasBackgroundProcessorFailureAtom,
  localVideoTrackAtom,
  patternBackgrounds,
  permissionDeniedAtom,
  sceneBackgrounds,
  usePublishLocalTrackCallback,
} from "../../twilio";
import Blur from "../../assets/backgrounds/thumb/blur.jpg";
import { useStoredAudioVideoSettings } from "../../twilio/useStoredAudioVideoSettings";
import {
  CAMERA_NOT_ENABLED_HELP_TEXT,
  CAMERA_OFF_HELP_TEXT,
} from "./constants";
import { useAtomValue } from "jotai";
import { BackgroundButton } from "./BackgroundButton";
import { BackgroundThumbnailGrid } from "./BackgroundThumbnailGrid";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { Alert, Text } from "@growtherapy/sprout-ui";
import { useInitBackgroundProcessorsCallback } from "../../twilio/useInitBackgroundProcessorsCallback";

export function BackgroundSettingsTab({
  setBackgroundSettings,
  isMirrored,
  hasBackgroundProcessorFailure,
  backgroundsSelectionIsDisabled,
  selectedBackground = BackgroundType.NONE,
  selectedBackgroundIndex = 0,
  helpText,
}: {
  setBackgroundSettings: (backgroundSettings: BackgroundSettings) => void;
  isMirrored: boolean;
  hasBackgroundProcessorFailure: boolean;
  backgroundsSelectionIsDisabled?: boolean;
  selectedBackground?: BackgroundType;
  selectedBackgroundIndex?: number;
  helpText?: string;
}) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-center">
        <SelfVideoThumbnail
          videoClassName="max-h-none max-w-none w-full h-[216px]"
          className="h-full w-full"
          shouldShowHideSelf={false}
          showExpandVideoToggle={false}
          showSetPositionMenu={false}
          isSettingsThumbnail={true}
          {...(helpText && {
            helpText,
          })}
        />
      </div>
      {hasBackgroundProcessorFailure && (
        <Alert
          use="warning"
          className="rounded-lg p-4 sm:p-4 m-0"
          heading="Not seeing a background change?"
        >
          <Text variant="sm">
            Your device or browser may not support virtual backgrounds.
          </Text>
        </Alert>
      )}
      <div className="grid grid-cols-2 gap-4 pb-3">
        <BackgroundButton
          isDisabled={backgroundsSelectionIsDisabled}
          isSelected={selectedBackground === BackgroundType.NONE}
          text="None"
          isLarge
          onClick={() =>
            setBackgroundSettings({
              backgroundType: BackgroundType.NONE,
            })
          }
        />
        <BackgroundButton
          isDisabled={backgroundsSelectionIsDisabled}
          image={Blur}
          text="Blur"
          isLarge
          isSelected={selectedBackground === BackgroundType.BLUR}
          onClick={() => {
            sendLoggingEvents(TrackingEvents.BLUR_BACKGROUND_USE);
            setBackgroundSettings({
              backgroundType: BackgroundType.BLUR,
            });
          }}
        />
      </div>
      <BackgroundThumbnailGrid
        title="Scenes"
        images={sceneBackgrounds}
        isMirrored={isMirrored}
        setBackgroundSettings={setBackgroundSettings}
        selectedBackground={selectedBackground}
        selectedBackgroundIndex={selectedBackgroundIndex}
        backgroundsSelectionIsDisabled={backgroundsSelectionIsDisabled}
      />
      <BackgroundThumbnailGrid
        title="Patterns"
        images={patternBackgrounds}
        isMirrored={isMirrored}
        setBackgroundSettings={setBackgroundSettings}
        selectedBackground={selectedBackground}
        selectedBackgroundIndex={selectedBackgroundIndex}
        backgroundsSelectionIsDisabled={backgroundsSelectionIsDisabled}
        hasBorder
      />
      <BackgroundThumbnailGrid
        title="Colors"
        images={colorBackgrounds}
        isMirrored={isMirrored}
        setBackgroundSettings={setBackgroundSettings}
        selectedBackground={selectedBackground}
        selectedBackgroundIndex={selectedBackgroundIndex}
        backgroundsSelectionIsDisabled={backgroundsSelectionIsDisabled}
        hasBorder
      />
    </div>
  );
}

export function BackgroundSettingsTabWrapper() {
  const { videoIsBlocked } = useAtomValue(permissionDeniedAtom);
  const hasBackgroundProcessorFailure = useAtomValue(
    hasBackgroundProcessorFailureAtom,
  );
  const { track: localVideoTrackValue, state: localVideoTrackState } =
    useAtomValue(localVideoTrackAtom);
  const isVideoEnabled =
    localVideoTrackValue && localVideoTrackState === LocalTrackState.READY;
  const setBackground = useInitBackgroundProcessorsCallback();
  const publishVideoTrack = usePublishLocalTrackCallback("video");
  const { storedAudioVideoSettings } = useStoredAudioVideoSettings();
  const helpText = videoIsBlocked
    ? CAMERA_NOT_ENABLED_HELP_TEXT
    : CAMERA_OFF_HELP_TEXT;

  return (
    <BackgroundSettingsTab
      setBackgroundSettings={async (backgroundSettings: BackgroundSettings) => {
        if (!isVideoEnabled) {
          await publishVideoTrack();
        }
        setBackground(backgroundSettings);
      }}
      isMirrored={storedAudioVideoSettings?.videoIsMirrored ?? false}
      backgroundsSelectionIsDisabled={videoIsBlocked}
      selectedBackground={storedAudioVideoSettings?.backgroundType}
      selectedBackgroundIndex={storedAudioVideoSettings?.backgroundIndex}
      {...(!isVideoEnabled && {
        helpText,
      })}
      hasBackgroundProcessorFailure={hasBackgroundProcessorFailure}
    />
  );
}
