import { DocumentNode } from "graphql";
import { MEASURES_CONTENT_FRAGMENTS } from "../components/measures/fragments";
import { createFragmentRegistry } from "@apollo/client/cache";
import { FRAGMENTS as SHARED_FRAGMENTS } from "@grow-therapy-team/seedling-components";

export const APP_FRAGMENTS: DocumentNode[] = [MEASURES_CONTENT_FRAGMENTS];

export function registerAppFragments(additionalFragments?: DocumentNode[]) {
  return createFragmentRegistry(
    ...APP_FRAGMENTS,
    ...SHARED_FRAGMENTS,
    ...(additionalFragments || []),
  );
}
