import type { ToastAria } from "@react-aria/toast";
import type { ToastOptions as ReactAriaToastOptions } from "@react-stately/toast";

export enum ToastVariant {
  Success = "success",
  Info = "info",
  Error = "error",
}

/**
 * Modified version of `ToastAria` from `@react-aria/toast` that replaces the
 * `onPress` handler with an `onClick` handler for the close button to support
 * the props contract for the Sprout implementation of a `Button`.
 */
export type ModifiedToastAria = Omit<
  ToastAria,
  "toastProps" | "closeButtonProps"
> & {
  closeButtonProps: Omit<ToastAria["closeButtonProps"], "onPress"> & {
    onClick: () => void;
  };
};

/**
 * Aria props used to render the contents of a toast. These are predominantly
 * provided by `@react-aria/toast`. We also supply a `closeToast` convenience
 * function to programmatically close the toast within the body of a custom
 * toast component.
 */
export type ToastRenderFnProps = Pick<
  ModifiedToastAria,
  "closeButtonProps" | "contentProps" | "descriptionProps" | "titleProps"
> & {
  closeToast: () => void;
};

/**
 * A function that takes the aria props and returns a React node to render the
 * toast.
 */
export type ToastRenderFn = (props: ToastRenderFnProps) => React.ReactNode;

export type CustomToast = {
  key?: string;
  renderFn: ToastRenderFn;
  tags: Set<string>;
};

/**
 * Valid positions for a toast. Each must have an associated `ToastQueue` in
 * order to be used.
 */
export const TOAST_POSITIONS = [
  "top",
  "bottom-left",
  "bottom-center",
  "bottom-right",
] as const;

export type ToastPosition = (typeof TOAST_POSITIONS)[number];

/**
 * Options for enqueueing a new toast. Most of these are passed directly to
 * @react-aria/toast.
 */
export type ToastOptions = ReactAriaToastOptions & {
  /** Defaults to "bottom-center". */
  position?: ToastPosition;
  /**
   * Supply a unique key to ID the toast, e.g. if you need to programmatically
   * close it from a different part of the application.
   */
  key?: string;
  /**
   * Supply a set of tags to associate with the toast. This can be used to
   * programmatically bulk-close all toasts with a given tag, i.e.
   * ```tsx
   * toast.closeByTag("my-tag");
   * ```
   */
  tags?: Set<string>;
};

/**
 * Options for enqueueing a new basic (i.e. non-custom) toast. Includes
 * additional UI options for the toast that would not be applicable to custom
 * toasts.
 */
export type BasicToastOptions = ToastOptions & {
  /** Whether the toast shows the dismiss button. Defaults to `true`. */
  isDismissible?: boolean;
  /** Whether the toast should be masked in Fullstory. Defaults to `false`. */
  isMasked?: boolean;
};
