import { useCallback, useEffect } from "react";
import { toast } from "../../toasts";
import { useAtomValue } from "jotai";
import { ConsentUpdateSource, twilioRoomAtom } from "../../twilio";
import { Icon, Text } from "@growtherapy/sprout-ui";
import { faPenLine } from "@fortawesome/pro-solid-svg-icons";
import { useShouldDisableTranscriptionForMultipleParticipants } from "../../twilio/controls/useShouldDisableTranscriptionForMultipleParticipants";
import { ProviderPatientConsentData } from "./useGetProviderPatientConsent";
import { CLIENT_CONSENT_PROMPT_TOAST_DURATION } from "./constants";
import { useClientConsentPromptToasts } from "./useClientConsentPromptToasts";

const TOAST_KEY = "client-consent-prompt";

/**
 * Hook that returns a callback function to show a toast prompting the provider
 * to request transcription consent from a client.
 */
export function useClientConsentPromptToastCallback() {
  const twilioRoom = useAtomValue(twilioRoomAtom);
  const { shouldShowToastForClient, recordToastForClient } =
    useClientConsentPromptToasts();
  const shouldShowMultipleParticipantTranscriptionDisabled =
    useShouldDisableTranscriptionForMultipleParticipants();

  useEffect(
    function clearToastOnTwilioRoomDisconnectOrMultiParticipant() {
      // If the session ends or a second participant joins the room and we don't
      // support transcription for multiple participants, remove the toast.
      if (!twilioRoom || shouldShowMultipleParticipantTranscriptionDisabled) {
        toast.close(TOAST_KEY);
      }
    },
    [twilioRoom, shouldShowMultipleParticipantTranscriptionDisabled],
  );

  return useCallback(
    (
      consentResponse: ProviderPatientConsentData,
      patientShortId: string,
      appointmentShortId?: string,
    ) => {
      if (!appointmentShortId) {
        return;
      }

      const patientConsent =
        !!consentResponse.clientTranscriptionConsent?.isOptedIn;
      const providerConsent =
        !!consentResponse.providerTranscriptionConsent?.isOptedIn;

      const patientConsentUpdateSource =
        consentResponse.clientTranscriptionConsent?.updateSource;
      const isTranscriptionOptedOutInClientPortal =
        patientConsentUpdateSource === ConsentUpdateSource.CLIENT_PORTAL &&
        !patientConsent;

      // If either the provider has not consented to transcription, or the
      // client already has, we don't need to show the toast.
      if (!providerConsent || patientConsent) {
        return;
      }

      if (isTranscriptionOptedOutInClientPortal) {
        // If patient has explicitly opted out in the client_portal
        // we don't need to show the toast
        return;
      }
      if (!shouldShowToastForClient(patientShortId, appointmentShortId)) {
        return;
      }

      recordToastForClient(patientShortId, appointmentShortId);
      toast.info(
        <Text as="span" variant="sm">
          Press the{" "}
          <span className="sr-only">request transcription consent</span>
          <Icon aria-hidden icon={faPenLine} /> button below to get client
          consent and transcribe the session.
        </Text>,
        {
          key: TOAST_KEY,
          position: "bottom-center",
          timeout: CLIENT_CONSENT_PROMPT_TOAST_DURATION,
        },
      );
    },
    [shouldShowToastForClient, recordToastForClient],
  );
}
