// This file should contain relatively pure functions and should not greedily
// import the TLDraw dependency

// This shouldn't affect package size since they're only TS types which are
// stripped out during compilation.
import type { TLAssetId, TLStore, TLStoreSnapshot } from "tldraw";
import { createWhiteboardStore } from "./createWhiteboardStore";

/**
 * Checks if the store contains all of the records in the snapshot. Returns true
 * if it does and false otherwise.
 */
export function equivalentSnapshot(store: TLStore, snapshot: TLStoreSnapshot) {
  return Object.keys(snapshot.store).every((recordId) => {
    return store.has(recordId as TLAssetId);
  });
}

/**
 * Merges the snapshot into the store. This is useful for syncing the store with
 * a snapshot from a remote source.
 */
export async function mergeSnapshot(store: TLStore, snapshot: TLStoreSnapshot) {
  const tempStore = await createWhiteboardStore();
  const changes = tempStore.extractingChanges(() => {
    tempStore.loadStoreSnapshot(snapshot);
  });
  store.mergeRemoteChanges(() => {
    store.applyDiff(changes);
  });
}
