import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../hooks";
import { Theme } from "../state";
import { ComponentProps, FC, HTMLAttributes, ReactElement } from "react";
import { AdditionalOptionsMenuProps, MenuChild } from "./AdditionalOptionsMenu";
import { MenuItem } from "@growtherapy/sprout-ui";
import { keep } from "../utils";

type ActionProps = {
  className?: string;
};

export type SessionControl = {
  /**
   * A unique key for the control. This key is used by React to identify the
   * control components.
   */
  key: string;
  /**
   * A component that renders the control in the toolbar. If
   * `additionalOptionsMenuItemComponent` is not also specified, then it
   * will always show in the toolbar. If both are specified, then the toolbar
   * component will only show on larger screens. Omitting this property
   * will only show the control in the additional options menu on smaller
   * screens.
   */
  toolbarComponent?: FC<ActionProps>;
  /**
   * A component that renders the control in the additional options menu, which
   * is only visible on smaller screens. If `toolbarComponent` is not also
   * specified, then it will only show in the additional options menu. Omitting this
   * property will only and always show the control in the toolbar.
   */
  additionalOptionsMenuItemComponent?: FC<ComponentProps<typeof MenuItem>>;
};

function renderToolbarControl({
  key,
  toolbarComponent: ToolbarComponent,
  additionalOptionsMenuItemComponent,
}: SessionControl) {
  if (!ToolbarComponent) {
    return null;
  }

  const isResponsive = !!additionalOptionsMenuItemComponent;

  return (
    <ToolbarComponent
      key={key}
      className={classNames({
        "hidden md:block": isResponsive,
      })}
    />
  );
}

function renderAdditionalOptionsMenuComponent({
  key,
  toolbarComponent,
  additionalOptionsMenuItemComponent: AdditionalOptionsMenuItemComponent,
}: SessionControl): null | MenuChild {
  if (!AdditionalOptionsMenuItemComponent) {
    return null;
  }

  const isResponsive = !!toolbarComponent;

  return (
    <AdditionalOptionsMenuItemComponent
      key={key}
      className={classNames({
        "sm:hidden": isResponsive,
      })}
    />
  );
}

export type SessionControlChild =
  | ReactElement<ComponentProps<typeof LeftsideSessionControls>>
  | ReactElement<ComponentProps<typeof CentralSessionControls>>
  | ReactElement<ComponentProps<typeof RightsideSessionControls>>
  | boolean
  | null;

export type SessionControlsProps = {
  children: SessionControlChild | SessionControlChild[];
  className?: string;
};

export function LeftsideSessionControls({
  className,
  children,
  ...otherProps
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={twMerge(
        "md:col-span-1 md:col-start-1 flex items-center",
        className,
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
}

type CentralControlsContainerProps = {
  additionalOptionsMenuComponent: FC<
    Pick<AdditionalOptionsMenuProps, "children">
  >;
  /**
   * An list of controls to be rendered in the center of the session controls.
   * These central section will also handle the overflowing of controls to the
   * additional options menu when applicable.
   */
  controls: SessionControl[];
} & Omit<HTMLAttributes<HTMLDivElement>, "children">;

export function CentralSessionControls({
  additionalOptionsMenuComponent: AdditionalOptionsMenuComponent,
  className,
  controls,
  ...otherProps
}: CentralControlsContainerProps) {
  return (
    <div
      className={twMerge(
        "md:col-span-1 md:col-start-2 flex md:flex-grow justify-center items-center gap-5",
        className,
      )}
      style={
        // Turn off the transitions for tooltips on the control buttons to
        // prevent their tooltips from overlapping during the animation
        // transition period.
        {
          ["--rt-transition-show-delay"]: 0,
          ["--rt-transition-closing-delay"]: 0,
        } as React.CSSProperties
      }
      {...otherProps}
    >
      {controls.map(renderToolbarControl)}
      <AdditionalOptionsMenuComponent>
        {keep(controls, renderAdditionalOptionsMenuComponent)}
      </AdditionalOptionsMenuComponent>
    </div>
  );
}

export function RightsideSessionControls({
  className,
  children,
  ...otherProps
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={twMerge(
        "md:col-span-1 md:col-start-3 justify-end flex items-center",
        className,
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
}

/**
 * Contains controls at the bottom of the screen for the session. Specify
 * left-side, central controls, and right-side controls by wrapping the given
 * children in the `LeftsideControls`, `CentralControls` and `RightsideControls`
 * components.
 */
export function SessionControlsLayout({
  children,
  className,
}: SessionControlsProps) {
  const { theme } = useTheme();

  return (
    <div
      aria-label="Session controls"
      className={twMerge(
        classNames(
          "relative md:px-12 w-screen flex flex-row md:grid md:grid-cols-3 min-h-[4.5rem] gap-5 justify-center",
          {
            "bg-neutral-300": theme === Theme.LIGHT,
            "bg-neutral-800": theme === Theme.DARK,
          },
          className,
        ),
      )}
    >
      {children}
    </div>
  );
}
