import { useAtomCallback } from "jotai/utils";
import { useCallback, useState } from "react";
import { sessionInfoAtom } from "../../state";
import {
  providerShortIdAtom,
  whiteboardPersistenceKeyAtom,
} from "../../provider/state";
import { useSetAtom } from "jotai";
import { UserType } from "../../types";
import { useSendWhiteboardSnapshotCallback as useBaseSendWhiteboardSnapshotCallback } from "../../twilio/messages/useSendWhiteboardSnapshotCallback";
import { useSessionModeCallbacks } from "../../twilio/controls/useSessionModeCallbacks";
import { TLStore } from "tldraw";
import { loadWhiteboardStore } from "./loadAppWhiteboardStore";
import { toast } from "@growtherapy/sprout-ui";
import { logger } from "../../datadog";

type ShareWhiteboardCallback = () => Promise<void>;

export function useShareWhiteboardCallback({
  useSendWhiteboardSnapshotCallback = useBaseSendWhiteboardSnapshotCallback,
  store,
}: {
  /**
   * Useful for testing
   */
  useSendWhiteboardSnapshotCallback?: typeof useBaseSendWhiteboardSnapshotCallback;
  store?: TLStore;
} = {}): [ShareWhiteboardCallback, { isLoading: boolean }] {
  const { switchToMode } = useSessionModeCallbacks();
  const getSessionInfo = useAtomCallback(
    useCallback((get) => get(sessionInfoAtom), []),
  );
  const getPersistenceKey = useAtomCallback(
    useCallback((get) => get(whiteboardPersistenceKeyAtom), []),
  );

  const getProviderShortId = useAtomCallback(
    useCallback((get) => get(providerShortIdAtom), []),
  );
  const [isLoading, setIsLoading] = useState(false);
  const setPersistenceKey = useSetAtom(whiteboardPersistenceKeyAtom);
  const sendWhiteboardSnapshot = useSendWhiteboardSnapshotCallback();
  const shareWhiteboard = useCallback(async () => {
    const whiteboardStore = store ?? (await loadWhiteboardStore());
    switchToMode("whiteboard");

    const appointmentShortId = getSessionInfo()?.appointment?.shortId;
    const persistenceKey = getPersistenceKey();
    const appointmentShortIdMatchesPersistenceKey =
      appointmentShortId === persistenceKey;

    if (!!appointmentShortId && appointmentShortIdMatchesPersistenceKey) {
      sendWhiteboardSnapshot({
        entityType: UserType.PROVIDER,
        entityId: getProviderShortId() ?? "",
      });
    } else if (appointmentShortId) {
      whiteboardStore.clear();
      setPersistenceKey(appointmentShortId);
    } else {
      whiteboardStore.clear();
    }
  }, [
    switchToMode,
    getSessionInfo,
    getPersistenceKey,
    getProviderShortId,
    setPersistenceKey,
    sendWhiteboardSnapshot,
    store,
  ]);

  const handleShareWhiteboard = useCallback(async () => {
    setIsLoading(true);
    return shareWhiteboard()
      .catch((error) => {
        toast.error("Failed to start whiteboard");
        logger.error("Failed to start whiteboard", error);
      })
      .finally(() => setIsLoading(false));
  }, [shareWhiteboard]);

  return [handleShareWhiteboard, { isLoading: isLoading }];
}
