import { type InputControlProps } from "@growtherapy/sprout-ui";
import {
  ProviderFormElement,
  QuestionTypeEnum,
  GroupedResponse,
  ValidationError,
  ValidationErrors,
} from "../types";
import { GenericFormAnswers, QuestionProps } from "./types";

const MISSING_REQUIRED_FIELD_ERROR = "This field is required";
const OVER_CHARACTER_LIMIT_ERROR = "Your response exceeds the character limit.";

export function validateForm(
  questions: GroupedResponse[],
  answers?: GenericFormAnswers,
): ValidationErrors {
  const validationErrors: ValidationErrors = new Set();

  if (!questions.length) return validationErrors;

  const hasAnUnansweredQuestion =
    !answers ||
    questions.some(
      ({ questionName }) => questionName && !answers[questionName],
    );
  if (hasAnUnansweredQuestion) {
    validationErrors.add(ValidationError.INCOMPLETE_FORM);
  }

  return validationErrors;
}

/**
 * Removes answers to questions that are not supposed to be visible
 *
 * Ported over from
 * https://github.com/Grow-Therapy-Team/Grow-Dashboard/blob/main/grow-therapy-frontend/apps/csr-app/src/app/client-portal/Intake/utils/trimUnusedFormValues.ts#L6
 */
export function trimUnusedFormValues(
  values: GenericFormAnswers,
  questions: ProviderFormElement[],
  conditions = FormConditions,
): GenericFormAnswers {
  const formElementsMap = questions.reduce(
    (col, question) => ({ ...col, [question.name]: question }),
    {} as Record<string, ProviderFormElement>,
  );
  return Object.entries(values).reduce((col, [key, value]) => {
    const question = formElementsMap[key];
    const visible =
      !question?.visibleIf || conditions[question.visibleIf]?.(values);
    if (visible) {
      return { ...col, [key]: value };
    }
    return col;
  }, {});
}

/**
 * Converts form values to a submittable form response
 *
 * Ported over from
 * https://github.com/Grow-Therapy-Team/Grow-Dashboard/blob/main/grow-therapy-frontend/apps/csr-app/src/app/client-portal/Intake/hooks/useForm.ts#L67
 */
export function formValuesToFormResponse(formValues: GenericFormAnswers) {
  return {
    saved_page_num: 0,
    pages: [
      {
        elements: Object.keys(formValues).map((key) => ({
          name: key,
          value: formValues[key],
        })),
      },
    ],
  };
}

export function getWordCount(
  question: ProviderFormElement,
  value?: string,
): InputControlProps["wordCount"] {
  return question.maxLength && question.type === QuestionTypeEnum.TEXT_AREA
    ? {
        ...(question?.required ? { min: 1 } : {}),
        max: question.maxLength,
        currentLength: value?.length ?? 0,
      }
    : undefined;
}

export function validateValue({
  question,
  validationErrors,
  type,
  value,
}: QuestionProps): {
  inputControlError?: string;
  shouldShowBasicRequiredError?: boolean;
} {
  const isIncompleteForm = validationErrors?.has(
    ValidationError.INCOMPLETE_FORM,
  );
  const isInlineRadio =
    type === QuestionTypeEnum.RADIO && question.layout === "inline";

  const isOverCharacterLimit =
    isIncompleteForm &&
    question?.maxLength &&
    type === QuestionTypeEnum.TEXT_AREA &&
    (value?.length ?? 0) > question.maxLength;

  const isRequired = !value && isIncompleteForm && question.required;

  if (isRequired && !isInlineRadio) {
    return { inputControlError: MISSING_REQUIRED_FIELD_ERROR };
  }

  if (isRequired) {
    return { shouldShowBasicRequiredError: true };
  }

  if (isOverCharacterLimit) {
    return { inputControlError: OVER_CHARACTER_LIMIT_ERROR };
  }

  return {};
}

const SUBSTANCES = [
  "tobacco",
  "alcohol",
  "marijuana",
  "hard_drugs",
  "psychedelics",
  "inhalants",
  "prescription",
];
const YES = "Yes";
const NO = "No";
const OTHER = "Other";
const PREFER_NOT_TO_ANSWER = "Prefer not to answer";

/**
 * Conditions for showing/hiding form questions based on answers to other
 * questions. Copied from
 * https://github.com/Grow-Therapy-Team/Grow-Dashboard/blob/main/grow-therapy-frontend/libs/constants/src/lib/FormConditions.ts#L10
 */
export const FormConditions: Record<
  string,
  (form: GenericFormAnswers) => boolean
> = {
  CLIENT_NOT_COMPLETING_FORM: (form: GenericFormAnswers): boolean => {
    return form["completed_by"] && form["completed_by"] !== "Client / self";
  },
  OTHER_PREFERRED_LANGUAGE: (form: GenericFormAnswers): boolean => {
    return form["preferred_language"] === OTHER;
  },
  OTHER_SEXUAL_IDENTITY: (form: GenericFormAnswers): boolean => {
    return (
      Array.isArray(form["sexual_identity"]) &&
      (form["sexual_identity"] as string[]).includes(OTHER) &&
      !(form["sexual_identity"] as string[]).includes(PREFER_NOT_TO_ANSWER)
    );
  },
  OTHER_RELATIONSHIP_STATUS: (form: GenericFormAnswers): boolean => {
    return form["relationship_status"] === OTHER;
  },
  OTHER_LIVING_SITUATION: (form: GenericFormAnswers): boolean => {
    return form["living_situation"] === OTHER;
  },
  OTHER_EMPLOYMENT_STATUS: (form: GenericFormAnswers): boolean => {
    return form["employment_status"] === OTHER;
  },
  YES_CURRENT_MEDICAL_CONDITION_TREATMENT: (
    form: GenericFormAnswers,
  ): boolean => {
    return form["current_medical_condition_treatment"] === YES;
  },
  YES_PRIOR_ILLNESSES: (form: GenericFormAnswers): boolean => {
    return form["prior_illnesses"] === YES;
  },
  YES_PSYCHIATRIC_MEDICATIONS: (form: GenericFormAnswers): boolean => {
    return form["current_psychiatric_medications"] === YES;
  },
  YES_NON_PSYCHIATRIC_MEDICATIONS: (form: GenericFormAnswers): boolean => {
    return form["current_non_psychiatric_medications"] === YES;
  },
  YES_PRIMARY_CARE_PHYSICIAN: (form: GenericFormAnswers): boolean => {
    return form["client_pcp"] === YES;
  },
  YES_PSYCHIATRIST: (form: GenericFormAnswers): boolean => {
    return form["client_psychiatrist"] === YES;
  },
  YES_PRIOR_MENTAL_HEALTH_TREATMENT: (form: GenericFormAnswers): boolean => {
    return form["prior_mental_health_treatment"] === YES;
  },
  YES_PSYCHIATRICALLY_HOSPITALIZED: (form: GenericFormAnswers): boolean => {
    return form["psych_hospitalization_history"] === YES;
  },
  YES_RECREATIONAL_SUBSTANCE_USE: (form: GenericFormAnswers): boolean => {
    return form["substance_use"] === YES;
  },
  YES_HISTORY_SUBSTANCE_USE_BUT_NO_SUBSTANCE_SELECTED: (
    form: GenericFormAnswers,
  ): boolean => {
    return (
      FormConditions.YES_HISTORY_SUBSTANCE_USE(form) &&
      SUBSTANCES.every((substance) => form[`${substance}_history`] === NO)
    );
  },
  YES_SUBSTANCE_USE_BUT_NO_SUBSTANCE_SELECTED: (
    form: GenericFormAnswers,
  ): boolean => {
    return (
      FormConditions.YES_RECREATIONAL_SUBSTANCE_USE(form) &&
      SUBSTANCES.every((substance) => form[substance] === NO)
    );
  },
  YES_TOBACCO: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_RECREATIONAL_SUBSTANCE_USE(form) &&
      form["tobacco"] === YES
    );
  },
  CIGARETTES: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_TOBACCO(form) &&
      Array.isArray(form["tobacco_cigarettes"]) &&
      form["tobacco_cigarettes"][0] === "Cigarettes"
    );
  },
  CIGARETTES_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.CIGARETTES(form) &&
      form["tobacco_cigarettes_frequency"] === OTHER
    );
  },
  VAPE_PENS: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_TOBACCO(form) &&
      Array.isArray(form["tobacco_vape_pens"]) &&
      form["tobacco_vape_pens"][0] === "Vape pens or e-cigs"
    );
  },
  VAPE_PENS_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.VAPE_PENS(form) &&
      form["tobacco_vape_pens_frequency"] === OTHER
    );
  },
  CIGARS: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_TOBACCO(form) &&
      Array.isArray(form["tobacco_cigars"]) &&
      form["tobacco_cigars"][0] === "Cigars"
    );
  },
  CIGARS_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.CIGARS(form) && form["tobacco_cigars_frequency"] === OTHER
    );
  },
  DIP: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_TOBACCO(form) &&
      Array.isArray(form["tobacco_dip"]) &&
      form["tobacco_dip"][0] === "Chew or dip"
    );
  },
  DIP_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return FormConditions.DIP(form) && form["tobacco_dip_frequency"] === OTHER;
  },
  YES_ALCOHOL: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_RECREATIONAL_SUBSTANCE_USE(form) &&
      form["alcohol"] === YES
    );
  },
  BEER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_ALCOHOL(form) &&
      Array.isArray(form["alcohol_beer"]) &&
      form["alcohol_beer"][0] === "Beer"
    );
  },
  BEER_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.BEER(form) && form["alcohol_beer_frequency"] === OTHER
    );
  },
  WINE: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_ALCOHOL(form) &&
      Array.isArray(form["alcohol_wine"]) &&
      form["alcohol_wine"][0] === "Wine"
    );
  },
  WINE_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.WINE(form) && form["alcohol_wine_frequency"] === OTHER
    );
  },
  HARD_LIQUOR: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_ALCOHOL(form) &&
      Array.isArray(form["alcohol_hard_liquor"]) &&
      form["alcohol_hard_liquor"][0] === "Hard liquor"
    );
  },
  HARD_LIQUOR_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.HARD_LIQUOR(form) &&
      form["alcohol_hard_liquor_frequency"] === OTHER
    );
  },
  YES_MARIJUANA: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_RECREATIONAL_SUBSTANCE_USE(form) &&
      form["marijuana"] === YES
    );
  },
  MARIJUANA_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_MARIJUANA(form) &&
      form["marijuana_frequency"] === OTHER
    );
  },
  YES_HARD_DRUGS: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_RECREATIONAL_SUBSTANCE_USE(form) &&
      form["hard_drugs"] === YES
    );
  },
  COCAINE: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HARD_DRUGS(form) &&
      Array.isArray(form["hard_drugs_cocaine"]) &&
      form["hard_drugs_cocaine"][0] === "Cocaine"
    );
  },
  COCAINE_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.COCAINE(form) &&
      form["hard_drugs_cocaine_frequency"] === OTHER
    );
  },
  METH: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HARD_DRUGS(form) &&
      Array.isArray(form["hard_drugs_meth"]) &&
      form["hard_drugs_meth"][0] === "Meth"
    );
  },
  METH_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.METH(form) && form["hard_drugs_meth_frequency"] === OTHER
    );
  },
  MOLLY_ECSTASY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HARD_DRUGS(form) &&
      Array.isArray(form["hard_drugs_molly_ecstasy"]) &&
      form["hard_drugs_molly_ecstasy"][0] === "Molly / Ecstasy"
    );
  },
  MOLLY_ECSTASY_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.MOLLY_ECSTASY(form) &&
      form["hard_drugs_molly_ecstasy_frequency"] === OTHER
    );
  },
  HEROIN: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HARD_DRUGS(form) &&
      Array.isArray(form["hard_drugs_heroin"]) &&
      form["hard_drugs_heroin"][0] === "Heroin"
    );
  },
  HEROIN_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.HEROIN(form) &&
      form["hard_drugs_heroin_frequency"] === OTHER
    );
  },
  KETAMINE: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HARD_DRUGS(form) &&
      Array.isArray(form["hard_drugs_ketamine"]) &&
      form["hard_drugs_ketamine"][0] === "Ketamine"
    );
  },
  KETAMINE_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.KETAMINE(form) &&
      form["hard_drugs_ketamine_frequency"] === OTHER
    );
  },
  YES_PSYCHEDELICS: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_RECREATIONAL_SUBSTANCE_USE(form) &&
      form["psychedelics"] === YES
    );
  },
  MUSHROOMS: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_PSYCHEDELICS(form) &&
      Array.isArray(form["psychedelics_mushrooms"]) &&
      form["psychedelics_mushrooms"][0] === "Mushrooms"
    );
  },
  MUSHROOMS_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.MUSHROOMS(form) &&
      form["psychedelics_mushrooms_frequency"] === OTHER
    );
  },
  LSD: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_PSYCHEDELICS(form) &&
      Array.isArray(form["psychedelics_lsd"]) &&
      form["psychedelics_lsd"][0] === "LSD / Acid"
    );
  },
  LSD_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.LSD(form) && form["psychedelics_lsd_frequency"] === OTHER
    );
  },
  DMT: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_PSYCHEDELICS(form) &&
      Array.isArray(form["psychedelics_dmt"]) &&
      form["psychedelics_dmt"][0] === "DMT"
    );
  },
  DMT_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.DMT(form) && form["psychedelics_dmt_frequency"] === OTHER
    );
  },
  YES_INHALANTS: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_RECREATIONAL_SUBSTANCE_USE(form) &&
      form["inhalants"] === YES
    );
  },
  WHIPPITS: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_INHALANTS(form) &&
      Array.isArray(form["inhalants_whippits"]) &&
      form["inhalants_whippits"][0] === "Whippits"
    );
  },
  WHIPPITS_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.WHIPPITS(form) &&
      form["inhalants_whippits_frequency"] === OTHER
    );
  },
  GLUE: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_INHALANTS(form) &&
      Array.isArray(form["inhalants_glue"]) &&
      form["inhalants_glue"][0] === "Glue"
    );
  },
  GLUE_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.GLUE(form) && form["inhalants_glue_frequency"] === OTHER
    );
  },
  GAS_AEROSOLS: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_INHALANTS(form) &&
      Array.isArray(form["inhalants_gas_aerosols"]) &&
      form["inhalants_gas_aerosols"][0] === "Gas / aerosols"
    );
  },
  GAS_AEROSOLS_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.GAS_AEROSOLS(form) &&
      form["inhalants_gas_aerosols_frequency"] === OTHER
    );
  },
  POPPERS: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_INHALANTS(form) &&
      Array.isArray(form["inhalants_poppers"]) &&
      form["inhalants_poppers"][0] === "Poppers"
    );
  },
  POPPERS_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.POPPERS(form) &&
      form["inhalants_poppers_frequency"] === OTHER
    );
  },

  YES_PRESCRIPTION_MEDICATIONS: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_RECREATIONAL_SUBSTANCE_USE(form) &&
      form["prescription"] === YES
    );
  },
  PAIN_PILLS: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_PRESCRIPTION_MEDICATIONS(form) &&
      Array.isArray(form["prescription_pain_pills"]) &&
      form["prescription_pain_pills"][0] === "Pain pills"
    );
  },
  PAIN_PILLS_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.PAIN_PILLS(form) &&
      form["prescription_pain_pills_frequency"] === OTHER
    );
  },
  UPPSERS_STIMULANTS: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_PRESCRIPTION_MEDICATIONS(form) &&
      Array.isArray(form["prescription_uppers_stimulants"]) &&
      form["prescription_uppers_stimulants"][0] === "Uppers/stimulants"
    );
  },
  UPPERS_STIMULANTS_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.UPPSERS_STIMULANTS(form) &&
      form["prescription_uppers_stimulants_frequency"] === OTHER
    );
  },
  DOWNERS_DEPRESSANTS: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_PRESCRIPTION_MEDICATIONS(form) &&
      Array.isArray(form["prescription_downers_depressants"]) &&
      form["prescription_downers_depressants"][0] === "Downers/depressants"
    );
  },
  DOWNERS_DEPRESSANTS_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.DOWNERS_DEPRESSANTS(form) &&
      form["prescription_downers_depressants_frequency"] === OTHER
    );
  },
  YES_HISTORY_SUBSTANCE_USE: (form: GenericFormAnswers): boolean => {
    return form["history_substance_use"] === YES;
  },
  YES_TOBACCO_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HISTORY_SUBSTANCE_USE(form) &&
      form["tobacco_history"] === YES
    );
  },
  CIGARETTES_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_TOBACCO_HISTORY(form) &&
      Array.isArray(form["tobacco_cigarettes_history"]) &&
      form["tobacco_cigarettes_history"][0] === "Cigarettes"
    );
  },
  VAPE_PENS_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_TOBACCO_HISTORY(form) &&
      Array.isArray(form["tobacco_vape_pens_history"]) &&
      form["tobacco_vape_pens_history"][0] === "Vape pens or e-cigs"
    );
  },
  CIGARS_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_TOBACCO_HISTORY(form) &&
      Array.isArray(form["tobacco_cigars_history"]) &&
      form["tobacco_cigars_history"][0] === "Cigars"
    );
  },
  CHEW_OR_DIP_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_TOBACCO_HISTORY(form) &&
      Array.isArray(form["tobacco_dip_history"]) &&
      form["tobacco_dip_history"][0] === "Chew or dip"
    );
  },
  DIP_HISTORY_FREQUENCY_OTHER: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.DIP_HISTORY(form) &&
      form["tobacco_dip_history_frequency"] === OTHER
    );
  },
  YES_ALCOHOL_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HISTORY_SUBSTANCE_USE(form) &&
      form["alcohol_history"] === YES
    );
  },
  BEER_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_ALCOHOL_HISTORY(form) &&
      Array.isArray(form["alcohol_beer_history"]) &&
      form["alcohol_beer_history"][0] === "Beer"
    );
  },
  WINE_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_ALCOHOL_HISTORY(form) &&
      Array.isArray(form["alcohol_wine_history"]) &&
      form["alcohol_wine_history"][0] === "Wine"
    );
  },
  HARD_LIQUOR_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_ALCOHOL_HISTORY(form) &&
      Array.isArray(form["alcohol_hard_liquor_history"]) &&
      form["alcohol_hard_liquor_history"][0] ===
        "Hard liquor (like vodka, rum, tequila)"
    );
  },
  YES_MARIJUANA_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HISTORY_SUBSTANCE_USE(form) &&
      form["marijuana_history"] === YES
    );
  },

  YES_PRESCRIPTION_MEDICATIONS_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HISTORY_SUBSTANCE_USE(form) &&
      form["prescription_history"] === YES
    );
  },
  PAIN_PILLS_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_PRESCRIPTION_MEDICATIONS_HISTORY(form) &&
      Array.isArray(form["prescription_pain_pills_history"]) &&
      form["prescription_pain_pills_history"][0] ===
        "Pain pills (Oxy, Vicodin, Percocet etc.)"
    );
  },
  UPPERS_STIMULANTS_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_PRESCRIPTION_MEDICATIONS_HISTORY(form) &&
      Array.isArray(form["prescription_uppers_stimulants_history"]) &&
      form["prescription_uppers_stimulants_history"][0] ===
        "Uppers/stimulants (Adderall, Ritalin etc.)"
    );
  },
  DOWNERS_DEPRESSANTS_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_PRESCRIPTION_MEDICATIONS_HISTORY(form) &&
      Array.isArray(form["prescription_downers_depressants_history"]) &&
      form["prescription_downers_depressants_history"][0] ===
        "Downers/depressants"
    );
  },
  YES_HARD_DRUGS_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HISTORY_SUBSTANCE_USE(form) &&
      form["hard_drugs_history"] === YES
    );
  },
  COCAINE_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HARD_DRUGS_HISTORY(form) &&
      Array.isArray(form["hard_drugs_cocaine_history"]) &&
      form["hard_drugs_cocaine_history"][0] === "Cocaine"
    );
  },
  METH_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HARD_DRUGS_HISTORY(form) &&
      Array.isArray(form["hard_drugs_meth_history"]) &&
      form["hard_drugs_meth_history"][0] === "Meth"
    );
  },
  MOLLY_ECSTASY_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HARD_DRUGS_HISTORY(form) &&
      Array.isArray(form["hard_drugs_molly_ecstasy_history"]) &&
      form["hard_drugs_molly_ecstasy_history"][0] === "Molly / Ecstasy"
    );
  },
  HEROIN_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HARD_DRUGS_HISTORY(form) &&
      Array.isArray(form["hard_drugs_heroin_history"]) &&
      form["hard_drugs_heroin_history"][0] === "Heroin"
    );
  },
  KETAMINE_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HARD_DRUGS_HISTORY(form) &&
      Array.isArray(form["hard_drugs_ketamine_history"]) &&
      form["hard_drugs_ketamine_history"][0] === "Ketamine (Special K)"
    );
  },
  YES_PSYCHEDELICS_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HISTORY_SUBSTANCE_USE(form) &&
      form["psychedelics_history"] === YES
    );
  },
  MUSHROOMS_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_PSYCHEDELICS_HISTORY(form) &&
      Array.isArray(form["psychedelics_mushrooms_history"]) &&
      form["psychedelics_mushrooms_history"][0] === "Mushrooms"
    );
  },
  LSD_ACID_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_PSYCHEDELICS_HISTORY(form) &&
      Array.isArray(form["psychedelics_lsd_history"]) &&
      form["psychedelics_lsd_history"][0] === "LSD / Acid"
    );
  },
  DMT_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_PSYCHEDELICS_HISTORY(form) &&
      Array.isArray(form["psychedelics_dmt_history"]) &&
      form["psychedelics_dmt_history"][0] === "DMT"
    );
  },
  YES_INHALANTS_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_HISTORY_SUBSTANCE_USE(form) &&
      form["inhalants_history"] === YES
    );
  },
  WHIPPITS_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_INHALANTS_HISTORY(form) &&
      Array.isArray(form["inhalants_whippits_history"]) &&
      form["inhalants_whippits_history"][0] === "Whippits (nitrous oxide gas)"
    );
  },
  GLUE_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_INHALANTS_HISTORY(form) &&
      Array.isArray(form["inhalants_glue_history"]) &&
      form["inhalants_glue_history"][0] === "Glue"
    );
  },
  GAS_AEROSOLS_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_INHALANTS_HISTORY(form) &&
      Array.isArray(form["inhalants_gas_aerosols_history"]) &&
      form["inhalants_gas_aerosols_history"][0] === "Gas / aerosols"
    );
  },
  POPPERS_HISTORY: (form: GenericFormAnswers): boolean => {
    return (
      FormConditions.YES_INHALANTS_HISTORY(form) &&
      Array.isArray(form["inhalants_poppers_history"]) &&
      form["inhalants_poppers_history"][0] === "Poppers"
    );
  },
  YES_PREVIOUS_SUBSTANCE_USE_TREATMENT: (form: GenericFormAnswers): boolean => {
    return form["substance_use_treatment_history"] === YES;
  },
};

/**
 * Adapted from https://github.com/Grow-Therapy-Team/Grow-Dashboard/blob/main/grow-therapy-frontend/apps/csr-app/src/app/client-portal/Intake/components/FormSectionCard.tsx#L27-L33
 */
export function shouldShowQuestion(
  condition?: string | null,
  values?: GenericFormAnswers,
) {
  if (!condition || !values) return true;

  const check = FormConditions[condition];

  if (!check) return true;

  return check(values);
}
