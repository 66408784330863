import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { Button, Icon, Text } from "@growtherapy/sprout-ui";
import classNames from "classnames";
import { ElementType, PropsWithChildren } from "react";

type SelfViewControlButtonProps<TProps extends PropsWithChildren> = {
  as?: ElementType;
  buttonClassName?: string;
  icon: IconDefinition;
  label: string;
  labelClassName: string;
  labelIsVisible?: boolean;
  onClick?: () => void;
  screenReaderLabel: string;
} & TProps;

/**
 * A button that displays an icon and a label to overlay on the self view video
 * thumbnail. The button will automatically appear when the thumbnail is hovered
 * or focused. The label will be hidden by default; pass `labelClassName` to
 * orchestrate its visibility based on the group hover.
 */
export const SelfViewControlButton = <TProps extends PropsWithChildren>({
  as: Component = Button,
  buttonClassName,
  icon,
  label,
  labelClassName,
  labelIsVisible = true,
  onClick,
  screenReaderLabel,
  ...props
}: SelfViewControlButtonProps<TProps>) => {
  return (
    <Component
      {...props}
      onClick={onClick}
      className={classNames(
        "opacity-0 group-hover/video-thumbnail:opacity-100 group-focus/video-thumbnail:opacity-100 focus:opacity-100 px-1.5 h-8 hover:w-auto focus:w-auto flex gap-1 items-center justify-center shadow-border-none focus-visible:shadow-border-none bg-neutral-800 hover:bg-neutral-800 active:bg-neutral-800 text-neutral-300 active:text-neutral-300 outline-transparent focus-visible:outline-none focus-visible:outline-neutral-800 focus-visible:outline-offset-0 border-2 border-transparent focus-visible:border-lilac-400",
        buttonClassName,
      )}
    >
      {labelIsVisible && (
        <Text
          as="span"
          variant="sm"
          aria-hidden
          className={classNames("font-normal hidden", labelClassName)}
        >
          {label}
        </Text>
      )}
      <Icon aria-hidden className="w-4 h-4" icon={icon} />
      <span className="sr-only">{screenReaderLabel}</span>
    </Component>
  );
};
