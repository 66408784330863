import { useCallback } from "react";
import { DataTrackMessage, DataTrackMessageType } from "../../twilio/types";

import { Mutex } from "async-mutex";

import { useUpdateDataTrackAppStateCallback } from "./useUpdateDataTrackAppStateCallback";
import { useHandleWhiteboardUpdateCallback } from "../../twilio/messages/useHandleWhiteboardUpdateMessage";
import { useLoadWhiteboardStoreSnapshotCallback } from "./useLoadWhiteboardStoreSnapshotCallback";

const handleReceivedMessageMutex = new Mutex();

/**
 * Returns a callback that handles received messages on the data track for the
 * client
 */
export function useHandleReceivedMessageCallback() {
  const updateDataTrackState = useUpdateDataTrackAppStateCallback();
  const updateWhiteboard = useHandleWhiteboardUpdateCallback();
  const loadWhiteboardStoreSnapshot = useLoadWhiteboardStoreSnapshotCallback();

  return useCallback(
    (message: DataTrackMessage) => {
      return handleReceivedMessageMutex.runExclusive(async () => {
        switch (message.type) {
          case DataTrackMessageType.APP_STATE:
            updateDataTrackState(message);
            break;
          case DataTrackMessageType.WHITEBOARD_UPDATE:
            await updateWhiteboard(message);
            break;
          case DataTrackMessageType.WHITEBOARD_SNAPSHOT:
            await loadWhiteboardStoreSnapshot(message);
            break;
        }
      });
    },
    [updateDataTrackState, updateWhiteboard, loadWhiteboardStoreSnapshot],
  );
}
