import {
  ConnectionState as SyncConnectionState,
  SyncMapItem,
} from "twilio-sync";
import {
  LocalAudioTrack,
  LocalDataTrack,
  LocalParticipant,
  LocalTrackPublication,
  LocalVideoTrack,
  NetworkQualityLevel,
  NoiseCancellation,
  NetworkQualityStats,
  RemoteDataTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  RemoteVideoTrack,
  Room,
  Track,
  TwilioError,
} from "twilio-video";
import { Message } from "@twilio/conversations";
import { DataTrackAppState, WhiteboardUpdate } from "./messages/types";
import { StoreSnapshot, TLRecord } from "tldraw";

// source: twilio-video/tsdef/Room.d.ts
export type TwilioRoomEventListeners = {
  disconnected: (...args: [Room, TwilioError]) => Promise<void>;
  dominantSpeakerChanged: (...args: [RemoteParticipant]) => Promise<void>;
  participantConnected: (...args: [RemoteParticipant]) => Promise<void>;
  participantDisconnected: (...args: [RemoteParticipant]) => Promise<void>;
  participantReconnected: (...args: [RemoteParticipant]) => Promise<void>;
  participantReconnecting: (...args: [RemoteParticipant]) => Promise<void>;
  reconnected: () => Promise<void>;
  reconnecting: (...args: [TwilioError]) => Promise<void>;
  recordingStarted: () => Promise<void>;
  recordingStopped: () => Promise<void>;
  trackDimensionsChanged: (
    ...args: [RemoteVideoTrack, RemoteParticipant]
  ) => Promise<void>;
  trackDisabled: (
    ...args: [RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackEnabled: (
    ...args: [RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackMessage: (
    ...args: [string, RemoteDataTrack, RemoteParticipant]
  ) => Promise<void>;
  trackPublished: (
    ...args: [RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackPublishPriorityChanged: (
    ...args: [Track.Priority, RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackStarted: (...args: [RemoteTrack, RemoteParticipant]) => Promise<void>;
  trackSubscribed: (
    ...args: [RemoteTrack, RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackSubscriptionFailed: (
    ...args: [TwilioError, RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackSwitchedOff: (
    ...args: [RemoteTrack, RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackSwitchedOn: (
    ...args: [RemoteTrack, RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackUnpublished: (
    ...args: [RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackUnsubscribed: (
    ...args: [RemoteTrack, RemoteTrackPublication, RemoteParticipant]
  ) => Promise<void>;
  trackWarning: (
    ...args: [string, LocalTrackPublication, LocalParticipant]
  ) => Promise<void>;
  trackWarningsCleared: (
    ...args: [LocalTrackPublication, LocalParticipant]
  ) => Promise<void>;
};

export type TrackSubscriptionHandler = (
  ...args: Parameters<TwilioRoomEventListeners["trackSubscribed"]>
) => void;
export type ParticipantConnectionEventHandler = (
  ...args: Parameters<TwilioRoomEventListeners["participantConnected"]>
) => void;
export type RoomDisconnectedEventHandler = (
  ...args: Parameters<TwilioRoomEventListeners["disconnected"]>
) => void;
export type RoomReconnectedEventHandler = (
  ...args: Parameters<TwilioRoomEventListeners["reconnected"]>
) => void;
export type RoomReconnectingEventHandler = (
  ...args: Parameters<TwilioRoomEventListeners["reconnecting"]>
) => void;

export type ConnectionError = {
  terminal: boolean;
  message: string;
};

export type SyncMapEvent = "itemAdded" | "itemUpdated" | "itemRemoved";

export type SyncMapEventHandler = (SyncMapItem: { item: SyncMapItem }) => void;

export type TrackEvent =
  | "trackSubscribed"
  | "trackUnsubscribed"
  | "trackSwitchedOff"
  | "trackSwitchedOn"
  | "trackEnabled";

export type LocalAVTrack =
  | LocalVideoTrack
  | (LocalAudioTrack & { noiseCancellation?: NoiseCancellation });

export type DataTrackSender = {
  entityId: string;
  entityType: string;
};

export enum DataTrackMessageType {
  APP_STATE = "APP_STATE",
  WHITEBOARD_UPDATE = "WHITEBOARD_UPDATE",
  WHITEBOARD_SNAPSHOT = "WHITEBOARD_SNAPSHOT",
}

export type DataTrackAppStateMessage = {
  type: DataTrackMessageType.APP_STATE;
  participantState: DataTrackAppState;
  sender: DataTrackSender;
};

export type DataTrackWhiteboardUpdateMessage = {
  type: DataTrackMessageType.WHITEBOARD_UPDATE;
  update: WhiteboardUpdate;
  sender: DataTrackSender;
};

export type DataTrackWhiteboardSnapshotMessage = {
  type: DataTrackMessageType.WHITEBOARD_SNAPSHOT;
  snapshot: StoreSnapshot<TLRecord>;
  sender: DataTrackSender;
};

export type DataTrackMessage =
  | DataTrackAppStateMessage
  | DataTrackWhiteboardUpdateMessage
  | DataTrackWhiteboardSnapshotMessage;

export type LocalTrack = LocalDataTrack | LocalAVTrack;

export type TrackKind = "audio" | "video" | "data";

export type AudioVideoTrackKind = Extract<TrackKind, "audio" | "video">;

export enum TrackSource {
  Camera = "camera",
  Screen = "screen",
  Microphone = "microphone",
}

export type NetworkQualityChangeHandler = (
  networkQualityLevel: NetworkQualityLevel,
  networkQualityStats: NetworkQualityStats,
) => void;

export type MessageHandler = (message: Message) => void;

export type ConnectionStateStatus = SyncConnectionState;

// Enum for the ConnectionStateStatus i.e. ConnectionState from
// twilio-sync/conversations
export enum ConnectionState {
  CONNECTED = "connected",
  CONNECTING = "connecting",
  DISCONNECTED = "disconnected",
  DISCONNECTING = "disconnecting",
  DENIED = "denied",
  ERROR = "error",
  UNKNOWN = "unknown",
}

export type Constraint =
  | ConstrainDOMString
  | ConstrainBoolean
  | ConstrainDouble
  | ConstrainULong;

// enum for MediaDeviceKind
export enum DeviceType {
  AUDIO_INPUT = "audioinput",
  AUDIO_OUTPUT = "audiooutput",
  VIDEO_INPUT = "videoinput",
}

export enum ConsentStatus {
  UNKNOWN = "UNKNOWN", // When the consent status has not been fetched
  UNDECIDED = "UNDECIDED", // When the participant has not yet opted in or out
  DECIDING = "DECIDING", // When the provider prompts the patient to consent
  OPTED_OUT = "OPTED_OUT", // When the participant has explicity declined consent
  OPTED_IN = "OPTED_IN", // When the participant has consented
  SOFT_NO = "SOFT_NO", // When the participant has declined consent in-session (not recorded in BE)
}

export enum ConsentUpdateSource {
  UNKNOWN = "unknown",
  TELEHEALTH = "telehealth",
  PROVIDER_DASHBOARD = "provider_dashboard",
  CLIENT_PORTAL = "client_portal",
}

export enum ClientTranscriptionUserAction {
  REJECT = "reject", // Applied when user clicks to reject consent
  DISMISS = "dismiss", // Applied when user closes consent prompt without explicitly clicking reject button
}
