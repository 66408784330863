import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { Icon, Text } from "@growtherapy/sprout-ui";
import { twMerge } from "tailwind-merge";

import { OldToast } from "../../../components";
import { useDismissible } from "../../../hooks/useDismissible";
import { useEffect } from "react";

type UseDismissibleProps = ReturnType<typeof useDismissible>;

export type UpdatedUICalloutComponentProps = {
  className?: string;
  isDismissed: UseDismissibleProps["isDismissed"];
  setDismissibleState: UseDismissibleProps["setDismissibleState"];
};
export function UpdatedUICalloutComponent({
  className,
  isDismissed,
  setDismissibleState,
}: UpdatedUICalloutComponentProps) {
  if (isDismissed) return null;

  return (
    <OldToast
      onClose={() => setDismissibleState(new Date())}
      className={twMerge("gap-2 items-start", className)}
      data-testid="updated-ui-callout"
    >
      <Icon aria-hidden icon={faSparkles} className="mt-1" />
      <div>
        <Text variant="md" className="font-medium">
          This page has been updated!
        </Text>
        <Text variant="sm" className="mt-1">
          You can now check your audio & video settings before your session.
        </Text>
      </div>
    </OldToast>
  );
}

const UPDATED_UI_FEATURE_NAME = "client-intro-state-v2";
const SEEN_IT_ONCE_DURATION = 1000 * 60 * 60 * 24 * 7; // 1 week
export function UpdatedUICallout({ className }: { className?: string }) {
  const { isDismissed, dismissibleState, setDismissibleState } = useDismissible(
    UPDATED_UI_FEATURE_NAME,
  );

  const { dismissedAt } = dismissibleState;

  useEffect(() => {
    if (isDismissed) return;
    if (dismissedAt) return;

    setDismissibleState(Date.now() + SEEN_IT_ONCE_DURATION); // 1 week
  }, [dismissedAt, isDismissed, setDismissibleState]);

  return (
    <UpdatedUICalloutComponent
      className={className}
      isDismissed={isDismissed}
      setDismissibleState={setDismissibleState}
    />
  );
}
