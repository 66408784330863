import { Button, InputControl, TextInput } from "@growtherapy/sprout-ui";
import { ChangeEvent, useCallback, useState } from "react";
import { useSetAtom } from "jotai";
import { VisitorState, visitorStateAtom } from "../state";
import { AVPermissionCallout } from "./AVPermissionCallout";
import {
  EMPTY_INPUT_ERROR,
  MAX_NAME_LENGTH,
  TOO_LONG_INPUT_ERROR,
  UNSAFE_CHARACTERS_INPUT_ERROR,
} from "./constants";
import {
  containsNonAsciiCharacters,
  isOverCharacterLimit,
  shouldWarnForUnsafeCharacters,
} from "./utils";
import { twMerge } from "tailwind-merge";
import { setDefaultWaitingRoomFaviconAndTitle } from "../waiting-room/metadata";
import { useVisitorName } from "./useVisitorName";

type WrapperPassThroughProps = {
  buttonLabel?: string;
  buttonClassName?: string;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  shouldDisableSubmitWhenInvalid?: boolean;
  showAVPermissionCallout?: boolean;
};
export interface InputControlsProps extends WrapperPassThroughProps {
  children?: React.ReactNode;
  onEnterWaitingRoom: (visitorName: string) => void;
  setVisitorName: (visitorName: string) => void;
  visitorName: string;
}

export function InputControls({
  buttonLabel = "Enter waiting room",
  buttonClassName,
  className,
  onChange,
  onEnterWaitingRoom,
  setVisitorName,
  shouldDisableSubmitWhenInvalid = false,
  showAVPermissionCallout = true,
  visitorName,
}: InputControlsProps) {
  const [visitorNameInputError, setVisitorNameInputError] = useState("");

  const validateAndEnter = () => {
    if (!visitorName || visitorName.trim().length === 0) {
      setVisitorNameInputError(EMPTY_INPUT_ERROR);
    } else {
      setVisitorNameInputError("");
      onEnterWaitingRoom(visitorName.trim());
    }
  };

  const isErrorBlockingSubmit = visitorNameInputError === EMPTY_INPUT_ERROR;

  const isDisabled =
    shouldDisableSubmitWhenInvalid && (isErrorBlockingSubmit || !visitorName);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    if (
      visitorNameInputError &&
      (input.trim().length > 0 ||
        !isOverCharacterLimit(input, MAX_NAME_LENGTH) ||
        !containsNonAsciiCharacters(input))
    ) {
      setVisitorNameInputError("");
    }
    if (isOverCharacterLimit(input, MAX_NAME_LENGTH)) {
      setVisitorNameInputError(TOO_LONG_INPUT_ERROR);
    }
    if (shouldWarnForUnsafeCharacters(input)) {
      setVisitorNameInputError(UNSAFE_CHARACTERS_INPUT_ERROR);
    }
    setVisitorName(input);

    onChange?.(event);
  };

  return (
    <div className={twMerge("flex flex-col gap-y-4", className)}>
      <InputControl
        error={visitorNameInputError}
        label="Enter your first and last name"
        className="w-full mb-0"
      >
        <TextInput
          value={visitorName}
          name="visitor-name"
          onChange={onInputChange}
          onEnter={validateAndEnter}
          error={!!visitorNameInputError}
        />
      </InputControl>
      {showAVPermissionCallout && <AVPermissionCallout />}
      <Button
        disabled={isDisabled}
        onClick={validateAndEnter}
        type="submit"
        use="primary"
        className={buttonClassName}
      >
        {buttonLabel}
      </Button>
    </div>
  );
}

export type InputControlsWrapperProps = WrapperPassThroughProps & {
  showAVPermissionCallout?: boolean;
};

export function InputControlsWrapper(props: InputControlsWrapperProps) {
  const [visitorName, setVisitorName] = useVisitorName();
  const setVisitorState = useSetAtom(visitorStateAtom);

  const onEnterWaitingRoom = useCallback(
    (visitorName: string) => {
      setVisitorName(visitorName);
      setVisitorState(VisitorState.WAITING);
      setDefaultWaitingRoomFaviconAndTitle();
    },
    [setVisitorName, setVisitorState],
  );

  return (
    <InputControls
      {...props}
      onEnterWaitingRoom={onEnterWaitingRoom}
      setVisitorName={setVisitorName}
      visitorName={visitorName}
    />
  );
}
