import { useAtomValue } from "jotai";
import { participantCountAtom } from "../state";
import { isRecordingAtom } from "../messages/state";

export function useRecordingControl(
  hasAppointment: boolean = false,
  isPrescriber: boolean = false,
) {
  const isRecording = useAtomValue(isRecordingAtom).value;
  const inSession = !!useAtomValue(participantCountAtom);
  const isVisible = inSession && hasAppointment && !isPrescriber;

  return {
    isVisible,
    isRecording,
  };
}
