import { GroupedFormData } from "../types";
import { useAtom, useAtomValue } from "jotai";
import { formValuesForSelectedClientAtom } from "../state";
import { PageComponentProps } from "../../../components";
import { PendingForm } from "../../../components/forms/PendingForm";
import { isFunction } from "remeda";
import { Text } from "@growtherapy/sprout-ui";
import { isInSessionWithSelectedClientAtom } from "../../state";
import { CLIENT_HAS_LEFT_MESSAGE } from "../constants";
import { usePendingFormsContext } from "./usePendingFormsContext";
import {
  PendingFormProps,
  ValidationError,
} from "../../../components/forms/types";

export type ValidationErrorsByFormTitle = Record<string, Set<ValidationError>>;

export function PendingFormWrapper({
  page,
}: PageComponentProps<GroupedFormData>) {
  const pendingFormTitle = page?.formTitle;
  const [formValuesByTitle, setFormValuesByTitle] = useAtom(
    formValuesForSelectedClientAtom,
  );
  const initialFormValues =
    formValuesByTitle && formValuesByTitle[pendingFormTitle];
  const onChange: PendingFormProps["onChange"] = (valuesOrFunction) => {
    setFormValuesByTitle((prev) => {
      const prevFormValues = prev?.[pendingFormTitle];
      return {
        ...prev,
        [pendingFormTitle]: isFunction(valuesOrFunction)
          ? valuesOrFunction(prevFormValues)
          : valuesOrFunction,
      };
    });
  };
  const { validationErrorsByFormTitle } = usePendingFormsContext();
  const isInSessionWithSelectedClient = useAtomValue(
    isInSessionWithSelectedClientAtom,
  );
  const validationErrors = validationErrorsByFormTitle[pendingFormTitle];

  if (!isInSessionWithSelectedClient) {
    return (
      <Text variant="xs" className="text-neutral-700 text-center">
        {CLIENT_HAS_LEFT_MESSAGE}
      </Text>
    );
  }

  return (
    <PendingForm
      form={page}
      initialValues={initialFormValues}
      onChange={onChange}
      validationErrors={validationErrors}
    />
  );
}
