import classnames from "classnames";
import { GrowLogo } from "./GrowLogo";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../hooks";
import { Theme } from "../state";
import { Button } from "@growtherapy/sprout-ui";
import { HTMLAttributes } from "react";

type NavbarProps = {
  className?: string;
  onClickNeedHelpButton?: () => void;
  TranscriptionPillComponent?: React.FC;
} & HTMLAttributes<HTMLDivElement>;

export function Navbar({
  className,
  onClickNeedHelpButton,
  TranscriptionPillComponent,
  ...props
}: NavbarProps): JSX.Element {
  const { theme } = useTheme();
  const showNeedHelp = !!onClickNeedHelpButton;
  return (
    <div
      className={twMerge(
        classnames(
          {
            "bg-neutral-300": theme === Theme.LIGHT,
            "bg-neutral-800": theme === Theme.DARK,
          },
          "relative flex items-center justify-between px-6 py-7 h-18",
        ),
        className,
      )}
      {...props}
    >
      <div className="flex content-start items-center gap-4">
        <GrowLogo />
        {TranscriptionPillComponent && <TranscriptionPillComponent />}
      </div>
      {showNeedHelp && (
        <Button
          use="unstyled"
          className={twMerge(
            classnames({
              "text-lilac-700": theme === Theme.LIGHT,
              "text-neutral-100": theme === Theme.DARK,
            }),
            "px-0 py-0 underline hover:brightness-85 active:brightness-70",
          )}
          onClick={onClickNeedHelpButton}
        >
          <span className="hidden sm:block">Need help?</span>
          <span className="block sm:hidden">?</span>
        </Button>
      )}
    </div>
  );
}
