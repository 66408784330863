import { CalloutBanner } from "@growtherapy/sprout-ui";

import { faVideo } from "@fortawesome/pro-solid-svg-icons";

export function AVPermissionCallout() {
  return (
    <CalloutBanner
      data-testid="av-permission-callout"
      use="lilac"
      icon={faVideo}
    >
      You may see a pop-up requesting access to your camera and microphone once
      you enter the waiting room.
    </CalloutBanner>
  );
}
