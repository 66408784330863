import { useCallback } from "react";
import { DataTrackMessage, DataTrackMessageType } from "../../twilio/types";
import { Mutex } from "async-mutex";
import { useUpdateDataTrackAppStateCallback } from "./useUpdateDataTrackAppStateCallback";
import { useHandleWhiteboardUpdateCallback } from "../../twilio/messages/useHandleWhiteboardUpdateMessage";

const handleReceivedMessageMutex = new Mutex();

/**
 * Returns a callback that handles received messages on the data track for the
 * provider
 */
export function useHandleReceivedMessageCallback() {
  const updateDataTrackState = useUpdateDataTrackAppStateCallback();
  const updateWhiteboard = useHandleWhiteboardUpdateCallback();
  return useCallback(
    (message: DataTrackMessage) => {
      return handleReceivedMessageMutex.runExclusive(async () => {
        switch (message.type) {
          // Handles messages related to app state: consent, recording, whiteboard mode, etc.
          case DataTrackMessageType.APP_STATE:
            await updateDataTrackState(message);
            break;
          // Handles incoming whiteboard updates
          case DataTrackMessageType.WHITEBOARD_UPDATE:
            await updateWhiteboard(message);
            break;
        }
      });
    },
    [updateDataTrackState, updateWhiteboard],
  );
}
