import enableAudioToneUri from "../../assets/audio/enable-audio-tone.mp3?url";
import { gracefullyPlayAudio } from "../../audio";
import { NOTIFICATION_VOLUME } from "../../config";
import { ToastRenderFnProps, Toast, ToastVariant } from "../../toasts";
import { ToastButton } from "../../toasts/ToastButton";

type RestrictedAudioPlaybackToastProps = {
  onEnableAudio: () => void;
} & ToastRenderFnProps;

export function RestrictedAudioPlaybackToast({
  contentProps,
  descriptionProps,
  titleProps,
  onEnableAudio,
  ...toastAriaProps
}: RestrictedAudioPlaybackToastProps) {
  const enableAudio = async () => {
    const audio = new Audio(enableAudioToneUri);
    audio.volume = NOTIFICATION_VOLUME;
    await gracefullyPlayAudio(audio);
    onEnableAudio();
  };

  return (
    <Toast
      className="sm:w-max"
      closeButtonSize="sm"
      variant={ToastVariant.Info}
      {...toastAriaProps}
    >
      <div className="flex items-center gap-3" {...contentProps}>
        <span className="sr-only" {...titleProps}>
          Info:
        </span>
        <span {...descriptionProps}>
          Your browser may have restricted audio from playing.
        </span>
        <ToastButton onClick={enableAudio}>Enable audio</ToastButton>
      </div>
    </Toast>
  );
}
