import { Button, DrawerFooter, Tooltip } from "@growtherapy/sprout-ui";
import { useAdmitClientCallback } from "../clients-drawer/useAdmitClientCallback";
import { openWaitingRoomConversation } from "../clients-drawer/openWaitingRoomConversation";
import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import {
  waitingRoomConversationSidsByVisitorIdAtom,
  ProviderDrawerState,
  sessionConversationSidAtom,
} from "../state";
import { useOpenConversationCallback } from "../chat/useOpenConversationCallback";
import { useAtomValue } from "jotai";
import { ParticipantStatus } from "../../types";

type ClientInformationDrawerFooterProps = {
  visitorUuid: string;
  visitorName: string;
  admitClient?: (
    visitorUuid: string,
    visitorName: string,
    patientShortId: string,
  ) => void;
  visitorStatus?: ParticipantStatus;
  waitingForParticipantToJoin?: boolean;
  openConversation: () => void;
  status?: ParticipantStatus;
  patientShortId: string;
};

export function ClientInformationDrawerFooter({
  visitorUuid,
  visitorName,
  visitorStatus,
  admitClient,
  waitingForParticipantToJoin,
  openConversation,
  patientShortId,
}: ClientInformationDrawerFooterProps) {
  return (
    <DrawerFooter>
      <div className="flex flex-col w-full h-full justify-between">
        <div className="flex justify-end gap-2">
          {admitClient && (
            <Tooltip
              text="This client has left the waiting room and can no longer be admitted."
              disabled={!!visitorStatus}
              /**
               * Although this Tooltip wraps a Button, the tooltip only ever
               * shows when the Button is disabled. In order to make this
               * tooltip still accessible for all users, we're wrapping the
               * Button in a <div/> so that there is an element to add the
               * tooltip props to & setting childIsInteractive={false} (since
               * the Button won't be actually interactive when the tooltip is
               * enabled).
               */
              childIsInteractive={false}
            >
              <div>
                <Button
                  data-testid="client-information.footer.button.admit"
                  loading={waitingForParticipantToJoin}
                  onClick={() =>
                    admitClient(visitorUuid, visitorName, patientShortId)
                  }
                  size="sm"
                  disabled={!visitorStatus}
                >
                  Admit
                </Button>
              </div>
            </Tooltip>
          )}
          <Tooltip
            text="This client has left and can no longer chat."
            disabled={!!visitorStatus}
            /**
             * Although this Tooltip wraps a Button, the tooltip only ever shows
             * when the Button is disabled. In order to make this tooltip still
             * accessible for all users, we're wrapping the Button in a <div/>
             * so that there is an element to add the tooltip props to & setting
             * childIsInteractive={false} (since the Button won't be actually
             * interactive when the tooltip is enabled).
             */
            childIsInteractive={false}
          >
            <div>
              <Button
                data-testid="client-information.footer.button.chat"
                disabled={!visitorStatus}
                onClick={openConversation}
                size="sm"
                use="secondary"
              >
                Chat
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
    </DrawerFooter>
  );
}

type ClientInformationDrawerFooterWrapperProps = {
  visitorUuid: string;
  visitorName: string;
  visitorStatus?: ParticipantStatus;
  patientShortId: string;
};

export function ClientInformationDrawerFooterWrapper({
  visitorUuid,
  visitorStatus,
  visitorName,
  patientShortId,
}: ClientInformationDrawerFooterWrapperProps) {
  const [admitClient, { loading: waitingForParticipantToJoin }] =
    useAdmitClientCallback();
  const getWaitingRoomConversationSids = useAtomCallback(
    useCallback((get) => get(waitingRoomConversationSidsByVisitorIdAtom), []),
  );
  const sessionConversationSid = useAtomValue(sessionConversationSidAtom);
  const openConversation = useOpenConversationCallback();
  const onOpenWaitingRoomConversation = () =>
    openWaitingRoomConversation(
      visitorUuid,
      getWaitingRoomConversationSids,
      openConversation,
    );
  const onOpenSessionConversation = () => {
    openConversation(ProviderDrawerState.SESSION_CHAT, sessionConversationSid);
  };

  const participantIsInSession = visitorStatus === ParticipantStatus.IN_SESSION;
  return (
    <ClientInformationDrawerFooter
      visitorUuid={visitorUuid}
      visitorName={visitorName}
      admitClient={participantIsInSession ? undefined : admitClient}
      visitorStatus={visitorStatus}
      waitingForParticipantToJoin={waitingForParticipantToJoin}
      patientShortId={patientShortId}
      openConversation={
        participantIsInSession
          ? onOpenSessionConversation
          : onOpenWaitingRoomConversation
      }
    />
  );
}
