import { useAtom, useAtomValue } from "jotai";
import { useParams } from "react-router-dom";
import { HelpfulTipsDrawer } from "./waiting-room/HelpfulTipsDrawer";
import {
  VisitorDrawerState,
  activeConversationSidAtom,
  visitorDrawerStateAtom,
} from "./state";
import { PushDrawer, PushDrawerProps, SettingsDrawer } from "../components";
import { tabMap } from "./settingsTabMap";
import { currentConversationSidAtom } from "../twilio";
import { ChatDrawerWrapper as ChatDrawer } from "./chat/ChatDrawer";
import { useCallback, useEffect, useRef } from "react";
import { MoodCheckInDrawerWrapper as MoodCheckInDrawer } from "./components/forms-drawer/MoodCheckInDrawer";
import { ClientMeasuresDrawerWrapper as ClientMeasuresDrawer } from "./measures/ClientMeasuresDrawer";
import { useDrawerButtonRefs } from "../hooks/useDrawerButtonRefs";

function DrawerContent({
  drawerState,
  patientShortId,
}: {
  drawerState: VisitorDrawerState | null;
  patientShortId?: string;
}) {
  switch (drawerState) {
    case VisitorDrawerState.SETTINGS:
      return <SettingsDrawer tabMap={tabMap} />;
    case VisitorDrawerState.HELPFUL_TIPS_BEFORE_SESSION:
      return (
        <HelpfulTipsDrawer
          drawerState={VisitorDrawerState.HELPFUL_TIPS_BEFORE_SESSION}
        />
      );
    case VisitorDrawerState.HELPFUL_TIPS_AFTER_SESSION:
      return (
        <HelpfulTipsDrawer
          drawerState={VisitorDrawerState.HELPFUL_TIPS_AFTER_SESSION}
        />
      );
    case VisitorDrawerState.CHAT:
      return <ChatDrawer />;
    case VisitorDrawerState.MOOD_CHECK_IN_FORM:
      return <MoodCheckInDrawer patientShortId={patientShortId} />;
    case VisitorDrawerState.PENDING_FORMS:
      return <ClientMeasuresDrawer />;
    default:
      <></>;
  }
}

export function PushDrawerWrapper({
  className,
  id,
  onCloseDrawer: onCloseDrawerProp,
}: Pick<PushDrawerProps, "className" | "id"> & {
  onCloseDrawer?: () => void;
}) {
  const { buttonRefs: openButtonRefs } = useDrawerButtonRefs();
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const { patientShortId } = useParams();
  const [visitorDrawerState, setVisitorDrawerState] = useAtom(
    visitorDrawerStateAtom,
  );
  const [currentConversationSid, setCurrentConversationSid] = useAtom(
    currentConversationSidAtom,
  );
  const activeConversationSid = useAtomValue(activeConversationSidAtom);

  const onCloseDrawer = useCallback((): void => {
    setVisitorDrawerState(null);
    if (currentConversationSid) {
      setCurrentConversationSid(undefined);
    }
    onCloseDrawerProp?.();
    if (visitorDrawerState) {
      // On drawer close, try to refocus the button that would normally open
      // whatever `drawerState` was active at the time of closing.
      openButtonRefs.current?.get(visitorDrawerState)?.current?.focus();
    }
  }, [
    currentConversationSid,
    setCurrentConversationSid,
    setVisitorDrawerState,
    visitorDrawerState,
    openButtonRefs,
    onCloseDrawerProp,
  ]);

  // On drawer state change, refocus the "close" button.
  useEffect(() => {
    if (visitorDrawerState) {
      closeButtonRef.current?.focus();
    }
  }, [visitorDrawerState]);

  useEffect(() => {
    if (
      visitorDrawerState === VisitorDrawerState.CHAT &&
      !currentConversationSid
    ) {
      setCurrentConversationSid(activeConversationSid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConversationSid, visitorDrawerState, activeConversationSid]);

  return (
    <PushDrawer
      id={id}
      isOpen={!!visitorDrawerState}
      onClose={onCloseDrawer}
      className={className}
      closeButtonRef={closeButtonRef}
    >
      <DrawerContent
        drawerState={visitorDrawerState}
        patientShortId={patientShortId}
      />
    </PushDrawer>
  );
}
