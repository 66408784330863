import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../hooks";
import { Theme } from "../state";
import { HTMLAttributes, memo } from "react";

/**
 * The container for the center area in the waiting room and meeting rooms
 */
export const StageContainer = memo(function StageContainer({
  children,
  className,
  rounded,
  ...otherProps
}: {
  children: React.ReactNode;
  className?: string;
  rounded?: boolean;
} & HTMLAttributes<HTMLDivElement>) {
  const { theme } = useTheme();
  return (
    <div
      className={twMerge(
        classNames(
          {
            "sm:rounded-2xl border-neutral-800 border": rounded,
            "bg-neutral-300": theme === Theme.LIGHT,
            "bg-neutral-800": theme === Theme.DARK,
          },
          "overflow-hidden relative flex flex-col items-center flex-grow justify-center",
          className,
        ),
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
});
