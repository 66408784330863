import { IconBadge, IconBadgeUse, Text } from "@growtherapy/sprout-ui";
import { Toast } from "../toasts/Toast";
import { faMessage } from "@fortawesome/pro-solid-svg-icons";
import { ModifiedToastAria } from "../toasts";

export function NewMessageToast({
  senderName,
  messageContent,
  onOpenChatDrawer,
  contentProps,
  descriptionProps,
  titleProps,
  ...toastAriaProps
}: {
  senderName: string;
  messageContent: string;
  onOpenChatDrawer: () => void;
  className?: string;
} & ModifiedToastAria) {
  return (
    <Toast
      className="sm:w-[30rem]"
      closeButtonPlacement="top"
      closeButtonSize="sm"
      {...toastAriaProps}
    >
      <button
        className="flex gap-4 w-full align-start fs-exclude min-w-0"
        data-dd-privacy="mask"
        data-dd-action-name="Click on new message toast button"
        onClick={onOpenChatDrawer}
        aria-label="Open message"
      >
        <IconBadge
          aria-hidden
          icon={faMessage}
          size="lg"
          use={IconBadgeUse.Lilac}
        />
        <div className="flex flex-col min-w-0" {...contentProps}>
          <Text
            {...titleProps}
            data-dd-privacy="mask"
            data-dd-action-name="Click on new message toast title"
            className="grow font-medium text-lilac-700 text-left fs-exclude"
          >
            <span className="sr-only">{`New message from ${senderName}:`}</span>
            <span aria-hidden>{senderName}</span>
          </Text>
          <Text
            {...descriptionProps}
            data-dd-privacy="mask"
            data-dd-action-name="Click on new message toast body"
            variant="sm"
            className="line-clamp-2 sm:line-clamp-3 text-neutral-800 text-left fs-exclude break-words"
          >
            {messageContent}
          </Text>
        </div>
      </button>
    </Toast>
  );
}
