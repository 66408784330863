import { Icon, Text } from "@growtherapy/sprout-ui";
import { OldToast, ToastProps, ToastVariant } from "../../components";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";

export function ConnectionErrorToast(props: Omit<ToastProps, "children">) {
  const supportText = (
    <Text variant="sm">
      Unfortunately, we can&apos;t connect you. Please try refreshing. If the
      issue persists, please contact support through your portal.
    </Text>
  );

  return (
    <OldToast
      className="top-center-toast sm:max-w-[41.5rem]"
      variant={ToastVariant.Neutral}
      data-testid="connection-error-toast"
      {...props}
    >
      <Icon aria-hidden className="mr-3 text-coral-600" icon={faWarning} />
      {supportText}
    </OldToast>
  );
}
