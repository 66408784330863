import { Text } from "@growtherapy/sprout-ui";
import {
  BackgroundImageType,
  BackgroundSettings,
  BackgroundType,
} from "../../twilio";
import { BackgroundButton } from "./BackgroundButton";
import { TrackingEvents, sendLoggingEvents } from "../../events";

export function BackgroundThumbnailGrid({
  images,
  title,
  setBackgroundSettings,
  selectedBackground,
  selectedBackgroundIndex,
  backgroundsSelectionIsDisabled,
  isMirrored,
  hasBorder,
}: {
  images: BackgroundImageType[];
  title: string;
  setBackgroundSettings: (backgroundSettings: BackgroundSettings) => void;
  selectedBackground?: BackgroundType;
  selectedBackgroundIndex?: number;
  backgroundsSelectionIsDisabled?: boolean;
  isMirrored: boolean;
  hasBorder?: boolean;
}) {
  return (
    <>
      <Text variant="sm" className="font-medium pb-2" as="h3">
        {title}
      </Text>
      <ul className="grid grid-cols-3 gap-4 pb-3">
        {images.map((image) => {
          return (
            <li key={image.id} className="max-h-20">
              <BackgroundButton
                isDisabled={backgroundsSelectionIsDisabled}
                isSelected={
                  selectedBackground === BackgroundType.IMAGE &&
                  selectedBackgroundIndex === image.id
                }
                isMirrored={isMirrored}
                image={image.thumb}
                altText={image.name}
                color={image.className}
                hasBorder={hasBorder}
                onClick={() => {
                  sendLoggingEvents(TrackingEvents.ANY_VIRTUAL_BACKGROUND_USE);
                  setBackgroundSettings({
                    backgroundType: BackgroundType.IMAGE,
                    backgroundIndex: image.id,
                  });
                }}
              />
            </li>
          );
        })}
      </ul>
    </>
  );
}
