export enum TrackingEvents {
  CLIENT_TAB_CLOSE = "client_tab_close",
  CLIENT_SESSION_LEAVE = "client_session_leave",
  PROVIDER_TAB_CLOSE = "provider_tab_close",
  PROVIDER_SESSION_COMPLETE = "provider_session_complete",
  PROVIDER_END_AND_START_NEW_SESSION = "provider_end_and_start_new_session",
  ANY_MESSAGE_SEND = "any_message_send",
  ANY_WAITING_ROOM_MESSAGE_SEND = "any_waiting_room_message_send",
  ANY_SESSION_MESSAGE_SEND = "any_session_message_send",
  PROVIDER_MESSAGE_SEND = "provider_message_send",
  PROVIDER_TEMPLATE_MESSAGE_SEND = "provider_template_message_send",
  PROVIDER_QUICK_REPLY_CANNED = "provider_quick_reply_canned",
  PROVIDER_QUICK_REPLY_CUSTOM = "provider_quick_reply_custom",
  PROVIDER_CLIENT_CHAT = "provider_client_chat",
  CLIENT_MESSAGE_SEND = "client_message_send",
  ANY_SCREEN_SHARE = "any_screen_share",
  PROVIDER_SCREEN_SHARE = "provider_screen_share",
  PROVIDER_WHITEBOARD_SHARE = "provider_whiteboard_share",
  PROVIDER_WHITEBOARD_STOP_SHARE = "provider_whiteboard_stop_share",
  CLIENT_SCREEN_SHARE = "client_screen_share",
  REMOTE_DATA_TRACK_MESSAGE_RECEIVED = "remote_data_track_message_received",
  REMOTE_DATA_TRACK_MESSAGE_SENT = "remote_data_track_message_sent",
  ANY_VIRTUAL_BACKGROUND_USE = "any_virtual_background_use",
  BLUR_BACKGROUND_USE = "blur_background_use",
  ANY_HELP_REQUEST = "any_help_request",
  PROVIDER_HELP_REQUEST = "provider_help_request",
  CLIENT_HELP_REQUEST = "client_help_request",
  CLIENT_PRE_SESSION_TIP_OPEN = "client_pre_session_tip_open",
  ANY_CONNECTIVTY_ISSUE_NOTIF = "any_connectivity_issue_notif",
  PROVIDER_CLIENT_REMOVE = "provider_client_remove",
  CLIENT_POST_SESSION_REDIRECT = "client_post_session_redirect",
  CLIENT_ENTER_SESSION_ROOM = "client_enter_session_room",
  PROVIDER_ENTER_SESSION_ROOM = "provider_enter_session_room",
  PROVIDER_ADMIT_CLIENT = "provider_admit_client",
  REMOTE_TRACK_SWITCHED_OFF = "remote_track_switched_off",
  REMOTE_TRACK_SWITCHED_ON = "remote_track_switched_on",
  REMOTE_TRACK_UNSUBSCRIBED = "remote_track_unsubscribed",
  REMOTE_TRACK_SUBSCRIBED = "remote_track_subscribed",
  DEVICE_ORIENTATION_CHANGE = "device_orientation_change",
  ROOM_RECONNECTING = "room_reconnecting",
  ROOM_RECONNECTED = "room_reconnected",
  LOCAL_TRACK_PUBLISHED = "local_track_published",
  SETTING_UP_AUDIO_RECORDING_ENV = "setting_up_audio_recording_env",
  STARTING_AUDIO_RECORDING = "starting_audio_recording",
  STOPPING_AUDIO_RECORDING = "stopping_audio_recording",
  TEARING_DOWN_AUDIO_RECORDING_ENV = "tearing_down_audio_recording_env",
}

import { sendDataDogEvent } from "./datadog/events";
import { sendFullStoryEvent } from "./fullstory/events";
import { logger } from "./datadog/logger";

/**
 * Send logging events to FullStory and DataDog. Optionally, log to Datadog.
 */
export function sendLoggingEvents(
  eventName: TrackingEvents,
  eventProperties?: object,
  {
    logLevel,
    message,
    error,
  }: { logLevel?: keyof typeof logger; message?: string; error?: Error } = {},
) {
  sendFullStoryEvent(eventName, eventProperties);
  sendDataDogEvent(eventName, eventProperties);
  if (!logLevel) return;
  logger[logLevel](
    message ?? eventName,
    { ...eventProperties, eventName },
    ...(error ? [error] : []),
  );
}
