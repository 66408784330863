import {
  AccessibleSelect,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  RadioGroupLayout,
  TextArea,
  TextInput,
} from "@growtherapy/sprout-ui";
import { QuestionTypeEnum } from "../types";
import { QuestionProps } from "./types";
import classNames from "classnames";
import EmergencyContactInput from "./EmergencyContactInput";
import ProfessionalResourceInput from "./ProfessionalResourceInput";

const CLIENT_DECLINED_TO_ANSWER = "Client declined to answer";

/**
 * Renders the appropriate input field based on the question type
 */
export function InputField(props: QuestionProps) {
  const { onChange, question, type, value } = props;
  const {
    name,
    title,
    options = [],
    placeholder: givenPlaceholder,
    layout,
  } = question;
  const placeholder = givenPlaceholder || "Your response here";
  const sharedProps = {
    "aria-label": !title ? name : undefined,
    "data-testid": name,
    name,
  };

  switch (type) {
    case QuestionTypeEnum.TEXT:
      return (
        <TextInput
          {...sharedProps}
          value={value}
          onChange={(e): void => onChange?.(question, e.target.value)}
          placeholder={placeholder}
        />
      );
    case QuestionTypeEnum.TEXT_AREA:
      return (
        <TextArea
          {...sharedProps}
          placeholder={placeholder}
          value={value}
          onChange={(e): void => onChange?.(question, e.target.value)}
        />
      );
    case QuestionTypeEnum.SELECT:
      return (
        <AccessibleSelect
          {...sharedProps}
          onChange={(selection: string): void =>
            onChange?.(question, selection)
          }
          options={options}
          placeholder={placeholder}
          value={value}
        />
      );
    case QuestionTypeEnum.MULTI_SELECT:
      return (
        <AccessibleSelect
          {...sharedProps}
          isMultiselect
          onChange={(selection: string): void => {
            const newSet = new Set(value);
            if (
              selection === CLIENT_DECLINED_TO_ANSWER &&
              !newSet.has(selection)
            ) {
              newSet.clear();
            }
            if (
              newSet.has(CLIENT_DECLINED_TO_ANSWER) &&
              selection !== CLIENT_DECLINED_TO_ANSWER
            ) {
              newSet.delete(CLIENT_DECLINED_TO_ANSWER);
            }
            if (newSet.has(selection)) {
              newSet.delete(selection);
            } else {
              newSet.add(selection);
            }
            onChange?.(question, Array.from(newSet));
          }}
          options={options}
          placeholder={placeholder}
          value={value}
        />
      );
    case QuestionTypeEnum.RADIO:
      return (
        <RadioGroup
          {...sharedProps}
          className={classNames({
            "min-h-unset": layout === "inline",
          })}
          onChange={(e): void => onChange?.(question, e.target.value)}
          layout={layout ? (layout as RadioGroupLayout) : "column"}
          value={value as string}
        >
          {options?.map(
            ({ label: optionLabel, value: optionValue }): JSX.Element => {
              const id = `${name}-${optionLabel}`;
              return (
                <Radio
                  key={id}
                  data-testid={id}
                  label={optionLabel}
                  value={optionValue}
                  className="text-base"
                />
              );
            },
          )}
        </RadioGroup>
      );
    case QuestionTypeEnum.CHECKBOX:
      return (
        <CheckboxGroup
          {...sharedProps}
          onChange={(newValue): void => onChange?.(question, newValue)}
          value={value as string[]}
        >
          {options?.map(
            ({ label: optionLabel, value: optionValue }): JSX.Element => {
              const id = `${name}-${optionLabel}`;
              return (
                <Checkbox
                  key={id}
                  id={id}
                  data-testid={id}
                  defaultChecked={(value ?? []).includes(optionValue)}
                  label={optionLabel}
                  value={optionValue}
                  textClassName="text-base"
                />
              );
            },
          )}
        </CheckboxGroup>
      );
    case QuestionTypeEnum.EMERGENCY_CONTACT_INPUT:
      return <EmergencyContactInput {...props} />;
    case QuestionTypeEnum.PROFESSIONAL_RESOURCE_INPUT:
      return <ProfessionalResourceInput {...props} />;
    default:
      return;
  }
}
