import { useCallback, useEffect } from "react";
import {
  isShowingScreenShareConfirmationAtom,
  localScreenShareAudioTrackAtom,
  localScreenShareTrackAtom,
  remoteScreenShareParticipantAtom,
  shouldTriggerPictureInPictureAtom,
  twilioRoomAtom,
} from "../state";
import { logger } from "../../datadog/logger";
import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { Room } from "twilio-video";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { exitPictureInPicture, parseIdentity } from "../utils";
import { UserType } from "../../types";
import { createTrackName } from "../config";
import { TrackSource } from "../types";
import { getDeviceType } from "../../utils";
import { Statsig } from "../../statsig/StatsigProvider";
import { dynamicConfig } from "../../statsig/gates";

function handleStopTrack(track: MediaStreamTrack, room: Room | undefined) {
  room?.localParticipant.unpublishTrack(track);
  track.stop();
}

export type ShareScreenOptions = {
  shouldTakeOverScreenShare?: boolean;
};

export default function useScreenShareCallbacks(onError?: ErrorCallback) {
  const setLocalScreenShareTrack = useSetAtom(localScreenShareTrackAtom);
  const setLocalScreenShareAudioTrack = useSetAtom(
    localScreenShareAudioTrackAtom,
  );
  const setShouldTriggerPictureInPicture = useSetAtom(
    shouldTriggerPictureInPictureAtom,
  );
  const getRoom = useAtomCallback(
    useCallback((get) => {
      return get(twilioRoomAtom);
    }, []),
  );
  const screenShareParticipant = useAtomValue(remoteScreenShareParticipantAtom);
  const getIsSomeoneElseSharing = useAtomCallback(
    useCallback((get) => !!get(remoteScreenShareParticipantAtom), []),
  );
  const setIsShowingScreenShareConfirmation = useSetAtom(
    isShowingScreenShareConfirmationAtom,
  );

  const getLocalScreenShareTrack = useAtomCallback(
    useCallback((get) => {
      return get(localScreenShareTrackAtom);
    }, []),
  );

  const getLocalScreenShareAudioTrack = useAtomCallback(
    useCallback((get) => {
      return get(localScreenShareAudioTrackAtom);
    }, []),
  );

  const shareScreen = useCallback(
    async ({ shouldTakeOverScreenShare = true }: ShareScreenOptions = {}) => {
      if (!shouldTakeOverScreenShare && getIsSomeoneElseSharing()) {
        setIsShowingScreenShareConfirmation(true);
        return;
      }
      setIsShowingScreenShareConfirmation(false);
      /**
       * We have to try to start picture in picture immediately because it will
       * fail if we attempt it too late after a user gesture, and otherwise the
       * timeout may pass while the user is selecting a screen to share
       */
      setShouldTriggerPictureInPicture(true);
      const trackPriority = "high";
      const defaultScreenShareConfig: DisplayMediaStreamOptions = {
        video: {
          frameRate: {
            max: 15,
          },
          width: {
            max: 1280,
          },
          height: {
            max: 720,
          },
        },
        audio: true,
      };
      const screenShareConfig: DisplayMediaStreamOptions =
        Statsig.getDynamicConfig(dynamicConfig).get(
          "screen_share_config",
          defaultScreenShareConfig,
        );
      try {
        const stream =
          await navigator.mediaDevices.getDisplayMedia(screenShareConfig);
        const videoTrack = stream.getVideoTracks()[0];
        const room = getRoom();
        await room!.localParticipant.publishTrack(videoTrack, {
          name: createTrackName({
            source: TrackSource.Screen,
            deviceType: getDeviceType(),
          }),
        });
        setLocalScreenShareTrack(videoTrack);
        videoTrack.onended = stopScreenShare;
        const audioTrack = stream.getAudioTracks()[0];

        const { userType } = parseIdentity(
          room!.localParticipant.identity ?? "",
        );
        sendLoggingEvents(TrackingEvents.ANY_SCREEN_SHARE);
        if (userType === UserType.CLIENT) {
          sendLoggingEvents(TrackingEvents.CLIENT_SCREEN_SHARE);
        }
        if (userType === UserType.PROVIDER) {
          sendLoggingEvents(TrackingEvents.PROVIDER_SCREEN_SHARE);
        }

        //browsers do not always allow audio sharing (e.g. Chrome allows audio only when sharing a tab, not an entire screen or window), so only attempt to publish audio if it exists
        if (audioTrack) {
          await room!.localParticipant.publishTrack(audioTrack, {
            name: createTrackName({
              source: TrackSource.Screen,
              deviceType: getDeviceType(),
            }),
            priority: trackPriority,
          });
          setLocalScreenShareAudioTrack(audioTrack);
        } else {
          logger.warn("Unable to get audio track from screen share");
        }

        return { audioTrack, videoTrack };
      } catch (error) {
        // @ts-ignore error type is unknown
        if (error.name !== "AbortError" && error.name !== "NotAllowedError") {
          onError?.(error as DOMException);
          logger.error("Unable to share screen", {}, error as Error);
        }
      }
      // Close picture-in-picture if the user does not end up sharing their screen
      exitPictureInPicture();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onError],
  );

  const stopScreenShare = useCallback(async () => {
    const videoTrack = getLocalScreenShareTrack();
    const audioTrack = getLocalScreenShareAudioTrack();
    const room = getRoom();
    setIsShowingScreenShareConfirmation(false);
    if (videoTrack) {
      handleStopTrack(videoTrack, room);
      setLocalScreenShareTrack(null);
    }

    if (audioTrack) {
      handleStopTrack(audioTrack, room);
      setLocalScreenShareAudioTrack(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getLocalScreenShareTrack,
    setLocalScreenShareTrack,
    getLocalScreenShareAudioTrack,
    setLocalScreenShareAudioTrack,
  ]);

  const triggerBrowserScreenShare = useCallback(
    async (...args: Parameters<typeof shareScreen>) => {
      stopScreenShare();
      const room = getRoom();
      if (room) {
        shareScreen(...args);
      }
    },
    [shareScreen, stopScreenShare, getRoom],
  );

  useEffect(
    function stopScreenShareWhenOtherParticipantShares() {
      if (screenShareParticipant) {
        stopScreenShare();
      }
    },

    [screenShareParticipant, stopScreenShare],
  );

  return {
    triggerBrowserScreenShare,
    stopScreenShare,
  };
}
