import { DeviceType, Env, Possibly } from "../types";
import { deviceIsMobile, getEnvFromHostName, shortUuid } from "../utils";
import { isDevMode } from "../config";
import { logger } from "../datadog/logger";
import {
  ConnectOptions,
  CreateLocalAudioTrackOptions,
  CreateLocalTrackOptions,
} from "twilio-video";
import { TrackKind, TrackSource } from "./types";
import { dynamicConfig } from "../statsig/gates";
import { Statsig } from "../statsig/StatsigProvider";

export enum BackgroundType {
  NONE = "none",
  BLUR = "blur",
  IMAGE = "image",
}

export enum QualityMode {
  High = "high",
  Medium = "medium",
  Low = "low",
}

export const QUALITY_MODE_NUMBER_MAP = {
  [QualityMode.High]: 3,
  [QualityMode.Medium]: 2,
  [QualityMode.Low]: 1,
};

// TODO: Consolidate these constants into the getTwilioConfig function
export const DESKTOP_ASPECT_RATIO = 16 / 9;
export const MAX_MAP_PAGE_COUNT = 3;
export const MAX_ITEMS_PER_MAP_PAGE = 100; // 100 is Twilio's max
export const LOCAL_NQL_DETAIL_LEVEL = 3; // 3 is max
export const REMOTE_NQL_DETAIL_LEVEL = 3;
export const MAX_ROOM_DURATION_SECONDS = 60 * 60 * 3; // 3 hours
export const IS_DEV_CONNECTION_SETTINGS_ENABLED_KEY =
  "is-dev-connection-settings-enabled";
export const DEV_CONNECTION_SETTINGS_KEY = "dev-connection-settings";
export let isH264Supported: boolean;
export const STORED_AUDIO_VIDEO_SETTINGS_KEY = "audio-video-settings";
export const DEFAULT_BACKGROUND_SETTINGS = {
  backgroundType: BackgroundType.NONE,
};
export const DEFAULT_DEVICE_ID = "default";
/**
 * The audio level threshold to be considered an active speaker. Values
 * represent an amplitude that range from 0 to 32767 (16-bit integer). Set based
 * on experimentation.
 *
 * https://twilio.github.io/twilio-video-ios/docs/latest/Classes/TVIRemoteAudioTrackStats.html#//api/name/audioLevel
 */
export const ACTIVE_SPEAKER_THRESHOLD = 1000;

export const DEFAULT_VIDEO_SETTINGS: AudioVideoSettings = {
  isAudioEnabled: true,
  isVideoEnabled: true,
  videoIsMirrored: true,
  videoQualityMode: QualityMode.High,
  selfViewIsHidden: false,
  noiseCancellationIsEnabled: true,
  ...DEFAULT_BACKGROUND_SETTINGS,
};

export type TrackSettingsByQualityMode = Record<
  QualityMode,
  Record<
    DeviceType,
    {
      video: Record<
        TrackSource.Camera | TrackSource.Screen,
        CreateLocalTrackOptions
      >;
      audio: Record<
        TrackSource.Microphone | TrackSource.Screen,
        CreateLocalAudioTrackOptions
      >;
    }
  >
>;

export type TrackMetadata = {
  deviceType?: DeviceType;
  qualityMode?: QualityMode;
  source: TrackSource;
  shortId?: string;
};

export function createTrackName({
  source,
  ...data
}: Omit<TrackMetadata, "shortId">) {
  return `${shortUuid()}|${source}|${btoa(JSON.stringify(data))}`;
}

export function parseTrackName(name: string, kind: TrackKind): TrackMetadata {
  // This will happen on the React Native mobile app where we don't have the ability
  // to set a custom track name. In this case, we'll just return a default
  // source based on the track kind.
  if (!name.includes("|")) {
    return {
      source: kind === "video" ? TrackSource.Camera : TrackSource.Microphone,
    };
  }
  const [shortId, source, rawData] = name.split("|");
  return {
    shortId,
    source: source as TrackKind,
    ...(rawData ? JSON.parse(atob(rawData)) : {}),
  };
}

/**
 * Returns a number greater than 0 if a is greater than b, a number less than 0
 * if b is greater than a, or 0 if they are equal.
 */
export function compareQualityMode(a: QualityMode, b: QualityMode) {
  return QUALITY_MODE_NUMBER_MAP[a] - QUALITY_MODE_NUMBER_MAP[b];
}

/**
 * Returns the maximum size of a data track message in bytes. Defaults to 0
 * (falsey) i.e. no max size.
 *
 * This is necessary because large messages can crash data track functionality.
 * https://www.twilio.com/docs/video/using-datatrack-api#overview
 */
export function getMaxDataTrackMessageSizeBytes() {
  const max_size_bytes = Statsig.getDynamicConfig(dynamicConfig)?.get(
    "max_data_track_message_size_bytes",
    0,
  );
  return max_size_bytes >= 0 ? max_size_bytes : 0; // handles negatives just in case
}

export const trackOptsByQualityMode: TrackSettingsByQualityMode = {
  [QualityMode.High]: {
    // https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#desktop-browser-grid-recommended-settings
    [DeviceType.Mobile]: {
      video: {
        [TrackSource.Camera]: {
          height: { ideal: 480 },
          frameRate: { ideal: 24 },
          width: { ideal: 640 },
          name: createTrackName({
            deviceType: DeviceType.Mobile,
            qualityMode: QualityMode.High,
            source: TrackSource.Camera,
          }),
        },
        [TrackSource.Screen]: {},
      },
      audio: {
        [TrackSource.Microphone]: {
          name: createTrackName({
            deviceType: DeviceType.Mobile,
            qualityMode: QualityMode.High,
            source: TrackSource.Microphone,
          }),
        },
        [TrackSource.Screen]: {
          name: createTrackName({
            deviceType: DeviceType.Mobile,
            qualityMode: QualityMode.High,
            source: TrackSource.Screen,
          }),
        },
      },
    },
    // https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#mobile-browser-grid-recommended-settings
    [DeviceType.Desktop]: {
      video: {
        [TrackSource.Camera]: {
          aspectRatio: DESKTOP_ASPECT_RATIO,
          height: { ideal: 720 },
          frameRate: { ideal: 24 },
          width: { ideal: 1280 },
          name: createTrackName({
            deviceType: DeviceType.Desktop,
            qualityMode: QualityMode.High,
            source: TrackSource.Camera,
          }),
        },
        [TrackSource.Screen]: {},
      },
      audio: {
        [TrackSource.Microphone]: {
          name: createTrackName({
            deviceType: DeviceType.Desktop,
            qualityMode: QualityMode.High,
            source: TrackSource.Microphone,
          }),
        },
        [TrackSource.Screen]: {
          name: createTrackName({
            deviceType: DeviceType.Desktop,
            qualityMode: QualityMode.High,
            source: TrackSource.Screen,
          }),
        },
      },
    },
  },
  [QualityMode.Medium]: {
    [DeviceType.Mobile]: {
      video: {
        [TrackSource.Camera]: {
          height: { ideal: 360 },
          frameRate: { ideal: 24 },
          width: { ideal: 480 },
          name: createTrackName({
            deviceType: DeviceType.Mobile,
            qualityMode: QualityMode.Medium,
            source: TrackSource.Camera,
          }),
        },
        [TrackSource.Screen]: {},
      },
      audio: {
        [TrackSource.Microphone]: {
          name: createTrackName({
            deviceType: DeviceType.Mobile,
            qualityMode: QualityMode.Medium,
            source: TrackSource.Microphone,
          }),
        },
        [TrackSource.Screen]: {
          name: createTrackName({
            deviceType: DeviceType.Mobile,
            qualityMode: QualityMode.Medium,
            source: TrackSource.Screen,
          }),
        },
      },
    },
    [DeviceType.Desktop]: {
      video: {
        [TrackSource.Camera]: {
          aspectRatio: DESKTOP_ASPECT_RATIO,
          height: { ideal: 360 },
          frameRate: { ideal: 24 },
          width: { ideal: 640 },
          name: createTrackName({
            deviceType: DeviceType.Desktop,
            qualityMode: QualityMode.Medium,
            source: TrackSource.Camera,
          }),
        },
        [TrackSource.Screen]: {},
      },
      audio: {
        [TrackSource.Microphone]: {},
        [TrackSource.Screen]: {},
      },
    },
  },
  [QualityMode.Low]: {
    [DeviceType.Mobile]: {
      video: {
        [TrackSource.Camera]: {
          height: { ideal: 240 },
          frameRate: { ideal: 24 },
          width: { ideal: 320 },
          name: createTrackName({
            deviceType: DeviceType.Mobile,
            qualityMode: QualityMode.Low,
            source: TrackSource.Camera,
          }),
        },
        [TrackSource.Screen]: {},
      },
      audio: {
        [TrackSource.Microphone]: {
          name: createTrackName({
            deviceType: DeviceType.Mobile,
            qualityMode: QualityMode.Low,
            source: TrackSource.Microphone,
          }),
        },
        [TrackSource.Screen]: {
          name: createTrackName({
            deviceType: DeviceType.Mobile,
            qualityMode: QualityMode.Low,
            source: TrackSource.Screen,
          }),
        },
      },
    },
    [DeviceType.Desktop]: {
      video: {
        [TrackSource.Camera]: {
          aspectRatio: DESKTOP_ASPECT_RATIO,
          height: { ideal: 360 },
          frameRate: { ideal: 24 },
          width: { ideal: 640 },
          name: createTrackName({
            deviceType: DeviceType.Desktop,
            qualityMode: QualityMode.Low,
            source: TrackSource.Camera,
          }),
        },
        [TrackSource.Screen]: {},
      },
      audio: {
        [TrackSource.Microphone]: {
          name: createTrackName({
            deviceType: DeviceType.Desktop,
            qualityMode: QualityMode.Low,
            source: TrackSource.Microphone,
          }),
        },
        [TrackSource.Screen]: {
          name: createTrackName({
            deviceType: DeviceType.Desktop,
            qualityMode: QualityMode.Low,
            source: TrackSource.Screen,
          }),
        },
      },
    },
  },
};

/**
 * Returns a quality mode that the UI supports i.e. only low and high quality
 */
export function consolidateQualityMode(qualityMode: Possibly<QualityMode>) {
  switch (qualityMode) {
    case QualityMode.High: // falls through
    case QualityMode.Medium:
      return QualityMode.High;
    default:
      return QualityMode.Low;
  }
}

export function consolidateBackgroundType(
  backgroundType: Possibly<BackgroundType>,
) {
  switch (backgroundType) {
    case BackgroundType.BLUR: // falls through
    case BackgroundType.IMAGE:
      return backgroundType;
    default:
      return BackgroundType.NONE;
  }
}

export interface BackgroundSettings {
  backgroundIndex?: number;
  backgroundType: BackgroundType;
}

export type AudioVideoSettings = {
  isAudioEnabled: boolean;
  isVideoEnabled: boolean;
  inputAudioDeviceId?: string;
  inputVideoDeviceId?: string;
  videoIsMirrored: boolean;
  videoQualityMode: QualityMode;
  selfViewIsHidden: boolean;
  noiseCancellationIsEnabled: boolean;
} & BackgroundSettings;

export function getStoredAudioVideoSettings(): AudioVideoSettings {
  try {
    const rawSettings = localStorage.getItem(STORED_AUDIO_VIDEO_SETTINGS_KEY);

    if (!rawSettings) return DEFAULT_VIDEO_SETTINGS;

    const settings = JSON.parse(rawSettings) as Possibly<AudioVideoSettings>;

    // trust but verify
    return {
      isAudioEnabled:
        settings.isAudioEnabled !== undefined
          ? Boolean(settings.isAudioEnabled)
          : DEFAULT_VIDEO_SETTINGS.isAudioEnabled,
      isVideoEnabled:
        settings.isVideoEnabled !== undefined
          ? Boolean(settings.isVideoEnabled)
          : DEFAULT_VIDEO_SETTINGS.isVideoEnabled,
      inputAudioDeviceId: settings.inputAudioDeviceId
        ? String(settings.inputAudioDeviceId)
        : undefined,
      inputVideoDeviceId: settings.inputVideoDeviceId
        ? String(settings.inputVideoDeviceId)
        : undefined,
      videoQualityMode: consolidateQualityMode(settings.videoQualityMode),
      backgroundType: consolidateBackgroundType(settings.backgroundType),
      videoIsMirrored: Boolean(settings.videoIsMirrored),
      selfViewIsHidden: Boolean(settings.selfViewIsHidden),
      noiseCancellationIsEnabled: Boolean(settings.noiseCancellationIsEnabled),
      backgroundIndex: Number(settings.backgroundIndex),
    };
  } catch (error) {
    logger.warn("Error parsing stored settings", {}, error as Error);
  }
  return DEFAULT_VIDEO_SETTINGS;
}

export function getStoredBackgroundSettings(): BackgroundSettings {
  const { backgroundType, backgroundIndex } = getStoredAudioVideoSettings();
  return { backgroundType, backgroundIndex };
}

/**
 * Test support for H264 codec. Adapted from
 * https://www.twilio.com/docs/video/managing-codecs
 *
 * Some mobile browsers have trouble with H264:
 * https://developer.mozilla.org/en-US/docs/Web/Media/Formats/WebRTC_codecs#supported_video_codecs
 */
export async function testH264Support() {
  if (typeof isH264Supported === "boolean") {
    return isH264Supported;
  }
  if (
    typeof RTCRtpSender !== "undefined" &&
    typeof RTCRtpSender.getCapabilities === "function"
  ) {
    isH264Supported = !!RTCRtpSender.getCapabilities("video")?.codecs.find(
      ({ mimeType }) => mimeType === "video/H264",
    );
    logger.info(`H264 support`, { isH264Supported });
    return isH264Supported;
  }
  if (typeof RTCPeerConnection === "undefined") {
    isH264Supported = false;
    logger.info("H264 support", { isH264Supported });
    return isH264Supported;
  }

  const offerOptions: RTCOfferOptions = {};
  const pc = new RTCPeerConnection();
  try {
    pc.addTransceiver("video");
  } catch (e) {
    offerOptions.offerToReceiveVideo = true;
  }

  try {
    const offer = await pc.createOffer(offerOptions);
    isH264Supported = /^a=rtpmap:.+ H264/m.test(offer.sdp ?? "");
    pc.close();
  } catch (e) {
    isH264Supported = false;
  }
  logger.info("H264 support", { isH264Supported });
  return isH264Supported;
}

function getDevConnectionSettings(): Omit<ConnectOptions, "video" | "audio"> {
  if (!isDevMode) return {};
  try {
    const isDevConnectionSettingsEnabled = !!parseInt(
      localStorage.getItem(IS_DEV_CONNECTION_SETTINGS_ENABLED_KEY) ?? "0",
    );
    if (!isDevConnectionSettingsEnabled) return {};
    const rawSettings = localStorage.getItem(DEV_CONNECTION_SETTINGS_KEY);
    if (!rawSettings) return {};
    const settings = JSON.parse(rawSettings);
    logger.debug("Using dev connection settings", settings);
    return settings;
  } catch (error) {
    logger.debug("Error parsing dev connection settings", {}, error as Error);
    return {};
  }
}

/**
 * Returns Twilio connection settings used when connecting to a room.
 *
 * IMPORTANT: Please do not rely on this function for specifying
 * video/audio-track-creation settings. Use the settings in the `tracks` file
 * instead.
 */
export function getConnectSettings(): Omit<ConnectOptions, "video" | "audio"> {
  return {
    preferredVideoCodecs: "auto",
    networkQuality: {
      local: LOCAL_NQL_DETAIL_LEVEL,
      remote: REMOTE_NQL_DETAIL_LEVEL,
    },
    maxAudioBitrate: 16000,
    bandwidthProfile: {
      video: {
        mode: "presentation",
        trackSwitchOffMode: "disabled",
        // https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#desktop-browser-grid-recommended-settings
        ...(deviceIsMobile() ? { maxSubscriptionBitrate: 2500000 } : {}),
      },
    },
    ...getDevConnectionSettings(),
  };
}

/**
 * Returns the Twilio config for the current environment.
 */
export function getTwilioConfig() {
  const env = getEnvFromHostName(window.location.hostname);
  switch (env) {
    case Env.PROD:
      return {
        maxParticipants: 5,
      };
    default:
      return {
        maxParticipants: 15,
      };
  }
}
