import {
  findMostRecentCompletedPHQBundle,
  MeasureBundleObject,
  MeasureSchemaType,
  MeasureValidationError,
  useGetMeasureSchemaByType,
  useSubmitMeasureResponseCallback,
  UseSubmitMeasureResponseCallbackResponse,
} from "@grow-therapy-team/seedling-components";
import { toast } from "@growtherapy/sprout-ui";
import { useCallback, useState } from "react";
import {
  ProviderDrawerState,
  drawerStateAtom,
  providerAtom,
  selectedBundleAtom,
  selectedClientInfoAtom,
  selectedClientUserShortIdAtom,
} from "../state";
import { useSetAtom } from "jotai";
import {
  GET_PATIENT_BY_PATIENT_SHORT_ID_GQL,
  useLazyGetPatientByShortId,
} from "../useGetPatientByShortId";
import { logger } from "../../datadog";
import { useAtomCallback } from "jotai/utils";

function findMostRecentPHQResponseShortId(
  measureBundles: MeasureBundleObject[],
) {
  return findMostRecentCompletedPHQBundle(
    measureBundles,
  )?.measureBundleItems?.find(
    // TODO: CARED-2000 Replace with exported function from seedling-components
    function isPHQMeasureItem(bundleItem) {
      const schemaType = bundleItem?.measureSchema?.type;
      return (
        schemaType === MeasureSchemaType.PHQ9_WITH_SCREENER ||
        schemaType === MeasureSchemaType.PHQ9
      );
    },
  )?.measureResponse?.measureResponseShortId;
}

function useFetchSelectedPatientDataCallback() {
  const [fetchPatientData] = useLazyGetPatientByShortId();
  const getPatientShortId = useAtomCallback(
    (get) => get(selectedClientInfoAtom)?.patientShortId,
  );

  return useCallback(async () => {
    return await fetchPatientData({
      variables: {
        patientShortId: getPatientShortId(),
      },
    });
  }, [fetchPatientData, getPatientShortId]);
}

type MeasureResponseSubmissionResultData = Awaited<
  ReturnType<UseSubmitMeasureResponseCallbackResponse[0]>
>["data"];

function useHandleSuccessfulCSSRSMeasureSubmissionCallback() {
  const fetchSelectedPatientData = useFetchSelectedPatientDataCallback();
  const setSelectedBundle = useSetAtom(selectedBundleAtom);
  const setDrawerState = useSetAtom(drawerStateAtom);

  const updateSelectedMeasureBundle = useCallback(
    async (measureResponseData: MeasureResponseSubmissionResultData) => {
      const { data: patientData } = await fetchSelectedPatientData();
      const submittedMeasureResponse =
        measureResponseData?.submitMeasureResponse?.measureResponse;
      const updatedMeasureBundles = patientData?.patientChart?.measureBundles;
      const newMeasureBundle = updatedMeasureBundles?.find(
        (bundle) =>
          bundle.measureBundleItems[0]?.measureResponse
            ?.measureResponseShortId ===
          submittedMeasureResponse?.measureResponseShortId,
      );

      if (submittedMeasureResponse && updatedMeasureBundles) {
        setSelectedBundle(newMeasureBundle);
      } else {
        setSelectedBundle(undefined);
      }
    },
    [fetchSelectedPatientData, setSelectedBundle],
  );

  return useCallback(
    (measureResponseData: MeasureResponseSubmissionResultData) => {
      updateSelectedMeasureBundle(measureResponseData);
      setDrawerState(ProviderDrawerState.MEASURE_RESPONSES);
    },
    [updateSelectedMeasureBundle, setDrawerState],
  );
}

export const useSubmitCSSRSMeasureCallback = (): [
  () => Promise<void>,
  { isLoading: boolean },
] => {
  const getProviderShortId = useAtomCallback(
    (get) => get(providerAtom)?.userData?.shortId,
  );
  const getClientUserShortId = useAtomCallback((get) =>
    get(selectedClientUserShortIdAtom),
  );
  const { data: cssrsSchemaData } = useGetMeasureSchemaByType(
    MeasureSchemaType.COLUMBIA_SUICIDE_SEVERITY_RATING_SCALE,
  );
  const [submitMeasure] = useSubmitMeasureResponseCallback();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleSuccessfulCSSRSMeasureSubmission =
    useHandleSuccessfulCSSRSMeasureSubmissionCallback();
  const fetchSelectedPatientData = useFetchSelectedPatientDataCallback();

  const handleSubmitMeasure = useCallback(async () => {
    const clientUserShortId = getClientUserShortId();
    const providerShortId = getProviderShortId();
    if (
      !clientUserShortId ||
      !cssrsSchemaData?.measureSchema ||
      !providerShortId
    ) {
      logger.error("Could not submit measure due to missing values");
      // TODO: TOAST SPROUT
      toast.error("Unable to submit form due to missing values.");
      return;
    }

    try {
      setIsLoading(true);
      const measureBundles =
        (await fetchSelectedPatientData())?.data?.patientChart
          ?.measureBundles ?? [];
      const { data: measureResponseData } = await submitMeasure({
        clientUserShortId: clientUserShortId,
        schema: cssrsSchemaData.measureSchema,
        providerShortId,
        shouldShowErrorToast: false,
        startedAt: new Date().toISOString(),
        apolloOptions: {
          awaitRefetchQueries: true,
          refetchQueries: [GET_PATIENT_BY_PATIENT_SHORT_ID_GQL],
        },
        previousResponseShortId:
          findMostRecentPHQResponseShortId(measureBundles),
      });

      handleSuccessfulCSSRSMeasureSubmission(measureResponseData);
    } catch (err) {
      if (!(err instanceof MeasureValidationError)) {
        logger.error(
          "Could not submit measure",
          { clientUserShortId },
          err as Error,
        );
        // TODO: TOAST SPROUT
        toast.error("Unable to submit form due to an expected error.");
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    getClientUserShortId,
    getProviderShortId,
    cssrsSchemaData,
    fetchSelectedPatientData,
    submitMeasure,
    handleSuccessfulCSSRSMeasureSubmission,
  ]);

  return [handleSubmitMeasure, { isLoading }];
};
