import { Button, ButtonProps } from "@growtherapy/sprout-ui";
import { forwardRef } from "react";

/**
 * A light wrapper around `Button` which tailors its appearance to match the
 * size of the `ToastCloseButton` with size "sm".
 */
export const ToastButton = forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps, "size">
>(function ToastButton(props, ref) {
  return <Button ref={ref} className="max-h-[2.25rem]" size="sm" {...props} />;
});
