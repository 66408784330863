import toast from "react-hot-toast";
import { useState, useCallback } from "react";
import { secondsToMilliseconds } from "date-fns";
import { ClientTranscriptionConsentToast } from "./ClientTranscriptionConsentToast";

export function useClientTranscriptionResponseToastCallback() {
  const [showingToast, setShowingToast] = useState(false);
  return useCallback(
    (isOptIn: boolean) => {
      if (!showingToast) {
        // TODO: TOAST HOT
        toast.custom(
          ({ id: toastId }) => {
            return (
              <ClientTranscriptionConsentToast id={toastId} isOptIn={isOptIn} />
            );
          },
          {
            position: "bottom-center",
            duration: secondsToMilliseconds(30),
          },
        );
        setShowingToast(true);
      }
    },
    [showingToast],
  );
}
