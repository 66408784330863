import { ToastState, useToastQueue } from "@react-stately/toast";
import { CustomToast, ToastPosition } from "./types";
import { AriaToastRegionProps, useToastRegion } from "@react-aria/toast";
import React, { useRef } from "react";
import BaseToast from "./BaseToast";
import classNames from "classnames";
import { queues } from "./queues";
import { createPortal } from "react-dom";
import { filterWarnings, shouldUseViewTransitions } from "./util";

filterWarnings();

type VisibleToastRegionProps<T> = AriaToastRegionProps & {
  position: ToastPosition;
  regionClassNames: string;
  state: ToastState<T>;
};

/**
 * Renders the visible toasts in a given toast region, coordinating view
 * transitions when toasts are added or removed.
 */
function VisibleToastRegion<T extends CustomToast>({
  position,
  regionClassNames,
  state,
  ...props
}: VisibleToastRegionProps<T>) {
  const ref = useRef<HTMLOListElement>(null);
  const { regionProps } = useToastRegion(props, state, ref);
  const usesViewTransitions = shouldUseViewTransitions();

  // As we might technically have toasts visible in multiple toast regions
  // simultaneously, we modify the aria-label supplied by @react-aria/toast to
  // ensure each region has a unique, descriptive label. Ideally, if we used
  // different positions to convey different types of information, we could
  // label the regions more semantically. However, that is not the case
  // currently, so we describe the region with the position it appears.
  const ariaLabel = `Notifications, ${position.replace("-", " ")} of screen: ${regionProps["aria-label"] ?? ""}`;
  return (
    <ol
      {...regionProps}
      // Only set role if there are visible toasts to avoid surfacing an empty
      // landmark region to screen readers.
      role={state.visibleToasts.length ? regionProps.role : undefined}
      aria-label={ariaLabel}
      ref={ref}
      className={classNames(
        regionClassNames,
        // TODO: https://growtherapy.atlassian.net/browse/CARED-1848
        // NOTE: Toasts are currently expected to appear on top of everything
        // else, including modals, e.g. in client consent modal. Toasting while
        // a modal is open is not generally recommended because modals are
        // intended to trap focus and would thus prevent interaction with
        // toasts. However, since this is the behavior today, we need to support
        // it for now. This should be removed and #root should be locked to
        // isolation: isolate in the future when the modal is refactored.
        "absolute p-4 flex flex-col gap-2 w-full sm:w-auto z-[1000]",
      )}
    >
      {state.visibleToasts.map((toast, index) => {
        return (
          <BaseToast
            key={toast.key}
            className={classNames("w-full flex", {
              // Fallback to basic CSS entrance animation if view transitions
              // are not enabled.
              "static-animation": !usesViewTransitions,
              bottom: position !== "top",
              top: position === "top",
              "justify-center":
                position === "top" || position === "bottom-center",
              "justify-end": position === "bottom-right",
            })}
            state={state}
            style={
              {
                zIndex: index,
                // This gives us a unique view transition for each list item.
                // It's irrelevant if usesViewTransitions is false.
                viewTransitionName: `toast-transition-${toast.key.replace(".", "")} `,
                viewTransitionClass: classNames("toast", {
                  fade: index !== state.visibleToasts.length - 1,
                  bottom: position !== "top",
                  top: position === "top",
                }),
              } as React.CSSProperties
            }
            toast={toast}
          />
        );
      })}
    </ol>
  );
}

const POSITION_TO_CLASSNAMES: Record<ToastPosition, string> = {
  top: "top-0 left-1/2 transform -translate-x-1/2",
  "bottom-left": "left-0",
  "bottom-center": "left-1/2 transform -translate-x-1/2",
  "bottom-right": "right-0",
};

type ToastRegionProps = {
  bottomOffsetClassname?: string;
  position: ToastPosition;
  portalContainer?: HTMLElement | null;
};

/**
 * Renders a single toast region for the given position's toast queue. Region is
 * rendered in a portal to the given container, or to the body if no container
 * is given, in order to avoid overflow issues and ensure the toasts are
 * rendered above all other content. If no toasts are visible, nothing is
 * rendered.
 */
function ToastRegion({
  bottomOffsetClassname = "bottom-16",
  position,
  portalContainer,
}: ToastRegionProps) {
  const state = useToastQueue(queues[position]);
  const regionClassNames = POSITION_TO_CLASSNAMES[position];

  return (
    <>
      {createPortal(
        <VisibleToastRegion
          position={position}
          regionClassNames={classNames(
            { [bottomOffsetClassname]: position !== "top" },
            regionClassNames,
          )}
          state={state}
        />,
        portalContainer ?? document.body,
      )}
    </>
  );
}

/**
 * Renders all toast regions, one for each positional queue.
 */
export default function ToastRegions(
  props: Pick<ToastRegionProps, "portalContainer" | "bottomOffsetClassname">,
) {
  return (
    <>
      <ToastRegion position="top" {...props} />
      <ToastRegion position="bottom-left" {...props} />
      <ToastRegion position="bottom-center" {...props} />
      <ToastRegion position="bottom-right" {...props} />
    </>
  );
}
