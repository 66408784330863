import {
  BackgroundAnimationOverlay,
  Navbar,
  PageContainer,
  PageProviders,
  PushDrawerContainer,
  StageContainer,
} from "../../components";
import { PushDrawerWrapper as PushDrawer } from "../PushDrawer";
import { useAtomValue, useSetAtom } from "jotai";
import { visitorClientShortIdAtom } from "../state";
import { sessionInfoAtom, Theme } from "../../state";
import { useParams } from "react-router-dom";
import { useGetTelehealthSessionInfo } from "../../hooks";
import {
  PageContent,
  PageContentWrapperProps,
} from "./page-content/PageContent";
import { FC } from "react";
import { INTRO_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID } from "../constants";

interface PageProps {
  pageContentComponent: FC<PageContentWrapperProps>;
}

export function Page({
  pageContentComponent: PageContentComponent,
}: PageProps) {
  return (
    <>
      <PageProviders theme={Theme.LIGHT}>
        <PageContainer>
          <BackgroundAnimationOverlay autoplay={false} />
          <div className="relative flex flex-col h-full w-full">
            <Navbar />
            <PushDrawerContainer>
              <StageContainer className="bg-transparent overflow-auto sm:py-12 justify-normal">
                <PageContentComponent
                  drawerId={INTRO_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID}
                />
              </StageContainer>
              <PushDrawer
                className="sm:my-2 sm:h-auto"
                id={INTRO_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID}
              />
            </PushDrawerContainer>
          </div>
        </PageContainer>
      </PageProviders>
    </>
  );
}

export function PageWrapper() {
  const { providerShortId, patientShortId } = useParams();

  useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const appointment = useAtomValue(sessionInfoAtom)?.appointment;
  const setClientShortId = useSetAtom(visitorClientShortIdAtom);

  setClientShortId(appointment?.clientUserShortId ?? "");

  return <Page pageContentComponent={PageContent} />;
}
