import { faPhoneHangup } from "@fortawesome/pro-solid-svg-icons";
import { Button, ButtonProps, IconButton } from "@growtherapy/sprout-ui";
import { twMerge } from "tailwind-merge";

export function EndSessionButton({
  children,
  className,
  ...props
}: ButtonProps & {
  "aria-label": string;
}) {
  return (
    <>
      <IconButton
        id="end-session-button-mobile"
        className="md:hidden min-w-12 max-w-12 min-h-12 max-h-12 justify-center p-0"
        use="destructive"
        iconDefinition={faPhoneHangup}
        {...props}
      />
      <Button
        id="end-session-button"
        {...props}
        className={twMerge("hidden md:flex", className)}
        use="destructive"
      >
        {children}
      </Button>
    </>
  );
}
