import { useAtomValue } from "jotai";
import { TranscriptionPill } from "../../twilio/TranscriptionPill";
import { useGetProviderPatientConsent } from "./useGetProviderPatientConsent";
import { inSessionPatientInformationAtom, providerShortIdAtom } from "../state";
import { ConsentStatus, ConsentUpdateSource } from "../../twilio/types";
import { useShouldDisableTranscriptionForMultipleParticipants } from "../../twilio/controls/useShouldDisableTranscriptionForMultipleParticipants";
import { useRecordingControl } from "./useRecordingControl";

const shouldHideTranscriptionPill = (
  recordingControlIsVisible: boolean,
  providerConsent: ConsentStatus,
  shouldDisableTranscriptionForMultipleParticipants: boolean,
  isOptedOutInClientPortal: boolean,
) => {
  return (
    !recordingControlIsVisible ||
    providerConsent !== ConsentStatus.OPTED_IN ||
    shouldDisableTranscriptionForMultipleParticipants ||
    isOptedOutInClientPortal
  );
};

export function TranscriptionPillWrapper() {
  const { patientShortId } =
    useAtomValue(inSessionPatientInformationAtom) ?? {};
  const providerShortId = useAtomValue(providerShortIdAtom);
  const { isRecording, isVisible: recordingControlIsVisible } =
    useRecordingControl();

  const { providerConsent, patientConsent, patientUpdateSource } =
    useGetProviderPatientConsent(providerShortId, patientShortId);

  const shouldDisableTranscriptionForMultipleParticipants =
    useShouldDisableTranscriptionForMultipleParticipants();
  const isOptedOutInClientPortal =
    patientConsent === ConsentStatus.OPTED_OUT &&
    patientUpdateSource === ConsentUpdateSource.CLIENT_PORTAL;

  if (
    shouldHideTranscriptionPill(
      recordingControlIsVisible,
      providerConsent,
      shouldDisableTranscriptionForMultipleParticipants,
      isOptedOutInClientPortal,
    )
  ) {
    return null;
  }

  return (
    <TranscriptionPill
      isRecording={isRecording}
      patientConsent={patientConsent}
    />
  );
}
