import { isWhiteboardModeAtom } from "../messages/state";
import { participantCountAtom } from "../state";
import { useAtomValue } from "jotai";
import { useSessionModeCallbacks } from "./useSessionModeCallbacks";
import { useShareWhiteboardCallback } from "../../components/whiteboard/useShareWhiteboardCallback";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";

export function useWhiteboardControl() {
  const canWhiteboard = !!useAtomValue(participantCountAtom);
  const [startWhiteboarding, { isLoading }] = useShareWhiteboardCallback();
  const { stopCurrentMode: stopWhiteboarding } = useSessionModeCallbacks();
  const isWhiteboardMode = useAtomValue(isWhiteboardModeAtom).value;
  const getIsWhiteboarding = useAtomCallback(
    useCallback((get) => get(isWhiteboardModeAtom).value, []),
  );

  const toggleWhiteboard = useCallback(() => {
    if (getIsWhiteboarding()) {
      stopWhiteboarding();
    } else {
      startWhiteboarding();
    }
  }, [getIsWhiteboarding, startWhiteboarding, stopWhiteboarding]);

  return {
    canWhiteboard,
    isLoading,
    isWhiteboardMode,
    toggleWhiteboard,
  };
}
