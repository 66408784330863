import { OldToast, ToastVariant } from "../../components";
import { Text, Icon } from "@growtherapy/sprout-ui";
import toast from "react-hot-toast";
import { faCircleMinus } from "@fortawesome/pro-solid-svg-icons";
import { TRANSCRIPTION_NOT_AVAILABLE_FOR_MULTIPLE_PARTICIPANTS } from "./constants";

export function RecordingControlMultipleParticipantsToast({
  id: toastId,
}: {
  id: string;
}) {
  return (
    <OldToast
      className="flex gap-3"
      variant={ToastVariant.Neutral}
      onClose={() => {
        toast.remove(toastId);
      }}
    >
      <Icon icon={faCircleMinus} />
      <Text>{TRANSCRIPTION_NOT_AVAILABLE_FOR_MULTIPLE_PARTICIPANTS}</Text>
    </OldToast>
  );
}
