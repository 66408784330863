import { useWindowSize } from "usehooks-ts";
import { SMALL_SCREEN_BREAKPOINT_PX } from "../assets/constants";

function checkFullScreenDrawerIsOpen(
  windowWidth: number,
  isDrawerOpen: boolean,
) {
  const isWithinSmallScreenBreakpoint =
    windowWidth < SMALL_SCREEN_BREAKPOINT_PX;
  return isDrawerOpen && isWithinSmallScreenBreakpoint;
}

export const OBSCURED_CONTENT_PROPS = {
  "aria-hidden": true,
  // The truthy value for inert MUST be a string or it will not appear in the
  // DOM - React seems to ignore unknown boolean attributes
  inert: "true",
};

export type ObscuredContentProps = typeof OBSCURED_CONTENT_PROPS;

/**
 * Returns the HTML attributes to be used on the main page content element when the main page
 * content is obscured by a full-screen component e.g. drawer on mobile.
 */
export function useObscuredContentProps({
  isDrawerOpen,
}: {
  isDrawerOpen: boolean;
}): Partial<ObscuredContentProps> {
  const { width } = useWindowSize();
  const isFullScreenDrawerOpen = checkFullScreenDrawerIsOpen(
    width,
    isDrawerOpen,
  );

  if (isFullScreenDrawerOpen) {
    return OBSCURED_CONTENT_PROPS;
  }

  return {};
}
