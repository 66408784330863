import {
  faFaceMeh,
  faFaceSadSweat,
  faFaceSmile,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { Text, Icon } from "@growtherapy/sprout-ui";
import classNames from "classnames";

type ButtonType = {
  text: string;
  icon: IconDefinition;
  colorClass: string;
};

export function MockRatingButtons() {
  const buttons: ButtonType[] = [
    {
      text: "It was great",
      icon: faFaceSmile,
      colorClass: "text-neutral-800 bg-green-100",
    },
    {
      text: "I'm not sure",
      icon: faFaceMeh,
      colorClass: "text-neutral-800 bg-yellow-100",
    },
    {
      text: "Not so great",
      icon: faFaceSadSweat,
      colorClass: "text-neutral-800 bg-coral-300",
    },
  ];
  return (
    <div className="flex flex-row items-center justify-center gap-12 pt-6 pb-6">
      {buttons.map((button) => (
        <div
          key={button.text}
          className="flex flex-col items-center justify-center"
        >
          <div className={classNames("rounded-lg p-6", button.colorClass)}>
            <Icon
              size="lg"
              icon={button.icon}
              className={`${button.colorClass} w-6`}
            />
          </div>
          <Text className="pt-2 font-medium text-small">{button.text}</Text>
        </div>
      ))}
    </div>
  );
}
