import { useAtomValue, useSetAtom } from "jotai";
import {
  drawerStateAtom,
  ProviderDrawerState,
  selectedClientInfoAtom,
  ClientInformationDrawerTab,
} from "../state";
import { ClientInformationDrawerFooterWrapper as ClientInformationDrawerFooter } from "./ClientInformationDrawerFooter";
import { ClientInformationDrawerHeader } from "./ClientInformationDrawerHeader";
import { ClientInformationDrawerBodyWrapper as ClientInformationDrawerBody } from "./ClientInformationDrawerBody";
import { useTrackEvent } from "../../segment/segment";
import { ReactNode, useEffect } from "react";
import { EventTypeEnum } from "../../segment/types";
import { ClientInformationSchedulingDrawerFooterWrapper as ClientInformationSchedulingDrawerFooter } from "./ClientInformationSchedulingDrawerFooter";
import { ParticipantStatus } from "../../types";
import { activeClientInformationTabAtom } from "./state";
import { ClientMoodWrapper as ClientMood } from "./ClientMood";

type ClientInformationDrawerProps = {
  visitorName?: string;
  patientShortId?: string;
  headerCaption?: ReactNode;
  isEmpty: boolean;
  visitorStatus?: ParticipantStatus;
  visitorUuid?: string;
  onClickBackButton: () => void;
  selectedPatientShortId: string | undefined;
  isInfoTabActive?: boolean;
};

export function ClientInformationDrawer({
  headerCaption,
  isEmpty,
  visitorName,
  visitorStatus,
  visitorUuid,
  onClickBackButton,
  selectedPatientShortId,
  isInfoTabActive,
}: ClientInformationDrawerProps) {
  const isScheduledClient = visitorStatus === ParticipantStatus.SCHEDULED;
  return (
    <>
      <ClientInformationDrawerHeader
        drawerTitle={visitorName}
        drawerTitleCaption={headerCaption}
        visitorStatus={visitorStatus}
        onClickBackButton={isScheduledClient ? undefined : onClickBackButton}
      />
      <ClientInformationDrawerBody
        selectedPatientShortId={selectedPatientShortId || ""}
        isEmpty={isEmpty}
      />
      {isScheduledClient && isInfoTabActive && (
        <ClientInformationSchedulingDrawerFooter />
      )}
      {!isScheduledClient && (
        <ClientInformationDrawerFooter
          visitorStatus={visitorStatus}
          visitorUuid={visitorUuid || ""}
          visitorName={visitorName || ""}
          patientShortId={selectedPatientShortId || ""}
        />
      )}
    </>
  );
}

export function ClientInformationDrawerWrapper() {
  const selectedClient = useAtomValue(selectedClientInfoAtom);
  const activeClientInformationTab = useAtomValue(
    activeClientInformationTabAtom,
  );
  const setDrawerState = useSetAtom(drawerStateAtom);

  const { userViewed } = useTrackEvent();
  const onClickBackButton = () => {
    setDrawerState(ProviderDrawerState.CLIENTS);
  };

  useEffect(() => {
    userViewed(EventTypeEnum.CLIENT_INFO, {
      patientShortId: selectedClient?.patientShortId ?? "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClientInformationDrawer
      headerCaption={<ClientMood visitorUuid={selectedClient?.visitorUuid} />}
      selectedPatientShortId={selectedClient?.patientShortId}
      visitorName={selectedClient?.name ?? "Client Information"}
      visitorStatus={selectedClient?.status}
      visitorUuid={selectedClient?.visitorUuid}
      onClickBackButton={onClickBackButton}
      isEmpty={!selectedClient}
      isInfoTabActive={
        activeClientInformationTab === ClientInformationDrawerTab.INFORMATION
      }
    />
  );
}
