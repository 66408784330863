import {
  Navigate,
  Route,
  Routes as RouterRoutes,
  createRoutesFromElements,
  matchRoutes,
  useLocation,
} from "react-router-dom";
import { Routes as VisitorRoutes } from "../visitor";

import {
  Page as ProviderPage,
  ProviderProtectedWrapper as ProviderProtected,
} from "../provider";
import { PROVIDER_PATH, isDevMode } from "../config";
import { Error404Page } from "../error/404Page";
import { ClearState } from "../ClearState";
import { ReactNode, useEffect, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { StatsigProvider as VisitorStatsigProvider } from "../visitor/StatsigProvider";
import { StatsigProvider as ProviderStatsigProvider } from "../provider/StatsigProvider";
import { AppointmentLinkChangedPage } from "../appointment-link-changed/AppointmentLinkChangedPage";
import { ToastRegions } from "../toasts";

export function Routes() {
  const [DevPage, setDevPage] = useState<ReactNode>();
  const [DevTools, setDevTools] = useState<ReactNode>();

  const WithDevtools = ({ children }: { children: ReactNode }) => (
    <>
      {children}
      {DevTools}
    </>
  );

  const routes = (
    <>
      <Route
        id="visitor-page"
        path="/:providerShortId/:patientShortId/*"
        element={
          <VisitorStatsigProvider>
            <WithDevtools>
              {/* ToastRegions perform a feature flag gate check and therefore
               needs to be a child of the StatsigProvider */}
              <ToastRegions />
              <VisitorRoutes />
            </WithDevtools>
          </VisitorStatsigProvider>
        }
      />
      <Route
        id="provider-page"
        path={PROVIDER_PATH}
        element={
          <WithDevtools>
            <ProviderProtected>
              <ProviderStatsigProvider>
                {/* ToastRegions perform a feature flag gate check and therefore
                needs to be a child of the StatsigProvider */}
                <ToastRegions />
                <ProviderPage />
              </ProviderStatsigProvider>
            </ProviderProtected>
          </WithDevtools>
        }
      />
      {DevPage && <Route path="/dev" element={DevPage} />}
      <Route path="/clear-state" element={<ClearState />}></Route>
      <Route path="/404" element={<Error404Page />}></Route>
      <Route
        path="/appointment-link-changed"
        element={<AppointmentLinkChangedPage />}
      ></Route>
      <Route path="/" element={<Navigate to={PROVIDER_PATH} />}></Route>
      <Route path="*" element={<Error404Page />}></Route>
    </>
  );

  // Datadog clobbers our routes because they have alphanumeric ids in them
  // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#react-router-instrumentation
  const location = useLocation();
  useEffect(() => {
    const matches = matchRoutes(
      createRoutesFromElements(routes),
      location.pathname,
    );
    if (matches) {
      const {
        pathname,
        route: { id },
      } = matches[0];
      const viewName = id ? `${id} (${pathname})` : pathname;
      datadogRum.startView(viewName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(
    function handleDevMode() {
      // Only load the dev page if Vite is running in dev mode
      if (isDevMode) {
        import("../dev/Page").then((module) => {
          const DevPageComponent = module.Page;
          setDevPage(<DevPageComponent />);
        });
        import("../dev/DevTools").then((module) => {
          const DevToolsComponent = module.DevTools;
          setDevTools(<DevToolsComponent />);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <RouterRoutes>{routes}</RouterRoutes>;
}
