import { differenceInMinutes, parseISO } from "date-fns";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { JoinSessionCalloutWrapper as JoinSessionCallout } from "./delayed-entry/JoinSessionCallout";
import { useCallback, useContext, useEffect, useRef } from "react";
import { TelehealthSessionInfoData_TelehealthSessionInfo_Appointment } from "../../hooks";
import { delayedEntryGate, preSessionVibesGate } from "../../statsig/gates";
import { recordedMoodCheckin } from "../components/forms-drawer/mood-check-in/useMoodCheckinSelectionsState";
import { VisitorDrawerState, visitorMeasuresAtom } from "../state";
import {
  deadlineAtom,
  sessionConversationSidAtom,
} from "./delayed-entry/state";
import {
  PreSessionPromptWrapper as PreSessionPrompt,
  PreSessionPromptVariant,
} from "./PreSessionPrompt";
import { useTransitionToMeetingRoom } from "./useWatchForTwilioRoomToken";
import { usePreSessionPromptState } from "./usePreSessionPromptState";
import { Statsig, StatsigLoadingContext } from "../../statsig/StatsigProvider";
import { MeasureSchemaType } from "@grow-therapy-team/seedling-components";
import { useFeatureGate } from "@statsig/react-bindings";

const PRE_SESSION_PROMPT_THRESHOLD_MINUTES = 4;

type SessionInfoSectionWrapperProps = {
  appointment?: TelehealthSessionInfoData_TelehealthSessionInfo_Appointment;
  formsAreLoading?: boolean;
  hasForms?: boolean;
  openDrawer: (state: VisitorDrawerState) => void;
};

function isWithinThreshold(appointmentStartTime: string) {
  return (
    differenceInMinutes(parseISO(appointmentStartTime), new Date()) >
    PRE_SESSION_PROMPT_THRESHOLD_MINUTES
  );
}

function appointmentHasStarted(appointmentStartTime?: string) {
  if (!appointmentStartTime) return false;
  return Date.now() > new Date(appointmentStartTime).getTime();
}
export function SessionInfoSectionWrapper({
  appointment,
  hasForms,
  ...props
}: SessionInfoSectionWrapperProps) {
  const isLoading = useContext(StatsigLoadingContext);
  const { preSessionPrompt, setPreSessionPrompt, clearPreSessionPrompt } =
    usePreSessionPromptState();
  const hasClearedLocalStorageRef = useRef(false); // to prevent re-computation
  const deadline = useAtomValue(deadlineAtom);
  const hasMood = !!useAtomValue(recordedMoodCheckin);
  const isPreSessionVibesEnabled = useFeatureGate(preSessionVibesGate).value;
  const measures = useAtomValue(visitorMeasuresAtom) ?? [];
  const visitorMeasuresAreAllScreeners = measures.reduce(
    (prev, currMeasure) =>
      prev &&
      !!currMeasure &&
      (currMeasure.schema.type === MeasureSchemaType.GAD7_WITH_SCREENER ||
        currMeasure.schema.type === MeasureSchemaType.PHQ9_WITH_SCREENER),
    true,
  );

  const transitionToMeetingRoom = useTransitionToMeetingRoom();
  const getSessionConversationSid = useAtomCallback(
    useCallback((get) => get(sessionConversationSidAtom), []),
  );
  const joinCallback = useCallback(() => {
    transitionToMeetingRoom(getSessionConversationSid(), {
      onDone() {
        hasClearedLocalStorageRef.current = true;
        clearPreSessionPrompt();
      },
    });
  }, [
    clearPreSessionPrompt,
    getSessionConversationSid,
    transitionToMeetingRoom,
  ]);

  useEffect(
    function allowPreSessionPromptToRecompute() {
      if (!hasMood) return;

      clearPreSessionPrompt();
    },
    [clearPreSessionPrompt, hasMood],
  );

  useEffect(() => {
    if (hasClearedLocalStorageRef.current) return;

    const shouldShowScreener =
      !appointmentHasStarted(appointment?.timeStart) &&
      visitorMeasuresAreAllScreeners;

    if (preSessionPrompt !== undefined) {
      // once set, always set
      return;
    } else if (!appointment?.timeStart) {
      setPreSessionPrompt(
        isPreSessionVibesEnabled
          ? PreSessionPromptVariant.VIBES
          : PreSessionPromptVariant.TIPS,
      );
    } else if (
      (isWithinThreshold(appointment?.timeStart) || shouldShowScreener) &&
      hasForms
    ) {
      setPreSessionPrompt(PreSessionPromptVariant.CLIENT_MEASURES);
    } else if (!hasMood && !appointmentHasStarted(appointment?.timeStart)) {
      // skip if already submitted or appointment has already started
      // this means a client could submit a mood if reconnecting, but
      // prevents an edge case where they join close to appointment end
      // and then still attempt to submit a mood
      setPreSessionPrompt(PreSessionPromptVariant.MOOD_SURVEY);
    } else {
      setPreSessionPrompt(
        isPreSessionVibesEnabled
          ? PreSessionPromptVariant.VIBES
          : PreSessionPromptVariant.TIPS,
      );
    }
  }, [
    appointment?.timeStart,
    hasForms,
    hasMood,
    preSessionPrompt,
    setPreSessionPrompt,
    visitorMeasuresAreAllScreeners,
    isPreSessionVibesEnabled,
  ]);

  if (!isLoading && preSessionPrompt !== undefined) {
    return (
      <div className="flex flex-col items-center gap-y-2 p-4">
        {Statsig.checkGate(delayedEntryGate) && deadline && (
          <JoinSessionCallout deadline={deadline} joinCallback={joinCallback} />
        )}
        <PreSessionPrompt variant={preSessionPrompt} {...props} />
      </div>
    );
  }
}
