import { OldToast, ToastVariant } from "../../components";
import toast from "react-hot-toast";
import { Icon, Text } from "@growtherapy/sprout-ui";
import { faCircleX, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

const CLIENT_CONSENT_COPY =
  "Your client has consented. Transcription is in progress";
const CLIENT_DID_NOT_CONSENT_COPY =
  "Your client does not consent to transcription. Your session will not be transcribed.";
export function ClientTranscriptionConsentToast({
  id: toastId,
  isOptIn,
}: {
  id: string;
  isOptIn: boolean;
}) {
  return (
    <OldToast
      className="bottom-center-toast sm:max-w-fit"
      variant={isOptIn ? ToastVariant.Success : ToastVariant.Neutral}
      onClose={() => {
        toast.remove(toastId);
      }}
    >
      <div className="flex flex-row gap-2 items-center">
        <Icon aria-hidden icon={isOptIn ? faCheckCircle : faCircleX} />
        <Text variant="sm">
          {isOptIn ? CLIENT_CONSENT_COPY : CLIENT_DID_NOT_CONSENT_COPY}
        </Text>
      </div>
    </OldToast>
  );
}
