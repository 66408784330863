import { memoize } from "../../utils";

const importTldrawMemoized = memoize(async () => await import("tldraw"));

/**
 * Lazily imports the tldraw package to create a new whiteboard store
 */
export async function createWhiteboardStore() {
  return (await importTldrawMemoized()).createTLStore();
}
