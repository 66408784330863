import { useSetAtom } from "jotai";
import { useCallback } from "react";
import {
  ProviderDrawerState,
  drawerStateAtom,
  inSessionPatientInformationAtom,
  selectedClientIdAtom,
} from "../state";
import { useAtomCallback } from "jotai/utils";
import { getIsClientInfoDrawerOpen } from "../utils";

export function useOpenClientInformationCallback() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setSelectedClientId = useSetAtom(selectedClientIdAtom);
  const getInSessionVisitorId = useAtomCallback(
    useCallback((get) => get(inSessionPatientInformationAtom)?.visitorUuid, []),
  );

  return useCallback(
    (visitorId?: string) => {
      setSelectedClientId(visitorId ?? getInSessionVisitorId() ?? "");
      setDrawerState(ProviderDrawerState.CLIENT_INFORMATION);
    },
    [getInSessionVisitorId, setDrawerState, setSelectedClientId],
  );
}

export function useCloseClientInformationCallback() {
  const setSelectedClientId = useSetAtom(selectedClientIdAtom);
  const setDrawerState = useSetAtom(drawerStateAtom);

  return useCallback(() => {
    setDrawerState(null);
    setSelectedClientId(undefined);
  }, [setDrawerState, setSelectedClientId]);
}

export function useToggleClientInformationDrawerCallback() {
  const getDrawerState = useAtomCallback(
    useCallback((get) => get(drawerStateAtom), []),
  );
  const openClientInformationDrawer = useOpenClientInformationCallback();
  const closeClientInformationDrawer = useCloseClientInformationCallback();

  return useCallback(
    (
      ...openClientInformationDrawerCallbackArgs: Parameters<
        typeof openClientInformationDrawer
      >
    ) => {
      const drawerState = getDrawerState();
      if (getIsClientInfoDrawerOpen(drawerState)) {
        closeClientInformationDrawer();
        return;
      }
      openClientInformationDrawer(...openClientInformationDrawerCallbackArgs);
    },
    [closeClientInformationDrawer, getDrawerState, openClientInformationDrawer],
  );
}

export function useClientInformationDrawerCallbacks() {
  return {
    openClientInformationDrawer: useOpenClientInformationCallback(),
    closeClientInformationDrawer: useCloseClientInformationCallback(),
    toggleClientInformationDrawer: useToggleClientInformationDrawerCallback(),
  };
}
