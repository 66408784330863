import { useSetAtom } from "jotai";
import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen";
import { preSessionVibesOpenAtom } from "../../state";
import { VerticalVibes } from "./VerticalVibes";
import { HorizontalVibes } from "./HorizontalVibes";
import { VibesComponentProps } from "./types";
import { useBackgroundMusicPlayback as defaultUseBackgroundMusicPlayback } from "../background-action/useBackgroundMusicPlayback";

type PreSessionVibesWrapperProps = {
  VerticalVibesComponent?: React.FC<VibesComponentProps>;
  HorizontalVibesComponent?: React.FC<VibesComponentProps>;
  useBackgroundMusic?: typeof defaultUseBackgroundMusicPlayback;
};
export function PreSessionVibesWrapper({
  useBackgroundMusic = defaultUseBackgroundMusicPlayback,
  VerticalVibesComponent = VerticalVibes,
  HorizontalVibesComponent = HorizontalVibes,
}: PreSessionVibesWrapperProps) {
  const isSmallScreen = useIsSmallScreen();
  const setPreSessionVibesOpen = useSetAtom(preSessionVibesOpenAtom);
  const { handlePause } = useBackgroundMusic();

  function closePreSessionVibes() {
    handlePause();
    setPreSessionVibesOpen(false);
  }

  if (isSmallScreen) {
    return (
      <VerticalVibesComponent closePreSessionVibes={closePreSessionVibes} />
    );
  }

  return (
    <HorizontalVibesComponent closePreSessionVibes={closePreSessionVibes} />
  );
}
