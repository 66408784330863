import {
  AdditionalOptionsMenu,
  AdditionalOptionsMenuProps,
} from "../../components/AdditionalOptionsMenu";
import { useChatDrawerButtonUtils } from "../chat/useChatDrawerButtonUtils";

export function VisitorAdditionalOptionsMenuWrapper({
  children,
}: Pick<AdditionalOptionsMenuProps, "children">) {
  const shouldShowVisualIndicator =
    useChatDrawerButtonUtils().unreadMessagesCount > 0;
  return (
    <AdditionalOptionsMenu
      shouldShowVisualIndicator={shouldShowVisualIndicator}
    >
      {children}
    </AdditionalOptionsMenu>
  );
}
