import { ScheduledAppointment, AppointmentLocation } from "./types";
import { useOpenClientInformationCallback } from "../client-information";
import { useSetAtom } from "jotai";
import { scheduledPatientInformationAtom } from "./state";
import { useTrackEvent } from "../../segment/segment";
import { EventTypeEnum } from "../../segment/types";
import { UpcomingAgendaTileWrapper as UpcomingAgendaTile } from "@grow-therapy-team/seedling-components";

export function SchedulePreviewAppointment({
  appointment,
  onOpenClientInformation,
}: {
  appointment: NonNullable<ScheduledAppointment>;
  onOpenClientInformation: () => void;
}) {
  const { patient, timeStart, appointmentType, timeEnd, inOffice } =
    appointment;

  return (
    <UpcomingAgendaTile
      onClick={onOpenClientInformation}
      appointmentTimeStart={timeStart}
      appointmentTimeEnd={timeEnd}
      appointmentType={appointmentType}
      appointmentPatientPreferredFullName={
        patient.preferredFullName ?? undefined
      }
      appointmentPatientLatestIntakeResponse={
        patient.latestIntakeFormResponse ?? undefined
      }
      appointmentPatientNudges={patient.nudges ?? undefined}
      appointmentIsInPerson={inOffice === AppointmentLocation.InOffice}
    />
  );
}

export function SchedulePreviewAppointmentWrapper({
  appointment,
  appointmentPosition,
}: {
  appointment: ScheduledAppointment;
  appointmentPosition: number;
}) {
  const setScheduledPatientInfo = useSetAtom(scheduledPatientInformationAtom);
  const { userClicked } = useTrackEvent();
  const openClientInformation = useOpenClientInformationCallback();
  const patientShortId = appointment?.patient.shortId;
  const patientName = appointment?.patient.preferredFullName;
  if (!patientShortId || !patientName || !appointment) return;
  const onOpenClientInformation = () => {
    setScheduledPatientInfo({
      name: patientName,
      patientShortId: patientShortId,
      appointmentType: appointment.appointmentType,
      appointmentStartTime: appointment.timeStart,
      appointmentEndTime: appointment.timeEnd,
      recurringAppointmentGrouping: appointment.recurringAppointmentGrouping,
      appointmentLocation: appointment.inOffice,
      appointmentId: appointment.id,
      appointmentShortId: appointment.appointmentShortId,
      patientId: appointment.patient.id,
    });
    openClientInformation(patientShortId);
    userClicked(EventTypeEnum.WAITING_ROOM_SCHEDULE, {
      appointmentPosition,
      appointmentShortId: appointment.appointmentShortId,
    });
  };
  return (
    <SchedulePreviewAppointment
      appointment={appointment}
      onOpenClientInformation={() => onOpenClientInformation()}
    />
  );
}
