import {
  LocalAudioVideoThumbnail,
  LocalAudioVideoThumbnailProps,
} from "../twilio/session-grid/LocalAudioVideoThumbnail";
import { useGetCurrentProviderQuery } from "./useGetCurrentProviderQuery";

export function SelfVideoThumbnail(
  props: Partial<LocalAudioVideoThumbnailProps>,
) {
  const { data } = useGetCurrentProviderQuery();

  return (
    <LocalAudioVideoThumbnail
      name={data?.currentProvider?.name ?? ""}
      pronouns={data?.currentProvider?.pronouns ?? []}
      {...props}
    />
  );
}
