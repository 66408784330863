import { Text, PhotoAvatar, Skeleton } from "@growtherapy/sprout-ui";
import { Provider } from "../types";
import { twMerge } from "tailwind-merge";
import { useGetCurrentProviderQuery } from "./useGetCurrentProviderQuery";

type ProviderAvatarProps = {
  className?: string;
  provider: Pick<Provider, "image" | "name" | "pronouns">;
};

export function ProviderAvatar({ className, provider }: ProviderAvatarProps) {
  return (
    <div
      className={twMerge("flex items-center gap-x-3 fs-exclude", className)}
      data-dd-privacy="mask"
      data-testid="provider-avatar"
    >
      <PhotoAvatar
        containerClassName="bg-neutral-100"
        alt={`Provider ${provider.name}`}
        src={provider?.image ?? ""}
        size="md"
      />
      <Text
        variant="lg"
        className="font-medium text-neutral-100"
        data-dd-action-name="Click on provider avatar"
      >
        {provider.name}
        {(provider?.pronouns?.length ?? 0) > 0 && (
          <span className="font-normal"> ({provider.pronouns![0]})</span>
        )}
      </Text>
    </div>
  );
}

export function ProviderAvatarWrapper(props: Partial<ProviderAvatarProps>) {
  const { data, loading } = useGetCurrentProviderQuery();

  if (loading || !data) {
    return (
      <div className="flex gap-3 items-center">
        <Skeleton className="my-0 mx-0 rounded-full w-18 h-18" />
        <Skeleton className="my-0 mx-0 w-28" />
      </div>
    );
  }

  return <ProviderAvatar provider={data.currentProvider} {...props} />;
}
