import { ToastQueue, ToastStateProps } from "@react-stately/toast";
import type { CustomToast, ToastPosition } from "./types";
import { maybeWrapInViewTransition } from "./util";

const MAX_VISIBLE_TOASTS = 8;
const QUEUE_OPTIONS: ToastStateProps = {
  maxVisibleToasts: MAX_VISIBLE_TOASTS,
  wrapUpdate: maybeWrapInViewTransition,
};
export const DEFAULT_POSITION: ToastPosition = "bottom-center";

const topQueue = new ToastQueue<CustomToast>(QUEUE_OPTIONS);
const bottomLeftQueue = new ToastQueue<CustomToast>(QUEUE_OPTIONS);
const bottomCenterQueue = new ToastQueue<CustomToast>(QUEUE_OPTIONS);
const bottomRightQueue = new ToastQueue<CustomToast>(QUEUE_OPTIONS);

export const queues: Record<ToastPosition, ToastQueue<CustomToast>> = {
  top: topQueue,
  "bottom-left": bottomLeftQueue,
  "bottom-center": bottomCenterQueue,
  "bottom-right": bottomRightQueue,
};
