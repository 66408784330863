import { useAtomValue, useAtom } from "jotai";
import { useState, useEffect } from "react";
import {
  localScreenShareTrackAtom,
  shouldTriggerPictureInPictureAtom,
  pictureInPictureParticipantIdAtom,
  trackStatusByParticipantIdAtom,
} from "../state";
import { logger } from "../../datadog/logger";
import { exitPictureInPicture } from "../utils";

export function useTriggerPictureInPicture(
  remoteParticipantId: string,
  videoRef: React.RefObject<HTMLVideoElement>,
) {
  const [videoMetadataLoaded, setVideoMetadataLoaded] = useState(false);

  const isLocalScreenShareMode = useAtomValue(localScreenShareTrackAtom);
  const trackStatusByParticipantId = useAtomValue(
    trackStatusByParticipantIdAtom,
  );

  const [shouldTriggerPictureInPicture, setShouldTriggerPictureInPicture] =
    useAtom(shouldTriggerPictureInPictureAtom);
  const pictureInPictureParticipantId = useAtomValue(
    pictureInPictureParticipantIdAtom,
  );

  const remoteParticipantIsPictureInPictureTarget =
    pictureInPictureParticipantId === remoteParticipantId;
  const isRemoteVideoOff =
    trackStatusByParticipantId[remoteParticipantId]?.isVideoOff;

  // If we try to trigger PiP before the video metadata has loaded we'll get an error
  useEffect(
    function listenForVideoMetadataLoaded() {
      const video = videoRef.current;
      if (video) {
        const abortController = new AbortController();
        video.addEventListener(
          "loadedmetadata",
          () => setVideoMetadataLoaded(true),
          {
            signal: abortController.signal,
          },
        );

        return () => abortController.abort();
      }
    },
    [videoRef],
  );

  useEffect(
    function listenForVideoMetadataUnloaded() {
      if (isRemoteVideoOff) {
        setVideoMetadataLoaded(false);
      }
    },
    [isRemoteVideoOff],
  );

  useEffect(
    function triggerPictureInPicture() {
      if (remoteParticipantIsPictureInPictureTarget && videoMetadataLoaded) {
        const tryToEnterPictureInPicture = async () => {
          const shouldEnterForScreenshare =
            isLocalScreenShareMode || shouldTriggerPictureInPicture;
          const shouldEnterAutomatically = !document.hasFocus();
          const shouldEnterPictureInPicture =
            shouldEnterAutomatically || shouldEnterForScreenshare;

          if (
            videoRef?.current?.requestPictureInPicture &&
            shouldEnterPictureInPicture
          ) {
            try {
              await videoRef.current.requestPictureInPicture();
              logger.info("Entered picture in picture", {
                remoteParticipantId,
              });
            } catch (error) {
              exitPictureInPicture();

              logger.warn(
                "Could not initiate picture in picture",
                {},
                error as Error,
              );
            } finally {
              setShouldTriggerPictureInPicture(false);
            }
          }
        };

        // Immediately try to enter picture in picture if the remote participant is our PiP target
        tryToEnterPictureInPicture();

        try {
          // Handles opening picture in picture when a user changes tabs
          window.navigator.mediaSession.setActionHandler(
            "enterpictureinpicture" as MediaSessionAction,
            tryToEnterPictureInPicture,
          );
        } catch (error) {
          logger.warn(
            "Could not listen for enterpictureinpicture",
            {},
            error as Error,
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      remoteParticipantIsPictureInPictureTarget,
      videoRef,
      videoMetadataLoaded,
      shouldTriggerPictureInPicture,
      setShouldTriggerPictureInPicture,
      isLocalScreenShareMode,
    ],
  );

  /**
   * Handles exiting out of picture in picture when the user stops
   * screensharing
   */
  useEffect(
    function exitPipWhenScreenShareStops() {
      if (!isLocalScreenShareMode) {
        exitPictureInPicture();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLocalScreenShareMode],
  );

  useEffect(
    function exitSafariPipWhenScreenShareStops() {
      const video = videoRef.current;
      return () => {
        if (
          isLocalScreenShareMode && // @ts-ignore webkitPresentationMode is specific to Safari
          video?.webkitPresentationMode === "picture-in-picture"
        ) {
          // @ts-ignore webkitSetPresentationMode is specific to Safari
          video?.webkitSetPresentationMode?.("inline");
        }
      };
    },
    [videoRef, isLocalScreenShareMode],
  );
}
