import { useAtomValue } from "jotai";
import { drawerStateAtom } from "../state";
import { useObscuredContentProps } from "../../a11y";

/**
 * See `useObscuredContentProps` for more information.
 */
export function useObscuredProviderContentProps() {
  const isDrawerOpen = !!useAtomValue(drawerStateAtom);

  return useObscuredContentProps({
    isDrawerOpen,
  });
}
