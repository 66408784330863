import { useEffect } from "react";
import { getAppConfig } from "../../config";
import { logger } from "../../datadog/logger";
import { minutesToMilliseconds } from "date-fns";
import { toast } from "../../toasts";
import { Button } from "@growtherapy/sprout-ui";

export const VERSION_CHECK_INTERVAL_MS = minutesToMilliseconds(5);
const TOAST_KEY = "stale-version";

export async function fetchVersion() {
  const config = getAppConfig();
  try {
    const version = await fetch("/version");
    const text = await version.text();
    return text.trim();
  } catch (error) {
    logger.error("Unable to fetch version", config, error as Error);
  }
}

/**
 * Detects stale version and shows toast if necessary. If the toast is not able
 * not be shown, then a toast will be pending until it can be shown.
 *
 * `shouldShowToast` is used to determine whether or not the toast should be
 * shown, and will hide a visible toast immediately if it returns false.
 */
export function useDetectStaleVersionInterval(shouldShowToast: boolean) {
  const showToast = () => {
    toast.info(
      <span>
        A telehealth update is available. Refresh your browser or{" "}
        <Button
          onClick={() => location.reload()}
          className="p-0 m-0 font-normal text-neutral-900"
          use="link"
        >
          click here
        </Button>{" "}
        for the latest version.
      </span>,
      {
        key: TOAST_KEY,
        position: "top",
        timeout: Infinity,
      },
    );
  };

  useEffect(
    function handleStaleVersionDetection() {
      if (!shouldShowToast) return;

      const fetchAndCheckVersion = async () => {
        const version = await fetchVersion();
        if (!version || version === getAppConfig().version) {
          return toast.close(TOAST_KEY);
        }
        logger.info("Stale version detected", {
          currentVersion: getAppConfig().version,
          latestVersion: version,
        });
        showToast();
      };

      // Immediately check for stale version
      fetchAndCheckVersion();

      const interval = setInterval(
        fetchAndCheckVersion,
        VERSION_CHECK_INTERVAL_MS,
      );

      return () => clearInterval(interval);
    },
    [shouldShowToast],
  );

  useEffect(
    function handleHidingStaleVersionToast() {
      if (!shouldShowToast) toast.close(TOAST_KEY);
    },
    [shouldShowToast],
  );
}
