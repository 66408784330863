import { toast } from "../../toasts";

export const showErrorToast = () => {
  toast.error("Failed to save changes", {
    position: "bottom-right",
  });
};

export const showAppointmentCreatedToast = () => {
  toast.success("Appointment added!", {
    position: "bottom-right",
  });
};

export const showAppointmentUpdatedToast = () => {
  toast.success("Appointment updated!", {
    position: "bottom-right",
  });
};
