import { DRAWER_TITLE_ID, IconButton, Text } from "@growtherapy/sprout-ui";
import { DrawerHeader } from "../../components/DrawerHeader";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { useAtomValue, useSetAtom } from "jotai";
import { ProviderDrawerState, drawerStateAtom } from "../state";
import { scheduledPatientInformationAtom } from "../schedule-preview/state";
import { formatAppointmentTime } from "./utils";

export function EditAppointmentDrawerHeader({
  onClickBackButton,
  clientName,
  appointmentTime,
}: {
  onClickBackButton: () => void;
  clientName: string;
  appointmentTime: string | JSX.Element;
}) {
  return (
    <DrawerHeader>
      <div className="flex items-center">
        <IconButton
          onClick={onClickBackButton}
          className="[&>svg]:w-[0.75rem] mr-4 w-8 h-8 flex justify-center items-center"
          size="sm"
          aria-label="Go back"
          data-testid="edit-appointment.button.back"
          iconDefinition={faArrowLeft}
        />
        <span>
          <span
            className="fs-exclude"
            data-dd-privacy="mask"
            data-dd-action-name="Client Name"
            data-testid="edit-appointment.header.name"
          >
            <Text variant="lg" className="font-medium" id={DRAWER_TITLE_ID}>
              <span className="sr-only">Edit appointment for </span>
              {clientName}
            </Text>
          </span>
          <Text variant="sm" className="text-neutral-700">
            {appointmentTime}
          </Text>
        </span>
      </div>
    </DrawerHeader>
  );
}

export function EditAppointmentDrawerHeaderWrapper() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  const scheduledAppointmentInfo = useAtomValue(
    scheduledPatientInformationAtom,
  );

  const appointmentTime = formatAppointmentTime(
    scheduledAppointmentInfo?.appointmentStartTime,
    scheduledAppointmentInfo?.appointmentEndTime,
  );
  return (
    <EditAppointmentDrawerHeader
      clientName={scheduledAppointmentInfo?.name || ""}
      onClickBackButton={() => setDrawerState(ProviderDrawerState.CLIENT_INFORMATION)}
      appointmentTime={appointmentTime}
    />
  );
}
