import { useAtomValue } from "jotai";
import { visitorDrawerStateAtom } from "../state";
import { useObscuredContentProps } from "../../a11y";

/**
 * See `useObscuredContentProps` for more information.
 */
export function useObscuredVisitorContentProps() {
  const isDrawerOpen = !!useAtomValue(visitorDrawerStateAtom);

  return useObscuredContentProps({ isDrawerOpen });
}
