import {
  Navbar,
  PageContainer,
  PageProviders,
  PushDrawerContainer,
  StageContainer,
} from "../../components";
import { Theme } from "../../state";
import { useClientTwilioRoomListeners } from "./useClientTwilioRoomListeners";
import { isMediaShareModeAtom, useHandleDeviceChanges } from "../../twilio";
import { SelfVideoThumbnail } from "../SelfVideoThumbnail";
import { useNetworkQualityLogging } from "../../twilio/useNetworkQualityLogging";
import { useSessionFocus, nonEssentialElementProps } from "../../hooks";
import { useResetLocalVideoOnScreenOrientationChange } from "../../twilio/useResetLocalVideoOnScreenOrientationChange";
import { useResetVideoOnMobileNavigation } from "../../twilio/useResetVideoOnMobileNavigation";
import { useAtomValue, useSetAtom } from "jotai";
import { SessionGrid } from "../../twilio/session-grid/SessionGrid";
import { VisitorDrawerState, visitorDrawerStateAtom } from "../state";
import { PushDrawerWrapper as PushDrawer } from "../PushDrawer";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { HelpModalWrapper as HelpModal } from "../HelpModal";
import { useLeaveSessionNudgeToast } from "./useLeaveSessionNudgeToast";
import { sendLoggingEvents, TrackingEvents } from "../../events";
import { useSessionConversation } from "./useSessionConversation";
import classNames from "classnames";
import { useSetProviderParticipantData } from "./useSetProviderParticipantData";
import { useUpdateTrackStatsInterval } from "../../twilio/stats/useUpdateTrackStatsInterval";
import { RemoteParticipant } from "../RemoteParticipant";
import { TranscriptionPillWrapper } from "../session-recording/TranscriptionPill";
import { usePeriodicallySendParticipantState } from "../../twilio/messages/usePeriodicallySendParticipantState";
import { useSendVisitorParticipantStateCallback } from "../messages/useSendVisitorParticipantState";
import { VisitorWhiteboard } from "./VisitorWhiteboard";
import { MEETING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID } from "../constants";
import { useObscuredVisitorContentProps } from "../a11y";
import { VisitorMeetingSessionControlsWrapper as SessionControls } from "./controls/VisitorMeetingSessionControls";

function StageEffects() {
  const setDrawerState = useSetAtom(visitorDrawerStateAtom);
  const sendParticipantState = useSendVisitorParticipantStateCallback();
  useClientTwilioRoomListeners();
  useHandleDeviceChanges();
  useNetworkQualityLogging({
    onOpenSettings: () => {
      setDrawerState(VisitorDrawerState.SETTINGS);
    },
  });
  useSessionFocus();
  useResetLocalVideoOnScreenOrientationChange();
  useResetVideoOnMobileNavigation();
  useLeaveSessionNudgeToast();
  useSetProviderParticipantData();
  useUpdateTrackStatsInterval();
  usePeriodicallySendParticipantState(sendParticipantState);

  return null;
}

const Stage = memo(function Stage() {
  const isMediaShareMode = useAtomValue(isMediaShareModeAtom);
  const obscuredContentProps = useObscuredVisitorContentProps();

  const thumbnail = useMemo(
    () => (
      <SelfVideoThumbnail
        showExpandVideoToggle={false}
        shouldShowHideSelf={false}
        showSetPositionMenu={false}
        videoClassName={classNames({
          "max-w-[15rem] max-h-[8.5rem] min-w-[unset] w-auto h-full [&>video]:relative mb-2 flex-1 rounded-2xl border-neutral-800 border":
            isMediaShareMode,
        })}
        className={classNames(
          "max-w-[15rem] max-h-[8.5rem] min-w-[unset] w-auto h-full [&>video]:relative mr-2 sm:mr-0 flex-1 border border-neutral-800 rounded-2xl",
          {
            "flex-1 min-w-[10rem] sm:min-w-[unset] min-h-[7.875rem] w-auto h-full sm:mb-2":
              isMediaShareMode,
          },
        )}
      />
    ),
    [isMediaShareMode],
  );

  return (
    <>
      <StageEffects />
      <StageContainer {...obscuredContentProps} rounded>
        <SessionGrid
          whiteboardComponent={VisitorWhiteboard}
          remoteParticipantComponent={RemoteParticipant}
          thumbnail={thumbnail}
        />
        {!isMediaShareMode && (
          <SelfVideoThumbnail
            className="absolute bottom-2"
            showSetPositionMenu
            shouldShowHideSelf
            showExpandVideoToggle={false}
          />
        )}
      </StageContainer>
    </>
  );
});

export function Page() {
  useSessionConversation();
  const obscuredContentProps = useObscuredVisitorContentProps();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  useEffect(function sendLoggingEventsOnPageLoad() {
    sendLoggingEvents(TrackingEvents.CLIENT_ENTER_SESSION_ROOM);
  }, []);

  const openHelpModal = useCallback(() => {
    sendLoggingEvents(TrackingEvents.ANY_HELP_REQUEST);
    sendLoggingEvents(TrackingEvents.CLIENT_HELP_REQUEST);
    setIsHelpModalOpen(true);
  }, []);

  return (
    <PageProviders theme={Theme.DARK}>
      <HelpModal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
      />
      <PageContainer>
        <Navbar
          {...obscuredContentProps}
          onClickNeedHelpButton={openHelpModal}
          TranscriptionPillComponent={TranscriptionPillWrapper}
          {...nonEssentialElementProps}
          className={classNames(nonEssentialElementProps.className, "order-1")}
        />
        <SessionControls />
        <PushDrawerContainer
          // The push drawer must come _after_ the buttons that can trigger it
          // in the DOM. This is so that a keyboard user can tab forward from
          // the triggering button to the drawer. However, the drawer and stage
          // are meant to be visually positioned _before_ the control buttons,
          // so we reorder them within their flex container.
          className="order-2"
        >
          <Stage />
          <PushDrawer id={MEETING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID} />
        </PushDrawerContainer>
      </PageContainer>
    </PageProviders>
  );
}
