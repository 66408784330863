import { flushSync } from "react-dom";
import { deviceSupportsViewTransitions } from "../utils";
import { Statsig } from "../statsig/StatsigProvider";
import { toastViewTransitionsGate } from "../statsig/gates";
import { SMALL_SCREEN_BREAKPOINT_PX } from "../assets/constants";
import type { CustomToast, ToastPosition } from "./types";
import { queues } from "./queues";
import type { ToastQueue } from "@react-stately/toast";

/**
 * Check if the device supports view transitions and the feature flag is
 * enabled.
 *
 * @returns Whether or not to use view transitions.
 */
export function shouldUseViewTransitions(): boolean {
  return (
    Statsig.checkGate(toastViewTransitionsGate) &&
    deviceSupportsViewTransitions()
  );
}

/**
 * Lifted almost verbatim from `@react-stately/toast` example for view
 * transitions:
 * https://github.com/adobe/react-spectrum/blob/260eb700f4ece25446fde230588409af7721bad9/packages/%40react-spectrum/toast/src/ToastContainer.tsx#L42-L56
 */
export function maybeWrapInViewTransition<R>(fn: () => R): R {
  // We need to check the gate dynamically at call-time because Statsig is not
  // initialized when this module is imported. The gate value should be cached
  // after the first call, though, so this should be a cheap check.
  if (shouldUseViewTransitions()) {
    let result: R;
    document.startViewTransition(() => {
      flushSync(() => {
        result = fn();
      });
    });
    // @ts-ignore
    return result;
  } else {
    return fn();
  }
}

/**
 * Gets the appropriate queue to add the next toast to based on the desired
 * position and the current window size.
 *
 * @param position the desired position for the toast
 * @returns the queue to add the toast to
 */
export function getQueueForWindowSize(
  position: ToastPosition,
): ToastQueue<CustomToast> {
  if (position === "top") return queues.top;
  if (window.innerWidth < SMALL_SCREEN_BREAKPOINT_PX) {
    return queues["bottom-center"];
  }
  return queues[position];
}

/**
 * Filter out false positive warnings from the console that are triggered by
 * react-aria/toast's `useLandmark` hook. Returns the original console.warn.
 *
 * react-aria/toast internally performs a check to ensure that landmarks defined
 * with its `useLandmark` hook are unique and logs a warning if it detects
 * multiple landmarks with the same role and label. This is a reasonable check
 * to perform, but in our case, we only apply the role to the landmark region
 * when there are actually visible toasts present in it. This means that the
 * warning triggers unnecessarily, because it is only aware that it has produced
 * multiple non-unique props for landmarks, and it is not aware that those props
 * are not actually being applied at the same time.
 */
export function filterWarnings() {
  /* eslint-disable no-console */
  const originalConsoleWarn = console.warn;
  // Override console.warn to filter out the specific warning
  console.warn = (...args) => {
    const warningMessage = args[0];
    if (
      typeof warningMessage === "string" &&
      warningMessage.includes("Page contains more than one landmark")
    ) {
      return;
    }
    /* eslint-enable no-console */
    originalConsoleWarn(...args);
  };
  return originalConsoleWarn;
}
