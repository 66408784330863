import { useAtomValue } from "jotai";
import { LocalTrackState, localVideoTrackAtom } from "../state";
import { usePublishLocalTrackCallback } from "./usePublishLocalTrackCallback";
import { useUnpublishLocalTrackCallback } from "./useUnpublishLocalTrackCallback";
import classNames from "classnames";
import oldToast from "react-hot-toast";
import { toast } from "../../toasts";
import { secondsToMilliseconds } from "date-fns";
import { NoAccessToast, NoAccessVariant } from "./NoAccessToast";
import { ControlPanelButton } from "../../components";
import { faVideo, faVideoSlash } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@growtherapy/sprout-ui";
import { useStoredAudioVideoSettings } from "../useStoredAudioVideoSettings";
import { useCallback } from "react";

export function VideoControl({
  isEnabled,
  loading,
  onToggle,
}: {
  isEnabled?: boolean;
  loading?: boolean;
  onToggle: (enable: boolean) => void;
}) {
  return (
    <Tooltip
      childIsInteractive
      text={`Toggle video ${isEnabled ? "off" : "on"}`}
    >
      <ControlPanelButton
        aria-label={`Toggle video ${isEnabled ? "off" : "on"}`}
        loading={loading}
        className={classNames({ "text-coral-600": !isEnabled })}
        iconDefinition={isEnabled ? faVideo : faVideoSlash}
        data-testid="video-control"
        onClick={() => onToggle(!isEnabled)}
      />
    </Tooltip>
  );
}

export function VideoControlWrapper({
  openHelpModal,
}: {
  openHelpModal?: () => void;
}) {
  const { track: localVideoTrackValue, state: localVideoTrackState } =
    useAtomValue(localVideoTrackAtom);
  const publishLocalTrack = usePublishLocalTrackCallback("video");
  const unpublishLocalTrack = useUnpublishLocalTrackCallback("video");
  const isLoading = localVideoTrackState === LocalTrackState.LOADING;
  const isEnabled =
    localVideoTrackValue && localVideoTrackState === LocalTrackState.READY;
  const { setStoredAudioVideoSettings } = useStoredAudioVideoSettings();
  const setStoredVideoControl = useCallback(
    (enabled: boolean) =>
      setStoredAudioVideoSettings((prev) => ({
        ...prev,
        isVideoEnabled: enabled,
      })),
    [setStoredAudioVideoSettings],
  );

  return (
    <VideoControl
      isEnabled={isEnabled}
      loading={isLoading}
      onToggle={async (enable) => {
        if (enable) {
          if (await publishLocalTrack()) {
            setStoredVideoControl(true);
            return;
          }
          if (!openHelpModal) return;
          // TODO: TOAST HOT
          oldToast.custom(
            (t) => (
              <NoAccessToast
                className="bottom-center-toast"
                onClose={() => oldToast.remove(t.id)}
                openHelpModal={openHelpModal}
                variant={NoAccessVariant.Camera}
              />
            ),
            {
              duration: secondsToMilliseconds(10),
              position: "bottom-center",
              id: `no-access-${NoAccessVariant.Camera}`,
            },
          );
          return;
        }
        if (await unpublishLocalTrack()) {
          setStoredVideoControl(false);
          return;
        }
        toast.error(
          "An unexpected error occurred while trying to disable your camera.",
          { position: "bottom-center" },
        );
      }}
    />
  );
}
