import { Button, DrawerFooter, Link } from "@growtherapy/sprout-ui";
import { useAtomValue, useSetAtom } from "jotai";
import { scheduledPatientInformationAtom } from "../schedule-preview/state";
import { useState } from "react";
import {
  RecurringAppointmentModalWrapper as RecurringAppointmentModal,
  RecurringAppointmentModalState,
} from "./RecurringAppointmentModal";
import { getAppointmentCancelLink } from "./utils";
import { useTrackEvent } from "../../segment/segment";
import {
  AppointmentRescheduledSource,
  RescheduleAppointmentMutationData,
  useRescheduleAppointmentMutation,
} from "../useRescheduleAppointmentMutation";
import { FetchResult } from "@apollo/client";
import { EditAppointmentFormValues } from "./EditAppointmentDrawer";
import {
  UpdateRecurringAppointmentMutationData,
  useRescheduleRecurringAppointmentMutation,
} from "../useRescheduleRecurringAppointmentMutation";
import {
  ClientInformationDrawerTab,
  ProviderDrawerState,
  drawerStateAtom,
  providerShortIdAtom,
} from "../state";
import { AppointmentLocation } from "../schedule-preview/types";
import { showAppointmentUpdatedToast, showErrorToast } from "./toasts";
import { logger } from "../../datadog";
import { activeClientInformationTabAtom } from "../client-information/state";
import { mightNeedToRefreshAppointmentListAtom } from "./state";
import { EventTypeEnum } from "../../segment/types";

export function EditAppointmentDrawerFooter({
  recurringAppointmentModalState,
  setRecurringAppointmentModalState,
  nonRecurringAppointmentLink,
  isSaveDisabled,
  onRescheduleSingle,
  onRescheduleRecurring,
  isLoading,
}: {
  recurringAppointmentModalState: RecurringAppointmentModalState;
  setRecurringAppointmentModalState: (
    state: RecurringAppointmentModalState,
  ) => void;
  isSaveDisabled: boolean;
  nonRecurringAppointmentLink?: string;
  onRescheduleSingle: () => Promise<void | FetchResult<UpdateRecurringAppointmentMutationData>>;
  onRescheduleRecurring: () => Promise<void | FetchResult<RescheduleAppointmentMutationData>>;
  isLoading: boolean;
}) {
  const isNonRecurring = !!nonRecurringAppointmentLink;
  const setMightNeedToRefreshAppointmentList = useSetAtom(
    mightNeedToRefreshAppointmentListAtom,
  );
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setActiveTab = useSetAtom(activeClientInformationTabAtom);
  const onClickCancel = () => {
    setMightNeedToRefreshAppointmentList(true);
    setDrawerState(ProviderDrawerState.CLIENT_INFORMATION);
    setActiveTab(ClientInformationDrawerTab.APPOINTMENTS);
  };
  return (
    <>
      <DrawerFooter>
        <Button
          disabled={isSaveDisabled}
          loading={isLoading}
          onClick={
            isNonRecurring
              ? () => onRescheduleSingle()
              : () =>
                  setRecurringAppointmentModalState({
                    isOpen: true,
                    isCancel: false,
                  })
          }
        >
          Save changes
        </Button>
        {nonRecurringAppointmentLink ? (
          <Link
            external
            href={nonRecurringAppointmentLink}
            buttonUse="destructive"
            className="ml-2"
            onClick={onClickCancel}
          >
            Cancel appointment
          </Link>
        ) : (
          <Button
            onClick={() =>
              setRecurringAppointmentModalState({
                isOpen: true,
                isCancel: true,
              })
            }
            use="destructive"
          >
            Cancel appointment
          </Button>
        )}
      </DrawerFooter>
      <RecurringAppointmentModal
        setRecurringAppointmentModalState={setRecurringAppointmentModalState}
        recurringAppointmentModalState={recurringAppointmentModalState}
        onRescheduleSingle={onRescheduleSingle}
        onRescheduleRecurring={onRescheduleRecurring}
        onCancel={onClickCancel}
      />
    </>
  );
}

export function EditAppointmentDrawerFooterWrapper({
  isSaveDisabled,
  formValues,
}: {
  isSaveDisabled: boolean;
  formValues: EditAppointmentFormValues;
}) {
  const scheduledPatientInformation = useAtomValue(
    scheduledPatientInformationAtom,
  );
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setActiveTab = useSetAtom(activeClientInformationTabAtom);

  const [rescheduleAppointment, { loading: singleLoading }] =
    useRescheduleAppointmentMutation();

  const [updateRecurringAppointment, { loading: recurringLoading }] =
    useRescheduleRecurringAppointmentMutation();
  const { formSubmitted } = useTrackEvent();

  const isLoading = singleLoading || recurringLoading;

  const [recurringAppointmentModalState, setRecurringAppointmentModalState] =
    useState({
      isOpen: false,
      isCancel: false,
    });
  const providerShortId = useAtomValue(providerShortIdAtom);

  if (!scheduledPatientInformation) return;
  const sendSuccessfulFormSubmissionEvent = () => {
    formSubmitted(EventTypeEnum.WAITING_ROOM_APPOINTMENT_UPDATED, {
      appointmentShortId: scheduledPatientInformation.appointmentShortId,
      patientShortId: scheduledPatientInformation.patientShortId,
      appointmentStatus: "RESCHEDULED",
    });
  };

  const isRecurring =
    !!scheduledPatientInformation?.recurringAppointmentGrouping;

  const nonRecurringAppointmentLink = !isRecurring
    ? getAppointmentCancelLink({
        appointmentShortId: scheduledPatientInformation.appointmentShortId,
      })
    : undefined;

  const onRescheduleSingle = async () =>
    await rescheduleAppointment({
      variables: {
        values: {
          appointmentId: scheduledPatientInformation.appointmentId,
          startDate: formValues.startDate,
          timeEnd: formValues.endDate,
          inOffice:
            formValues.appointmentLocation ?? AppointmentLocation.Virtual,
          rescheduledSource: AppointmentRescheduledSource.Telehealth,
        },
      },
    })
      .then(() => {
        showAppointmentUpdatedToast();
        setDrawerState(ProviderDrawerState.CLIENT_INFORMATION);
        setActiveTab(ClientInformationDrawerTab.APPOINTMENTS);
        sendSuccessfulFormSubmissionEvent();
      })
      .catch((error) => {
        showErrorToast();
        logger.error(
          "Failed to reschedule single appointment",
          {},
          error as Error,
        );
      });

  const onRescheduleRecurring = async () =>
    await updateRecurringAppointment({
      variables: {
        values: {
          inOffice:
            formValues.appointmentLocation === AppointmentLocation.InOffice,
          patientId: scheduledPatientInformation.patientId,
          providerShortId: providerShortId,
          //@ts-ignore - recurringAppointmentGroupingId will not actually be undefined
          recurringAppointmentGroupingId:
            scheduledPatientInformation.recurringAppointmentGrouping
              ?.recurringAppointmentGroupingId,
          rescheduledSource: AppointmentRescheduledSource.Telehealth,
          timeEnd: formValues.endDate,
          timeStart: formValues.startDate,
        },
      },
    })
      .then(() => {
        sendSuccessfulFormSubmissionEvent();
        showAppointmentUpdatedToast();
        setDrawerState(ProviderDrawerState.CLIENT_INFORMATION);
        setActiveTab(ClientInformationDrawerTab.APPOINTMENTS);
      })
      .catch((error) => {
        logger.error(
          "Failed to reschedule recurring appointment",
          {},
          error as Error,
        );
        showErrorToast();
      });

  return (
    <EditAppointmentDrawerFooter
      recurringAppointmentModalState={recurringAppointmentModalState}
      setRecurringAppointmentModalState={setRecurringAppointmentModalState}
      nonRecurringAppointmentLink={nonRecurringAppointmentLink}
      isSaveDisabled={isSaveDisabled}
      onRescheduleSingle={onRescheduleSingle}
      onRescheduleRecurring={onRescheduleRecurring}
      isLoading={isLoading}
    />
  );
}
