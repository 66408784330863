import { IconButtonProps, Tag, Tooltip } from "@growtherapy/sprout-ui";
import { faMessage } from "@fortawesome/pro-solid-svg-icons";
import { DrawerButton, DrawerButtonProps } from "../../components/DrawerButton";
import { ControlPanelButton, IconMenuItem } from "../../components";

export function ChatDrawerControlButton({
  drawerId,
  isChatDrawerOpen,
  messageCount = 0,
  loading,
  forDrawerStates,
  ...otherProps
}: {
  drawerId: string;
  isChatDrawerOpen: boolean;
  messageCount?: number;
} & Omit<
  IconButtonProps,
  "aria-label" | "icon" | "iconName" | "iconDefinition" | "iconVariant"
> &
  Pick<DrawerButtonProps<IconButtonProps>, "forDrawerStates">) {
  const label = isChatDrawerOpen
    ? "Close chat"
    : `Open chat, ${messageCount} unread ${messageCount === 1 ? "message" : "messages"}`;

  return (
    <div className="hidden md:block relative">
      {/* NOTE: !w-max encourages the tooltip not to constrain its
          width to the child `DrawerButton`'s width. */}
      <Tooltip childIsInteractive text={label} className="!w-max">
        <DrawerButton
          as={ControlPanelButton}
          forDrawerStates={forDrawerStates}
          aria-label={label}
          aria-expanded={isChatDrawerOpen}
          aria-controls={drawerId}
          iconDefinition={faMessage}
          loading={loading}
          disabled={loading}
          showVisualIndicator={messageCount > 0}
          {...otherProps}
        />
      </Tooltip>
    </div>
  );
}

export function ChatDrawerControlMenuItem({
  drawerId,
  isChatDrawerOpen,
  onClickChatButton,
  isChatButtonDisabled,
  unreadMessageCount,
}: {
  drawerId: string;
  isChatDrawerOpen: boolean;
  onClickChatButton: () => void;
  isChatButtonDisabled: boolean;
  unreadMessageCount?: number;
}) {
  return (
    <IconMenuItem
      aria-controls={drawerId}
      aria-expanded={isChatDrawerOpen}
      onClick={onClickChatButton}
      disabled={isChatButtonDisabled}
      icon={faMessage}
    >
      <span className="sr-only">
        {isChatDrawerOpen ? "Close" : "Open"} chat,
      </span>
      <span aria-hidden>Chat</span>
      {unreadMessageCount ? (
        <Tag use="lilac">{unreadMessageCount}</Tag>
      ) : (
        <span className="sr-only">0</span>
      )}
      <span className="sr-only">
        unread {unreadMessageCount === 1 ? "message" : "messages"}
      </span>
    </IconMenuItem>
  );
}
