import { Button, toast } from "@growtherapy/sprout-ui";
import { PageComponentProps } from "../../../components";
import { GroupedFormData } from "../types";
import { usePendingFormsContext } from "./usePendingFormsContext";
import { PendingFormsContextActionType } from "./state";
import { useSubmitFormCallback } from "./useSubmitFormCallback";
import { FormSubmissionStatusCode } from "../../../components/forms/types";
import { useMemo, useState } from "react";
import { ValidationErrors } from "./types";
import { validateForm } from "../../../components/forms/question/utils";
import { combinedFormToQuestions } from "../../../components/forms/utils";
import { useAtomValue, useSetAtom } from "jotai";
import { formValuesForSelectedClientAtom } from "../state";
import {
  ProviderDrawerState,
  drawerStateAtom,
  isInSessionWithSelectedClientAtom,
} from "../../state";

export function PendingFormsDrawerActions({
  page,
  pageIndex,
  nextPage,
  isLastPage,
}: PageComponentProps<GroupedFormData>) {
  const { dispatchPendingFormsContextAction } = usePendingFormsContext();
  const submitForm = useSubmitFormCallback();
  const formValuesByTitle = useAtomValue(formValuesForSelectedClientAtom);
  const [isLoading, setIsLoading] = useState(false);
  const setDrawerState = useSetAtom(drawerStateAtom);
  const goToCompletionDrawer = () =>
    setDrawerState(ProviderDrawerState.PENDING_FORMS_COMPLETED);
  const startedAt = useMemo(
    () => new Date().toISOString(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex],
  );
  const setValidationErrors = (validationErrors: ValidationErrors) =>
    dispatchPendingFormsContextAction?.({
      type: PendingFormsContextActionType.SET_VALIDATION_ERRORS,
      formTitle: pendingFormTitle,
      validationErrors,
    });
  const isInSessionWithSelectedClient = useAtomValue(
    isInSessionWithSelectedClientAtom,
  );

  if (!page) return null;

  const pendingFormTitle = page.formTitle;
  const formValues = formValuesByTitle?.[pendingFormTitle];
  const isDisabled =
    !isInSessionWithSelectedClient ||
    validateForm(combinedFormToQuestions(page), formValues).size > 0;

  const onClick = async () => {
    try {
      setIsLoading(true);

      const {
        code: formSubmissionResultCode,
        value: formSubmissionResultValue,
      } = await submitForm({
        isLastForm: isLastPage,
        pendingForm: page,
        startedAt,
        completedBy: "PROVIDER",
      });

      switch (formSubmissionResultCode) {
        case FormSubmissionStatusCode.SUCCESS:
          if (isLastPage) {
            goToCompletionDrawer();
          } else {
            nextPage();
          }
          break;
        case FormSubmissionStatusCode.VALIDATION_ERROR:
          setValidationErrors(formSubmissionResultValue);
          break;
        case FormSubmissionStatusCode.MISSING_REQUIRED_FIELDS:
        case FormSubmissionStatusCode.ERROR:
          // TODO: TOAST SPROUT
          toast.error("Unable to submit form due to an unexpected error.");
          break;
        default:
          break;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button disabled={isDisabled} loading={isLoading} onClick={onClick}>
      {isLastPage ? "Submit" : "Continue"}
    </Button>
  );
}
