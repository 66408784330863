import { Button, Link } from "@growtherapy/sprout-ui";

import { getAppConfig } from "../config";
import { useAtomValue } from "jotai";
import { useChatDrawerButtonUtils } from "./chat/useChatDrawerButtonUtils";
import {
  visitorDrawerStateAtom,
  providerIsOnlineAtom,
  VisitorDrawerState,
} from "./state";
import { wordpressUrlMigrationGate } from "../statsig/gates";
import { useFeatureGate } from "@statsig/react-bindings";
type ProviderNotHereDescriptionProps = {
  providerIsOnline: boolean | null;
  clientHasAppointment: boolean;
  appointmentHasProviderNoShowReport: boolean;
  isPastNoShowThreshold: boolean;
  clientJoinedAfterWindowToReport: boolean;
  recordProviderNoShowAndRedirect: () => void;
  onOpenChatDrawer: () => void;
  onClose: () => void;
};
export function ProviderNotHereDescription({
  providerIsOnline,
  clientHasAppointment,
  appointmentHasProviderNoShowReport,
  isPastNoShowThreshold,
  clientJoinedAfterWindowToReport,
  recordProviderNoShowAndRedirect,
  onOpenChatDrawer,
  onClose,
}: ProviderNotHereDescriptionProps) {
  const marketplaceOrigin = getAppConfig().marketplaceOrigin;

  const { value: wordpressUrlMigration } = useFeatureGate(
    wordpressUrlMigrationGate,
  );
  const contactUsUrlPath = wordpressUrlMigration
    ? "/contact-us"
    : "/wp/contact-us";

  const supportText = (
    <>
      There is no session scheduled for this time. If you believe this is an
      error, please reach out to us{" "}
      <Link
        href={`${marketplaceOrigin}/${contactUsUrlPath}`}
        size="md"
        className="text-neutral-800 hover:text-neutral-900"
      >
        here
      </Link>{" "}
    </>
  );

  if (providerIsOnline) {
    return (
      <>
        Your provider appears to be online but hasn’t admitted you yet. Please{" "}
        <Button
          use="link"
          onClick={onOpenChatDrawer}
          className="text-neutral-800 hover:text-neutral-900"
        >
          message your provider
        </Button>{" "}
        to be admitted.
      </>
    );
  }

  if (!clientHasAppointment) {
    return supportText;
  }
  if (appointmentHasProviderNoShowReport) {
    return (
      <>
        We received a confirmation that your provider didn&apos;t show up for
        the appointment. No further action is required. In the meantime,
        consider{" "}
        <Link
          external
          className="text-neutral-800 font-medium hover:text-neutral-900"
          href={`${marketplaceOrigin}/filters?utm_source=telehealth-booking`}
        >
          booking with a new provider
        </Link>
      </>
    );
  }

  if (clientJoinedAfterWindowToReport) {
    return <>The grace period for the session has passed.</>;
  }

  return (
    <>
      Your provider has a 10-minute grace period. When 10 minutes have passed,
      please let us know using the button below:
      <Button
        disabled={!isPastNoShowThreshold}
        className="w-full mt-3"
        aria-live="polite"
        onClick={() => {
          recordProviderNoShowAndRedirect();
          onClose();
        }}
      >
        {isPastNoShowThreshold && (
          // alerts screen readers when button state changes
          <span className="sr-only">Button enabled:</span>
        )}
        My provider is not here
      </Button>
    </>
  );
}

export function ProviderNotHereDescriptionWrapper(
  props: Omit<
    ProviderNotHereDescriptionProps,
    "providerIsOnline" | "onOpenChatDrawer"
  >,
) {
  const { toggleChatDrawer } = useChatDrawerButtonUtils();
  const drawerState = useAtomValue(visitorDrawerStateAtom);
  const providerIsOnline = useAtomValue(providerIsOnlineAtom);

  return (
    <ProviderNotHereDescription
      providerIsOnline={providerIsOnline}
      onOpenChatDrawer={() => {
        if (drawerState !== VisitorDrawerState.CHAT) {
          toggleChatDrawer();
        }
        props.onClose();
      }}
      {...props}
    />
  );
}
