import { toast } from "@growtherapy/sprout-ui";
import { useAtom } from "jotai";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { logger } from "../../../../datadog";
import { useSyncClientMoodMutation } from "../../../../hooks/measures/useSyncClientMoodMutation";
import { useTrackEvent } from "../../../../segment/segment";
import { EventTypeEnum } from "../../../../segment/types";
import { useVisitorId } from "../../../useVisitorId";
import { moodCheckinAtom } from "./useMoodCheckinSelectionsState";
import { ChoiceValue } from "./utils";

export function useSyncMoodCheckinChoicesCallback() {
  const visitorId = useVisitorId();
  const { patientShortId = "" } = useParams();
  const { formSubmitted } = useTrackEvent();
  const [, setMoodCheckin] = useAtom(moodCheckinAtom);
  const [onSyncClientMood] = useSyncClientMoodMutation();

  return useCallback(
    (
      selections: ChoiceValue[],
      options?: Pick<
        NonNullable<Parameters<typeof onSyncClientMood>[0]>,
        "onError" | "onCompleted"
      >,
    ) => {
      if (!selections?.length) return;

      onSyncClientMood({
        ...options,
        onCompleted(data, clientOptions) {
          setMoodCheckin((prev) => ({
            ...prev,
            emotions: clientOptions?.variables?.emotions,
            moodCheckinAt: data.syncClientMood.recordedAt,
          }));

          formSubmitted(EventTypeEnum.CLIENT_MOOD_CHECKIN_SUBMITTED, {
            patientShortId,
          });

          options?.onCompleted?.(data, clientOptions);
        },
        onError(error) {
          logger.error("Unable to save mood", { visitorId }, error);
          // TODO: TOAST SPROUT
          toast.error("Unable to save mood, please try again.");
        },
        variables: {
          visitorId,
          emotions: selections,
        },
      });
    },
    [
      formSubmitted,
      onSyncClientMood,
      patientShortId,
      setMoodCheckin,
      visitorId,
    ],
  );
}
