import { useAtomValue } from "jotai";
import { useRecordingControl as useBaseRecordingControl } from "../../twilio/controls/useRecordingControl";
import { inSessionPatientInformationAtom } from "../state";
import { useGetCurrentProviderQuery } from "../useGetCurrentProviderQuery";

export function useRecordingControl() {
  const { data: providerData } = useGetCurrentProviderQuery();
  const hasAppointment = !!useAtomValue(inSessionPatientInformationAtom)
    ?.inSessionAppointmentShortId;
  return useBaseRecordingControl(
    hasAppointment,
    providerData?.currentProvider?.isPrescriber,
  );
}
