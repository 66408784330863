import { Mutex } from "async-mutex";
import silentAudioFile from "../assets/audio/5s-silence.opus";
import { gracefullyPlayAudio } from "../audio";
import { deviceIsDesktopChrome } from "../utils";

async function gracefullyStartAndStopAudio(audio: HTMLAudioElement) {
  const isPlaying = !!(await gracefullyPlayAudio(audio));
  if (isPlaying) {
    audio.pause();
  }
}

function initializeSilentAudio() {
  const silentAudio = new Audio(silentAudioFile);
  silentAudio.loop = true;
  silentAudio.preload = "auto";
  return silentAudio;
}

function checkEnabled() {
  return deviceIsDesktopChrome();
}

type ConstructorOpts = {
  /**
   * Override that controls whether to initialize audio - if false, then the
   * singleton will not initialize audio and the singleton's operations will be
   * noops.
   *
   * Useful for testing.
   */
  enabledPredicate?: () => boolean;
};

/**
 * Singleton class that manages the Chrome toolbar playback controls. See
 * https://growtherapy.atlassian.net/browse/CARED-1620 for more information.
 */
export class ChromeToolbarPlaybackControlsSingleton {
  static #instance: ChromeToolbarPlaybackControlsSingleton | null = null;
  #initializationMutex = new Mutex();
  #controlsMutex = new Mutex();
  #audio: HTMLAudioElement | null = null;

  /**
   * Tears down the silent audio element and unsets it.
   */
  async #teardownSilentAudio() {
    return await this.#initializationMutex.runExclusive(async () => {
      this.#audio?.remove();
      this.#audio = null;
    });
  }

  /**
   * Initializes a silent audio element that can be used to play silent audio
   * on loop in the background.
   *
   * Does nothing if the browser is not desktop Chrome, the feature gate is
   * disabled, or if an audio object already exists.
   */
  async #initializeSilentAudio({
    enabledPredicate = checkEnabled,
  }: ConstructorOpts = {}) {
    if (!enabledPredicate()) return;
    return await this.#initializationMutex.runExclusive(async () => {
      if (this.#audio) return;
      const silentAudio = initializeSilentAudio();
      this.#audio = silentAudio;
      return silentAudio;
    });
  }

  private constructor(options?: ConstructorOpts) {
    this.#initializeSilentAudio(options);
  }

  /**
   * Returns the singleton instance or creates one if it doesn't exist.
   */
  public static getInstance(options?: ConstructorOpts) {
    if (!ChromeToolbarPlaybackControlsSingleton.#instance) {
      ChromeToolbarPlaybackControlsSingleton.#instance =
        new ChromeToolbarPlaybackControlsSingleton(options);
    }
    return ChromeToolbarPlaybackControlsSingleton.#instance;
  }

  /**
   * Shows the Chrome toolbar playback controls by playing and then immediately
   * pausing the silent background audio.
   */
  public async showChromeToolbarPlaybackControls() {
    return await this.#controlsMutex.runExclusive(async () => {
      if (!this.#audio) return;
      await gracefullyStartAndStopAudio(this.#audio);
      return this.#audio;
    });
  }

  /**
   * Hides the Chrome toolbar playback controls by removing the silent
   * background audio.
   */
  public async hideChromeToolbarPlaybackControls(opts?: ConstructorOpts) {
    return await this.#controlsMutex.runExclusive(async () => {
      if (!this.#audio) return;
      await this.#teardownSilentAudio();
      // Remove the current audio element which will hide the toolbar controls
      // and set up a new one for next time
      return await this.#initializeSilentAudio(opts);
    });
  }

  /**
   * Returns the silent audio object used for the background audio
   */
  public getAudio() {
    return this.#audio;
  }

  /**
   * Unsets the singleton instance for testing purposes.
   */
  public static unsetInstance() {
    ChromeToolbarPlaybackControlsSingleton.#instance = null;
  }
}

export async function showChromeToolbarPlaybackControls(
  ...args: Parameters<
    typeof ChromeToolbarPlaybackControlsSingleton.prototype.showChromeToolbarPlaybackControls
  >
) {
  return await ChromeToolbarPlaybackControlsSingleton.getInstance().showChromeToolbarPlaybackControls(
    ...args,
  );
}

export async function hideChromeToolbarPlaybackControls(
  ...args: Parameters<
    typeof ChromeToolbarPlaybackControlsSingleton.prototype.hideChromeToolbarPlaybackControls
  >
) {
  return await ChromeToolbarPlaybackControlsSingleton.getInstance().hideChromeToolbarPlaybackControls(
    ...args,
  );
}
