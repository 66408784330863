import { parse } from "tldts";
import {
  Browser,
  DeviceType,
  Env,
  NamespaceKeys,
  UnnamespaceKeys,
} from "./types";
import qs from "qs";
import { parseISO, differenceInMilliseconds } from "date-fns";
import { v4 as uuid4 } from "uuid";
import Bowser from "bowser";
import { PrimitiveAtom, atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { twMerge } from "tailwind-merge";
import { mapKeys, mergeDeep, pipe, debounce as baseDebounce } from "remeda";

export function getCurrentHost() {
  if (typeof window === "undefined") return "";

  return window.location.hostname;
}

export function getEnvFromHostName(
  hostname: string,
  defaultEnvironment = Env.DEV,
): Env {
  const { domainWithoutSuffix, hostname: host } = parse(hostname);
  switch (domainWithoutSuffix ?? host) {
    case "growtherapy":
      return Env.PROD;
    case "growtherapystaging":
      return Env.STAGING;
    case "growtherapydev":
      return Env.DEV;
    case "localhost":
    case "ngrok":
    case "growtherapylocal":
      return Env.LOCAL;
    default:
      return defaultEnvironment || Env.DEV;
  }
}

// centralized isProduction helper
// in the future (if we have SSR) we should also consider environment variables
export function environmentIsProduction() {
  // just to be sure we're not running in node (e.g., tests or SSR)
  if (typeof location === "undefined") return undefined;

  return getEnvFromHostName(location.hostname) === Env.PROD;
}

export function callAndDelay(callback: () => unknown, delayMs: number) {
  callback();

  return new Promise((resolve) => {
    setTimeout(resolve, delayMs);
  });
}

export function setQueryParams(
  url: string,
  params: Record<string, string>,
): string {
  const givenUrlParams = qs.parse(new URL(url).search, {
    ignoreQueryPrefix: true,
  }) as Record<string, string>;
  const updatedUrl = new URL(url);
  updatedUrl.search = qs.stringify({ ...givenUrlParams, ...params });
  return updatedUrl.href;
}

export function noop<T>(..._args: unknown[]) {
  return undefined as unknown as T;
}

export function nameToInitials(name: string) {
  const alphaNumericChars = name.replace(/[^ a-zA-Z0-9]/g, "");
  const nameParts = alphaNumericChars.split(" ").filter((w) => w.length > 0);
  const numInitialsToInclude = nameParts.length === 2 ? 2 : 1;
  const shortenedNameParts = nameParts.splice(0, numInitialsToInclude);

  return shortenedNameParts
    .map((w) => w.charAt(0))
    .join("")
    .toUpperCase();
}

export function nameToInitialsWithPeriods(
  firstName?: string | null,
  lastName?: string | null,
) {
  if (firstName && lastName) {
    return `${firstName[0]}.${lastName[0]}.`;
  }
  return "";
}
export function olderThan(timestamp: string, millis: number) {
  return differenceInMilliseconds(new Date(), parseISO(timestamp)) >= millis;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(value: unknown): value is Function {
  return typeof value === "function";
}

export function shortUuid() {
  return uuid4().slice(0, 8);
}

/**
 * Debounce a function, ensuring that it is only called at most once within the
 * given time. Drops calls that occur during the cooldown.
 */
export function debounce<FnParams extends unknown[], FnReturn = unknown>(
  fn: (...args: FnParams) => FnReturn,
  waitMs: number,
  timing: "both" | "leading" | "trailing" = "leading",
): (...funcArgs: FnParams) => FnReturn | undefined {
  const debouncer = baseDebounce(fn, {
    waitMs,
    // Typings are wack here; it accepts "trailing" but not when in the union
    timing: timing as "both" | "leading",
  });

  return (...args: FnParams) => {
    // This prevents the cooldown from being extended everytime a call is made
    if (timing === "leading" && debouncer.isPending) return;
    return debouncer.call(...args);
  };
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function deviceIsIOS() {
  return /iphone|ipad/i.test(navigator.userAgent);
}

export function deviceIsAndroid() {
  return /android/i.test(navigator.userAgent);
}

export function deviceIsMobile() {
  return deviceIsIOS() || deviceIsAndroid();
}

export function getDeviceType() {
  return deviceIsMobile() ? DeviceType.Mobile : DeviceType.Desktop;
}

export function deviceSupportsViewTransitions() {
  return (
    "startViewTransition" in document &&
    !deviceIsMobile() &&
    !window.matchMedia("(prefers-reduced-motion: reduce)").matches
  );
}

export function deviceIsDesktopChrome() {
  return /Chrome/.test(navigator.userAgent);
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function addImmutablyToSet<T>(set: Set<T>, value: T) {
  return new Set([...set, value]);
}

export function removeImmutablyFromSet<T>(set: Set<T>, value: T) {
  const newSet = new Set(set);
  newSet.delete(value);
  return newSet;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function memoize<T extends (...args: any[]) => any>(fn: T): T {
  const cache: { [key: string]: ReturnType<T> } = {};

  return ((...args: Parameters<T>): ReturnType<T> => {
    const key = JSON.stringify(args);
    if (key in cache) {
      return cache[key];
    } else {
      const result = fn(...args);
      cache[key] = result;
      return result;
    }
  }) as T;
}

export function memoizedDeserializerWithInitialValue<T>(
  initialValue: T | (() => T),
  // calling methods should handle the error (to avoid a circular dependency)
  onParseError?: (error: Error) => void,
) {
  // returns a memoized version of the deserializer defined in useLocalStorage:
  // https://github.com/juliencrn/usehooks-ts/blob/master/packages/usehooks-ts/src/useLocalStorage/useLocalStorage.ts#L71-L87
  return memoize((value: string): T => {
    const defaultValue =
      initialValue instanceof Function ? initialValue() : initialValue;

    // this is a break from the original source, which returns undefined cast as
    // T. IMO, this is a less error-prone default behavior.
    if (value === "undefined") {
      return defaultValue;
    }

    let parsed: unknown;
    try {
      parsed = JSON.parse(value);
    } catch (error) {
      onParseError?.(error as Error);
      return defaultValue;
    }

    return parsed as T;
  });
}

export function isElementVisible(element: HTMLElement) {
  const style = window.getComputedStyle(element);
  return (
    style.display !== "none" &&
    style.visibility !== "hidden" &&
    style.opacity !== "0"
  );
}

export function gracefullyParseJson<T>(json?: string | null): T | null {
  if (!json) return null;
  try {
    return JSON.parse(json ?? "{}");
  } catch {
    return null;
  }
}

export function gracefullyStringifyJson(obj: object): string | null {
  try {
    return JSON.stringify(obj);
  } catch {
    return null;
  }
}

/**
 * Returns true if the value is in the list, otherwise false. If the list is
 * undefined, the value is always allowed. If `isDenyList` is true, the value is
 * allowed if it is not in the list.
 */
export function checkIsAllowed<T>(
  value: T,
  {
    allowList,
    denyList,
  }: {
    allowList?: T[];
    denyList?: T[];
  } = {},
) {
  if (!allowList && !denyList) return true;

  const isAllowed = !allowList || allowList.includes(value);
  const isDenied = denyList && denyList.includes(value);
  return isAllowed && !isDenied;
}

export const detectBrowser = memoize((): Browser => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserName = browser.getBrowserName().toLowerCase();

  if (browserName.includes(Browser.Chrome)) {
    return Browser.Chrome;
  }

  if (browserName.includes(Browser.Safari)) {
    return Browser.Safari;
  }

  if (browserName.includes(Browser.Firefox)) {
    return Browser.Firefox;
  }

  if (browserName.includes(Browser.Edge)) {
    return Browser.Edge;
  }

  if (browserName.includes(Browser.Opera)) {
    return Browser.Opera;
  }

  return Browser.Unknown;
});

// Common hashing function, similar to the one here: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
export function hashString(str: string) {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
}

export function hashObject(obj: object) {
  return hashString(JSON.stringify(obj));
}

// a function to shuffle an array with a seed, such that given the same array
// with the same seed, you will get the same shuffled array
export function seededShuffle<T>(source: T[], shuffleSeed: number): T[] {
  // we modify the array in place, so we make a copy to avoid modifying the original
  const output = [...source];
  let m = output.length;
  let t: T;
  let i: number;

  // ensure seed is positive to avoid issues with modulo putting elements at negative indices
  let seed = shuffleSeed < 0 ? -1 * shuffleSeed : shuffleSeed;

  while (m) {
    i = Math.floor(seed % m--);
    t = output[m];
    output[m] = output[i];
    output[i] = t;
    seed = Math.floor(seed / m);
  }

  return output;
}

export function asTimeWithTimeZone(time?: string) {
  if (!time) return "";

  return new Date(time).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  });
}

/**
 * Maps over the array with the given function and then filters out
 * null/undefined values. Note, this means other falsey return values will be
 * included.
 */
export function keep<T, U>(
  arr: Array<T>,
  fn: (val: T, index: number) => U | null | undefined,
) {
  return arr.map(fn).filter((val) => val !== null && val !== undefined) as U[];
}

export function createDerivedWritableAtom<Type, Key extends keyof Type>(
  base: PrimitiveAtom<Type>,
  key: Key,
  options?: {
    defaultGetValue: NonNullable<Type[Key]>;
    debugLabel?: string;
  },
): PrimitiveAtom<Type[Key]> {
  const derivedAtom = atom(
    // I do not typically like type assertion, especially where it could be
    // undefined, but the signature of the method should ensure that the value
    // cannot be undefined/null
    (get) => get(base)[key] ?? (options?.defaultGetValue as Type[Key]),
    (_get, set, valueOrFunction) => {
      set(base, (prev) => ({
        ...prev,
        [key]: isFunction(valueOrFunction)
          ? valueOrFunction(prev[key])
          : valueOrFunction,
      }));
    },
  );

  const baseDebugLabelPrefix = base?.debugLabel ? `${base.debugLabel}.` : "";
  derivedAtom.debugLabel =
    options?.debugLabel ?? `${baseDebugLabelPrefix}${String(key)}`;

  return derivedAtom;
}

export function createDerivedWritableAtomFamily<
  Type,
  Param extends keyof Type,
  Key extends keyof Type[Param],
>(
  base: PrimitiveAtom<Type>,
  key: Key,
  options?: {
    defaultGetValue: NonNullable<Type[Param][Key]>;
  },
) {
  return atomFamily((param: Param) =>
    atom(
      // I do not typically like type assertion, especially where it could be
      // undefined, but the signature of the method should ensure that the value
      // cannot be undefined/null
      (get) =>
        get(base)[param]?.[key] ??
        (options?.defaultGetValue as Type[Param][Key]),
      (_get, set, valueOrFunction) => {
        set(base, (prev) => ({
          ...prev,
          [param]: {
            ...prev[param],
            [key]: isFunction(valueOrFunction)
              ? valueOrFunction(prev[param][key])
              : valueOrFunction,
          },
        }));
      },
    ),
  );
}

export const pluralizeString = (count: number, singular: string) =>
  count === 1 ? singular : `${singular}s`;

/**
 * Filters out null and undefined values from an array.
 */
export function filterNonNullable<T>(array: Array<T>): NonNullable<T>[] {
  return array.filter((item) => item !== null && item !== undefined);
}

export function arrayEquals<T>(a?: T[], b?: T[]) {
  // this is effectively XOR, but ^ confuses the linter!
  if (a === b) return true;
  if (!a || !b) return false;

  if (a?.length !== b?.length) return false;

  return a.every((v, i) => b[i] === v);
}

type MergableProps = {
  className?: string;
  [key: string]: unknown;
};

export function mergeProps<
  Source extends MergableProps,
  Destination extends MergableProps,
>(source: Source, destination: Destination): Source & Destination {
  const { className: sourceClassName } = source;
  const { className: destinationClassName } = destination;

  const classNameProp =
    sourceClassName && destinationClassName
      ? { className: twMerge(sourceClassName, destinationClassName) }
      : {};

  return pipe(
    source,
    (source) => mergeDeep(source, destination),
    (merged) => ({ ...merged, ...classNameProp }),
  ) as Source & Destination;
}

/**
 * Prepend a given object's keys with a given namespace string.
 */
export function namespaceKeys<
  T extends Record<string, unknown>,
  Namespace extends string,
>(obj: T, namespace: Namespace) {
  return mapKeys<typeof obj, string>(
    obj,
    (key) => `${namespace}.${String(key)}`,
  ) as NamespaceKeys<T, Namespace>;
}

/**
 * Remove a given, prepended namespace string from a given object's keys.
 */
export function unnamespaceKeys<
  T extends Record<string, unknown>,
  Namespace extends string,
>(obj: T, namespace: string) {
  return mapKeys<typeof obj, typeof namespace>(obj, (key) =>
    String(key).replace(`${namespace}.`, ""),
  ) as UnnamespaceKeys<T, Namespace>;
}
