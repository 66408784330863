import { useAtom } from "jotai";
import { visitorNameAtom } from "../state";
import { useCallback, useEffect } from "react";
import { logger } from "../../datadog";
import { useParams } from "react-router-dom";
import { getEnvFromHostName } from "../../utils";
import { Env } from "../../types";

const STORED_VISITOR_NAMES_KEY =
  "VISITOR_NAME_5CFEB82B-BE4D-4659-B6AE-5096524DE190";

// Mapping from patientShortID to visitor name
type StoredVisitorNames = Record<string, string>;

function parseStoredVisitorNames(rawValue: string): StoredVisitorNames {
  try {
    // Try to parse the value as JSON
    const parsedValue = JSON.parse(rawValue) as Record<string, unknown>;
    // If the parsed value is an object, it's likely a valid format.
    if (
      typeof parsedValue === "object" &&
      parsedValue !== null &&
      !Array.isArray(parsedValue)
    ) {
      return parsedValue as StoredVisitorNames;
    }
  } catch (_) {
    // This just means whatever value that was set is invalid, so we ignore it.
    logger.warn("Found invalid value stored for STORED_VISITOR_NAMES_KEY:", {
      rawValue,
    });
  }
  return {};
}

function shouldPersist() {
  const env = getEnvFromHostName(window.location.hostname);
  const urlParams = new URLSearchParams(window.location.search);
  return (
    // Always enabled in local
    env === Env.LOCAL ||
    // Enabled in non-production if URL param is set
    (env !== Env.PROD && urlParams.has("prefill", "true"))
  );
}

function getStoredVisitorName(patientShortID: string) {
  if (!shouldPersist()) return null;
  const names = localStorage.getItem(STORED_VISITOR_NAMES_KEY);
  if (!names) return null;
  const parsedNames = parseStoredVisitorNames(names);
  return parsedNames[patientShortID] ?? null;
}

function setStoredVisitorName(patientShortID: string, name: string) {
  if (!shouldPersist()) return;
  const existingNames = localStorage.getItem(STORED_VISITOR_NAMES_KEY);
  const newNames: StoredVisitorNames = {
    ...(existingNames ? parseStoredVisitorNames(existingNames) : {}),
    [patientShortID]: name,
  };
  localStorage.setItem(STORED_VISITOR_NAMES_KEY, JSON.stringify(newNames));
}

/**
 * Custom hook to manage the visitor name in Jotai state and local storage,
 * doing the latter only in local and staging environments. This SHOULD NOT be
 * turned on in production, since this is PII. Sets the initial value from local
 * storage if available. Tracks unique visitor name per patientShortID.
 *
 * @returns A tuple containing the current value for the visitor name and a
 * function to set the visitor name.
 */
export function useVisitorName(): [
  visitorName: string,
  setVisitorName: (name: string) => void,
] {
  const { patientShortId } = useParams();
  const [visitorName, setVisitorNameAtom] = useAtom(visitorNameAtom);

  // Set the initial value from local storage if available.
  useEffect(
    function setInitialVisitorName() {
      if (!patientShortId) return;
      const storedName = getStoredVisitorName(patientShortId);
      if (storedName && storedName.trim().length > 0) {
        setVisitorNameAtom(storedName);
      }
    },
    [patientShortId, setVisitorNameAtom],
  );

  const setVisitorName = useCallback(
    (name: string) => {
      if (patientShortId) {
        setStoredVisitorName(patientShortId, name);
      }
      setVisitorNameAtom(name);
    },
    [patientShortId, setVisitorNameAtom],
  );

  return [visitorName, setVisitorName];
}
