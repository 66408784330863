import {
  RemoteParticipantWrapper as BaseRemoteParticipant,
  remoteDataTracksAtom,
  RemoteParticipantWrapperProps,
  TrackSubscriptionHandler,
} from "../twilio";
import { useHandleReceivedMessageCallback } from "./messages/useHandleReceivedMessageCallback";
import { useCallback } from "react";
import { useSendProviderParticipantStateCallback } from "./messages/useSendProviderParticipantStateCallback";
import { useAtomCallback } from "jotai/utils";
import { isWhiteboardModeAtom } from "../twilio/messages/state";
import { useSendWhiteboardSnapshotCallback } from "../twilio/messages/useSendWhiteboardSnapshotCallback";
import { providerShortIdAtom } from "./state";
import { UserType } from "../types";
import { RemoteDataTrack, RemoteTrack } from "twilio-video";

function checkShouldSendWhiteboardSnapshot(
  storedRemoteDataTracks: RemoteDataTrack[],
  subscribedDataTrack: RemoteTrack,
  isWhiteboardMode: boolean,
) {
  const isAlreadySubscribedToDataTrack = storedRemoteDataTracks.some(
    (storedTrack) => storedTrack.sid === subscribedDataTrack.sid,
  );
  return isWhiteboardMode && !isAlreadySubscribedToDataTrack;
}

export function RemoteParticipant(
  props: Omit<RemoteParticipantWrapperProps, "onMessageReceived">,
) {
  const handleReceivedMessage = useHandleReceivedMessageCallback();
  const getIsWhiteboardMode = useAtomCallback(
    useCallback((get) => get(isWhiteboardModeAtom), []),
  );
  const sendWhiteboardSnapshot = useSendWhiteboardSnapshotCallback();
  const getProviderShortId = useAtomCallback(
    useCallback((get) => get(providerShortIdAtom), []),
  );
  const getRemoteDataTracks = useAtomCallback(
    useCallback((get) => get(remoteDataTracksAtom), []),
  );

  const sendParticipantState = useSendProviderParticipantStateCallback();

  const onDataTrackSubscribed: TrackSubscriptionHandler = useCallback(
    (subscribedTrack) => {
      sendParticipantState();
      const shouldSendWhiteboardSnapshot = checkShouldSendWhiteboardSnapshot(
        getRemoteDataTracks(),
        subscribedTrack,
        getIsWhiteboardMode().value,
      );
      if (shouldSendWhiteboardSnapshot) {
        sendWhiteboardSnapshot({
          entityType: UserType.PROVIDER,
          entityId: getProviderShortId() ?? "",
        });
      }
    },
    [
      getIsWhiteboardMode,
      getProviderShortId,
      getRemoteDataTracks,
      sendParticipantState,
      sendWhiteboardSnapshot,
    ],
  );

  return (
    <BaseRemoteParticipant
      {...props}
      onMessageReceived={handleReceivedMessage}
      onDataTrackSubscribed={onDataTrackSubscribed}
    />
  );
}
