import {
  Modal,
  ModalBody,
  Text,
  Button,
  Heading,
} from "@growtherapy/sprout-ui";
import HandHoldingPencil from "../../assets/images/hand-holding-pencil.svg?react";
import { ConsentModalText } from "./constants";
import { useCallback, useEffect, useState } from "react";
import { minutesToMilliseconds, secondsToMilliseconds } from "date-fns";
import { useParams } from "react-router-dom";
import { useGetTelehealthSessionInfo } from "../../hooks/useGetTelehealthSessionInfo";
import { useAtom, useAtomValue } from "jotai";
import { participantCountAtom } from "../../twilio";
import {
  patientConsentAtom,
  providerConsentAtom,
} from "../../twilio/messages/state";
import {
  ClientTranscriptionUserAction,
  ConsentStatus,
} from "../../twilio/types";
import { ConsentDeclinedToast } from "./ConsentDeclinedToast";
import oldToast from "react-hot-toast";
import { toast } from "../../toasts";
import { useSendVisitorParticipantStateCallback } from "../messages/useSendVisitorParticipantState";
import { useTrackEvent } from "../../segment/segment";
import { UserType } from "../../types";
import { useAtomCallback } from "jotai/utils";
import { sessionInfoAtom } from "../../state";
import { EventTypeEnum } from "../../segment/types";
import { useToggleRecordingCallback } from "./useToggleRecordingCallback";
import { useGracefullyRecordClientTranscriptionConsentCallback } from "./useGracefullyRecordClientTranscriptionConsentCallback";
import { useShowTranscriptionStorageDisclaimer } from "./useShowTranscriptionStorageDisclaimer";

const CONSENT_TOAST_DURATION_MS = secondsToMilliseconds(30);

export type ConsentModalProps = {
  onDeclineConsent: () => void;
  onConsent: () => Promise<void>;
  onDismissConsent: () => void;
  isOpen: boolean;
  autoDismissTimeout?: number;
  providerName: string;
  isMultiPatientSession: boolean;
  showTranscriptionStorageDisclaimer: boolean;
};

export function ConsentModal({
  onDeclineConsent,
  onConsent,
  onDismissConsent,
  isOpen,
  providerName,
  isMultiPatientSession,
  showTranscriptionStorageDisclaimer,
  autoDismissTimeout = minutesToMilliseconds(2),
}: ConsentModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const recordConsent = () => {
    setIsLoading(true);
    onConsent().finally(() => setIsLoading(false));
  };

  useEffect(
    function dismissAfterTwoMinutes() {
      if (!isOpen) return;
      const timeout = setTimeout(function dismissModal() {
        onDismissConsent();
      }, autoDismissTimeout);
      return function cleanup() {
        clearTimeout(timeout);
      };
    },
    [isOpen, autoDismissTimeout, onDismissConsent],
  );

  const consentFooterText = showTranscriptionStorageDisclaimer
    ? `${ConsentModalText.TRANSCRIPTION_STORAGE_DISCLAIMER} ${ConsentModalText.FOOTER}`
    : ConsentModalText.FOOTER;
  return (
    <Modal
      onClose={onDismissConsent}
      isOpen={isOpen}
      className="shadow-none sm:max-w-[31rem] md:max-w-[31rem] sm:top-[25%]"
      onOutsideClick={onDismissConsent}
    >
      <ModalBody className="flex flex-col items-center gap-4 p-8 text-center">
        <HandHoldingPencil />
        <div className="flex flex-col gap-2 items-center">
          <Heading variant="md" as="h2">
            {providerName} {ConsentModalText.HEADING}
          </Heading>
        </div>
        <div className="w-full flex flex-col items-center text-center">
          {isMultiPatientSession && (
            <Text variant="sm">
              {ConsentModalText.MULTI_PARTICIPANT_DISCLAIMER}
            </Text>
          )}
          <Text variant="sm" className="text-neutral-700">
            {consentFooterText}
          </Text>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 w-full">
          <Button
            disabled={isLoading}
            onClick={onDeclineConsent}
            use="secondary"
            className="w-full"
          >
            {ConsentModalText.DONT_ENABLE_BUTTON}
          </Button>
          <Button
            loading={isLoading}
            onClick={recordConsent}
            use="primary"
            className="w-full m-0"
          >
            {ConsentModalText.ENABLE_BUTTON}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

enum RecordConsentResponse {
  "CONSENTED",
  "ERROR",
  "CONSENTED_AND_RECORDING",
}

function useRecordConsentCallback() {
  const { userClicked } = useTrackEvent();
  const getSessionInfo = useAtomCallback(
    useCallback((get) => get(sessionInfoAtom), []),
  );
  const getProviderConsent = useAtomCallback(
    useCallback((get) => get(providerConsentAtom).value, []),
  );
  const toggleRecording = useToggleRecordingCallback();
  const recordConsent = useGracefullyRecordClientTranscriptionConsentCallback();

  return useCallback(
    async (isOptedIn: boolean, userAction?: ClientTranscriptionUserAction) => {
      const sessionInfo = getSessionInfo();
      const appointmentShortId = sessionInfo?.appointment?.shortId;
      userClicked(EventTypeEnum.GAVE_TRANSCRIPTION_CONSENT, {
        appointmentShortId: appointmentShortId!,
        entityType: UserType.CLIENT,
      });

      const recordingResult = await recordConsent(isOptedIn, userAction);

      if (recordingResult.status === "failure") {
        toast.error(
          "An unexpected error occurred while trying to record your consent. Please try again.",
        );
        return RecordConsentResponse.ERROR;
      }

      if (recordingResult.status === "success" && isOptedIn) {
        // Start recording if provider has consented
        const providerConsent = getProviderConsent();
        if (providerConsent === ConsentStatus.OPTED_IN) {
          toggleRecording(true);
          return RecordConsentResponse.CONSENTED_AND_RECORDING;
        }
      }

      return RecordConsentResponse.CONSENTED;
    },
    [
      getProviderConsent,
      getSessionInfo,
      recordConsent,
      toggleRecording,
      userClicked,
    ],
  );
}

export function ConsentModalWrapper({
  onStartRecording,
}: {
  onStartRecording: () => void;
}) {
  const [patientConsentState, setPatientConsent] = useAtom(patientConsentAtom);
  const participantCount = useAtomValue(participantCountAtom);
  const { providerShortId, patientShortId } = useParams();
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);
  const providerName =
    data?.telehealthSessionInfo?.provider?.name ??
    ConsentModalText.PROVIDER_NAME_FALLBACK;
  const isOpen = patientConsentState.value === ConsentStatus.DECIDING;
  const sendParticipantState = useSendVisitorParticipantStateCallback();
  const showTranscriptionStorageDisclaimer =
    useShowTranscriptionStorageDisclaimer();

  const showConsentToast = () => {
    // TODO: TOAST HOT
    oldToast.custom(
      (t) => (
        <ConsentDeclinedToast
          onClose={() => oldToast.remove(t.id)}
          visitorDeclinedConsent
        />
      ),
      {
        position: "bottom-center",
        duration: CONSENT_TOAST_DURATION_MS,
      },
    );
  };

  // Dismiss modal and set consent to SOFT_NO and show toast
  const recordConsent = useRecordConsentCallback();
  const declineConsent = useCallback(() => {
    setPatientConsent({
      value: ConsentStatus.SOFT_NO,
      lastUpdated: Date.now(),
    });
    recordConsent(false, ClientTranscriptionUserAction.REJECT);
    sendParticipantState();
    showConsentToast();
  }, [sendParticipantState, setPatientConsent, recordConsent]);

  // Dismiss modal and set consent to SOFT_NO WITHOUT showing toast
  const dismissConsent = useCallback(() => {
    setPatientConsent({
      value: ConsentStatus.SOFT_NO,
      lastUpdated: Date.now(),
    });
    recordConsent(false, ClientTranscriptionUserAction.DISMISS);
    sendParticipantState();
  }, [sendParticipantState, setPatientConsent, recordConsent]);

  return (
    <ConsentModal
      onDismissConsent={dismissConsent}
      onDeclineConsent={declineConsent}
      isOpen={isOpen}
      providerName={providerName}
      isMultiPatientSession={participantCount > 1}
      showTranscriptionStorageDisclaimer={showTranscriptionStorageDisclaimer}
      onConsent={async () => {
        const startedRecording =
          (await recordConsent(true)) ===
          RecordConsentResponse.CONSENTED_AND_RECORDING;
        if (startedRecording) {
          onStartRecording();
        }
      }}
    />
  );
}
