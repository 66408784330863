import { KeyWrapper as Key } from "./Key";
import { KEY_BLOBS } from "./constants";

export function Keyboard() {
  return (
    <div className="flex max-w-full max-h-full items-center justify-center gap-0 flex-wrap">
      {KEY_BLOBS.map((keyBlob) => {
        return <Key key={keyBlob.letter} {...keyBlob} />;
      })}
    </div>
  );
}
