import { ReactNode } from "react";
import { Tag, IconButton, Text, DRAWER_TITLE_ID } from "@growtherapy/sprout-ui";
import { ParticipantStatus } from "../../types";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { DrawerHeader } from "../../components/DrawerHeader";

type ClientInformationDrawerHeaderProps = {
  visitorName?: string;
  visitorStatus?: ParticipantStatus;
  drawerTitle?: string;
  drawerTitleCaption?: ReactNode;
  onClickBackButton?: () => void;
};

export function ClientInformationDrawerHeader({
  drawerTitle,
  drawerTitleCaption,
  visitorStatus,
  onClickBackButton,
}: ClientInformationDrawerHeaderProps) {
  const isScheduledClient = visitorStatus === ParticipantStatus.SCHEDULED;
  const showVisitorStatus = visitorStatus && !isScheduledClient;
  const ariaLabel =
    visitorStatus === ParticipantStatus.IN_SESSION
      ? "The client whose information you are viewing is in session"
      : "The client whose information you are viewing is not in session";
  return (
    <DrawerHeader>
      <div
        className="flex items-center"
        data-testid="client-information.header"
      >
        {onClickBackButton && (
          <IconButton
            onClick={onClickBackButton}
            className="mr-4 w-8 h-8 flex justify-center items-center"
            iconSize="xs"
            aria-label="Go back"
            data-testid="client-information.button.back"
            iconDefinition={faArrowLeft}
          />
        )}
        <div className="flex flex-col">
          <div className="flex flex-grow items-center">
            <span
              className="fs-exclude"
              data-dd-privacy="mask"
              data-dd-action-name="Client Name"
              data-testid="client-information.header.name"
            >
              <Text
                as="span"
                variant={isScheduledClient ? "lg" : "md"}
                className={classNames("flex flex-col", {
                  "font-medium": isScheduledClient,
                })}
                id={DRAWER_TITLE_ID}
              >
                {drawerTitle}
              </Text>
            </span>

            {showVisitorStatus && (
              <Tag
                className="ml-2"
                aria-label={ariaLabel}
                use={
                  visitorStatus === ParticipantStatus.IN_SESSION
                    ? "green"
                    : "neutral"
                }
              >
                {visitorStatus}
              </Tag>
            )}
          </div>
          {drawerTitleCaption}
        </div>
      </div>
    </DrawerHeader>
  );
}
