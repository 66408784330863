/**
 * This file is used to log to Datadog. It defaults to using the console to log
 * if the environment is not deployed.
 */
import { datadogLogs } from "@datadog/browser-logs";
import { getDataDogConfig } from "./config";
import { environmentIsProduction } from "../utils";

const isProduction = environmentIsProduction();

export function getLogger() {
  return getDataDogConfig() ? datadogLogs.logger : console;
}

export function setLoggerGlobalContext(ctx: object) {
  if (!getDataDogConfig()) {
    // eslint-disable-next-line no-console
    console.log("Setting global DD logger context", ctx);
    return;
  }
  datadogLogs.addLoggerGlobalContext("global", ctx);
}

function logDebug(...params: Parameters<typeof datadogLogs.logger.debug>) {
  // ideally, the logger's level will handle whether this message is written,
  // but as a backup, we can check the environment
  if (isProduction) return;

  getLogger().debug(...params);
}

function logError(...params: Parameters<typeof datadogLogs.logger.error>) {
  if (params.length === 2) {
    // Two arg form doesn't seem to work
    getLogger().error(params[0], params[1], new Error(params[0]));
    return;
  }
  getLogger().error(...params);
}

function logInfo(...params: Parameters<typeof datadogLogs.logger.info>) {
  getLogger().info(...params);
}

function logWarning(...params: Parameters<typeof datadogLogs.logger.warn>) {
  getLogger().warn(...params);
}

export const logger = {
  debug: logDebug,
  error: logError,
  info: logInfo,
  warn: logWarning,
};

export type Logger = typeof logger;
