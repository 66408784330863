import { Text, Button, Icon, Card, CardSection } from "@growtherapy/sprout-ui";
import { faPause } from "@fortawesome/pro-solid-svg-icons";
import { useAtomValue } from "jotai";
import { otherPatientConsentedInSessionAtom } from "./state";

type RecordingNoticeProps = {
  onClose: () => void;
  otherPatientConsentedInSession: boolean;
};

export const RecordingNoticeText = {
  CONSENTING_PARTICIPANT_TEXT_PREFIX:
    "The session is being transcribed. Use the ",
  OTHER_PARTICIPANT_TEXT_PREFIX:
    "Another participant has consented to session summarization. Use the ",
  POST_PAUSE_ICON_TEXT: " button to exclude a topic from the summary.",
  BUTTON_TEXT: "Got it",
};

export function RecordingNotice({
  onClose,
  otherPatientConsentedInSession,
}: RecordingNoticeProps) {
  return (
    <Card className="w-[88vw] xs:max-w-[25rem]">
      <CardSection className="pb-4">
        <Text variant="sm" className="text-neutral-800">
          {otherPatientConsentedInSession
            ? RecordingNoticeText.OTHER_PARTICIPANT_TEXT_PREFIX
            : RecordingNoticeText.CONSENTING_PARTICIPANT_TEXT_PREFIX}
          <Icon aria-hidden icon={faPause} />
          <span className="sr-only">pause</span>
          {RecordingNoticeText.POST_PAUSE_ICON_TEXT}
        </Text>
      </CardSection>
      <CardSection className="pb-4">
        <Button onClick={onClose} use="primary" className="w-full">
          {RecordingNoticeText.BUTTON_TEXT}
        </Button>
      </CardSection>
    </Card>
  );
}

export function RecordingNoticeWrapper({
  ...rest
}: Omit<RecordingNoticeProps, "otherPatientConsentedInSession">) {
  const otherPatientConsentedInSession = useAtomValue(
    otherPatientConsentedInSessionAtom,
  );
  return (
    <RecordingNotice
      {...rest}
      otherPatientConsentedInSession={otherPatientConsentedInSession}
    />
  );
}
