import { toast } from "../../toasts";
import { useCallback } from "react";
import { Mutex } from "async-mutex";
import { RemoteAudioTrack } from "twilio-video";
import { RestrictedAudioPlaybackToast } from "./RestrictedAudioPlaybackToast";

const restrictedAudioPlaybackMutex = new Mutex();
const TOAST_KEY = "restricted-audio-playback";

export function useShowRestrictedAudioPlaybackToastCallback() {
  const showRestrictedAudioPlaybackToast = useCallback(async () => {
    if (restrictedAudioPlaybackMutex.isLocked()) return;
    const release = await restrictedAudioPlaybackMutex.acquire();
    toast.enqueue(
      (ariaProps) => (
        <RestrictedAudioPlaybackToast {...ariaProps} onEnableAudio={release} />
      ),
      {
        key: TOAST_KEY,
        onClose: release,
        position: "top",
        timeout: Infinity,
      },
    );
  }, []);

  const detectRestrictedAudioPlaybackTimeout = useCallback(
    (
      audioTrack: RemoteAudioTrack,
      audioElement: HTMLAudioElement,
      timeoutMs = 100,
    ) => {
      setTimeout(() => {
        const isUnexpectedlyPaused =
          audioTrack.isEnabled &&
          audioElement &&
          audioElement.srcObject &&
          !audioElement.ended &&
          audioElement.paused;
        if (isUnexpectedlyPaused) {
          showRestrictedAudioPlaybackToast();
        }
      }, timeoutMs);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    showRestrictedAudioPlaybackToast,
    detectRestrictedAudioPlaybackTimeout,
  };
}
