import { WhiteboardControlMenuItemWrapper as WhiteboardControlMenuItem } from "../../twilio/controls/WhiteboardControl";
import { ScreenShareMenuItemsWrapper as ScreenShareMenuItems } from "../../twilio/controls/ScreenShareControl";

export function SessionModeControlMenuItemsWrapper() {
  return (
    <>
      <ScreenShareMenuItems />
      <WhiteboardControlMenuItem />
    </>
  );
}
