import { useMutation, gql } from "@apollo/client";
import { Server } from "../apollo/types";
import { useVisitorId } from "./useVisitorId";
export type RecordClientMeasureData = {
  recordClientMeasure: {
    recordedAt: string;
  };
};

export type RecordClientMeasureVariables = {
  visitorId: string;
};
export const RECORD_CLIENT_MEASURE = gql`
  mutation RecordClientMeasureVisitor($visitorId: String!) {
    recordClientMeasure(visitorId: $visitorId) {
      recordedAt
    }
  }
`;

export function useRecordClientMeasure() {
  const visitorId = useVisitorId();
  return useMutation<RecordClientMeasureData, RecordClientMeasureVariables>(
    RECORD_CLIENT_MEASURE,
    {
      variables: { visitorId: visitorId },
      context: { server: Server.TELEHEALTH },
    },
  );
}
