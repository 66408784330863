import { Card, Text } from "@growtherapy/sprout-ui";

import { NoteType } from "./types";

type ClinicalPresentationProps = {
  sectionTitle: string;
  notes: NoteType[];
};

export function ClinicalPresentation({
  sectionTitle,
  notes,
}: ClinicalPresentationProps) {
  return (
    <Card key={sectionTitle} title={sectionTitle}>
      <div
        className="fs-exclude"
        data-testid="client-information.notes.clinical-presentation"
        data-dd-privacy="mask"
      >
        {notes.map((note) => (
          <div
            className={`${
              note.answer === "Yes" || note.answer === "No"
                ? "rounded-none border-b border-neutral-400 pt-2 pb-4"
                : ""
            }`}
            key={note.name}
          >
            <div
              className={`${
                note.answer === "Yes" || note.answer === "No"
                  ? "flex justify-between"
                  : ""
              }`}
            >
              {note.question &&
                (note.answer || note.name === "symptoms_header") && (
                  <Text
                    className={`${
                      note.textVariant === "HEADER"
                        ? "font-semibold text-lg mt-8"
                        : "font-semibold text-small mt-2"
                    }`}
                  >
                    {note.question}
                  </Text>
                )}

              <Text className="mt-2 text-small">{note.answer}</Text>
            </div>
            <ul>
              {note.details.length > 0 &&
                note.details.map((detail) => {
                  return (
                    <li key={detail}>
                      <Text className="text-small mt-2">{detail}</Text>
                    </li>
                  );
                })}
            </ul>
          </div>
        ))}
      </div>
    </Card>
  );
}
