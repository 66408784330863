import { RemoteParticipant } from "twilio-video";
import { useSetAtom } from "jotai";
import { TrackSubscriptionHandler, DataTrackMessage } from "./types";
import {
  remoteTracksByParticipantIdAtom,
  addTrackToParticipant,
  removeTrackFromParticipant,
  trackToDiagnosticInfo,
  remoteParticipantToDiagnosticInfo,
  gracefullyHandleTrackEvents,
  getMaxDataTrackMessageSizeBytes,
} from ".";
import { debounce, gracefullyParseJson } from "../utils";
import { useCallback, useMemo } from "react";
export type UseRemoteDataProps = {
  remoteParticipant: RemoteParticipant;
  onTrackSubscribed?: TrackSubscriptionHandler;
  onTrackUnsubscribed?: TrackSubscriptionHandler;
  onMessageReceived?: (message: DataTrackMessage) => void;
};
import { TrackingEvents, sendLoggingEvents } from "../events";
import { Mutex } from "async-mutex";
import { useRemoteTrackEventListeners } from "./useRemoteTrackEventListeners";
import { logger } from "../datadog/logger";
import { useAtomCallback } from "jotai/utils";
import { dataTrackAppStateAtom } from "./messages/state";
import { DATA_TRACK_MESSAGE_LOGGING_INTERVAL_MS } from "../config";
import { gracefullyDecompressJson } from "../compression/lazyStringCompression";

const dataTrackEventMutex = new Mutex();

const sendDebouncedLoggingEvents = debounce(
  sendLoggingEvents,
  DATA_TRACK_MESSAGE_LOGGING_INTERVAL_MS,
);

async function gracefullyParseMessage(
  message: string,
): Promise<[DataTrackMessage, null] | [null, Error] | [null, null]> {
  const maxMessageSizeBytes = getMaxDataTrackMessageSizeBytes();
  return maxMessageSizeBytes
    ? await gracefullyDecompressJson<DataTrackMessage>(message)
    : [gracefullyParseJson<DataTrackMessage>(message), null];
}

function useGetRemoteDataTrackEventListeners({
  remoteParticipant,
  onTrackSubscribed,
  onTrackUnsubscribed,
  onMessageReceived,
}: UseRemoteDataProps) {
  const setRemoteTracks = useSetAtom(remoteTracksByParticipantIdAtom);
  const getDataTrackState = useAtomCallback(
    useCallback((get) => get(dataTrackAppStateAtom), []),
  );

  return useMemo(
    () => {
      if (!remoteParticipant) return;
      const handleReceivedMessage = async (message: string) => {
        const [parsedMessage, error] = await gracefullyParseMessage(message);
        if (!parsedMessage) {
          logger.error(
            "Unable to parse received message",
            {
              message,
            },
            ...(error ? [error] : []),
          );
          return null;
        }

        const participantState = getDataTrackState();

        sendDebouncedLoggingEvents(
          TrackingEvents.REMOTE_DATA_TRACK_MESSAGE_RECEIVED,
          {
            isRecording: participantState.isRecording,
            patientConsent: participantState.patient.consent,
            providerConsent: participantState.provider.consent,
            isWhiteboardMode: participantState.isWhiteboardMode,
          },
          {
            logLevel: "info",
            message: "Received a message on the data track (DEBOUNCED)",
          },
        );
        onMessageReceived?.(parsedMessage);
      };
      return gracefullyHandleTrackEvents(dataTrackEventMutex, {
        trackSubscribed: (track, ...args) => {
          if (track.kind !== "data") return;
          onTrackSubscribed?.(track, ...args);
          setRemoteTracks((prev) =>
            addTrackToParticipant(prev, remoteParticipant.identity, track),
          );

          track.on("message", handleReceivedMessage);

          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_SUBSCRIBED,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant data track subscribed",
            },
          );
        },

        trackUnsubscribed: (track, ...args) => {
          if (track.kind !== "data") return;

          // On unsubscribe, the message event listener will not trigger anymore
          // regardless of the presence of the below line; this is just an extra
          // precaution to prevent memory leaks
          track.off("message", handleReceivedMessage);
          onTrackUnsubscribed?.(track, ...args);

          setRemoteTracks((prev) =>
            removeTrackFromParticipant(prev, remoteParticipant.identity, track),
          );

          sendLoggingEvents(
            TrackingEvents.REMOTE_TRACK_UNSUBSCRIBED,
            {
              ...trackToDiagnosticInfo(track),
              ...remoteParticipantToDiagnosticInfo(remoteParticipant),
            },
            {
              logLevel: "info",
              message: "Remote participant data track unsubscribed",
            },
          );
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onMessageReceived,
      onTrackSubscribed,
      onTrackUnsubscribed,
      remoteParticipant,
      getDataTrackState,
    ],
  );
}

export function useRemoteData({
  remoteParticipant,
  ...otherProps
}: UseRemoteDataProps) {
  const remoteTrackEventListeners = useGetRemoteDataTrackEventListeners({
    remoteParticipant,
    ...otherProps,
  });

  useRemoteTrackEventListeners({
    trackKind: "data",
    remoteParticipant,
    remoteTrackEventListeners,
  });
}
