import {
  ClientMeasureOptOutBannerConnected,
  ClientMeasureOptOutReminderBannerConnected,
} from "@grow-therapy-team/seedling-components";
import { Heading, Tag } from "@growtherapy/sprout-ui";
import { CombinedFormDataEntriesByFormTitle } from "./types";
import {
  IntakeFormListItemWrapper as IntakeFormListItem,
  MeasuresFormListItemWrapper as MeasuresFormListItem,
  SafetyPlanFormListItemWrapper as SafetyPlanFormListItem,
} from "../measures/FormListItems";
import { useAtomValue } from "jotai";
import { selectedClientUserShortIdAtom } from "../state";
import {
  CLIENT_OPT_OUT_REMINDER_BANNER_DISMISSED_VALUE,
  CLIENT_OPT_OUT_REMINDER_BANNER_DISMISSED,
  MEASURES_AND_FORMS_TITLE,
} from "./constants";
import { useLocalStorage } from "react-use";

type ClientInformationFormsAndQuestionnairesProps = {
  completedForms?: CombinedFormDataEntriesByFormTitle;
  hasNewForms?: boolean;
};
export function ClientInformationFormsAndQuestionnaires({
  hasNewForms,
}: ClientInformationFormsAndQuestionnairesProps) {
  const clientUserShortId = useAtomValue(selectedClientUserShortIdAtom);

  const [
    clientOptOutReminderBannerDismissed,
    setClientOptOutReminderBannerDismissed,
  ] = useLocalStorage(CLIENT_OPT_OUT_REMINDER_BANNER_DISMISSED, "false");

  return (
    <div className="flex flex-col" data-testid="client-info.measures-and-forms">
      <Heading variant="lg" className="mb-8" as="h3">
        {MEASURES_AND_FORMS_TITLE}
        {hasNewForms && (
          <Tag aria-label="Client has new forms" use="lilac" className="ml-2">
            New
          </Tag>
        )}
      </Heading>
      <>
        {clientUserShortId && (
          <ClientMeasureOptOutReminderBannerConnected
            renderContext="provider"
            clientUserShortId={clientUserShortId}
            handleClose={() =>
              setClientOptOutReminderBannerDismissed(
                CLIENT_OPT_OUT_REMINDER_BANNER_DISMISSED_VALUE,
              )
            }
            isClosed={
              clientOptOutReminderBannerDismissed ===
              CLIENT_OPT_OUT_REMINDER_BANNER_DISMISSED_VALUE
            }
            baseBanner={
              <ClientMeasureOptOutBannerConnected
                clientUserShortId={clientUserShortId}
                renderContext="telehealth"
              />
            }
          />
        )}
        <MeasuresFormListItem />
        <IntakeFormListItem />
        <SafetyPlanFormListItem />
      </>
    </div>
  );
}
