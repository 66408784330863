import { Button, Icon } from "@growtherapy/sprout-ui";
import { twMerge } from "tailwind-merge";
import {
  ANIMATION_ACTION_ICONS,
  getNextAction,
  useAnimationState,
} from "./useAnimationState";
import { ActionStateToggleButtonProps } from "./types";

export function AnimationStateToggleButton({
  className,
  currentActionState,
  onClick,
}: ActionStateToggleButtonProps): JSX.Element {
  const nextActionName = getNextAction(currentActionState);
  const nextActionIcon = ANIMATION_ACTION_ICONS[nextActionName];
  return (
    <Button
      use="link"
      size="sm"
      className={twMerge(
        "flex gap-x-2 items-center text-yellow-700 no-underline hover:brightness-75 hover:text-yellow-700",
        className,
      )}
      onClick={() => onClick()}
      aria-label={`${nextActionName} background animation`}
    >
      <Icon icon={nextActionIcon} />
      {nextActionName} animation
    </Button>
  );
}

export type AnimationStateToggleButtonWrapperProps = Pick<
  ActionStateToggleButtonProps,
  "className"
>;

export function AnimationStateToggleButtonWrapper(
  props: AnimationStateToggleButtonWrapperProps,
) {
  const { currentState, toggle } = useAnimationState();

  return (
    <AnimationStateToggleButton
      {...props}
      currentActionState={currentState}
      onClick={toggle}
    />
  );
}
