import { SelectOptionAtom } from "@growtherapy/sprout-ui";
import { useSetAtom } from "jotai";
import { availableDevicesAtom, getAvailableDevices } from "..";
import { useCallback, useEffect } from "react";
import { secondsToMilliseconds } from "date-fns";
import { logger } from "../../datadog/logger";
import { Mutex } from "async-mutex";

// TODO: Split into separate hook files

export const getDeviceOptionsFromDeviceList = (
  devices: MediaDeviceInfo[] = [],
): SelectOptionAtom[] => {
  return devices.map((device) => ({
    value: device.deviceId,
    label: device.label,
  }));
};

export const updateAvailableDevicesMutex = new Mutex();

export function useUpdateAvailableDevicesCallback() {
  const setDevices = useSetAtom(availableDevicesAtom);

  return useCallback(
    async () => {
      const release = await updateAvailableDevicesMutex.acquire();
      try {
        const devices = await getAvailableDevices();
        setDevices(devices);
        return devices;
      } catch (error) {
        logger.error("Unable to get available devices", {}, error as Error);
      } finally {
        release();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

export function useInitAvailableDevices() {
  const updateAvailableDevices = useUpdateAvailableDevicesCallback();

  return useEffect(
    function handleSetInitAvailableDevices() {
      updateAvailableDevices();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

export function useUpdateAvailableDevicesInterval() {
  const updateAvailableDevices = useUpdateAvailableDevicesCallback();

  return useEffect(
    function handleInterval() {
      updateAvailableDevices();
      const interval = setInterval(() => {
        // Prevent multiple calls to get available devices from queuing up
        if (updateAvailableDevicesMutex.isLocked()) {
          return;
        }
        updateAvailableDevices();
      }, secondsToMilliseconds(3));
      return () => clearInterval(interval);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

enum MediaPermissions {
  Camera = "camera",
  Microphone = "microphone",
}
// TypeScript's PermissionName omits a few permissions, so we cast to
// allow for things like camera and microphone
const permissionNames = Object.values(
  MediaPermissions,
) as unknown as PermissionName[];

/**
 * if you plan to use UI components that rely on the available devices, add this
 * hook to your component to ensure that the available devices are updated as
 * permissions change.
 */
export function useListenForAvailableDeviceChanges() {
  const updateAvailableDevices = useUpdateAvailableDevicesCallback();

  return useEffect(() => {
    updateAvailableDevices();

    // mostly for testing, but if there is a browser out there without support
    // for navigator.permissions, we're ready
    if (!navigator.permissions) return;

    const abortController = new AbortController();
    permissionNames.forEach(async (name) => {
      const status = await navigator.permissions.query({ name });

      // listen for changes to the permission status
      const handler = () => {
        // Prevent multiple calls to get available devices from queuing up
        if (updateAvailableDevicesMutex.isLocked()) return;

        updateAvailableDevices();
      };
      status.addEventListener("change", handler, {
        signal: abortController.signal,
      });
    });

    return () => abortController.abort();
  }, [updateAvailableDevices]);
}
