import { ControlPanelButton } from "../../components";
import { ComponentProps, useState } from "react";
import { faPlay, faPause, faPenLine } from "@fortawesome/pro-solid-svg-icons";
import { REQUEST_TRANSCRIPTION_CONSENT } from "./constants";
import { toast } from "../../toasts";
import { Tooltip, TooltipProps } from "@growtherapy/sprout-ui";
type ControlPanelButtonProps = Omit<
  ComponentProps<typeof ControlPanelButton>,
  "aria-label" | "icon" | "iconName" | "iconVariant" | "iconDefinition"
>;
type RecordingControlProps = ControlPanelButtonProps & {
  isRecording?: boolean;
  isRequestingConsent?: boolean;
  consentPending: boolean;
  onRecordingToggle: (shouldRecord: boolean) => void | Promise<void>;
  onPendingConsentClick?: () => void | Promise<void>;
  tooltipProps?: Partial<TooltipProps>;
};

export function RecordingControl({
  isRecording,
  isRequestingConsent,
  consentPending,
  onRecordingToggle,
  onPendingConsentClick,
  tooltipProps,
  ...props
}: RecordingControlProps) {
  const [isLoading, setIsLoading] = useState(false);

  let buttonLabel = `Toggle recording ${isRecording ? "off" : "on"}`;
  let buttonIcon = isRecording ? faPause : faPlay;

  if (consentPending) {
    buttonLabel = REQUEST_TRANSCRIPTION_CONSENT;
    buttonIcon = faPenLine;
  }

  if (isRequestingConsent) {
    buttonLabel = "Requesting client consent...";
  }

  return (
    <Tooltip
      {...tooltipProps}
      text={tooltipProps?.text ?? buttonLabel}
      childIsInteractive
    >
      <ControlPanelButton
        {...props}
        loading={isLoading || isRequestingConsent}
        aria-label={buttonLabel}
        iconDefinition={buttonIcon}
        data-testid="recording-control"
        onClick={async () => {
          if (!consentPending) {
            try {
              setIsLoading(true);
              await onRecordingToggle(!isRecording);
            } catch (error) {
              toast.error(
                "Something went wrong while toggling recording. Please try again.",
                { position: "bottom-center" },
              );
            } finally {
              setIsLoading(false);
            }
          } else if (onPendingConsentClick) {
            await onPendingConsentClick();
          }
        }}
      />
    </Tooltip>
  );
}
