import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { ButtonUseType, IconButton } from "@growtherapy/sprout-ui";
import classNames from "classnames";
import { HTMLAttributes, useEffect } from "react";
import { twMerge } from "tailwind-merge";

export enum ToastVariant {
  Success = "success",
  Neutral = "neutral",
  Error = "error",
}

export type ToastProps = {
  children: React.ReactNode;
  variant?: ToastVariant;
  onClose?: () => void;
  onCleanup?: () => void;
  closeButtonClassName?: string;
  closeButtonUse?: ButtonUseType;
  isBanner?: boolean;
} & HTMLAttributes<HTMLDivElement>;

/**
 * @deprecated Use `Toast` from `src/toasts` instead.
 */
export function OldToast({
  className,
  children,
  variant = ToastVariant.Neutral,
  onClose,
  onCleanup,
  closeButtonClassName,
  isBanner,
  closeButtonUse = "secondary",
  ...rest
}: ToastProps) {
  useEffect(
    function cleanup() {
      return () => {
        onCleanup?.();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div
      {...rest}
      className={twMerge(
        "bg-neutral-100",
        classNames(
          {
            "bg-neutral-200": variant === ToastVariant.Neutral,
            "bg-green-100": variant === ToastVariant.Success,
            "bg-coral-300": variant === ToastVariant.Error,
          },
          "p-4 rounded-lg flex justify-between items-center border-[1px] border-neutral-800 border-solid",
        ),
        className,
      )}
    >
      {children}
      {!!onClose && (
        <IconButton
          iconDefinition={faXmark}
          onClick={onClose}
          className={twMerge(
            "!ml-3 text-neutral-800 no-underline hover:text-neutral-800",
            classNames({
              "h-9 w-9": isBanner,
              "h-6 w-6 !min-w-[1.5rem] flex justify-center items-center p-0 ":
                !isBanner,
            }),
            closeButtonClassName,
          )}
          use={closeButtonUse}
          aria-label="Dismiss"
          size="sm"
        />
      )}
    </div>
  );
}
