import { TrackingEvents, sendLoggingEvents } from "../../events";
import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { dataTrackAppStateAtom } from "./state";
import {
  DataTrackAppStateMessage,
  DataTrackSender,
  DataTrackMessageType,
} from "../types";
import { DATA_TRACK_MESSAGE_LOGGING_INTERVAL_MS } from "../../config";
import { debounce } from "../../utils";
import { useSendDataTrackMessageCallback } from "./useSendDataTrackMessageCallback";

const sendDebouncedLoggingEvents = debounce(
  sendLoggingEvents,
  DATA_TRACK_MESSAGE_LOGGING_INTERVAL_MS,
);

export const useSendParticipantStateCallback = () => {
  const getDataTrackState = useAtomCallback(
    useCallback((get) => get(dataTrackAppStateAtom), []),
  );
  const sendDataTrackMessage = useSendDataTrackMessageCallback();

  return useCallback(
    async (senderIdentity: DataTrackSender) => {
      const participantState = getDataTrackState();

      const formattedMessage: DataTrackAppStateMessage = {
        participantState,
        sender: senderIdentity,
        type: DataTrackMessageType.APP_STATE,
      };
      const didSendMessage = await sendDataTrackMessage(formattedMessage);
      if (didSendMessage) {
        sendDebouncedLoggingEvents(
          TrackingEvents.REMOTE_DATA_TRACK_MESSAGE_SENT,
          {
            isRecording: participantState.isRecording,
            patientConsent: participantState.patient.consent,
            providerConsent: participantState.provider.consent,
            isWhiteboardMode: participantState.isWhiteboardMode,
          },
          {
            logLevel: "info",
            message:
              "Local participant sent a message on the data track (DEBOUNCED)",
          },
        );
      }
    },
    [getDataTrackState, sendDataTrackMessage],
  );
};
