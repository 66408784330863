import { useDynamicConfig } from "@statsig/react-bindings";
import { aiManualQaProviders } from "../../statsig/gates";
import { useParams } from "react-router-dom";

export function useShowTranscriptionStorageDisclaimer(): boolean {
  const aiManualQaConfig = useDynamicConfig(aiManualQaProviders);
  const providersOptedInToManualQa: string[] = aiManualQaConfig.get(
    "provider_short_ids",
    [],
  ) as string[];
  const { providerShortId } = useParams();
  return (
    !!providerShortId && providersOptedInToManualQa.includes(providerShortId)
  );
}
