import { PropsWithChildren, useCallback, useMemo, useRef } from "react";
import { ProviderDrawerState } from "../provider/state";
import { VisitorDrawerState } from "../visitor/state";
import {
  ButtonRefsContext,
  DrawerToButtonRefMap,
} from "../state/drawerButtonRefs";

export type ButtonRefsProviderProps = PropsWithChildren;

export function ButtonRefsProvider({ children }: PropsWithChildren) {
  // We'll keep our Map of drawer states to button refs in a React.ref itself.
  // This is so that the Map changing doesn't trigger re-renders, since we only
  // care about getting a button ref out at the time of a drawer closing.
  const buttonRefsMapRef = useRef<DrawerToButtonRefMap>(new Map());

  const addButtonRef = useCallback(
    (
      key: ProviderDrawerState | VisitorDrawerState,
      ref: React.RefObject<HTMLButtonElement>,
    ) => {
      // Overwrite the ref if it already exists. The newest button rendered on
      // the page will take precedence, in the case of multiple buttons that
      // toggle the same drawer.
      buttonRefsMapRef.current.set(key, ref);
    },
    [],
  );

  const removeButtonRef = useCallback(
    (key: ProviderDrawerState | VisitorDrawerState) => {
      buttonRefsMapRef.current.delete(key);
    },
    [],
  );

  const contextValue = useMemo(
    () => ({
      buttonRefs: buttonRefsMapRef,
      addButtonRef,
      removeButtonRef,
    }),
    [addButtonRef, removeButtonRef],
  );

  return (
    <ButtonRefsContext.Provider value={contextValue}>
      {children}
    </ButtonRefsContext.Provider>
  );
}
