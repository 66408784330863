import {
  Button,
  CalloutBanner,
  Card,
  Icon,
  Text,
} from "@growtherapy/sprout-ui";
import PreSessionImage from "../../assets/images/pre-session-image.svg?react";
import { faChevronRight, faPenLine } from "@fortawesome/pro-solid-svg-icons";
import { useAtom, useSetAtom } from "jotai";
import {
  VisitorDrawerState,
  preSessionVibesOpenAtom,
  visitorDrawerStateAtom,
} from "../state";
import { useEffect } from "react";
import { VisitorDrawerButton } from "../VisitorDrawerButton";
import { WAITING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID } from "../constants";
import { useBackgroundMusicPlayback } from "./background-action/useBackgroundMusicPlayback";

interface PreSessionPromptProps {
  callToActionContent: React.ReactNode;
}

export function PreSessionPrompt({
  callToActionContent,
}: PreSessionPromptProps) {
  return (
    <Card className="flex flex-col mobile:border-x rounded-xl items-center max-w-[38rem] gap-y-10 sm:p-10">
      <div className="hidden sm:block">
        <PreSessionImage aria-label="A light purple sketch of a pair of hands clasped together." />
      </div>

      <div className="flex flex-col gap-y-4 items-center">
        <Text as="h2" variant="lg" className="font-medium">
          You&apos;re in the right place.
        </Text>

        <Text className="text-neutral-700 text-center">
          You&apos;ll be notified when your provider is ready to start your
          session. They have a 10-min grace period.
        </Text>

        {callToActionContent}
      </div>
    </Card>
  );
}

type CTAProps = { openDrawer: (drawer: VisitorDrawerState) => void };

function ClientMeasuresCta({ openDrawer }: CTAProps) {
  return (
    <CalloutBanner
      use="lilac"
      className="border-none w-fit"
      heading="Take this time for a mental health check-in"
      headingAs="h2"
      headingVariant="md"
      icon={faPenLine}
      data-testid="pre-session-prompt-client-measures"
    >
      <div className="flex flex-col gap-y-4">
        <Text>
          These short surveys help your provider better understand how
          you&apos;ve been doing.
        </Text>
        <VisitorDrawerButton
          closedAriaLabel="Open check-in survey"
          openedAriaLabel="Close check-in survey"
          drawerId={WAITING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID}
          forDrawerStates={VisitorDrawerState.PENDING_FORMS}
          className="flex gap-4 justify-center items-center w-full"
          onClick={() => openDrawer(VisitorDrawerState.PENDING_FORMS)}
        >
          <span>Start check-in</span>
          <Icon aria-hidden icon={faChevronRight} />
        </VisitorDrawerButton>
      </div>
    </CalloutBanner>
  );
}

function MoodSurveyCta({ openDrawer }: CTAProps) {
  const [drawerState, setDrawerState] = useAtom(visitorDrawerStateAtom);

  useEffect(() => {
    return function closeDrawerOnUmount() {
      if (drawerState !== VisitorDrawerState.MOOD_CHECK_IN_FORM) return;

      setDrawerState(null);
    };
  }, [drawerState, setDrawerState]);

  return (
    <>
      <Text
        data-testid="pre-session-prompt-mood-survey"
        className="font-medium"
      >
        In the meantime, take a moment to...
      </Text>

      <VisitorDrawerButton
        closedAriaLabel="Open mood survey"
        openedAriaLabel="Close mood survey"
        drawerId={WAITING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID}
        forDrawerStates={VisitorDrawerState.MOOD_CHECK_IN_FORM}
        className="flex gap-4 justify-center items-center w-full"
        onClick={() => openDrawer(VisitorDrawerState.MOOD_CHECK_IN_FORM)}
      >
        <span>Check in with your mood</span>
        <Icon aria-hidden icon={faChevronRight} />
      </VisitorDrawerButton>
    </>
  );
}

function TipsCta({ openDrawer }: CTAProps) {
  return (
    <>
      <Text data-testid="pre-session-prompt-tips" className="font-medium">
        In the meantime, take a moment to...
      </Text>

      <VisitorDrawerButton
        closedAriaLabel="Open session tips"
        openedAriaLabel="Close session tips"
        drawerId={WAITING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID}
        forDrawerStates={VisitorDrawerState.HELPFUL_TIPS_BEFORE_SESSION}
        className="flex gap-4 justify-center items-center w-full"
        onClick={() =>
          openDrawer(VisitorDrawerState.HELPFUL_TIPS_BEFORE_SESSION)
        }
      >
        <span>See tips for your session</span>
        <Icon aria-hidden icon={faChevronRight} />
      </VisitorDrawerButton>
    </>
  );
}

type VibesCtaProps = CTAProps & {
  openPreSessionVibes: () => void;
};

function VibesCta({ openDrawer, openPreSessionVibes }: VibesCtaProps) {
  return (
    <>
      <Text data-testid="pre-session-prompt-vibes" className="font-medium">
        In the meantime, take a moment to...
      </Text>
      <Button
        className="flex gap-4 justify-center items-center w-full"
        onClick={openPreSessionVibes}
      >
        <span>Unwind while you wait</span>
      </Button>

      <div className="flex justify-center">
        <VisitorDrawerButton
          closedAriaLabel="Open session tips"
          openedAriaLabel="Close session tips"
          drawerId={WAITING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID}
          forDrawerStates={VisitorDrawerState.HELPFUL_TIPS_BEFORE_SESSION}
          onClick={() =>
            openDrawer(VisitorDrawerState.HELPFUL_TIPS_BEFORE_SESSION)
          }
          use="link"
          className="pt-2"
        >
          Or view session tips
        </VisitorDrawerButton>
      </div>
    </>
  );
}

function VibesCtaWrapper({ openDrawer }: CTAProps) {
  const setPreSessionVibesOpen = useSetAtom(preSessionVibesOpenAtom);
  const { handlePlay } = useBackgroundMusicPlayback();
  const openPreSessionVibes = () => {
    setPreSessionVibesOpen(true);
    handlePlay();
  };

  return (
    <VibesCta
      openDrawer={openDrawer}
      openPreSessionVibes={openPreSessionVibes}
    />
  );
}

export enum PreSessionPromptVariant {
  CLIENT_MEASURES = "CLIENT_MEASURES",
  MOOD_SURVEY = "MOOD_SURVEY",
  TIPS = "TIPS",
  VIBES = "VIBES",
}

export const VariantComponents: Record<
  PreSessionPromptVariant,
  React.FC<{
    openDrawer: (drawer: VisitorDrawerState) => void;
  }>
> = {
  [PreSessionPromptVariant.CLIENT_MEASURES]: ClientMeasuresCta,
  [PreSessionPromptVariant.MOOD_SURVEY]: MoodSurveyCta,
  [PreSessionPromptVariant.TIPS]: TipsCta,
  [PreSessionPromptVariant.VIBES]: VibesCtaWrapper,
};

export function PreSessionPromptWrapper({
  variant,
  openDrawer,
}: {
  variant: PreSessionPromptVariant;
  openDrawer: (drawer: VisitorDrawerState) => void;
}) {
  const CallToAction = VariantComponents[variant];
  return (
    <PreSessionPrompt
      callToActionContent={<CallToAction openDrawer={openDrawer} />}
    />
  );
}
