import { MEETING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID } from "../../constants";
import {
  VisitorSettingsDrawerButton,
  VisitorSettingsDrawerControlMenuItemWrapper as BaseVisitorSettingsDrawerControlMenuItem,
} from "../../controls";

export function VisitorMeetingSettingsControlButton() {
  return (
    <VisitorSettingsDrawerButton
      drawerId={MEETING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID}
    />
  );
}

export function VisitorMeetingSettingsDrawerControlMenuItem() {
  return (
    <BaseVisitorSettingsDrawerControlMenuItem
      drawerId={MEETING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID}
    />
  );
}
