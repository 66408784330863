import { useSetAtom } from "jotai";
import { visitorIsHelpModalOpenAtom } from "./state";
import { useCallback } from "react";
import { sendLoggingEvents, TrackingEvents } from "../events";
import { useAnimationState } from "./waiting-room/background-action";

export function useOpenHelpModalCallback() {
  const setIsHelpModalOpen = useSetAtom(visitorIsHelpModalOpenAtom);
  const { pause: pauseAnimation } = useAnimationState();
  return useCallback(() => {
    sendLoggingEvents(TrackingEvents.ANY_HELP_REQUEST);
    sendLoggingEvents(TrackingEvents.CLIENT_HELP_REQUEST);
    setIsHelpModalOpen(true);
    // Pause the background animation when the help modal is opened.
    pauseAnimation();
  }, [setIsHelpModalOpen, pauseAnimation]);
}
