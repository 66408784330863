import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@growtherapy/sprout-ui";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export type ProviderLoginBaseProps = Omit<
  ComponentProps<typeof Button>,
  "onClick"
>;
export type ProviderLoginProps = ProviderLoginBaseProps & {
  onLogin: () => void;
};

export function ProviderLogin({
  onLogin,
  className,
  use = "link",
  ...buttonProps
}: ProviderLoginProps) {
  return (
    <Button
      {...buttonProps}
      className={twMerge("py-0 px-0 ml-0", className)}
      use={use}
      onClick={onLogin}
    >
      Log in as a provider
    </Button>
  );
}

export function ProviderLoginWrapper(props: ProviderLoginBaseProps) {
  const { loginWithRedirect } = useAuth0();

  return <ProviderLogin onLogin={() => loginWithRedirect()} {...props} />;
}
