import { useCallback, useMemo } from "react";
import {
  ConversationStatus,
  useTwilioConversationCallbacks,
} from "../../twilio/chat/useTwilioConversationCallbacks";
import { useSetAtom } from "jotai";
import {
  ProviderDrawerState,
  drawerStateAtom,
  joiningVisitorIdsAtom,
  sessionConversationReadyAtom,
  sessionConversationSidAtom,
  visitorPresenceAtom,
  waitingRoomConversationSidsByVisitorIdAtom,
} from "../state";
import { omit } from "remeda";
import { useShowNewMessageToastCallback } from "./useShowNewMessageToastCallback";
import {
  currentConversationParticipantIdsAtom,
  currentConversationSidAtom,
} from "../../twilio/state";
import { useAtomCallback } from "jotai/utils";
import { useGetVisitorChatUser } from "./useGetVisitorChatParticipant";
import { parseIdentity } from "../../twilio";

function useHandleConversationOnClientDisconnectCallback() {
  const getCurrentConversationParticipantIds = useAtomCallback(
    useCallback((get) => get(currentConversationParticipantIdsAtom), []),
  );
  const getDrawerState = useAtomCallback(
    useCallback((get) => get(drawerStateAtom), []),
  );
  const getSessionConversationReady = useAtomCallback(
    useCallback((get) => get(sessionConversationReadyAtom), []),
  );
  const getSessionConversationSid = useAtomCallback(
    useCallback((get) => get(sessionConversationSidAtom), []),
  );
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setCurrentConversationSid = useSetAtom(currentConversationSidAtom);
  const setWaitingRoomConversationSids = useSetAtom(
    waitingRoomConversationSidsByVisitorIdAtom,
  );

  return useCallback(
    (visitorId: string) => {
      const isVisitorInCurrentConvo = Array.from(
        getCurrentConversationParticipantIds(),
      ).some((participantId) => parseIdentity(participantId).id === visitorId);

      const isWaitingRoomChat =
        getDrawerState() === ProviderDrawerState.WAITING_ROOM_CHAT;

      // handle if we are simply transitioning from waiting room -> session chat
      // otherwise handle the participant disconnecting
      if (isWaitingRoomChat && isVisitorInCurrentConvo) {
        if (getSessionConversationReady()) {
          setDrawerState(ProviderDrawerState.SESSION_CHAT);
          setCurrentConversationSid(getSessionConversationSid());
        } else {
          setDrawerState(ProviderDrawerState.PARTICIPANT_DISCONNECTED_CHAT);
          setCurrentConversationSid(undefined);
        }
      }

      setWaitingRoomConversationSids((prev) => omit(prev, [visitorId]));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

function useHandleChatParticipantListenersCallback() {
  const setVisitors = useSetAtom(visitorPresenceAtom);
  const getJoiningVisitorIds = useAtomCallback(
    useCallback((get) => get(joiningVisitorIdsAtom), []),
  );
  const getVisitorChatUser = useGetVisitorChatUser();
  const handleConversationOnClientDisconnect =
    useHandleConversationOnClientDisconnectCallback();

  return useCallback(
    async (visitorId: string) => {
      const visitorUser = await getVisitorChatUser(visitorId);
      visitorUser?.on("updated", ({ user }) => {
        if (user.isOnline) return;
        handleConversationOnClientDisconnect(visitorId);
        if (!getJoiningVisitorIds().has(visitorId)) {
          setVisitors((prev) => omit(prev, [visitorId]));
        }
        visitorUser.removeAllListeners("updated");
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

export function useWaitingRoomConversationCallbacks() {
  const { joinConversation, leaveConversation } =
    useTwilioConversationCallbacks();
  const setWaitingRoomConversationSids = useSetAtom(
    waitingRoomConversationSidsByVisitorIdAtom,
  );
  const getCurrentConversationSid = useAtomCallback(
    useCallback((get) => get(currentConversationSidAtom), []),
  );
  const setCurrentConversationSid = useSetAtom(currentConversationSidAtom);
  const showNewMessageToast = useShowNewMessageToastCallback();
  const getDrawerState = useAtomCallback(
    useCallback((get) => get(drawerStateAtom), []),
  );
  const handleChatParticipantListeners =
    useHandleChatParticipantListenersCallback();

  return useMemo(
    () => ({
      joinWaitingRoomConversation: async (
        visitorId: string,
        waitingRoomConversationSid: string,
      ) => {
        const conversationResult = await joinConversation(
          waitingRoomConversationSid,
          {
            onMessageAdded: (message) => {
              showNewMessageToast(message, ProviderDrawerState.WAITING_ROOM_CHAT);
            },
          },
        );

        const successfullyJoinedConvo =
          conversationResult.status === "success" &&
          conversationResult.code === ConversationStatus.JOINED;
        if (successfullyJoinedConvo) {
          setWaitingRoomConversationSids((prev) => ({
            ...prev,
            [visitorId]: waitingRoomConversationSid,
          }));
          const drawerState = getDrawerState();
          const currentConversationSid = getCurrentConversationSid();
          // if the chat drawer is open in an empty state, set it to the waiting room conversation
          if (
            drawerState === ProviderDrawerState.WAITING_ROOM_CHAT &&
            !currentConversationSid
          ) {
            setCurrentConversationSid(waitingRoomConversationSid);
          }
          await handleChatParticipantListeners(visitorId);
        }
        return conversationResult;
      },
      leaveWaitingRoomConversation: async (
        visitorId: string,
        waitingRoomConversationSid: string,
      ) => {
        const conversationResult = await leaveConversation(
          waitingRoomConversationSid,
        );
        if (conversationResult.status === "success") {
          setWaitingRoomConversationSids((prev) => omit(prev, [visitorId]));
        }
        return conversationResult;
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
