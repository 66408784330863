import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { Icon, MenuItem } from "@growtherapy/sprout-ui";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export function IconMenuItem({
  children,
  className,
  icon,
  onClick,
  ...otherMenuItemProps
}: {
  icon: IconDefinition;
} & ComponentProps<typeof MenuItem>) {
  return (
    <MenuItem
      className={twMerge("flex items-center gap-2", className)}
      onClick={onClick}
      {...otherMenuItemProps}
    >
      <Icon aria-hidden icon={icon} className="h-4 w-4" />
      {children}
    </MenuItem>
  );
}
