import { Text } from "@growtherapy/sprout-ui";
import { ReactNode } from "react";

export function HelpModalItem({
  heading,
  description,
}: {
  heading: string;
  description: ReactNode;
}) {
  return (
    <li className="border-b border-neutral-500 pb-6 last-of-type:border-none last-of-type:pb-0 relative before:absolute before:content-[counter(list-item)] before:bg-neutral-400 before:text-neutral-800 before:rounded-full before:items-center before:justify-center before:text-xs before:px-2 before:py-1 before:w-fit before:inline-block">
      <Text as="h3" className="text-neutral-800 ml-8 pb-2">
        {heading}
      </Text>
      <Text as="div" className="text-neutral-800 font-normal ml-8">
        {description}
      </Text>
    </li>
  );
}
