import { Auth0ProviderWithNav as Auth0Provider } from "./auth";
import { AuthedApolloProvider } from "./apollo";
import { Routes } from "./nav";
import { BrowserRouter } from "react-router-dom";
import { Toaster as SproutToaster } from "@growtherapy/sprout-ui";
import { Toaster as DefaultToaster } from "react-hot-toast";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "./error/ErrorPage";
import { DevTools as JotaiDevTools, useAtomsDebugValue } from "jotai-devtools";
import { useBackForwardCacheReload } from "./nav/useBackForwardCacheReload";
// Support for React Devtools
function DebugAtoms() {
  useAtomsDebugValue();
  return null;
}

function App() {
  useBackForwardCacheReload();
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      {/* Rest assured that the below dev tools are not available in the prod build */}
      <JotaiDevTools />
      <DebugAtoms />
      <BrowserRouter>
        <Auth0Provider>
          <AuthedApolloProvider>
            {/*
              TODO: https://growtherapy.atlassian.net/browse/CARED-1848
              NOTE: Toasts are currently expected to appear on top of everything
              else, including modals, e.g. in client consent modal. Toasting while
              a modal is open is not generally recommended because modals are
              intended to trap focus and would thus prevent interaction with
              toasts. However, since this is the behavior today, we need to support
              it for now. This should be removed and #root should be locked to
              isolation: isolate in the future when the modal is refactored.
            */}
            <DefaultToaster containerClassName="z-[1000]" />
            <SproutToaster />
            <Routes />
          </AuthedApolloProvider>
        </Auth0Provider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
