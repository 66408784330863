import { Menu, MenuToggleButton } from "@growtherapy/sprout-ui";
import { WhiteboardControlMenuItemWrapper } from "../../twilio";
import { ScreenShareMenuItemsWrapper } from "../../twilio/controls/ScreenShareControl";
import { faAirplay } from "@fortawesome/pro-solid-svg-icons";
import { ControlPanelButton } from "../../components";

export function SessionModeControlButtonWrapper({
  className,
}: {
  className?: string;
}) {
  return (
    <Menu
      className={className}
      placement="top"
      ariaLabel="Screen sharing and whiteboarding options"
      toggleButtonTooltip="Screen share or whiteboard"
      toggleButton={
        <MenuToggleButton<typeof ControlPanelButton>
          as={ControlPanelButton}
          visibleCaret={false}
          aria-label="View screen sharing and whiteboarding options"
          iconDefinition={faAirplay}
          shouldOverrideClassName
        />
      }
    >
      <ScreenShareMenuItemsWrapper />
      <WhiteboardControlMenuItemWrapper />
    </Menu>
  );
}
