import { FetchResult, gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useSetAtom } from "jotai";
import { patientConsentAtom } from "../../twilio/messages/state";
import {
  ConsentStatus,
  ConsentUpdateSource,
  ClientTranscriptionUserAction,
} from "../../twilio/types";
import { useParams } from "react-router";
import { useAtomCallback } from "jotai/utils";
import { sessionInfoAtom } from "../../state";
import { logger } from "../../datadog/logger";
import { apiTokenAtom } from "../state";
import { CallbackFailure, CallbackSuccess } from "../../types";
import { useSendVisitorParticipantStateCallback } from "../messages/useSendVisitorParticipantState";

export type RecordPatientConsentVariables = {
  providerShortId: string;
  patientShortId: string;
  isOptedIn: boolean;
  updateSource: string;
  appointmentShortId: string;
  userAction?: ClientTranscriptionUserAction;
};

// Selecting these fields out of RecordPatientConsentVariables
// allows them to be optional. In most cases we will pull them
// out of state, but we allow them to be passed for overrides.
export type RecordClientTranscriptionConsentInputData = Pick<
  Partial<RecordPatientConsentVariables>,
  "providerShortId" | "patientShortId" | "appointmentShortId"
> & { apiToken?: string };

export type RecordPatientConsentData = {
  recordProviderTranscriptionConsent: {
    providerShortId: string;
    isOptedIn: boolean;
    createdAt: Date;
  };
};

const PATIENT_TRANCRIPTION_CONSENT_MUTATION = gql`
  mutation RecordClientTranscriptionConsent(
    $patientShortId: String!
    $providerShortId: String!
    $isOptedIn: Boolean!
    $updateSource: String!
    $appointmentShortId: String
    $userAction: String
  ) {
    recordClientTranscriptionConsent(
      patientShortId: $patientShortId
      providerShortId: $providerShortId
      isOptedIn: $isOptedIn
      updateSource: $updateSource
      appointmentShortId: $appointmentShortId
      userAction: $userAction
    ) {
      patientShortId
      isOptedIn
      createdAt
    }
  }
`;

function validateRequiredFields({
  providerShortId,
  patientShortId,
  appointmentShortId,
  apiToken,
}: RecordClientTranscriptionConsentInputData):
  | [Required<RecordClientTranscriptionConsentInputData>, null]
  | [null, Set<keyof RecordPatientConsentVariables | "apiToken">] {
  const missingFields = new Set<
    keyof RecordPatientConsentVariables | "apiToken"
  >();
  if (!providerShortId) {
    missingFields.add("providerShortId");
  }
  if (!patientShortId) {
    missingFields.add("patientShortId");
  }
  if (!appointmentShortId) {
    missingFields.add("appointmentShortId");
  }
  if (!apiToken) {
    missingFields.add("apiToken");
  }
  if (missingFields.size > 0) {
    return [null, missingFields];
  }
  return [
    {
      apiToken: apiToken!,
      providerShortId: providerShortId!,
      patientShortId: patientShortId!,
      appointmentShortId: appointmentShortId!,
    },
    null,
  ];
}

export enum RecordClientTranscriptionConsentStatus {
  CONSENTED = "CONSENTED",
  CONSENT_DENIED = "CONSENT_DENIED",
  MISSING_REQUIRED_FIELDS = "MISSING_REQUIRED_FIELDS",
  ERROR = "ERROR",
}

type RecordClientTranscriptionConsentResponse =
  | CallbackSuccess<
      FetchResult<RecordPatientConsentData> | null,
      | RecordClientTranscriptionConsentStatus.CONSENTED
      | RecordClientTranscriptionConsentStatus.CONSENT_DENIED
    >
  | CallbackFailure<
      Set<keyof RecordPatientConsentVariables | "apiToken">,
      RecordClientTranscriptionConsentStatus.MISSING_REQUIRED_FIELDS
    >
  | CallbackFailure<Error, RecordClientTranscriptionConsentStatus.ERROR>;

/**
 * Returns a callback that records the client's transcription consent and sends
 * the updated participant state. Handles errors gracefully.
 *
 * **IMPORTANT**: Must be used from a visitor-page route that has a
 * `patientShortId` URL parameter.
 */
export function useGracefullyRecordClientTranscriptionConsentCallback() {
  const { patientShortId: patientShortIdFromParams } = useParams<{
    patientShortId: string;
  }>();
  const setPatientConsent = useSetAtom(patientConsentAtom);
  const getSessionInfo = useAtomCallback(
    useCallback((get) => get(sessionInfoAtom), []),
  );
  const getApiToken = useAtomCallback(
    useCallback((get) => get(apiTokenAtom), []),
  );
  const sendParticipantState = useSendVisitorParticipantStateCallback();

  const [recordClientConsent] = useMutation<
    RecordPatientConsentData,
    RecordPatientConsentVariables
  >(PATIENT_TRANCRIPTION_CONSENT_MUTATION);

  return useCallback(
    async (
      isOptedIn: boolean,
      userAction?: ClientTranscriptionUserAction,
      overrides: RecordClientTranscriptionConsentInputData = {},
    ): Promise<RecordClientTranscriptionConsentResponse> => {
      const setAndSendParticipantState = () => {
        setPatientConsent({
          value: isOptedIn ? ConsentStatus.OPTED_IN : ConsentStatus.OPTED_OUT,
          lastUpdated: Date.now(),
        });
        sendParticipantState();
      };

      const sessionInfo = getSessionInfo();

      const patientShortId =
        overrides?.patientShortId ?? patientShortIdFromParams;
      const providerShortId =
        overrides?.providerShortId ?? sessionInfo?.provider?.shortId;
      const appointmentShortId =
        overrides?.appointmentShortId ?? sessionInfo?.appointment?.shortId;
      const apiToken = overrides.apiToken ?? getApiToken();
      const [validatedFields, missingFields] = validateRequiredFields({
        apiToken,
        providerShortId,
        patientShortId,
        appointmentShortId,
      });

      if (!validatedFields) {
        logger.error(
          "Failed to record client consent due to missing required fields",
          {
            // DD doesn't parse set values, so convert to array
            missingFields: Array.from(missingFields),
          },
        );
        return {
          status: "failure",
          code: RecordClientTranscriptionConsentStatus.MISSING_REQUIRED_FIELDS,
          value: missingFields,
        };
      }

      try {
        let result: FetchResult<RecordPatientConsentData> | null = null;
        result = await recordClientConsent({
          context: {
            headers: {
              Authorization: `Bearer ${validatedFields.apiToken}`,
            },
            skipAuth: true,
          },
          variables: {
            appointmentShortId: validatedFields.appointmentShortId,
            patientShortId: validatedFields.patientShortId,
            providerShortId: validatedFields.providerShortId,
            updateSource: ConsentUpdateSource.TELEHEALTH,
            userAction: userAction,
            isOptedIn: isOptedIn,
          },
        });
        setAndSendParticipantState();
        return {
          status: "success",
          code: isOptedIn
            ? RecordClientTranscriptionConsentStatus.CONSENTED
            : RecordClientTranscriptionConsentStatus.CONSENT_DENIED,
          value: result,
        };
      } catch (e) {
        const error = e as Error;
        logger.error(
          "Failed to record client consent",
          {
            appointmentShortId,
            patientShortId,
            providerShortId,
          },
          error as Error,
        );
        return {
          status: "failure",
          code: RecordClientTranscriptionConsentStatus.ERROR,
          value: error as Error,
        };
      }
    },
    [
      getSessionInfo,
      getApiToken,
      patientShortIdFromParams,
      recordClientConsent,
      setPatientConsent,
      sendParticipantState,
    ],
  );
}
