import { useCallback } from "react";
import { providerShortIdAtom } from "../state";
import { UserType } from "../../types";
import { useSendParticipantStateCallback as useSendParticipantStateCallback } from "../../twilio/messages/useSendParticipantStateCallback";
import { useAtomCallback } from "jotai/utils";

export function useSendProviderParticipantStateCallback() {
  const getProviderShortId = useAtomCallback(
    useCallback((get) => get(providerShortIdAtom), []),
  );

  const sendParticipantState = useSendParticipantStateCallback();

  return useCallback(() => {
    sendParticipantState({
      entityId: getProviderShortId()!,
      entityType: UserType.PROVIDER,
    });
  }, [sendParticipantState, getProviderShortId]);
}
