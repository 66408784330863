import { gql, useQuery } from "@apollo/client";

import { ConsentStatus, ConsentUpdateSource } from "../../twilio/types";
import { useAtomValue, useSetAtom } from "jotai";
import {
  patientConsentAtom,
  patientConsentUpdateSourceAtom,
  providerConsentAtom,
} from "../../twilio/messages/state";
import { useRecordAudioCallbacks } from "./useRecordAudioCallbacks";
import { sessionInfoAtom } from "../../state";

export type ProviderPatientConsentVariable = {
  providerShortId?: string;
  patientShortId?: string;
};
export type ProviderPatientConsentData = {
  clientTranscriptionConsent?: {
    isOptedIn: boolean;
    updateSource: string;
  };
  providerTranscriptionConsent?: {
    isOptedIn: boolean;
    updateSource: string;
  };
};
export const GET_PROVIDER_PATIENT_CONSENT = gql`
  query GetProviderPatientTranscriptionConsent(
    $patientShortId: String!
    $providerShortId: String!
  ) {
    clientTranscriptionConsent(patientShortId: $patientShortId) {
      isOptedIn
      updateSource
    }
    providerTranscriptionConsent(providerShortId: $providerShortId) {
      isOptedIn
      updateSource
    }
  }
`;

export function optedInToConsentStatus(optedIn?: boolean): ConsentStatus {
  if (optedIn === undefined) return ConsentStatus.UNDECIDED;

  return optedIn ? ConsentStatus.OPTED_IN : ConsentStatus.OPTED_OUT;
}

export function resolveUpdateSource(updateSource?: string) {
  if (updateSource === ConsentUpdateSource.CLIENT_PORTAL)
    return ConsentUpdateSource.CLIENT_PORTAL;
  if (updateSource === ConsentUpdateSource.PROVIDER_DASHBOARD)
    return ConsentUpdateSource.PROVIDER_DASHBOARD;
  if (updateSource === ConsentUpdateSource.TELEHEALTH)
    return ConsentUpdateSource.TELEHEALTH;
  return ConsentUpdateSource.UNKNOWN;
}

/**
 * Automatically fetches/returns the provider and patient consent statuses for
 * transcription. If both have opted in & there is a scheduled appointment, it will also start recording.
 */
export function useGetProviderPatientConsent(
  providerShortId?: string,
  patientShortId?: string,
) {
  const setProviderTranscriptionConsent = useSetAtom(providerConsentAtom);
  const setPatientTranscriptionConsent = useSetAtom(patientConsentAtom);
  const setPatientConsentUpdateSource = useSetAtom(
    patientConsentUpdateSourceAtom,
  );

  const sessionInfo = useAtomValue(sessionInfoAtom);

  const { startRecording } = useRecordAudioCallbacks();

  const handleRequestSuccess = (data: ProviderPatientConsentData) => {
    const providerOptIn = data?.providerTranscriptionConsent?.isOptedIn;
    const patientOptIn = data?.clientTranscriptionConsent?.isOptedIn;
    const patientUpdateSource = resolveUpdateSource(
      data?.clientTranscriptionConsent?.updateSource,
    );
    const appointmentShortId = sessionInfo?.appointment.shortId;
    const providerConsent = optedInToConsentStatus(providerOptIn);
    const patientConsent = optedInToConsentStatus(patientOptIn);

    setProviderTranscriptionConsent({
      value: providerConsent,
      lastUpdated: Date.now(),
    });
    setPatientTranscriptionConsent({
      value: patientConsent,
      lastUpdated: Date.now(),
    });
    setPatientConsentUpdateSource({
      value: patientUpdateSource,
      lastUpdated: Date.now(),
    });

    if (
      providerConsent === ConsentStatus.OPTED_IN &&
      patientConsent === ConsentStatus.OPTED_IN &&
      appointmentShortId
    ) {
      startRecording();
    }
  };

  const { refetch } = useQuery<
    ProviderPatientConsentData,
    ProviderPatientConsentVariable
  >(GET_PROVIDER_PATIENT_CONSENT, {
    variables: { providerShortId, patientShortId },
    skip: !providerShortId || !patientShortId,
    context: { skipAuth: false },
    onCompleted: handleRequestSuccess,
  });

  const providerConsent = useAtomValue(providerConsentAtom).value;
  const patientConsent = useAtomValue(patientConsentAtom).value;
  const patientUpdateSource = useAtomValue(
    patientConsentUpdateSourceAtom,
  ).value;
  return {
    refetch,
    patientConsent,
    providerConsent,
    patientUpdateSource,
  };
}
