import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { localDataTrackAtom, LocalTrackState } from "../state";
import { LocalDataTrack } from "twilio-video";
import { useSetAtom } from "jotai";

/**
 * Returns a callback function that gets or creates a local data track. If a
 * local data track already exists, it will return that track. If a local data
 * track does not exist, it will create a new local data track and store it.
 */
export function useGetOrCreateLocalDataTrackCallback() {
  const getStoredLocalDataTrack = useAtomCallback(
    useCallback((get) => get(localDataTrackAtom).track, []),
  );
  const setStoredLocalDataTrack = useSetAtom(localDataTrackAtom);

  return useCallback(() => {
    const storedLocalDataTrack = getStoredLocalDataTrack();
    if (storedLocalDataTrack) {
      return storedLocalDataTrack;
    }

    const localDataTrack = new LocalDataTrack();
    setStoredLocalDataTrack({
      track: localDataTrack,
      state: LocalTrackState.READY,
    });
    return localDataTrack;
  }, [getStoredLocalDataTrack, setStoredLocalDataTrack]);
}
