import { useMemo, useRef } from "react";
import { CustomToast } from "./types";
import { AriaToastProps } from "@react-aria/toast";
import { ToastState } from "@react-stately/toast";
import { useToast } from "./useToast";
import classNames from "classnames";

type ToastProps<T> = AriaToastProps<T> & {
  className?: string;
  state: ToastState<T>;
  style?: React.CSSProperties;
};

/**
 * A basic, structural toast component built with @react-aria/toast's `useToast`
 * hook. Provides the plumbing to render a toast and applies layout styling from
 * its parent region, but does not include any visual styling or content itself.
 * Relies on `state` from the toast region it is expected to be rendered in.
 */
export default function BaseToast<T extends CustomToast>({
  className,
  state,
  style,
  ...props
}: ToastProps<T>) {
  const ref = useRef(null);
  const [toastProps, ariaProps] = useToast(props, state, ref);
  const { renderFn } = props.toast.content;

  // Only re-invoke the render function if the aria props we feed it change.
  const memoizedContents = useMemo(() => {
    return renderFn({
      closeToast: ariaProps.closeButtonProps.onClick,
      ...ariaProps,
    });
  }, [ariaProps, renderFn]);

  return (
    <li
      {...toastProps}
      ref={ref}
      className={classNames("toast", className)}
      style={style}
    >
      {memoizedContents}
    </li>
  );
}
