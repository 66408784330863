import {
  Icon,
  Menu,
  MenuItem,
  MenuToggleButton,
  Tag,
} from "@growtherapy/sprout-ui";
import { useTrackEvent } from "../../segment/segment";
import { EventTypeEnum } from "../../segment/types";
import { VisualIndicator } from "../../components/VisualIndicator";
import {
  faEllipsis,
  faMessage,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";

type ClientActionsMenuProps = {
  visitorName: string;
  loading?: boolean;
  patientShortId?: string;
  isConversationLoading?: boolean;
  isClientInformationLoading?: boolean;
  unreadMessagesCount?: number;
  onChat?: () => void;
  onOpenClientInformationDrawer: () => void;
  hasNewClientInfo?: boolean;
};

export const ClientActionsMenu = ({
  visitorName,
  loading,
  isConversationLoading,
  patientShortId,
  unreadMessagesCount,
  onChat,
  onOpenClientInformationDrawer,
  hasNewClientInfo,
}: ClientActionsMenuProps) => {
  const { userClicked } = useTrackEvent();

  const handleOpenClientInformationDrawer = () => {
    userClicked(EventTypeEnum.CLIENT_INFO, {
      patientShortId: patientShortId ?? "",
    });
    onOpenClientInformationDrawer();
  };
  return (
    <Menu
      placement="bottom end"
      buttonClassName="w-12 h-12"
      toggleButton={
        <MenuToggleButton
          aria-label="Additional actions"
          data-testid={`client-actions.additional-menu:${visitorName}`}
          loading={loading}
          disabled={loading}
          visibleCaret={false}
        >
          <Icon aria-hidden icon={faEllipsis} />
          {(!!unreadMessagesCount || hasNewClientInfo) && (
            <VisualIndicator className="absolute -top-1 -right-1" />
          )}
        </MenuToggleButton>
      }
    >
      <MenuItem
        onClick={onChat}
        loading={isConversationLoading}
        className="flex items-center gap-2"
      >
        <Icon aria-hidden icon={faMessage} className="w-4 h-4" />
        <span className="sr-only">Open chat,</span>
        <span aria-hidden>Chat</span>
        {unreadMessagesCount ? (
          <Tag className="ml-2" use="lilac">
            {unreadMessagesCount}
          </Tag>
        ) : (
          <span className="sr-only">0</span>
        )}
        <span className="sr-only">
          unread {unreadMessagesCount === 1 ? "message" : "messages"}
        </span>
      </MenuItem>
      <MenuItem
        onClick={handleOpenClientInformationDrawer}
        className="flex items-center gap-2"
      >
        <Icon aria-hidden icon={faUser} className="w-4 h-4" />
        View client info
        {hasNewClientInfo && (
          <Tag aria-label="new info available" use="lilac" className="ml-2">
            New
          </Tag>
        )}
      </MenuItem>
    </Menu>
  );
};
