import {
  Popover as BasePopover,
  PopoverPanel,
  PopoverPanelProps,
} from "@headlessui/react";
import { PropsWithChildren, ReactElement } from "react";
import { twMerge } from "tailwind-merge";
import { PopoverButtonProps } from "./PopoverButton";
import { DEFAULT_POPOVER_GAP, DEFAULT_POPOVER_PLACEMENT } from "./constants";

type AnchorProps = Exclude<
  NonNullable<PopoverPanelProps["anchor"]>,
  boolean | string
>;

export type PopoverProps = PropsWithChildren<{
  /**
   * The class name for the popover container i.e. container for the popover
   * button *and* the popover panel
   */
  containerClassName?: string;
  /**
   * The class name for the popover panel
   */
  popoverClassName?: string;
  /**
   * The element that toggles the popover
   */
  popoverButton: ReactElement<PopoverButtonProps>;
  /**
   * The placement of the popover relative to the popover button e.g. "top"
   */
  placement?: AnchorProps["to"];
  /**
   * The gap between the popover and the popover button e.g. 1rem
   */
  gap?: string;
  /**
   * The horizontal offset of the popover from the popover button e.g. 1rem
   */
  offset?: string;
}>;

export function Popover({
  children,
  containerClassName,
  gap = DEFAULT_POPOVER_GAP,
  offset,
  placement = DEFAULT_POPOVER_PLACEMENT,
  popoverButton,
  popoverClassName,
}: PopoverProps) {
  return (
    // Unfortunately doesn't allow programmatic opening of the popover
    <BasePopover className={containerClassName}>
      {popoverButton}
      <PopoverPanel
        anchor={{ to: placement, offset, gap }}
        className={twMerge(
          "w-max p-4 bg-neutral-300 border border-neutral-800 rounded-xl shadow-flat-left",
          popoverClassName,
        )}
      >
        {children}
      </PopoverPanel>
    </BasePopover>
  );
}
