import {
  NudgeObject,
  NudgeSchemaTrigger,
  NudgeList,
} from "@grow-therapy-team/seedling-components";
import { Card, Heading } from "@growtherapy/sprout-ui";
import { getAppConfig } from "../../config";
import { useSetAtom } from "jotai";
import { drawerStateAtom } from "../state";
import { useTrackEvent } from "../../segment/segment";
import { createNudgeOnClickHandlers } from "./utils";

export interface ClinicalConsiderationsSectionProps {
  nudges: NudgeObject[];
  onClickHandlers: Partial<Record<NudgeSchemaTrigger, () => void>> | null;
  isInSessionWithSelectedClient: boolean;
}

export function ClinicalConsiderationsSection({
  nudges,
  onClickHandlers,
  isInSessionWithSelectedClient,
}: ClinicalConsiderationsSectionProps) {
  return (
    <div
      className="flex flex-col mb-6 gap-y-6"
      data-testid="clinical-considerations-section.body.content"
    >
      <Heading variant="md" as="h3">
        Clinical considerations
      </Heading>
      <Card data-testid="clinical-considerations-section.card.content">
        <NudgeList
          nudges={nudges}
          onClickHandlers={onClickHandlers || undefined}
          context={
            isInSessionWithSelectedClient
              ? "telehealth-session"
              : "telehealth-waiting-room"
          }
        />
      </Card>
    </div>
  );
}

export function ClinicalConsiderationsSectionWrapper({
  nudges,
  patientId,
  isInSessionWithSelectedClient,
}: {
  nudges: NudgeObject[];
  patientId: string;
  isInSessionWithSelectedClient: boolean;
}) {
  const providerDashboardReferralUri = `${
    getAppConfig().providerOrigin
  }/refer-client/filters/${patientId}`;

  const { userClicked } = useTrackEvent();
  const onClickHandlers = createNudgeOnClickHandlers(
    nudges,
    useSetAtom(drawerStateAtom),
    providerDashboardReferralUri,
    userClicked,
    isInSessionWithSelectedClient,
  );

  return (
    <ClinicalConsiderationsSection
      nudges={nudges}
      onClickHandlers={onClickHandlers}
      isInSessionWithSelectedClient={isInSessionWithSelectedClient}
    />
  );
}
