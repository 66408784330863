import { RemoteParticipant as RemoteParticipantType } from "twilio-video";
import { RemoteParticipantWrapper as RemoteParticipant } from "./RemoteParticipant";
import { StopScreenShareButtonWrapper as StopScreenShareButton } from "../controls/StopScreenShareButton";
import {
  parseIdentity,
  participantsAtom,
  remoteScreenShareParticipantAtom,
} from "..";
import { Text } from "@growtherapy/sprout-ui";
import { useAtomValue } from "jotai";
import { FC, ReactNode } from "react";
import { ScrollableGallery } from "../../components/ScrollableGallery";
import { RemoteScreenShare } from "./RemoteScreenShare";
import { LocalScreenShare } from "./LocalScreenShare";
import { isWhiteboardModeAtom } from "../messages/state";

interface MediaShareLayoutProps {
  remoteParticipants: RemoteParticipantType[];
  remoteScreenShareParticipant?: RemoteParticipantType;
  thumbnail: ReactNode;
  remoteParticipantComponent: typeof RemoteParticipant;
  sharedMediaComponent: ReactNode;
}

export function MediaShareLayout({
  remoteParticipants,
  remoteScreenShareParticipant,
  remoteParticipantComponent: RemoteParticipantComponent,
  thumbnail,
  sharedMediaComponent,
}: MediaShareLayoutProps) {
  const { name: screenShareParticipantName } = parseIdentity(
    remoteScreenShareParticipant?.identity ?? "",
  );

  return (
    <div className="rounded-2xl w-screen sm:w-full sm:h-full p-2 bg-neutral-800">
      <StopScreenShareButton />
      {remoteScreenShareParticipant && (
        <div data-dd-privacy="mask">
          <Text
            variant="sm"
            className="text-neutral-100 text-center pb-2 fs-exclude"
          >
            {screenShareParticipantName} is sharing screen
          </Text>
        </div>
      )}
      <div className="flex flex-col sm:flex-row h-[calc(100%-3rem)] justify-between items-center">
        {sharedMediaComponent}
        <div className="order-1 sm:order-2 sm:h-full w-full sm:w-[unset]">
          <ScrollableGallery>
            {thumbnail}
            {remoteParticipants.map((remoteParticipant) => {
              return (
                <RemoteParticipantComponent
                  key={remoteParticipant.identity}
                  className="max-w-[15rem] min-w-[10rem] sm:min-w-[unset] max-h-[8.5rem] min-h-[7.875rem] w-auto h-full sm:mb-2 [&:not(:last-child)]:mr-2 sm:[&:not(:last-child)]:mr-0 flex-1 rounded-2xl border-neutral-800 border"
                  remoteParticipant={remoteParticipant}
                />
              );
            })}
          </ScrollableGallery>
        </div>
      </div>
    </div>
  );
}

export function MediaShareLayoutWrapper({
  thumbnail,
  remoteParticipantComponent: RemoteParticipantComponent,
  whiteboardComponent: WhiteboardComponent,
}: Pick<MediaShareLayoutProps, "thumbnail" | "remoteParticipantComponent"> & {
  whiteboardComponent: FC;
}) {
  const participants = useAtomValue(participantsAtom);
  const remoteScreenShareParticipant = useAtomValue(
    remoteScreenShareParticipantAtom,
  );
  const isWhiteboardMode = useAtomValue(isWhiteboardModeAtom);
  const screenShareComponent = remoteScreenShareParticipant ? (
    <RemoteScreenShare />
  ) : (
    <LocalScreenShare />
  );

  return (
    <MediaShareLayout
      remoteParticipants={participants}
      remoteScreenShareParticipant={remoteScreenShareParticipant}
      remoteParticipantComponent={RemoteParticipantComponent}
      thumbnail={thumbnail}
      sharedMediaComponent={
        isWhiteboardMode.value ? <WhiteboardComponent /> : screenShareComponent
      }
    />
  );
}
