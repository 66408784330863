import {
  AudioControlWrapper as AudioControl,
  participantCountAtom,
} from "../../twilio";
import { VideoControlWrapper as VideoControl } from "../../twilio/controls/VideoControl";
import { memoize } from "../../utils";
import { useObscuredProviderContentProps } from "../a11y";
import { ClientInformationDrawerControlButtonWrapper as ClientInformationDrawerControlButton } from "./ClientInformationDrawerControlButton";
import { CompleteSessionButton } from "../CompleteSessionButton";
import { ProviderAdditionalOptionsMenuWrapper as ProviderAdditionalOptionsMenu } from "./ProviderAdditionalOptionsMenu";
import {
  ProviderSettingsDrawerButton,
  ProviderSettingsDrawerControlMenuItemWrapper as ProviderSettingsDrawerControlMenuItem,
} from "./ProviderSettingsDrawerControls";
import { useAtomValue } from "jotai";
import { ClientInformationDrawerControlMenuItemWrapper as ClientInformationDrawerControlMenuItem } from "./ClientInformationDrawerControlMenuItem";
import { ClientListDrawerControlMenuItemWrapper as ClientListDrawerControlMenuItem } from "./ClientListDrawerControlMenuItem";
import { ClientsDrawerButtonWrapper as ClientsDrawerButton } from "../clients-drawer";
import { ProviderChatDrawerControlButtonWrapper as ProviderChatDrawerControlButton } from "./ProviderChatDrawerControlButton";
import { ChatDrawerControlMenuItemWrapper as ChatDrawerControlMenuItem } from "./ChatDrawerControlMenuItem";
import { RecordingControl } from "../session-recording/RecordingControl";
import {
  CentralSessionControls,
  LeftsideSessionControls,
  RightsideSessionControls,
  SessionControl,
  SessionControlsLayout,
} from "../../components";
import { ToolbarSeparator } from "../../components/ToolbarSeparator";
import { SessionModeControlButtonWrapper as SessionModeControlButton } from "./SessionModeControlButton";
import { SessionModeControlMenuItemsWrapper as SessionModeControlMenuItems } from "./SessionModeControlMenuItems";
import { PatientNotePopoverControlButtonWrapper as PatientNotePopoverControlButton } from "./PatientNotePopoverControlButton";

const inSessionCentralControls: SessionControl[] = [
  {
    key: "client-list",
    additionalOptionsMenuItemComponent: ClientListDrawerControlMenuItem,
  },
  {
    key: "session-mode",
    toolbarComponent: SessionModeControlButton,
    additionalOptionsMenuItemComponent: SessionModeControlMenuItems,
  },
  {
    key: "client-information",
    toolbarComponent: ClientInformationDrawerControlButton,
    additionalOptionsMenuItemComponent: ClientInformationDrawerControlMenuItem,
  },
  {
    key: "patient-note",
    toolbarComponent: PatientNotePopoverControlButton,
  },
  {
    key: "session-tools-separator",
    toolbarComponent: ToolbarSeparator,
  },
  {
    key: "recording",
    toolbarComponent: RecordingControl,
  },
];

const getCentralControlsMemoized = memoize(
  (isInSession: boolean): SessionControl[] => {
    return [
      {
        key: "audio",
        toolbarComponent: AudioControl,
      },
      {
        key: "video",
        toolbarComponent: VideoControl,
      },
      {
        key: "av-separator",
        toolbarComponent: ToolbarSeparator,
      },
      {
        key: "chat",
        toolbarComponent: ProviderChatDrawerControlButton,
        additionalOptionsMenuItemComponent: ChatDrawerControlMenuItem,
      },
      ...(isInSession ? inSessionCentralControls : []),
      {
        key: "settings",
        toolbarComponent: ProviderSettingsDrawerButton,
        additionalOptionsMenuItemComponent:
          ProviderSettingsDrawerControlMenuItem,
      },
    ];
  },
);

export function ProviderSessionControlsWrapper() {
  const obscuredContentProps = useObscuredProviderContentProps();
  const isInSession = !!useAtomValue(participantCountAtom);

  return (
    <SessionControlsLayout {...obscuredContentProps} className="order-3">
      <LeftsideSessionControls>
        <ClientsDrawerButton />
      </LeftsideSessionControls>
      <CentralSessionControls
        controls={getCentralControlsMemoized(isInSession)}
        additionalOptionsMenuComponent={ProviderAdditionalOptionsMenu}
      />
      {isInSession && (
        <RightsideSessionControls>
          <CompleteSessionButton />
        </RightsideSessionControls>
      )}
    </SessionControlsLayout>
  );
}
