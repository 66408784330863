import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { MenuItem } from "@growtherapy/sprout-ui";
import { ComponentProps } from "react";
import { useAtomValue } from "jotai";
import { ProviderDrawerState, drawerStateAtom } from "../state";
import { PUSH_DRAWER_ARIA_CONTROLS_ID } from "../constants";
import { IconMenuItem } from "../../components";
import { useToggleProviderDrawerStateCallback } from "./useToggleProviderDrawerStateCallback";
import { SettingsDrawerControl } from "../../twilio";

export type ProviderSettingsDrawerMenuItemProps = {
  isSettingsDrawerOpen: boolean;
} & Pick<ComponentProps<typeof MenuItem>, "onClick">;

export function ProviderSettingsDrawerControlMenuItem({
  isSettingsDrawerOpen,
  onClick,
}: ProviderSettingsDrawerMenuItemProps) {
  return (
    <IconMenuItem
      aria-controls={PUSH_DRAWER_ARIA_CONTROLS_ID}
      aria-expanded={isSettingsDrawerOpen}
      onClick={onClick}
      icon={faGear}
    >
      <span className="sr-only">
        {isSettingsDrawerOpen ? "Close" : "Open"} settings
      </span>
      <span aria-hidden>Settings</span>
    </IconMenuItem>
  );
}

export function ProviderSettingsDrawerControlMenuItemWrapper() {
  const { isSettingsDrawerOpen, toggleDrawerState } =
    useProviderDrawerControls();
  return (
    <ProviderSettingsDrawerControlMenuItem
      isSettingsDrawerOpen={isSettingsDrawerOpen}
      onClick={toggleDrawerState}
    />
  );
}

export function ProviderSettingsDrawerButton() {
  const { isSettingsDrawerOpen, toggleDrawerState } =
    useProviderDrawerControls();
  return (
    <SettingsDrawerControl
      forDrawerStates={ProviderDrawerState.SETTINGS}
      isSettingsDrawerOpen={isSettingsDrawerOpen}
      onClick={toggleDrawerState}
      drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID}
    />
  );
}

function useProviderDrawerControls() {
  const drawerState = useAtomValue(drawerStateAtom);
  const isSettingsDrawerOpen = drawerState === ProviderDrawerState.SETTINGS;

  const toggleDrawerState = useToggleProviderDrawerStateCallback(
    ProviderDrawerState.SETTINGS,
  );

  return {
    isSettingsDrawerOpen,
    toggleDrawerState,
  };
}
