import { Button, Icon, Tooltip } from "@growtherapy/sprout-ui";
import { CLIENT_ACCOUNT_PAUSED_TOOLTIP_TEXT } from "./constants";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";

export function AddAppointmentButton({
  onClickHandler,
  isClientUserAccountPaused,
}: {
  onClickHandler: () => void;
  isClientUserAccountPaused: boolean;
}): JSX.Element {
  return (
    <>
      <Button
        onClick={onClickHandler}
        use="link"
        className="mt-4"
        disabled={isClientUserAccountPaused}
      >
        Add appointment
      </Button>
      {isClientUserAccountPaused && (
        <Tooltip
          className="max-w-[256px] normal-case"
          text={CLIENT_ACCOUNT_PAUSED_TOOLTIP_TEXT}
          childIsInteractive={false}
          placement="right"
        >
          <Icon
            icon={faCircleInfo}
            className="text-lilac-700 ml-2"
            data-testid="telehealth-add-appointment-button-icon"
            size="sm"
          />
        </Tooltip>
      )}
    </>
  );
}
