import { DrawerBody } from "@growtherapy/sprout-ui";
import { AppointmentType } from "../../schedule-preview/types";
import { getConflictingAppointmentMessage } from "../utils";
import { useEffect, useState } from "react";
import { useGetEventConflicts } from "../useGetEventConflicts";
import { EditAppointmentFormValues } from "../EditAppointmentDrawer";
import { ScheduleAppointmentForm } from "../ScheduleAppointmentForm";
import { getAppConfig } from "../../../config";

interface AddAppointmentDrawerBodyProps {
  formValues: EditAppointmentFormValues;
  onUpdateValues: (values: Partial<EditAppointmentFormValues>) => void;
  appointmentType: AppointmentType;
  conflictMessage?: string;
  timeInputErrorMessage?: string;
  calendarLink?: string;
}

export function AddAppointmentDrawerBody({
  formValues,
  onUpdateValues,
  appointmentType,
  conflictMessage,
  timeInputErrorMessage,
  calendarLink,
}: AddAppointmentDrawerBodyProps) {
  return (
    <DrawerBody>
      <ScheduleAppointmentForm
        formValues={formValues}
        onUpdateValues={onUpdateValues}
        appointmentType={appointmentType}
        conflictMessage={conflictMessage}
        timeInputErrorMessage={timeInputErrorMessage}
        isRecurrenceEditable={true}
        calendarLink={calendarLink}
      />
    </DrawerBody>
  );
}

export function AddAppointmentDrawerBodyWrapper({
  ...props
}: Omit<
  AddAppointmentDrawerBodyProps,
  "appointmentType" | "recurringGroupingId" | "conflictMessage"
>) {
  const [conflictMessage, setConflictMessage] = useState<string | undefined>(
    undefined,
  );

  const { startDate, endDate, numRecurrences, isRecurring, weeksBetween } =
    props.formValues;

  const { error, loading, data } = useGetEventConflicts(
    startDate,
    endDate,
    !!isRecurring,
    numRecurrences ?? 0,
    weeksBetween ?? 0,
  );

  useEffect(
    function updateConflictMessage() {
      if (error || loading) {
        setConflictMessage("");
        return;
      }

      setConflictMessage(
        getConflictingAppointmentMessage(data, props.formValues),
      );
    },
    [data, loading, error, props.formValues],
  );

  const calendarLink = `${
    getAppConfig().providerOrigin
  }/dashboard/appointments/calendar/filters?utm_source=telehealth`;

  return (
    <AddAppointmentDrawerBody
      {...props}
      appointmentType={AppointmentType.FollowUp}
      conflictMessage={conflictMessage}
      calendarLink={calendarLink}
    />
  );
}
