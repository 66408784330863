import { useAtomValue } from "jotai";
import { ChatDrawerControlMenuItem } from "../../../twilio/controls/ChatDrawerControl";
import { ChatDrawerButtonWrapper as ChatDrawerButton } from "../../chat/ChatDrawerButton";
import { WAITING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID } from "../../constants";
import { VisitorDrawerState, visitorDrawerStateAtom } from "../../state";
import { useAnimationState } from "../background-action";
import { DEFAULT_WAITING_ROOM_PAGE_TITLE } from "../metadata";
import { useChatDrawerButtonUtils } from "../../chat/useChatDrawerButtonUtils";

export function VisitorWaitingChatDrawerButtonWrapper() {
  const { pause: pauseAnimation, play: playAnimation } = useAnimationState();

  return (
    <ChatDrawerButton
      defaultTitle={DEFAULT_WAITING_ROOM_PAGE_TITLE}
      drawerId={WAITING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID}
      onOpen={pauseAnimation}
      onClose={playAnimation}
    />
  );
}

export function VisitorWaitingChatDrawerMenuItemWrapper() {
  const { pause: pauseAnimation, play: playAnimation } = useAnimationState();
  const drawerState = useAtomValue(visitorDrawerStateAtom);
  const { unreadMessagesCount, toggleChatDrawer, isChatDrawerDisabled } =
    useChatDrawerButtonUtils({
      onOpen: pauseAnimation,
      onClose: playAnimation,
    });

  return (
    <ChatDrawerControlMenuItem
      drawerId={WAITING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID}
      isChatDrawerOpen={drawerState === VisitorDrawerState.CHAT}
      onClickChatButton={toggleChatDrawer}
      isChatButtonDisabled={isChatDrawerDisabled}
      unreadMessageCount={unreadMessagesCount}
    />
  );
}
