// Lazily loads the stringCompression module for its operations
import { memoize } from "../utils";
import type {
  gracefullyCompressJsonType,
  gracefullyDecompressJsonType,
} from "./stringCompression";

export const loadStringCompressionModule = memoize(
  async () => await import("./stringCompression"),
);

/**
 * Lazily loads the stringCompression module for compression
 */
export async function gracefullyCompressJson(
  ...args: Parameters<gracefullyCompressJsonType>
) {
  const { gracefullyCompressJson } = await loadStringCompressionModule();
  return gracefullyCompressJson(...args);
}

/**
 * Lazily loads the stringCompression module for decompression
 */
export async function gracefullyDecompressJson<T>(
  ...args: Parameters<gracefullyDecompressJsonType>
) {
  const { gracefullyDecompressJson } = await loadStringCompressionModule();
  return gracefullyDecompressJson<T>(...args);
}
