import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import {
  Button,
  Collapse,
  Icon,
  Text,
  TextInput,
} from "@growtherapy/sprout-ui";
import classNames from "classnames";
import { useSetAtom } from "jotai";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import type { PageComponentProps } from "../../../../components";
import { hashString, seededShuffle } from "../../../../utils";
import { visitorDrawerStateAtom } from "../../../../visitor/state";
import { useMoodCheckinSelectionsState } from "./useMoodCheckinSelectionsState";
import { useSyncMoodCheckinChoicesCallback } from "./useSyncMoodCheckinChoicesCallback";
import {
  ChoiceValue,
  MOOD_CHECKIN_CHOICES,
  getChoicesForDisplay,
} from "./utils";

export function Content({ pageIndex, nextPage }: PageComponentProps<string[]>) {
  const { patientShortId = "" } = useParams();
  const [customResponse, setCustomResponse] = useState<string>("");
  const [selections, setSelections] = useMoodCheckinSelectionsState();
  const onSyncChoices = useSyncMoodCheckinChoicesCallback();
  const setDrawerState = useSetAtom(visitorDrawerStateAtom);

  const onCustomInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCustomResponse(e.target.value);
    },
    [setCustomResponse],
  );

  const shuffledChoicesForDisplay = useMemo(() => {
    const choicesForDisplay = getChoicesForDisplay(
      MOOD_CHECKIN_CHOICES,
      selections,
      pageIndex,
    );

    if (!choicesForDisplay) return;

    return seededShuffle(choicesForDisplay, hashString(patientShortId));
  }, [pageIndex, selections, patientShortId]);

  const onChoiceChange = useCallback(
    (choice: ChoiceValue) => {
      // take depth number of selections, then add the new choice
      setSelections((previousSelection) => [
        ...previousSelection.slice(0, pageIndex),
        choice,
      ]);

      nextPage();
    },
    [setSelections, pageIndex, nextPage],
  );
  const onSaveCustomResponse = useCallback(() => {
    onSyncChoices([customResponse], {
      onCompleted() {
        setCustomResponse("");
        setDrawerState(null);
      },
    });
  }, [customResponse, onSyncChoices, setDrawerState]);

  if (!shuffledChoicesForDisplay) return null;

  const listItemClassName = "py-2 px-4 border rounded-sm";

  return (
    <>
      <Text variant="sm" className="text-neutral-700">
        We&apos;ll share this with your provider so you can process whatever you
        may be feeling together.
      </Text>

      <ul className="flex flex-col gap-3 mt-4">
        {shuffledChoicesForDisplay.map(({ value, label = value, children }) => (
          <li
            key={value}
            className={classNames(listItemClassName, {
              "bg-neutral-300": selections[pageIndex] === value,
              "hover:bg-neutral-200": selections[pageIndex] !== value,
            })}
          >
            <button
              onClick={() => onChoiceChange(value)}
              className="flex justify-between w-full items-center"
            >
              <span
                className="font-medium fs-exclude"
                data-dd-privacy="mask"
                data-dd-action-name="Click on mood checkin choice"
              >
                {label}
              </span>
              {(children?.length ?? 0) > 0 && (
                <Icon aria-hidden icon={faChevronRight} />
              )}
            </button>
          </li>
        ))}

        {pageIndex === 0 && (
          <li>
            <Collapse title="Something else" className={listItemClassName}>
              <div className="flex gap-3">
                <TextInput
                  value={customResponse}
                  onChange={onCustomInputChange}
                />

                <Button
                  disabled={!customResponse}
                  onClick={onSaveCustomResponse}
                >
                  Save
                </Button>
              </div>
            </Collapse>
          </li>
        )}
      </ul>
    </>
  );
}
