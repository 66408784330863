import { useCallback } from "react";
import { DataTrackWhiteboardSnapshotMessage } from "../../twilio";
import { loadWhiteboardStore } from "../../components/whiteboard/loadAppWhiteboardStore";
import {
  equivalentSnapshot,
  mergeSnapshot,
} from "../../components/whiteboard/utils";
import { Statsig } from "../../statsig/StatsigProvider";
import { whiteboardEventualConsistencyGate } from "../../statsig/gates";
import { useAtomCallback } from "jotai/utils";
import { isWhiteboardUserEditingAtom } from "../../components/whiteboard/state";

export function useLoadWhiteboardStoreSnapshotCallback() {
  const getIsWhiteboardUserEditing = useAtomCallback(
    useCallback((get) => get(isWhiteboardUserEditingAtom), []),
  );

  return useCallback(
    async (receivedMessage: DataTrackWhiteboardSnapshotMessage) => {
      const currentStore = await loadWhiteboardStore();

      if (Statsig.checkGate(whiteboardEventualConsistencyGate)) {
        const receivedSnapshot = receivedMessage.snapshot;
        const isEditingWhiteboard = getIsWhiteboardUserEditing();
        if (
          isEditingWhiteboard ||
          equivalentSnapshot(currentStore, receivedSnapshot)
        ) {
          return;
        }
        await mergeSnapshot(currentStore, receivedSnapshot);
      } else {
        currentStore.loadStoreSnapshot(receivedMessage.snapshot);
      }
    },
    [getIsWhiteboardUserEditing],
  );
}
