import { Card, Text, Checkbox } from "@growtherapy/sprout-ui";

import { NoteType } from "./types";

type AppointmentDetailsProps = {
  sectionTitle: string;
  notes: NoteType[];
};

export function AppointmentDetails({
  sectionTitle,
  notes,
}: AppointmentDetailsProps) {
  return (
    <Card key={sectionTitle} title={sectionTitle} titleAs="h3">
      <div
        className="fs-exclude"
        data-testid="client-information.notes.appointment-details"
        data-dd-privacy="mask"
      >
        {notes.map(
          (note) =>
            note.answer && (
              <div key={note.name} className="mb-2">
                {note.fieldType === "checkbox" && note.answer === true ? (
                  <Checkbox
                    label={note.question}
                    disabled={true}
                    checked={Boolean(note.answer)}
                  />
                ) : (
                  <div>
                    {note.question && note.fieldType !== "checkbox" && (
                      <>
                        <Text className="font-semibold text-small mt-2">
                          {note.question}
                        </Text>
                        <Text className="mb-4">{note.answer}</Text>
                      </>
                    )}
                  </div>
                )}
              </div>
            ),
        )}
      </div>
    </Card>
  );
}
