import { NewMessageToast } from "../../components/NewMessageToast";
import { Message } from "@twilio/conversations";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import {
  currentConversationSidAtom,
  twilioIdentityAtom,
  unreadMessageCountByConversationSidAtom,
} from "../../twilio/state";
import {
  VisitorDrawerState,
  activeConversationSidAtom,
  visitorDrawerStateAtom,
} from "../state";
import { parseIdentity } from "../../twilio/utils";
import { toast, ToastOptions } from "../../toasts";
import { useSetAtom } from "jotai";
import { minutesToMilliseconds } from "date-fns";

export const NEW_MESSAGE_TOAST_TAG = "NEW_MESSAGE_TOAST";
const TOAST_OPTIONS: ToastOptions = {
  position: "bottom-left",
  timeout: minutesToMilliseconds(1),
  tags: new Set([NEW_MESSAGE_TOAST_TAG]),
};

export function useShowNewMessageToastCallback() {
  const setDrawerState = useSetAtom(visitorDrawerStateAtom);

  const getCurrentConversationSid = useAtomCallback(
    useCallback((get) => get(currentConversationSidAtom), []),
  );
  const getActiveConversationSid = useAtomCallback(
    useCallback((get) => get(activeConversationSidAtom), []),
  );
  const setCurrentConversationSid = useSetAtom(currentConversationSidAtom);

  const getTwilioIdentity = useAtomCallback(
    useCallback((get) => get(twilioIdentityAtom), []),
  );

  const setUnreadMessageCount = useSetAtom(
    unreadMessageCountByConversationSidAtom,
  );

  return useCallback(
    (message: Message) => {
      const isConversationOpen = !!getCurrentConversationSid();
      const isSelf = message.author === getTwilioIdentity();
      if (isSelf || isConversationOpen) return;
      const senderName = message.author
        ? parseIdentity(message.author).name
        : "New message";
      const activeConversationSid = getActiveConversationSid();

      // Dismiss any existing toasts first.
      toast.closeByTag(NEW_MESSAGE_TOAST_TAG);

      toast.enqueue(
        ({ closeToast, ...ariaProps }) => (
          <NewMessageToast
            {...ariaProps}
            senderName={senderName}
            messageContent={message.body ?? ""}
            onOpenChatDrawer={() => {
              closeToast();
              setDrawerState(VisitorDrawerState.CHAT);
              setCurrentConversationSid(getActiveConversationSid());
              setUnreadMessageCount((prev) =>
                activeConversationSid ? { [activeConversationSid]: 0 } : prev,
              );
            }}
          />
        ),
        TOAST_OPTIONS,
      );
    },
    [
      getCurrentConversationSid,
      getActiveConversationSid,
      setDrawerState,
      setCurrentConversationSid,
      setUnreadMessageCount,
      getTwilioIdentity,
    ],
  );
}
