import { Button, Icon, Text } from "@growtherapy/sprout-ui";
import { OldToast, ToastProps, ToastVariant } from "./Toast";
import { QualityMode } from "../twilio/config";
import { useStoredAudioVideoSettings } from "../twilio/useStoredAudioVideoSettings";
import { faWifiExclamation } from "@fortawesome/pro-solid-svg-icons";

type UnstableInternetToastProps = Omit<ToastProps, "children"> & {
  onOpenSettings: () => void;
  isPerformanceMode: boolean;
};

export function UnstableInternetToast({
  onClose,
  onOpenSettings,
  isPerformanceMode,
  ...toastProps
}: UnstableInternetToastProps) {
  return (
    <OldToast
      className="top-center-toast sm:max-w-[33rem]"
      variant={ToastVariant.Neutral}
      data-testid="connection-error-toast"
      onClose={onClose}
      {...toastProps}
    >
      <Icon className="mr-3" icon={faWifiExclamation} aria-hidden />
      <Text variant="sm">
        Your internet connection is unstable.{" "}
        {isPerformanceMode ? (
          "Try turning off your camera."
        ) : (
          <>
            <Button
              inline
              className="font-normal text-inherit text-inherit"
              use="link"
              onClick={() => {
                onOpenSettings();
                onClose?.();
              }}
            >
              Try changing your video quality
            </Button>{" "}
            or turning off your camera.
          </>
        )}
      </Text>
    </OldToast>
  );
}

export function UnstableInternetToastWrapper(
  props: Omit<UnstableInternetToastProps, "isPerformanceMode">,
) {
  const isPerformanceMode =
    useStoredAudioVideoSettings().storedAudioVideoSettings?.videoQualityMode ===
    QualityMode.Low;

  return <UnstableInternetToast {...{ ...props, isPerformanceMode }} />;
}
