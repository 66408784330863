import {
  BackgroundAnimationOverlay,
  BackgroundAnimationOverlayProps,
} from "../../../components";
import { FC } from "react";
import { ActionType } from "./types";
import { useAnimationState } from "./useAnimationState";
import { AnimationStateToggleButtonWrapper } from "./AnimationStateToggleButton";

type BackgroundAnimationContainerProps = {
  animationStateToggleButtonComponent: FC<{ className?: string }>;
  backgroundAnimationOverlayComponent?: FC<BackgroundAnimationOverlayProps>;
  currentAnimationState: ActionType;
} & BackgroundAnimationOverlayProps;

type BackgroundAnimationContainerWrapperProps = {
  children: React.ReactNode;
  isPreSessionVibesOpen: boolean;
};

export function BackgroundAnimationContainer({
  animationStateToggleButtonComponent: AnimationStateToggleButtonComponent,
  backgroundAnimationOverlayComponent:
    BackgroundAnimationOverlayComponent = BackgroundAnimationOverlay,
  children,
  currentAnimationState,
  ...overlayProps
}: BackgroundAnimationContainerProps) {
  return (
    <>
      <BackgroundAnimationOverlayComponent
        {...overlayProps}
        autoplay={currentAnimationState === ActionType.PLAY}
        data-animation-state={currentAnimationState}
      />
      {/* "relative" to ensure the content is rendered above the animation */}
      <div className="relative flex flex-col overflow-y-auto h-full w-[calc(100%+0.2rem)]">
        {children}
        <AnimationStateToggleButtonComponent className="self-center sm:self-end mx-3 mb-3" />
      </div>
    </>
  );
}

export function BackgroundAnimationContainerWrapper({
  children,
  isPreSessionVibesOpen,
}: BackgroundAnimationContainerWrapperProps) {
  const { lottieRef, currentState } = useAnimationState();

  if (isPreSessionVibesOpen) {
    return <>{children}</>;
  }

  return (
    <BackgroundAnimationContainer
      animationStateToggleButtonComponent={AnimationStateToggleButtonWrapper}
      currentAnimationState={currentState}
      lottieRef={lottieRef}
    >
      {children}
    </BackgroundAnimationContainer>
  );
}
