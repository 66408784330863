import { useCallback } from "react";
import { DataTrackWhiteboardUpdateMessage } from "../types";
import { loadWhiteboardStore } from "../../components/whiteboard/loadAppWhiteboardStore";

export function useHandleWhiteboardUpdateCallback() {
  return useCallback(
    async (receivedMessage: DataTrackWhiteboardUpdateMessage) => {
      const store = await loadWhiteboardStore();
      store.mergeRemoteChanges(() => {
        store.applyDiff(receivedMessage.update.changes);
      });
    },
    [],
  );
}
