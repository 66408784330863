import { Card, Text } from "@growtherapy/sprout-ui";
import { NoteType } from "./types";

type ClientProgressProps = {
  sectionTitle: string;
  notes: NoteType[];
};

export function ClientProgress({ sectionTitle, notes }: ClientProgressProps) {
  return (
    <Card key={sectionTitle} title={sectionTitle} className="p-0">
      <div
        className="fs-exclude"
        data-testid="client-information.notes.client-progress"
        data-dd-privacy="mask"
      >
        {notes.map((note) => (
          <div
            className={`${
              note.answer === "Yes" || note.answer === "No"
                ? "rounded-none border-b border-neutral-400 pt-2 pb-4"
                : ""
            }`}
            key={note.name}
          >
            <div
              className={`${
                note.answer === "Yes" || note.answer === "No"
                  ? "flex justify-between"
                  : ""
              }`}
            >
              {note.question && (
                <Text
                  className={`${
                    note.name === "symptom_header"
                      ? "font-semibold text-lg mt-2"
                      : "font-semibold text-sm mt-2"
                  }`}
                >
                  {note.question}
                </Text>
              )}

              <Text className="mt-2 text-sm">{note.answer}</Text>
            </div>
            <ul>
              {note.details.length > 0 &&
                note.details.map((detail) => {
                  return (
                    <li key={detail}>
                      <Text className="text-sm">{detail}</Text>
                    </li>
                  );
                })}
            </ul>
          </div>
        ))}
      </div>
    </Card>
  );
}
