import { DrawerTitle } from "@growtherapy/sprout-ui";
import { PaginatedDrawer, PaginatedDrawerProps } from "..";
import { type MeasureSchemaAndAnswers } from "./types";

export function MeasuresDrawer(
  props: Omit<
    PaginatedDrawerProps<MeasureSchemaAndAnswers>,
    "headerComponent" | "contentOnly"
  >,
) {
  return <PaginatedDrawer {...props} headerComponent={Header} contentOnly />;
}

function Header() {
  return <DrawerTitle>Completing measure</DrawerTitle>;
}
