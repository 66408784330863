import { secondsToMilliseconds } from "date-fns";
import { useCallback, useEffect } from "react";

import { useSendWhiteboardSnapshotCallback } from "../../twilio/messages/useSendWhiteboardSnapshotCallback";
import { useAtomCallback } from "jotai/utils";
import { providerShortIdAtom } from "../state";
import { UserType } from "../../types";
import { useAtomValue } from "jotai";
import { isWhiteboardModeAtom } from "../../twilio/messages/state";
import { useDynamicConfig, useFeatureGate } from "@statsig/react-bindings";
import {
  dynamicConfig,
  whiteboardEventualConsistencyGate,
} from "../../statsig/gates";

/**
 * This interval shouldn't be too short as the whiteboard snapshot can be large.
 */
const DEFAULT_INTERVAL_MS = secondsToMilliseconds(60);

/**
 * Periodically sends the provider's whiteboard snapshots to all participants,
 * aiming for eventual consistency with the provider's whiteboard state.
 */
export function usePeriodicallySendWhiteboardSnapshots() {
  const sendWhiteboardSnapshot = useSendWhiteboardSnapshotCallback();
  const getProviderShortId = useAtomCallback(
    useCallback((get) => get(providerShortIdAtom), []),
  );
  const isWhiteboardMode = useAtomValue(isWhiteboardModeAtom).value;
  const isEnabled = useFeatureGate(whiteboardEventualConsistencyGate);
  const dynamic_interval_seconds = useDynamicConfig(dynamicConfig)?.get<number>(
    "whiteboard_eventual_consistency_interval_seconds",
  );
  const interval_ms = dynamic_interval_seconds
    ? secondsToMilliseconds(dynamic_interval_seconds)
    : DEFAULT_INTERVAL_MS;

  useEffect(
    function handleSendingInterval() {
      if (!isWhiteboardMode || !isEnabled) return;

      const interval = setInterval(async () => {
        const providerShortId = getProviderShortId();
        if (!providerShortId) return;
        sendWhiteboardSnapshot({
          entityId: providerShortId,
          entityType: UserType.PROVIDER,
        });
      }, interval_ms);

      return () => {
        clearInterval(interval);
      };
    },
    [
      interval_ms,
      isEnabled,
      isWhiteboardMode,
      getProviderShortId,
      sendWhiteboardSnapshot,
    ],
  );
}
