export enum ActionType {
  PLAY = "Play",
  PAUSE = "Pause",
}

export type ActionStateToggleButtonProps = {
  className?: string;
  currentActionState: ActionType;
  onClick: () => void;
};
