import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { logger } from "../datadog/logger";
import { memoize, shortUuid } from "../utils";
import { hoursToMilliseconds } from "date-fns";

export const VISITOR_UUID_KEY = "visitor-uuid";
export const LAST_VISIT_TIMESTAMP_KEY = "last-visit-timestamp";

function getVisitorUuid(storage = localStorage) {
  return storage.getItem(VISITOR_UUID_KEY);
}

function storeNewVisitorUuid(storage = localStorage) {
  const visitorUuid = shortUuid();
  storage.setItem(VISITOR_UUID_KEY, visitorUuid);
  return visitorUuid;
}

/**
 * Gets an existing visitor unique ID or generates and stores a new one if it
 * doesn't exist.
 */
function getOrStoreVisitorUuid(storage = localStorage) {
  return getVisitorUuid(storage) ?? storeNewVisitorUuid(storage);
}

function getLastVisitTimestamp(storage = localStorage) {
  const lastVisitTimestampStr = storage.getItem(LAST_VISIT_TIMESTAMP_KEY);
  return lastVisitTimestampStr ? parseInt(lastVisitTimestampStr) : Date.now();
}

function storeNewLastVisitTimestamp(
  storage = localStorage,
  givenCurrentTimestamp?: number,
) {
  const currentTimestamp = givenCurrentTimestamp ?? Date.now();
  storage.setItem(LAST_VISIT_TIMESTAMP_KEY, currentTimestamp.toString());
  return currentTimestamp;
}

/**
 * Gets the last visit timestamp from local storage or sets a new one if it
 * doesn't exist.
 */
function getOrStoreLastVisitTimestamp(storage = localStorage) {
  return getLastVisitTimestamp(storage) ?? storeNewLastVisitTimestamp(storage);
}

/**
 * Returns true if the last visit timestamp is within the last 24 hours.
 */
function checkShouldUpdateVisitorUuid(
  lastVisitTimestamp: number,
  currentTimestamp?: number,
) {
  const timeSinceLastVisit =
    (currentTimestamp ?? Date.now()) - lastVisitTimestamp;
  return timeSinceLastVisit <= hoursToMilliseconds(24);
}

/**
 * Returns a newly stored [in localstorage] visitor UUID or an existing one
 * based on the last visit timestamp.
 *
 * Always updates the stored last visit timestamp.
 *
 * This is useful for cutting down our Twilio Conversation MAUs. Ideally visitor
 * IDs will be stable between appointments, but will still update if the user
 * refreshes/opens multiple tabs during a single appointment/session, making
 * this change largely backwards compatible.
 *
 * Accepts parameters for storage and current timestamp for testing purposes.
 */
export function renewVisitorUuid(
  storage = localStorage,
  currentTimestamp?: number,
) {
  const lastVisitTimestamp = getOrStoreLastVisitTimestamp(storage);
  const shouldUpdateVisitorUuid = checkShouldUpdateVisitorUuid(
    lastVisitTimestamp,
    currentTimestamp,
  );

  storeNewLastVisitTimestamp(storage, currentTimestamp);

  if (shouldUpdateVisitorUuid) {
    return storeNewVisitorUuid(storage);
  }

  return getOrStoreVisitorUuid(storage);
}

const memoizedRenewVisitorUuid = memoize(renewVisitorUuid);

/**
 * Returns a concatenation of the visitor's UUID and the patient's short ID.
 *
 * Please use this hook to get the visitor ID. It should be used anywhere a
 * visitor ID is needed.
 *
 * Should be used on routes containing patientShortId.
 */
export function useVisitorId() {
  const visitorUuid = memoizedRenewVisitorUuid();
  const { patientShortId } = useParams();

  useEffect(
    function warnWhenMissingPatientShortId() {
      if (!patientShortId)
        logger.warn("Missing patientShortId", { visitorUuid });
    },
    [patientShortId, visitorUuid],
  );

  return `${patientShortId}_${visitorUuid}`;
}
