import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "@growtherapy/sprout-ui";
import { ReactNode } from "react";

export function FormListItemContent({
  title,
  subtitle,
}: {
  title: ReactNode;
  subtitle?: ReactNode;
}) {
  return (
    <div className="flex grow justify-between">
      <div>
        {title}
        {subtitle}
      </div>
      <Icon aria-hidden icon={faAngleRight} className="mr-2 self-center" />
    </div>
  );
}
