import { ComponentProps, JSXElementConstructor } from "react";

export enum Env {
  LOCAL = "local",
  /** DEV refers to Sandbox, LOCAL refers to local dev. */
  DEV = "development",
  STAGING = "staging",
  PROD = "production",
}

export enum UserType {
  CLIENT = "CLIENT",
  PROVIDER = "PROVIDER",
}

export type Provider = {
  name: string;
  image?: string;
  shortId: string;
  pronouns?: string[];
  isPrescriber: boolean;
};

export type Appointment = {
  timeStart: string;
  timeEnd: string;
  shortId: string;
  clientUserShortId: string;
  hasProviderNoShowReport?: boolean;
};

export type TelehealthSessionInfo = {
  provider?: Provider;
  appointment?: Appointment;
};

export type UserData = {
  userType: UserType;
  id: string;
  name: string;
  pronouns?: string[];
};

export enum ParticipantConversationStatus {
  IN_SESSION = "IN SESSION",
  WAITING = "WAITING",
}

export enum ParticipantStatus {
  IN_SESSION = "IN SESSION",
  WAITING = "WAITING",
  SCHEDULED = "SCHEDULED",
}

/***
 * !! when adding attributes to this type, make sure to update mapItemToVisitor
 * which scrubs the data before it is added to the visitorPresenceMapAtom
 */
type ISO8601Timestamp = string;
export type VisitorPresence = {
  name?: string;
  patientShortId: string;
  timestamp: string;
  status?: ParticipantStatus;
  visitorUuid?: string;
  waitingRoomConversationSid?: string;
  moodCheckinAt?: ISO8601Timestamp;
  emotions?: string[];
  inSessionAppointmentShortId?: string;
};

export type VisitorPresenceMap = Record<string, VisitorPresence>;

type DefaultCodeType = string;
export interface CallbackResponse<ValueType, CodeType = DefaultCodeType> {
  status: "success" | "error" | "failure";
  code?: CodeType;
  value?: ValueType;
}

export interface CallbackSuccess<ValueType, CodeType = DefaultCodeType>
  extends CallbackResponse<ValueType, CodeType> {
  status: "success";
  value: ValueType;
}

export interface CallbackFailure<ValueType, CodeType = DefaultCodeType>
  extends CallbackResponse<ValueType, CodeType> {
  status: "failure";
  value: ValueType;
}

export interface CallbackError<CodeType = DefaultCodeType>
  extends CallbackResponse<Error, CodeType> {
  status: "error";
}

export enum Browser {
  Chrome = "chrome",
  Firefox = "firefox",
  Safari = "safari",
  Edge = "edge",
  Opera = "opera",
  Unknown = "unknown",
}

export enum DeviceType {
  Mobile = "mobile",
  Desktop = "desktop",
}

export enum AppState {
  MEETING = "meeting",
  WAITING = "waiting",
  INTRO = "intro",
}

/**
 * An announcement that can be shown to users. Please do not alter this without
 * also updating [this coda
 * doc](https://coda.io/d/Care-Delivery-Central-Doc_d0cGUjr-ldg/Telehealth-System-Announcements-Guide_sujZ4#Telehealth-announcement-options_tulY0/r13).
 */
export type Announcement = {
  content: string;
  allowAppStates?: AppState[];
  allowUserIds?: string[];
  allowAppVersions?: string[];
  browsers?: Browser[];
  devices?: DeviceType[];
  environments?: Env[];
  denyAppStates?: AppState[];
  denyAppVersions?: string[];
  denyUserIds?: string[];
  expirationTimestamp?: number;
  userTypes?: UserType[];
};

export type RequiredProps<T> = {
  [K in keyof T as T[K] extends undefined ? never : K]: T[K];
};
export type PartialComponentPropsWithRequired<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>,
> = Partial<ComponentProps<T>> & RequiredProps<ComponentProps<T>>;

/**
 * Possibly is a generic type that is used to add some entropy to a type. It is
 * most useful when you don't entirely trust the shape of the data you are
 * working with.
 *
 * Examples of times you should wrap a type in Possibly are:
 * - When you are parsing data from localStorage
 * - When you are parsing data from an API
 * - When you are working with data that is not under your control
 *
 * wrapping a type in Possibly at the moment you parse or otherwise receive data
 * from an external source, will highlight that the data may not be in the shape
 * and should be scrubbed.
 */
export type Possibly<T> =
  T extends Array<infer U>
    ? Array<Possibly<U>>
    : T extends object
      ? { [K in keyof T]: Possibly<T[K]> }
      : T | void;

/* eslint-disable @typescript-eslint/no-explicit-any */
/** All built-in and custom scalars, mapped to their actual values */
// These types are copied from the monolith
// general use types copied from the monolith
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};

/**
 * Prepend a given record type's keys with a given namespace string.
 *
 * e.g. `Namespaced<{a: string, b: number}, 'foo'>` will result in `{foo.a: string, foo.b: number}`
 */
export type NamespaceKeys<
  T extends Record<string, unknown>,
  Namespace extends string,
> = {
  [K in keyof T as `${Namespace}.${string & K}`]: T[K];
};

/**
 * Remove a given, prepended namespace string from a given record type's keys.
 *
 * e.g. `Unnamespaced<{foo.a: string, foo.b: number}, 'foo'>` will result in `{a: string, b: number}`
 */
export type UnnamespaceKeys<
  T extends Record<string, unknown>,
  Namespace extends string,
> = {
  [K in keyof T as K extends `${Namespace}.${infer Rest}` ? Rest : K]: T[K];
};

export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**  The `Cents` scalar type represents the currency USD and is an integer containing the number of cents. $10.23 is represented as a value of 1023 cents.  */
  Cents: { input: any; output: any };
  /**  The `Date` scalar type represents a Date value as specified by [iso8601](https://en.wikipedia.org/wiki/ISO_8601).  */
  Date: { input: any; output: any };
  /**  The `DateTime` scalar type represents a DateTime value as specified by [iso8601](https://en.wikipedia.org/wiki/ISO_8601).  */
  DateTime: { input: any; output: any };
  /**  The `GenericScalar` scalar type represents a generic GraphQL scalar value that could be: String, Boolean, Int, Float, List or Object.  */
  GenericScalar: { input: any; output: any };
  /**  Allows use of a JSON String for input / output from the GraphQL schema. Use of this type is *not recommended* as you lose the benefits of having a defined, static schema (one of the key benefits of GraphQL).  */
  JSONString: { input: any; output: any };
  /**  This scalar extends graphene.List for Range (used for estimated range for cost).  */
  Range: { input: any; output: any };
  /**  The `Time` scalar type represents a Time value as specified by [iso8601](https://en.wikipedia.org/wiki/ISO_8601).  */
  Time: { input: any; output: any };
  /**  Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects in fields, resolvers and input.  */
  UUID: { input: any; output: any };
  CompletedBy: { input: any; output: any };
};
