import { useCallback } from "react";
import { UserType } from "../../types";
import { useSendParticipantStateCallback as useSendParticipantStateCallback } from "../../twilio/messages/useSendParticipantStateCallback";
import { useVisitorId } from "../useVisitorId";

export function useSendVisitorParticipantStateCallback() {
  const visitorId = useVisitorId();

  const sendParticipantState = useSendParticipantStateCallback();

  return useCallback(() => {
    sendParticipantState({
      entityId: visitorId,
      entityType: UserType.CLIENT,
    });
  }, [sendParticipantState, visitorId]);
}
