import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { Icon, MenuItem, Tag } from "@growtherapy/sprout-ui";
import { PUSH_DRAWER_ARIA_CONTROLS_ID } from "../constants";
import { getIsClientListDrawerOpen } from "../utils";
import { useAtomValue } from "jotai";
import { ProviderDrawerState, drawerStateAtom, numWaitingVisitorsAtom } from "../state";
import { useToggleProviderDrawerStateCallback } from "./useToggleProviderDrawerStateCallback";

export function ClientListDrawerControlMenuItem({
  isClientListDrawerOpen,
  onClickClientsButton,
  numberOfWaitingClients,
}: {
  isClientListDrawerOpen: boolean;
  onClickClientsButton: () => void;
  numberOfWaitingClients?: number;
}) {
  return (
    <MenuItem
      aria-controls={PUSH_DRAWER_ARIA_CONTROLS_ID}
      aria-expanded={isClientListDrawerOpen}
      onClick={onClickClientsButton}
      className="flex items-center gap-2"
    >
      <Icon aria-hidden icon={faUser} className="h-4 w-4" />
      <span className="sr-only">
        {isClientListDrawerOpen ? "Close" : "Open"} clients
        {!!numberOfWaitingClients && ", "}
      </span>
      <span aria-hidden>Clients</span>
      {!!numberOfWaitingClients && (
        <>
          <Tag use="lilac">{numberOfWaitingClients}</Tag>
          <span className="sr-only">waiting</span>
        </>
      )}
    </MenuItem>
  );
}

export function ClientListDrawerControlMenuItemWrapper() {
  const isClientListDrawerOpen = getIsClientListDrawerOpen(
    useAtomValue(drawerStateAtom),
  );
  const numberOfWaitingClients = useAtomValue(numWaitingVisitorsAtom);
  const toggleClientListDrawer = useToggleProviderDrawerStateCallback(
    ProviderDrawerState.CLIENTS,
  );

  return (
    <ClientListDrawerControlMenuItem
      isClientListDrawerOpen={isClientListDrawerOpen}
      numberOfWaitingClients={numberOfWaitingClients}
      onClickClientsButton={toggleClientListDrawer}
    />
  );
}
