import {
  CalloutBanner,
  DrawerBody,
  DrawerTitle,
  Heading,
  Text,
} from "@growtherapy/sprout-ui";
import { BulletedList } from "../../components";
import { MockRatingButtons } from "./MockRatingButtons";
import { HelpfulTipsDrawerStates, VisitorDrawerState } from "../state";
import { DrawerHeader } from "../../components/DrawerHeader";
import {
  faHandsHoldingHeart,
  faLightbulbOn,
} from "@fortawesome/pro-solid-svg-icons";

const QUESTIONS_TO_ASK_YOUR_THERAPIST = [
  "Have you dealt with cases similar to mine?",
  "Which type of therapy would you suggest for my condition or situation?",
  "What is the expected duration of therapy?",
  "How do you determine and establish the goals of therapy?",
];

const QUESTIONS_TO_ASK_YOURSELF = [
  "Have I struggled to be myself lately?",
  "Has daily life felt harder lately?",
  "Is there a big choice in my life I have been struggling with?",
  "What was the first thing I thought of when I woke up today?",
  "How am I feeling today?",
];

export function HelpfulTipsDrawer({
  drawerState,
}: {
  drawerState: HelpfulTipsDrawerStates;
}) {
  const shouldShowBeforeSessionTips =
    drawerState === VisitorDrawerState.HELPFUL_TIPS_BEFORE_SESSION;

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>{`Tips for ${
          shouldShowBeforeSessionTips ? "before" : "after"
        } your appointment`}</DrawerTitle>
      </DrawerHeader>
      <DrawerBody>
        {shouldShowBeforeSessionTips ? (
          <div>
            <CalloutBanner
              use="green"
              icon={faHandsHoldingHeart}
              className="mb-6"
            >
              Feeling nervous about therapy is normal, and it is okay if this
              adjustment takes time.
            </CalloutBanner>
            <Heading variant="md" as="h3">
              If this is your first session
            </Heading>
            <Text className="pt-2 pb-4">
              Your therapist will likely be asking questions about yourself,
              your symptoms, coping mechanisms, and therapy goals. Being honest
              and candid with your therapist is one of the best things you can
              do for yourself.
            </Text>
            <Heading variant="md" as="h3">
              Questions you can ask your therapist
            </Heading>
            <BulletedList items={QUESTIONS_TO_ASK_YOUR_THERAPIST} />
            <Heading variant="md" as="h3">
              Questions you can ask yourself
            </Heading>
            <BulletedList items={QUESTIONS_TO_ASK_YOURSELF} />
          </div>
        ) : (
          <div>
            <CalloutBanner use="green" icon={faLightbulbOn} className="mb-6">
              Self-reflection is the key to self-awareness, which has been tied
              to better mental health outcomes.
            </CalloutBanner>
            <Text>
              After your session, you will have a chance to reflect on how your
              session went. It will look something like this:
            </Text>
            <MockRatingButtons />
            <Text>
              Based on your answer, we’ll provide custom recommendations on next
              steps. We’ll be here through every step of the way.
            </Text>
          </div>
        )}
      </DrawerBody>
    </>
  );
}
