import { Tooltip } from "@growtherapy/sprout-ui";
import { ControlPanelButton, IconMenuItem } from "../../components";
import { faPaintbrushPencil } from "@fortawesome/pro-solid-svg-icons";
import { useWhiteboardControl } from "./useWhiteboardControl";

export function WhiteboardControl({
  isWhiteboardMode,
  isLoading,
  onClick,
}: {
  isWhiteboardMode: boolean;
  isLoading?: boolean;
  onClick: () => void;
}) {
  const label = isWhiteboardMode ? "Close whiteboard" : "Open whiteboard";

  return (
    <div className="hidden md:block">
      <Tooltip text={label} childIsInteractive>
        <ControlPanelButton
          aria-label={label}
          loading={isLoading}
          iconDefinition={faPaintbrushPencil}
          data-testid="whiteboard-control"
          onClick={onClick}
        />
      </Tooltip>
    </div>
  );
}

export function WhiteboardControlWrapper() {
  const { canWhiteboard, isLoading, isWhiteboardMode, toggleWhiteboard } =
    useWhiteboardControl();

  if (!canWhiteboard) return;

  return (
    <WhiteboardControl
      isWhiteboardMode={isWhiteboardMode}
      onClick={toggleWhiteboard}
      isLoading={isLoading}
    />
  );
}

export function WhiteboardControlMenuItem({
  isWhiteboardMode,
  onClick,
  isLoading,
}: {
  isWhiteboardMode: boolean;
  onClick: () => void;
  isLoading?: boolean;
}) {
  return (
    <IconMenuItem
      loading={isLoading}
      aria-expanded={isWhiteboardMode}
      onClick={onClick}
      icon={faPaintbrushPencil}
    >
      <span>{isWhiteboardMode ? "Close" : "Open"} whiteboard</span>
    </IconMenuItem>
  );
}

export function WhiteboardControlMenuItemWrapper() {
  const { isWhiteboardMode, isLoading, canWhiteboard, toggleWhiteboard } =
    useWhiteboardControl();

  if (!canWhiteboard) return;

  return (
    <WhiteboardControlMenuItem
      isLoading={isLoading}
      isWhiteboardMode={isWhiteboardMode}
      onClick={toggleWhiteboard}
    />
  );
}
