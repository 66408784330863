import { useAtomValue } from "jotai";
import {
  LocalTrackState,
  localVideoTrackStateAtom,
  participantsAtom,
  twilioRoomAtom,
} from "./state";
import { useCallback, useEffect, useState } from "react";
import {
  NetworkQualityLevel,
  NetworkQualityStats,
  RemoteParticipant,
} from "twilio-video";
import { NetworkQualityChangeHandler } from "./types";
import { parseIdentity, roomToDiagnosticInfo } from "./utils";
import { sendDataDogEvent } from "../datadog/events";
import type { Room } from "twilio-video";
import toast from "react-hot-toast";
import { TrackingEvents, sendLoggingEvents } from "../events";
import { secondsToMilliseconds } from "date-fns";
import { useAtomCallback } from "jotai/utils";
import { UnstableInternetToastWrapper as UnstableInternetToast } from "../components/UnstableInternetToast";
import { logger } from "../datadog/logger";

function emitNetworkQualityEvents(
  type: "local" | "remote",
  twilioRoom: Room,
  stats?: NetworkQualityStats,
  additionalCtx?: object,
) {
  if (!stats) return;

  const ctx = {
    // level
    level: stats.level,

    // audio recv
    "audio.recv.level": stats.audio?.recv,
    "audio.recv.latency.rtt": stats.audio?.recvStats?.latency?.rtt,
    "audio.recv.latency.jitter": stats.audio?.recvStats?.latency?.jitter,
    "audio.recv.bandwidth.actual": stats.audio?.recvStats?.bandwidth?.actual,
    "audio.recv.bandwidth.available":
      stats.audio?.recvStats?.bandwidth?.available,
    "audio.recv.fractionLost": stats.audio?.recvStats?.fractionLost,

    // audio send
    "audio.send.level": stats.audio?.send,
    "audio.send.latency.rtt": stats.audio?.sendStats?.latency?.rtt,
    "audio.send.latency.jitter": stats.audio?.sendStats?.latency?.jitter,
    "audio.send.bandwidth.actual": stats.audio?.sendStats?.bandwidth?.actual,
    "audio.send.bandwidth.available":
      stats.audio?.sendStats?.bandwidth?.available,
    "audio.send.fractionLost": stats.audio?.sendStats?.fractionLost,

    // video recv
    "video.recv.level": stats.video?.recv,
    "video.recv.latency.rtt": stats.video?.recvStats?.latency?.rtt,
    "video.recv.latency.jitter": stats.video?.recvStats?.latency?.jitter,
    "video.recv.bandwidth.actual": stats.video?.recvStats?.bandwidth?.actual,
    "video.recv.bandwidth.available":
      stats.video?.recvStats?.bandwidth?.available,
    "video.recv.fractionLost": stats.video?.recvStats?.fractionLost,

    // video send
    "video.send.level": stats.video?.send,
    "video.send.latency.rtt": stats.video?.sendStats?.latency?.rtt,
    "video.send.latency.jitter": stats.video?.sendStats?.latency?.jitter,
    "video.send.bandwidth.actual": stats.video?.sendStats?.bandwidth?.actual,
    "video.send.bandwidth.available":
      stats.video?.sendStats?.bandwidth?.available,
    "video.send.fractionLost": stats.video?.sendStats?.fractionLost,

    ...roomToDiagnosticInfo(twilioRoom),
    ...additionalCtx,
  };
  sendDataDogEvent(`twilio.networkQuality.${type}`, ctx);
  logger.debug("Network quality changed", ctx);
}

const QUALITY_LEVEL_THRESHOLD = 1;
const POOR_QUALITY_DURATION_THRESHOLD = secondsToMilliseconds(15);

export function useNetworkQualityLogging({
  onOpenSettings,
}: {
  onOpenSettings: () => void;
}) {
  const twilioRoom = useAtomValue(twilioRoomAtom);
  const remoteParticipants = useAtomValue(participantsAtom);
  const getLocalVideoTrackState = useAtomCallback(
    useCallback((get) => get(localVideoTrackStateAtom), []),
  );
  const [sentNetworkQualityToast, setSentNetworkQualityToast] = useState(false);
  const [isNetworkQualityPoor, setIsNetworkQualityPoor] = useState(false);

  useEffect(
    function popInternetUnstableToast() {
      if (isNetworkQualityPoor && !sentNetworkQualityToast) {
        // Trigger the toast if network quality is poor for 15 seconds and user video is on
        const timeoutId = setTimeout(() => {
          if (getLocalVideoTrackState() === LocalTrackState.READY) {
            setSentNetworkQualityToast(true);
            sendLoggingEvents(TrackingEvents.ANY_CONNECTIVTY_ISSUE_NOTIF);
            // TODO: TOAST HOT
            toast.custom(
              ({ id: toastId }) => {
                return (
                  <UnstableInternetToast
                    onClose={() => {
                      toast.remove(toastId);
                    }}
                    onOpenSettings={onOpenSettings}
                  />
                );
              },
              {
                duration: Infinity,
                position: "top-center",
              },
            );
          }
        }, POOR_QUALITY_DURATION_THRESHOLD);

        return () => {
          // If network quality improves, clear the timeout and don't trigger the toast
          clearTimeout(timeoutId);
        };
      }
    },
    [
      isNetworkQualityPoor,
      getLocalVideoTrackState,
      onOpenSettings,
      sentNetworkQualityToast,
    ],
  );

  useEffect(
    function handleLocalNetworkQualityLevelChanges() {
      if (!twilioRoom) return;
      const localParticipant = twilioRoom.localParticipant;

      const logLocalNetworkQualityStatsAndControlToast = (
        networkQualityLevel: NetworkQualityLevel,
        networkQualityStats: NetworkQualityStats,
      ) => {
        emitNetworkQualityEvents("local", twilioRoom, networkQualityStats);

        // Stop updating isNetworkQualityPoor if we aren't going to pop a toast for it
        if (sentNetworkQualityToast) {
          return;
        }

        if (networkQualityLevel > QUALITY_LEVEL_THRESHOLD) {
          setIsNetworkQualityPoor(false);
        }

        if (networkQualityLevel <= QUALITY_LEVEL_THRESHOLD) {
          setIsNetworkQualityPoor(true);
        }
      };

      localParticipant.on(
        "networkQualityLevelChanged",
        logLocalNetworkQualityStatsAndControlToast,
      );
      return function cleanup() {
        localParticipant.off(
          "networkQualityLevelChanged",
          logLocalNetworkQualityStatsAndControlToast,
        );
      };
    },
    [twilioRoom, sentNetworkQualityToast, isNetworkQualityPoor],
  );

  useEffect(
    function handleRemoteNetworkQualityLevelChanges() {
      if (!twilioRoom) return;

      const remoteParticipantNetworkQualityHandlers: Array<
        [RemoteParticipant, NetworkQualityChangeHandler]
      > = [];

      remoteParticipants.forEach((participant) => {
        const logRemoteNetworkQualityStats: NetworkQualityChangeHandler = (
          _networkQualityLevel,
          networkQualityStats,
        ) => {
          const { id: remoteUserId, userType: remoteUserType } = parseIdentity(
            participant.identity,
          );
          emitNetworkQualityEvents("remote", twilioRoom, networkQualityStats, {
            remoteParticipantSid: participant.sid,
            remoteUserId,
            remoteUserType,
          });
        };
        remoteParticipantNetworkQualityHandlers.push([
          participant,
          logRemoteNetworkQualityStats,
        ]);
        participant.on(
          "networkQualityLevelChanged",
          logRemoteNetworkQualityStats,
        );
      });

      return function cleanup() {
        remoteParticipantNetworkQualityHandlers.forEach(
          ([participant, handler]) => {
            participant.off("networkQualityLevelChanged", handler);
          },
        );
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [remoteParticipants, twilioRoom],
  );
}
