import {
  AccessibleSelect,
  Checkbox,
  InputControl,
} from "@growtherapy/sprout-ui";
import classNames from "classnames";
import { EditAppointmentFormValues } from "./EditAppointmentDrawer";
import { useLocalStorage } from "react-use";
import {
  DEFAULT_APPOINTMENT_RECURRING_KEY,
  FREQUENCY_VALIDATION_MESSAGE,
  RECURRENCE_INPUT_ARIA_LABEL,
  REPEAT_OPTIONS,
} from "./constants";
import { checkRecurrenceValidity } from "./utils";
import { pluralizeString } from "../../utils";

export function RecurrenceInput({
  values,
  onUpdateValues,
  setDefaultRecurrence,
}: {
  values: EditAppointmentFormValues;
  onUpdateValues: (values: Partial<EditAppointmentFormValues>) => void;
  setDefaultRecurrence: (value: string) => void;
}) {
  const { isFrequencyValid, isRecurrencesValid } =
    checkRecurrenceValidity(values);
  const isInvalid = !isFrequencyValid || !isRecurrencesValid;
  return (
    <InputControl
      icon="repeat"
      iconClassName="text-lilac-700"
      error={isInvalid ? FREQUENCY_VALIDATION_MESSAGE : undefined}
      aria-label={RECURRENCE_INPUT_ARIA_LABEL}
    >
      <div>
        <div className="flex items-center space-x-1">
          <AccessibleSelect
            mask="exclude"
            onChange={(value): void => {
              onUpdateValues({
                isRecurring: value === REPEAT_OPTIONS[0].value,
              });
              setDefaultRecurrence(
                value === REPEAT_OPTIONS[0].value ? "true" : "false",
              );
            }}
            options={REPEAT_OPTIONS}
            value={
              values.isRecurring
                ? REPEAT_OPTIONS[0].value
                : REPEAT_OPTIONS[1].value
            }
            className="w-fit"
          />
          {values.isRecurring && (
            <>
              <span>every</span>
              <input
                className={classNames("form-input w-18", {
                  error: !isFrequencyValid,
                })}
                type="number"
                min={1}
                max={4}
                value={values.weeksBetween ?? 1}
                onChange={({ target: { value } }): void =>
                  onUpdateValues({ weeksBetween: parseInt(value) })
                }
                data-testid="schedule-event-repeats-input__weeks-between"
              />
              <span>{pluralizeString(values.weeksBetween ?? 0, "week")}</span>
              {!values.indefinite && (
                <>
                  <input
                    className={classNames("form-input w-18", {
                      error: !isRecurrencesValid,
                    })}
                    type="number"
                    min={1}
                    max={20}
                    value={values.numRecurrences ?? 1}
                    onChange={({ target: { value } }): void =>
                      onUpdateValues({ numRecurrences: parseInt(value) })
                    }
                    data-testid="schedule-event-repeats-input__num-recurrences"
                  />
                  <span>{`time${
                    values.numRecurrences && values.numRecurrences > 1
                      ? "s"
                      : ""
                  }`}</span>
                </>
              )}
            </>
          )}
        </div>
        {values.isRecurring && (
          <Checkbox
            className="mt-2 w-fit"
            checked={!!values.indefinite}
            iconClassName="ml-0 mr-2"
            label="Repeats indefinitely"
            onChange={({ target: { checked } }): void =>
              onUpdateValues({ indefinite: checked })
            }
            data-testid="schedule-event-repeats-indefinitely-checkbox"
          />
        )}
      </div>
    </InputControl>
  );
}

export function RecurrenceInputWrapper({
  values,
  onUpdateValues,
}: {
  values: EditAppointmentFormValues;
  onUpdateValues: (values: Partial<EditAppointmentFormValues>) => void;
}) {
  const [_defaultRecurrence, setDefaultRecurrence] = useLocalStorage(
    DEFAULT_APPOINTMENT_RECURRING_KEY,
    "true",
  );

  return (
    <RecurrenceInput
      values={values}
      onUpdateValues={onUpdateValues}
      setDefaultRecurrence={setDefaultRecurrence}
    />
  );
}
