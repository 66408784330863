import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { localDataTrackAtom } from "../state";
import { DataTrackMessage } from "../types";
import { logger as baseLogger, logger } from "../../datadog/logger";
import { getMaxDataTrackMessageSizeBytes } from "../config";
import { gracefullyCompressJson } from "../../compression/lazyStringCompression";

/**
 * Formats a data track message object into a compressed JSON string that can be
 * sent over the data track.
 *
 * Returns `null` if the message is too large to send.
 */
export async function formatDataTrackMessage(
  message: DataTrackMessage,
  maxMessageSizeBytes: number,
  { logger = baseLogger }: { logger?: typeof baseLogger } = {},
) {
  const [compressedJson, error] = await gracefullyCompressJson(message);

  if (error) {
    logger.error("Failed to compress datatrack message", {}, error);
    return null;
  }

  const messageSizeBytes = new TextEncoder().encode(compressedJson).length;
  const isMessageWithinSizeLimit = messageSizeBytes <= maxMessageSizeBytes;
  if (!isMessageWithinSizeLimit) {
    logger.error("DataTrack message too large", {
      messageSizeBytes,
      maxMessageSizeBytes,
    });
    return null;
  }

  return compressedJson;
}

export function useSendDataTrackMessageCallback() {
  const getLocalDataTrack = useAtomCallback(
    useCallback((get) => get(localDataTrackAtom), []),
  );

  return useCallback(
    async (message: DataTrackMessage) => {
      const dataTrack = getLocalDataTrack().track;
      if (!dataTrack) {
        logger.warn("Unable to send data track message: missing data track");
        return false;
      }

      const maxMessageSizeBytes = getMaxDataTrackMessageSizeBytes();
      if (maxMessageSizeBytes) {
        const compressedJson = await formatDataTrackMessage(
          message,
          maxMessageSizeBytes,
        );
        if (!compressedJson) {
          return false;
        }
        dataTrack.send(compressedJson);
      } else {
        dataTrack.send(JSON.stringify(message));
      }

      return true;
    },
    [getLocalDataTrack],
  );
}
