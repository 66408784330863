import { useCallback } from "react";
import {
  drawerStateAtom,
  isEditingFormOrMeasureAtom,
  selectedClientUserShortIdAtom,
} from "../state";
import { useSetAtom } from "jotai";
import { useSetMeasureValues } from "@grow-therapy-team/seedling-components";
import { useAtomCallback } from "jotai/utils";

export function useClearMeasureCallback() {
  const setSelectedClientUserShortId = useSetAtom(
    selectedClientUserShortIdAtom,
  );
  const setMeasureValues = useSetMeasureValues();
  const setDrawerState = useSetAtom(drawerStateAtom);
  const getIsEditingFormOrMeasure = useAtomCallback(
    useCallback((get) => get(isEditingFormOrMeasureAtom), []),
  );

  return useCallback(() => {
    setSelectedClientUserShortId(undefined);
    setMeasureValues({});
    if (getIsEditingFormOrMeasure()) {
      setDrawerState(null);
    }
  }, [
    getIsEditingFormOrMeasure,
    setSelectedClientUserShortId,
    setMeasureValues,
    setDrawerState,
  ]);
}
