import { toast } from "../../toasts";
import { useParams } from "react-router-dom";
import { minutesToMilliseconds } from "date-fns";
import { LeaveSessionNudgeToast } from "./LeaveSessionNudgeToast";
import { useEndOfSessionToast } from "../../hooks/useEndOfSessionToast";

const NUDGE_PRIOR_END_TIME_MS = minutesToMilliseconds(2);
const TOAST_TIMEOUT = minutesToMilliseconds(1);

export function useLeaveSessionNudgeToast() {
  const { providerShortId, patientShortId } = useParams();

  const showToast = () => {
    toast.enqueue(
      (ariaProps) => {
        return <LeaveSessionNudgeToast {...ariaProps} />;
      },
      { timeout: TOAST_TIMEOUT, position: "bottom-right" },
    );
  };

  useEndOfSessionToast(
    providerShortId,
    patientShortId,
    showToast,
    NUDGE_PRIOR_END_TIME_MS,
    true,
  );
}
