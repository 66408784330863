import { Button, ButtonProps } from "@growtherapy/sprout-ui";
import { DrawerButton, DrawerButtonProps } from "../components/DrawerButton";
import { useAtomValue } from "jotai";
import { VisitorDrawerState, visitorDrawerStateAtom } from "./state";
import { DrawerId } from "./constants";
import { FC } from "react";

/**
 * A stateful button component that can be used to accessibily open and close a
 * drawer in the "visitor" domain.
 */
export function VisitorDrawerButton({
  drawerId,
  forDrawerStates,
  closedAriaLabel,
  openedAriaLabel,
  as: ButtonComponent = Button,
  ...otherProps
}: Omit<
  DrawerButtonProps<ButtonProps>,
  "as" | "forDrawerStates" | "drawerId"
> & {
  forDrawerStates: VisitorDrawerState;
  closedAriaLabel: string;
  openedAriaLabel: string;
  drawerId: DrawerId;
  as?: FC<ButtonProps>;
}) {
  const drawerState = useAtomValue(visitorDrawerStateAtom);
  const isDrawerOpen = drawerState === forDrawerStates;
  const ariaLabel = isDrawerOpen ? openedAriaLabel : closedAriaLabel;

  return (
    <DrawerButton
      {...otherProps}
      as={ButtonComponent}
      forDrawerStates={forDrawerStates}
      aria-label={ariaLabel}
      aria-expanded={isDrawerOpen}
      aria-controls={drawerId}
    />
  );
}
