import blob1 from "../../../assets/vibes-keys/blob1.svg";
import blob2 from "../../../assets/vibes-keys/blob2.svg";
import blob3 from "../../../assets/vibes-keys/blob3.svg";
import blob4 from "../../../assets/vibes-keys/blob4.svg";
import blob5 from "../../../assets/vibes-keys/blob5.svg";

export const GO_BACK_CTA = "< Go Back";
export const UNWIND_WHILE_YOU_WAIT = "Unwind while you wait";
export const INSTRUCTIONS =
  "To make music, click on the shapes or use the corresponding keyboard keys.";

export const KEY_BLOBS = [
  { letter: "A", image: blob1, note: "C4" },
  { letter: "D", image: blob2, note: "E4" },
  { letter: "G", image: blob3, note: "G4" },
  { letter: "J", image: blob4, note: "B4" },
  { letter: "L", image: blob5, note: "C5" },
];
