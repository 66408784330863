import {
  VisitorAdditionalOptionsMenuWrapper as VisitorAdditionalOptionsMenu,
  VisitorAudioControlWrapper as VisitorAudioControl,
  VisitorVideoControlWrapper as VisitorVideoControl,
} from "../../controls";
import {
  VisitorWaitingChatDrawerButtonWrapper as VisitorWaitingChatDrawerButton,
  VisitorWaitingChatDrawerMenuItemWrapper as VisitorWaitingChatDrawerMenuItem,
} from "./VisitorWaitingChatDrawerControl";
import { useObscuredVisitorContentProps } from "../../a11y";
import {
  CentralSessionControls,
  SessionControl,
  SessionControlsLayout,
} from "../../../components";
import {
  VisitorWaitingSettingsControlButton,
  VisitorWaitingSettingsDrawerControlMenuItem,
} from "./VisitorWaitingSettingsDrawerControl";
import { ToolbarSeparator } from "../../../components/ToolbarSeparator";

const centralControls: SessionControl[] = [
  {
    key: "audio",
    toolbarComponent: VisitorAudioControl,
  },
  {
    key: "video",
    toolbarComponent: VisitorVideoControl,
  },
  {
    key: "av-separator",
    toolbarComponent: ToolbarSeparator,
  },
  {
    key: "chat",
    toolbarComponent: VisitorWaitingChatDrawerButton,
    additionalOptionsMenuItemComponent: VisitorWaitingChatDrawerMenuItem,
  },
  {
    key: "settings",
    toolbarComponent: VisitorWaitingSettingsControlButton,
    additionalOptionsMenuItemComponent:
      VisitorWaitingSettingsDrawerControlMenuItem,
  },
];

export function VisitorWaitingSessionControlsWrapper() {
  const obscuredContentProps = useObscuredVisitorContentProps();

  return (
    <SessionControlsLayout {...obscuredContentProps} className="order-3">
      <CentralSessionControls
        controls={centralControls}
        additionalOptionsMenuComponent={VisitorAdditionalOptionsMenu}
      />
    </SessionControlsLayout>
  );
}
