import { useCallback, useEffect } from "react";

export const useOnInactiveScreenDetected = (
  onLeaveCallback: () => void,
  onReturnCallback?: () => void,
) => {
  const handleOnVisibilityChange = useCallback(() => {
    if (document.hidden) {
      onLeaveCallback();
    } else {
      onReturnCallback?.();
    }
  }, [onLeaveCallback, onReturnCallback]);

  useEffect(
    function handleMobileLockOrTabAway() {
      const abortController = new AbortController();
      document.addEventListener("visibilitychange", handleOnVisibilityChange, {
        signal: abortController.signal,
      });

      return () => abortController.abort();
    },
    [handleOnVisibilityChange],
  );
};
