import {
  Button,
  DRAWER_TITLE_ID,
  DrawerBody,
  IconBadge,
  IconBadgeUse,
  Text,
} from "@growtherapy/sprout-ui";
import { DrawerHeader } from "../../../components/DrawerHeader";
import { useSetAtom } from "jotai";
import { ProviderDrawerState, drawerStateAtom } from "../../state";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

export function PendingFormsCompletedDrawer() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  const goToClientInfoDrawer = () =>
    setDrawerState(ProviderDrawerState.CLIENT_INFORMATION);

  return (
    <>
      <DrawerHeader>
        <Text className="font-medium" variant="lg" id={DRAWER_TITLE_ID}>
          Measures complete
        </Text>
      </DrawerHeader>
      <DrawerBody>
        <div className="flex flex-col gap-8">
          <div className="w-full flex gap-4">
            <IconBadge aria-hidden use={IconBadgeUse.Green} icon={faCheck} />
            <Text>
              Your client will receive an invitation periodically to re-assess
              their symptoms.
            </Text>
          </div>
          <Button
            className="self-center"
            onClick={goToClientInfoDrawer}
            use="secondary"
          >
            Return to client info
          </Button>
        </div>
      </DrawerBody>
    </>
  );
}
