import { createContext } from "react";
import { ProviderDrawerState } from "../provider/state";
import { VisitorDrawerState } from "../visitor/state";

export type DrawerToButtonRefMap = Map<
  ProviderDrawerState | VisitorDrawerState,
  React.RefObject<HTMLButtonElement>
>;

type ButtonRefsContextType = {
  buttonRefs: React.RefObject<DrawerToButtonRefMap>;
  addButtonRef: (
    key: ProviderDrawerState | VisitorDrawerState,
    ref: React.RefObject<HTMLButtonElement>,
  ) => void;
  removeButtonRef: (key: ProviderDrawerState | VisitorDrawerState) => void;
};

export const DEFAULT_BUTTON_REFS_CONTEXT_VALUE: ButtonRefsContextType = {
  buttonRefs: { current: new Map() },
  addButtonRef: () => {},
  removeButtonRef: () => {},
};

/**
 * Context to share and manage references to buttons that open drawers across
 * the application. References are indexed by the drawer state(s) that they can
 * open.
 */
export const ButtonRefsContext = createContext<ButtonRefsContextType>(
  DEFAULT_BUTTON_REFS_CONTEXT_VALUE,
);
