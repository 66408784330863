import { MeasureBundleObject } from "@grow-therapy-team/seedling-components";

export type BundleCompletionState = {
  state: "completed" | "partially_completed" | "incomplete";
  lastCompletion: Date | null;
};

export function sortMeasureBundles(
  measureBundles: MeasureBundleObject[],
  dateField: keyof MeasureBundleObject = "sentAt",
) {
  return [...measureBundles].sort(
    (a: MeasureBundleObject, b: MeasureBundleObject) => {
      return (
        new Date(b[dateField] || 0).getTime() -
        new Date(a[dateField] || 0).getTime()
      );
    },
  );
}

export function getBundleCompletionState(
  bundles: MeasureBundleObject[],
): BundleCompletionState {
  if (!bundles || bundles.length === 0) {
    return { state: "incomplete", lastCompletion: null };
  }

  const mostRecentBundle = bundles[0]!;
  if (mostRecentBundle.completedAt) {
    return {
      state: "completed",
      lastCompletion: new Date(mostRecentBundle.completedAt),
    };
  }

  const completedBundleItem = mostRecentBundle.measureBundleItems.find(
    (item) => item?.measureResponse?.completedAt,
  );
  if (completedBundleItem) {
    return {
      state: "partially_completed",
      lastCompletion: new Date(
        completedBundleItem.measureResponse!.completedAt!,
      ),
    };
  }

  const lastCompletion = bundles.find(
    (bundle) => bundle?.completedAt,
  )?.completedAt;
  return {
    state: "incomplete",
    lastCompletion: lastCompletion ? new Date(lastCompletion) : null,
  };
}

/**
 * Returns the measure bundle and measure bundle item that corresponds to the
 * given measure response short ID.
 */
export function findMeasureBundleInfoByResponseShortId(
  measureBundles: MeasureBundleObject[],
  measureResponseShortId: string,
) {
  for (const measureBundle of measureBundles) {
    for (const measureBundleItem of measureBundle.measureBundleItems) {
      if (
        measureBundleItem?.measureResponse?.measureResponseShortId ===
        measureResponseShortId
      ) {
        return { measureBundle, measureBundleItem };
      }
    }
  }
  return {};
}
