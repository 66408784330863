import {
  MeasureInsightTableConnected,
  type MeasureInsightTableConnectedProps,
} from "@grow-therapy-team/seedling-components";
import { Card } from "@growtherapy/sprout-ui";
import { useAtomValue, useSetAtom } from "jotai";
import { forwardRef, useCallback } from "react";
import { useCaptureComponentView } from "../../hooks/useCaptureComponentView";
import { useTrackEvent } from "../../segment/segment";
import { EventTypeEnum, UserViewedParams } from "../../segment/types";
import {
  ProviderDrawerState,
  drawerStateAtom,
  providerShortIdAtom,
  selectedBundleAtom,
  selectedClientInfoAtom,
  selectedClientUserShortIdAtom,
  selectedMeasureBundleItemIdAtom,
} from "../state";
import { useGetPatientByShortId } from "../useGetPatientByShortId";
import { PROGRESS_TITLE } from "./constants";
import { findMeasureBundleInfoByResponseShortId } from "../measures/utils";

type WrapperProps = Omit<
  Partial<MeasureInsightTableConnectedProps>,
  "clientUserShortId" | "providerShortId"
>;

export const ClientInformationProgress = forwardRef<
  HTMLDivElement,
  MeasureInsightTableConnectedProps
>((measureInsightTableProps, ref) => {
  return (
    <div className="flex flex-col gap-2" ref={ref}>
      <Card title={PROGRESS_TITLE} titleAs="h3">
        <MeasureInsightTableConnected {...measureInsightTableProps} />
      </Card>
    </div>
  );
});
ClientInformationProgress.displayName = "ClientInformationProgress";

// statically defined because:
// 1) we only show the table view of measure progress
// 2) this component only renders in-session
const LOGGING_PROPERTIES: UserViewedParams<EventTypeEnum.MEASURE_PROGRESS_VIEWED> =
  { measureProgressView: "table", inSession: true };

export function ClientInformationProgressWrapper({
  isProvider = true,
  isShownWithinPaperworkFlow = false,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  ...passthroughProps
}: WrapperProps) {
  const clientUserShortId = useAtomValue(selectedClientUserShortIdAtom);
  const providerShortId = useAtomValue(providerShortIdAtom);
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setSelectedBundle = useSetAtom(selectedBundleAtom);
  const setSelectedMeasureBundleItemId = useSetAtom(
    selectedMeasureBundleItemIdAtom,
  );

  const selectedClientInfo = useAtomValue(selectedClientInfoAtom);
  const patientShortId = selectedClientInfo?.patientShortId;
  // useGetPatientByShortId returns a ton of data, but we are likely to be
  // fetching it later anyway, so given that subsequent calls return cached
  // data, it shouldn't add too much overhead calling it here.
  const { data } = useGetPatientByShortId(patientShortId);
  const measureBundles = data?.patientChart?.measureBundles;
  const hasCompletedMeasureResponses =
    data?.patientChart?.hasCompletedMeasureResponses;

  // Segment events
  const { userClicked } = useTrackEvent();
  const { ref } = useCaptureComponentView({
    type: EventTypeEnum.MEASURE_PROGRESS_VIEWED,
    properties: LOGGING_PROPERTIES,
  });

  const handleMeasureResponseClick = useCallback(
    (measureResponseShortId: string) => {
      userClicked(EventTypeEnum.MEASURE_PROGRESS_CLICKED, LOGGING_PROPERTIES);

      if (!measureResponseShortId || !measureBundles) return;

      const {
        measureBundle: selectedMeasureBundle,
        measureBundleItem: selectedMeasureBundleItem,
      } = findMeasureBundleInfoByResponseShortId(
        measureBundles,
        measureResponseShortId,
      );

      if (!selectedMeasureBundle || !selectedMeasureBundleItem) return;

      setSelectedBundle(selectedMeasureBundle);
      setSelectedMeasureBundleItemId(
        selectedMeasureBundleItem.measureBundleItemId?.toString() ?? "",
      );
      setDrawerState(ProviderDrawerState.MEASURE_RESPONSES);
    },
    [
      measureBundles,
      setDrawerState,
      setSelectedBundle,
      setSelectedMeasureBundleItemId,
      userClicked,
    ],
  );

  if (!clientUserShortId || !providerShortId || !hasCompletedMeasureResponses) {
    return null;
  }

  return (
    <ClientInformationProgress
      {...passthroughProps}
      shouldAbbreviateScoreCategoryLabels={true}
      clientUserShortId={clientUserShortId}
      providerShortId={providerShortId}
      isProvider={isProvider}
      isShownWithinPaperworkFlow={isShownWithinPaperworkFlow}
      timezone={timezone}
      onClick={measureBundles ? handleMeasureResponseClick : undefined}
      ref={ref}
    />
  );
}
