import { atom } from "jotai";
import { createDerivedWritableAtom } from "../../utils";

export enum WhiteboardUserState {
  IDLE = "idle",
  EDITING = "editing",
}

type WhiteboardAtom = {
  userState: WhiteboardUserState | null;
};

const DEFAULT_WHITEBOARD_ATOM_VALUE: WhiteboardAtom = {
  userState: null,
};

export const whiteboardAtom = atom<WhiteboardAtom>(
  DEFAULT_WHITEBOARD_ATOM_VALUE,
);

export const whiteboardUserStateAtom = createDerivedWritableAtom(
  whiteboardAtom,
  "userState",
);

export const isWhiteboardUserEditingAtom = atom<boolean>(
  (get) => get(whiteboardUserStateAtom) === WhiteboardUserState.EDITING,
);
