import { UserType } from "../../types";
import { useVisitorId } from "../useVisitorId";
import { LazyWhiteboardCanvas } from "../../components/whiteboard/LazyWhiteboardCanvas";

export function VisitorWhiteboard() {
  const visitorId = useVisitorId();
  return (
    <LazyWhiteboardCanvas
      senderIdentity={{
        entityType: UserType.CLIENT,
        entityId: visitorId,
      }}
    />
  );
}
