import { useCallback } from "react";
import {
  DataTrackAppStateProperty,
  DataTrackAppState,
} from "../../twilio/messages/types";
import { otherPatientConsentedInSessionAtom } from "../session-recording/state";
import { useSetAtom } from "jotai";
import {
  ConsentStatus,
  DataTrackAppStateMessage,
  DataTrackSender,
} from "../../twilio/types";
import { UserType } from "../../types";
import {
  DataTrackUpdateStatus,
  resolveCorrectConsentState,
} from "../../twilio/messages/utils";
import {
  isRecordingAtom,
  isWhiteboardModeAtom,
  patientConsentAtom,
  providerConsentAtom,
} from "../../twilio/messages/state";
import { useAtomCallback } from "jotai/utils";
import toast from "react-hot-toast";
import { ConsentDeclinedToast } from "../session-recording/ConsentDeclinedToast";
import { secondsToMilliseconds } from "date-fns";
import useScreenShareCallbacks from "../../twilio/controls/useScreenShareCallbacks";

function useHandleRecordingUpdateCallback() {
  const setIsRecording = useSetAtom(isRecordingAtom);

  return useCallback(
    (receivedState: DataTrackAppState) => {
      setIsRecording(receivedState.isRecording);
    },
    [setIsRecording],
  );
}

function useHandleIsWhiteboardModeUpdateCallback() {
  const { stopScreenShare } = useScreenShareCallbacks();
  const setIsWhiteboardMode = useSetAtom(isWhiteboardModeAtom);

  return useCallback(
    (receivedState: DataTrackAppState) => {
      setIsWhiteboardMode(receivedState.isWhiteboardMode);
      if (receivedState.isWhiteboardMode.value) {
        stopScreenShare();
      }
    },
    [setIsWhiteboardMode, stopScreenShare],
  );
}

function useHandleMultiParticipantConsentCallback() {
  const setOtherPatientConsentedInSession = useSetAtom(
    otherPatientConsentedInSessionAtom,
  );

  return useCallback(
    (correctConsentState: DataTrackAppStateProperty<ConsentStatus>) => {
      /** Notifies the user if another patient declined consent */
      if (correctConsentState.value === ConsentStatus.SOFT_NO) {
        // TODO: TOAST HOT
        toast.custom(
          (t) => (
            <ConsentDeclinedToast
              onClose={() => toast.remove(t.id)}
              visitorDeclinedConsent={false}
            />
          ),
          {
            position: "bottom-center",
            duration: secondsToMilliseconds(30),
          },
        );
      }
      /** Notifies the user if another patient consented */
      if (correctConsentState.value === ConsentStatus.OPTED_IN) {
        setOtherPatientConsentedInSession(true);
      }
    },
    [setOtherPatientConsentedInSession],
  );
}

function useHandlePatientConsentUpdateCallback() {
  const getCurrentPatientConsent = useAtomCallback(
    useCallback((get) => get(patientConsentAtom), []),
  );

  const setPatientConsent = useSetAtom(patientConsentAtom);

  const handleMultiParticipantConsent =
    useHandleMultiParticipantConsentCallback();

  return useCallback(
    (receivedParticipantState: DataTrackAppState, sender: DataTrackSender) => {
      const currentConsentState = getCurrentPatientConsent();
      const [correctConsentState, comparisonResult] =
        resolveCorrectConsentState({
          receivedState: receivedParticipantState.patient.consent,
          userState: currentConsentState,
        });

      switch (comparisonResult) {
        case DataTrackUpdateStatus.LAST_UPDATED_CHANGED:
          setPatientConsent(correctConsentState);
          return;
        case DataTrackUpdateStatus.VALUE_AND_LAST_UPDATED_CHANGED:
          setPatientConsent(correctConsentState);
          if (sender.entityType === UserType.CLIENT) {
            handleMultiParticipantConsent(correctConsentState);
          }
          return;
        case DataTrackUpdateStatus.NO_UPDATE_NEEDED:
        default:
          return;
      }
    },
    [
      getCurrentPatientConsent,
      handleMultiParticipantConsent,
      setPatientConsent,
    ],
  );
}

export function useHandleProviderConsentUpdateCallback() {
  const setProviderConsent = useSetAtom(providerConsentAtom);

  return useCallback(
    (receivedParticipantState: DataTrackAppState) => {
      setProviderConsent(receivedParticipantState.provider.consent);
    },
    [setProviderConsent],
  );
}

export function useUpdateDataTrackAppStateCallback() {
  const handlePatientConsentUpdate = useHandlePatientConsentUpdateCallback();
  const handleProviderConsentUpdate = useHandleProviderConsentUpdateCallback();
  const handleRecordingUpdate = useHandleRecordingUpdateCallback();
  const handleIsWhiteboardModeUpdate =
    useHandleIsWhiteboardModeUpdateCallback();

  return useCallback(
    (message: DataTrackAppStateMessage) => {
      const { participantState, sender } = message;
      handlePatientConsentUpdate(participantState, sender);
      handleProviderConsentUpdate(participantState);
      handleRecordingUpdate(participantState);
      handleIsWhiteboardModeUpdate(participantState);
    },
    [
      handlePatientConsentUpdate,
      handleRecordingUpdate,
      handleProviderConsentUpdate,
      handleIsWhiteboardModeUpdate,
    ],
  );
}
