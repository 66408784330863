import { atom, useAtom } from "jotai";
import { useCallback, useEffect, useRef, useState } from "react";
import { ActionType } from "./types";
import { memoize } from "../../../utils";
import {
  IconDefinition,
  faVolume,
  faVolumeOff,
} from "@fortawesome/pro-solid-svg-icons";
import { useAtomCallback } from "jotai/utils";

const loadTone = memoize(async () => await import("tone"));

const musicStateAtom = atom<ActionType>(ActionType.PAUSE);
const FADE_TIME_SECONDS = 2;
type ToneModuleType = typeof import("tone");

type PlayerInstance = InstanceType<ToneModuleType["Player"]>;

type MusicPlayersType = {
  currentPlayer: PlayerInstance | null;
  nextPlayer: PlayerInstance | null;
};

let globalPlayers: MusicPlayersType | null = null;

export async function initializeGlobalPlayers(
  ToneModule: ToneModuleType,
): Promise<MusicPlayersType> {
  /**
   * Initializes and returns the global Tone.Player instances used for background music playback.
   *
   * This function implements a singleton pattern. On its first invocation, it:
   * - Preloads an audio buffer from the specified URL.
   * - Creates two Tone.Player instances:
   *   - `currentPlayer` for the currently playing track.
   *   - `nextPlayer` for the upcoming track, enabling seamless crossfading.
   *
   * Why two players?
   * - To ensure seamless looping, one player starts fading in while the other
   *   is still playing, preventing gaps or sudden silence between loops.
   * - The next track is preloaded and ready to play before the current one ends,
   *   ensuring continuous playback without loading delays.
   *
   */
  if (!globalPlayers) {
    const buffer = await ToneModule.ToneAudioBuffer.fromUrl(
      "/background-music/background-music.mp3",
    );
    const currentPlayer = new ToneModule.Player({
      url: buffer,
      loop: false,
      fadeIn: FADE_TIME_SECONDS,
      fadeOut: FADE_TIME_SECONDS,
    }).toDestination();

    const nextPlayer = new ToneModule.Player({
      url: buffer,
      loop: false,
      fadeIn: FADE_TIME_SECONDS,
      fadeOut: FADE_TIME_SECONDS,
    }).toDestination();

    globalPlayers = { currentPlayer, nextPlayer };
  }
  return globalPlayers;
}

export function getNextMusicAction(current: ActionType): ActionType {
  return current === ActionType.PAUSE ? ActionType.PLAY : ActionType.PAUSE;
}

export const MUSIC_ACTION_ICONS: Record<ActionType, IconDefinition> = {
  Play: faVolume,
  Pause: faVolumeOff,
};

export const useBackgroundMusicPlayback = ({
  toneLoader = loadTone,
}: { toneLoader?: () => Promise<ToneModuleType> } = {}) => {
  const [ToneModule, setToneModule] = useState<ToneModuleType | null>(null);
  const [currentMusicState, setMusicState] = useAtom(musicStateAtom);

  const getCurrentMusicState = useAtomCallback(
    useCallback((get) => get(musicStateAtom), []),
  );

  useEffect(() => {
    toneLoader().then(setToneModule);
  }, [toneLoader]);

  const playersRef = useRef<MusicPlayersType>({
    currentPlayer: null,
    nextPlayer: null,
  });

  const handlePlay = useCallback(() => {
    /**
     * Starts background music playback with seamless crossfading between two players.
     *
     * - Initializes playback using two `Tone.Player` instances (current and next).
     * - Uses `scheduleRepeat` to alternate between `current` and `next` players.
     * - Prevents gaps by starting and fading in the next track before the current one ends.
     * - The fade in and fade outs are built into Tone.Player and happen automatically on .start() and .stop()
     *
     *
     * Behavior:
     * - The first track starts immediately.
     * - The next track is scheduled to start before the initial loop ends, ensuring a smooth transition.
     * - The current track is stopped after it's duration is met, allowing for a natural fade-out.
     */
    if (getCurrentMusicState() === ActionType.PLAY || !ToneModule) return;

    const transport = ToneModule.getTransport();
    const { currentPlayer, nextPlayer } = playersRef.current;
    if (!currentPlayer || !nextPlayer) return;
    currentPlayer.start();

    const loopDuration = currentPlayer.buffer?.duration || 0;

    /**
     * earlyStartTimeSeconds is an arbitrarily chosen value.
     * The next iteration of the looped audio starts playing this many seconds
     * prior to the existing audio track to give the effect of a seamless piece of audio
     */
    const earlyStartTimeSeconds = 10;

    transport.scheduleRepeat(
      (time: number) => {
        // Since players are globals, we can directly use them.
        const localCurrent = currentPlayer;
        const localNext = nextPlayer;

        localNext.start(time);
        transport.scheduleOnce(() => {
          localCurrent.stop();
        }, time + loopDuration);
      },
      loopDuration - earlyStartTimeSeconds,
      "0",
    );

    if (transport.state !== "started") {
      transport.start();
    }
    setMusicState(ActionType.PLAY);
  }, [getCurrentMusicState, setMusicState, ToneModule]);

  const handlePause = useCallback(() => {
    /**
     * Pauses background music playback and stops the looping mechanism.
     *
     * - Stops both `Tone.Player` instances immediately.
     * - Cancels all scheduled transport events to prevent unintended restarts.
     * - Updates the music state to `"Pause"` to reflect the current status.
     *
     * Behavior:
     * - Music playback stops immediately, cutting off the current track.
     * - Any scheduled transitions (such as crossfades or looping) are canceled.
     * - When resumed via `playMusic()`, playback starts from the beginning (not from the paused position).
     *
     */
    if (getCurrentMusicState() === ActionType.PAUSE || !ToneModule) return;

    const transport = ToneModule.getTransport();
    transport.cancel();
    transport.stop();
    playersRef.current.currentPlayer?.stop();
    playersRef.current.nextPlayer?.stop();
    setMusicState(ActionType.PAUSE);
  }, [getCurrentMusicState, setMusicState, ToneModule]);

  useEffect(
    function initializePlayers() {
      if (!ToneModule) return;
      initializeGlobalPlayers(ToneModule).then((players) => {
        playersRef.current = players;
      });

      return () => {
        playersRef.current?.currentPlayer?.stop();
        playersRef.current.nextPlayer?.stop();
      };
    },
    [ToneModule],
  );

  const toggleMusic = useCallback(() => {
    const nextState = getNextMusicAction(
      getCurrentMusicState() ?? ActionType.PAUSE,
    );
    nextState === ActionType.PLAY ? handlePlay() : handlePause();
  }, [handlePlay, handlePause, getCurrentMusicState]);

  return {
    toggleMusic,
    handlePlay,
    handlePause,
    currentMusicState,
  };
};
