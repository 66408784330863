import React from "react";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { type ToastRenderFnProps, ToastVariant } from "./types";
import { ToastCloseButton } from "./ToastCloseButton";
import {
  faCircleCheck,
  faCircleInfo,
  faHexagonExclamation,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "@growtherapy/sprout-ui";

const VARIANT_TO_ICON: Record<ToastVariant, IconDefinition | null> = {
  [ToastVariant.Success]: faCircleCheck,
  [ToastVariant.Error]: faHexagonExclamation,
  [ToastVariant.Info]: faCircleInfo,
};

export type ToastProps = React.PropsWithChildren<{
  className?: string;
  closeButtonPlacement?: "top" | "center";
  /** Provide closeButtonProps to show button for user to dismiss toast. */
  closeButtonProps?: ToastRenderFnProps["closeButtonProps"];
  closeButtonSize?: "xs" | "sm";
  ["data-testid"]?: string;
  icon?: IconDefinition;
  variant?: ToastVariant;
}>;

// NOTE: This component is lifted from `client/src/Toast` and modified to
// integrate with @react-aria's toast ARIA props. Once the migration is
// complete, `Toast` will be totally superseded by this component.
/**
 * Renders a basic, styled toast container with the appropriate aria props and
 * an optional close button to dismiss the toast.
 */
export function Toast({
  children,
  className,
  closeButtonProps,
  closeButtonPlacement = "center",
  closeButtonSize = "xs",
  ["data-testid"]: dataTestId,
  icon: iconProp,
  variant,
}: ToastProps) {
  const icon = iconProp ?? (variant ? VARIANT_TO_ICON[variant] : null);
  return (
    <div
      data-testid={dataTestId}
      className={twMerge(
        "bg-neutral-200",
        classNames(
          {
            "bg-green-100": variant === ToastVariant.Success,
            "bg-coral-300": variant === ToastVariant.Error,
          },
          "w-full sm:w-auto p-4 rounded-lg flex justify-between items-center gap-3 border-[1px] border-neutral-800 border-solid shadow-md",
        ),
        className,
      )}
    >
      <div className="flex grow justify-start items-center gap-3 min-w-0">
        {icon && <Icon icon={icon} size="lg" className="text-neutral-800" />}
        {children}
      </div>
      {!!closeButtonProps && (
        <ToastCloseButton
          {...closeButtonProps}
          placement={closeButtonPlacement}
          size={closeButtonSize}
        />
      )}
    </div>
  );
}
