import {
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/pro-solid-svg-icons";
import { Button, Icon, Tooltip, TooltipProps } from "@growtherapy/sprout-ui";
import {
  PopoverButton as BasePopoverButton,
  PopoverButtonProps as BasePopoverButtonProps,
} from "@headlessui/react";
import {
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  HTMLAttributes,
  ReactNode,
  RefAttributes,
  useCallback,
  useRef,
  useState,
} from "react";
import { useKeyPressEvent } from "react-use";

export interface PopoverButtonAsProps
  extends HTMLAttributes<HTMLButtonElement>,
    RefAttributes<HTMLButtonElement> {
  /**
   * Informs the toggle button whether the popover is open or closed
   */
  "data-open"?: boolean;
}

export interface PopoverButtonProps extends Omit<BasePopoverButtonProps, "as"> {
  as?: ForwardRefExoticComponent<PopoverButtonAsProps>;
  /**
   * The tooltip content for the popover button
   */
  renderTooltipText?: (opts: { isPopoverOpen: boolean }) => ReactNode;
  /**
   * Whether to show the chevron icon that indicates whether the popover is open
   * or closed
   *
   * Defaults to `true`
   */
  shouldShowChevron?: boolean;
  /**
   * The component to use for the tooltip, useful for testing.
   */
  tooltipComponent?: FC<TooltipProps>;
  /**
   * The keyboard shortcut to toggle the popover e.g. "N" = shift + n.
   *
   * This property is **NOT** dynamic i.e. the component will need to be
   * reloaded to observe changes.
   */
  keyboardShortcutPredicate?: Parameters<typeof useKeyPressEvent>[0];
}

function ChevronIcon({ isOpen }: { isOpen?: boolean }) {
  return (
    <Icon
      data-testid="popover-button.chevron-icon"
      className="absolute -top-1 -right-1 bg-neutral-100 text-neutral-800 rounded-full"
      icon={isOpen ? faChevronCircleUp : faChevronCircleDown}
    />
  );
}

function withChevron(Component: NonNullable<PopoverButtonProps["as"]>) {
  // Popover button `as` components are expected to forward refs
  const PopoverButtonWithChevron = forwardRef<
    HTMLButtonElement,
    PopoverButtonAsProps
  >(function PopoverButtonWithChevron(
    { children, ...props }: PopoverButtonAsProps,
    ref,
  ) {
    const isOpen = "data-open" in props;
    return (
      <Component {...props} ref={ref}>
        {children}
        <ChevronIcon isOpen={isOpen} />
      </Component>
    );
  });

  return PopoverButtonWithChevron;
}

export function PopoverButton({
  as: PopoverButtonAs = Button,
  children,
  renderTooltipText,
  shouldShowChevron = true,
  tooltipComponent: TooltipComponent = Tooltip,
  keyboardShortcutPredicate,
  ...props
}: PopoverButtonProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopoverOpenState = () => {
    setIsPopoverOpen((prevIsOpen) => !prevIsOpen);
  };

  /**
   * Unfortunately there's no way to programmatically open and close the popover
   */
  const togglePopover = useCallback(() => {
    // This will also trigger the on-click event which will toggle the
    // popover-open state above
    buttonRef.current?.click();
  }, []);

  useKeyPressEvent(keyboardShortcutPredicate, togglePopover);

  return (
    <TooltipComponent
      disabled={!renderTooltipText}
      text={renderTooltipText?.({ isPopoverOpen })}
      childIsInteractive
    >
      <BasePopoverButton
        ref={buttonRef}
        onClick={togglePopoverOpenState}
        as={shouldShowChevron ? withChevron(PopoverButtonAs) : PopoverButtonAs}
        {...props}
      >
        {children}
      </BasePopoverButton>
    </TooltipComponent>
  );
}
