import { useAtomValue, useAtom, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import {
  currentConversationSidAtom,
  unreadMessagesCountFamily,
  unreadMessageCountByConversationSidAtom,
  currentConversationAtom,
} from "../../twilio";
import { gracefullySetAllMessagesRead } from "../../twilio/chat/utils";
import {
  activeConversationSidAtom,
  visitorDrawerStateAtom,
  VisitorDrawerState,
} from "../state";
import { NEW_MESSAGE_TOAST_TAG } from "./useShowNewMessageToastCallback";
import { toast } from "../../toasts";

type ChatDrawerButtonUtilsOpts = {
  onOpen?: () => void;
  onClose?: () => void;
};

export function useChatDrawerButtonUtils({
  onOpen,
  onClose,
}: ChatDrawerButtonUtilsOpts = {}) {
  const activeConversationSid = useAtomValue(activeConversationSidAtom);
  const [currentConversationSid, setCurrentConversationSid] = useAtom(
    currentConversationSidAtom,
  );
  const setVisitorDrawerState = useSetAtom(visitorDrawerStateAtom);
  const unreadMessagesCount = useAtomValue(
    unreadMessagesCountFamily(activeConversationSid ?? ""),
  );
  const setUnreadMessageCount = useSetAtom(
    unreadMessageCountByConversationSidAtom,
  );
  const getCurrentConversation = useAtomCallback(
    useCallback((get) => get(currentConversationAtom), []),
  );

  const toggleChatDrawer = useCallback(() => {
    if (currentConversationSid) {
      setCurrentConversationSid("");
      setVisitorDrawerState(null);
      onClose?.();
    } else {
      setCurrentConversationSid(activeConversationSid);
      setUnreadMessageCount((prev) =>
        activeConversationSid ? { [activeConversationSid]: 0 } : prev,
      );
      setVisitorDrawerState(VisitorDrawerState.CHAT);
      // Dismiss any toasts for this conversation as well.
      toast.closeByTag(NEW_MESSAGE_TOAST_TAG);
      onOpen?.();

      gracefullySetAllMessagesRead(getCurrentConversation());
    }
  }, [
    activeConversationSid,
    currentConversationSid,
    setVisitorDrawerState,
    setCurrentConversationSid,
    setUnreadMessageCount,
    getCurrentConversation,
    onOpen,
    onClose,
  ]);

  return {
    unreadMessagesCount,
    toggleChatDrawer,
    isChatDrawerDisabled: !activeConversationSid,
  };
}
