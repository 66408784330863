import { StatusDot, StatusDotUse } from "@growtherapy/sprout-ui";
import { twMerge } from "tailwind-merge";

export function VisualIndicator({
  className,
  "data-testid": dataTestId,
}: {
  className?: string;
  "data-testid"?: string;
}) {
  return (
    <StatusDot
      className={twMerge("bg-lilac-400", className)}
      data-testid={dataTestId}
      use={StatusDotUse.Success}
    />
  );
}
