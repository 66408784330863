import { useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import {
  LocalTrackState,
  localAudioVideoTrackFamily,
  localTrackFamily,
} from "../state";
import { useStoredAudioVideoSettings } from "../useStoredAudioVideoSettings";
import { useUnpublishLocalTrackCallback } from "./useUnpublishLocalTrackCallback";
import { CreateLocalTrackOptions } from "twilio-video";
import { createLocalAudioTrack, createLocalVideoTrack } from "../tracks";
import { LocalAVTrack, AudioVideoTrackKind, TrackKind } from "../types";
import { logger } from "../../datadog/logger";
import { getStoredAudioVideoSettings } from "../config";
import { useNoiseCancellationCallbacks } from "./useNoiseCancellationCallbacks";
import { constraintsToValue } from "../utils";

export function useHandleTrackErrorCallback(trackKind: TrackKind) {
  const setLocalTrack = useSetAtom(localTrackFamily(trackKind));

  return useCallback(
    (err: Error) => {
      logger.error(
        "Unexpected error when creating track",
        { trackKind },
        err as Error,
      );
      setLocalTrack((prev) => ({ ...prev, state: LocalTrackState.ERROR }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackKind],
  );
}

/**
 * Creates a track and sets it in the local track atom.
 */
function useGracefullyCreateLocalTrackCallback(trackKind: AudioVideoTrackKind) {
  const localTrackAtom = localAudioVideoTrackFamily(trackKind);
  const setLocalTrack = useSetAtom(localTrackAtom);
  const getLocalTrack = useAtomCallback(
    useCallback(
      (get) => get(localTrackAtom),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
  );
  const handleTrackError = useHandleTrackErrorCallback(trackKind);
  const { initNoiseCancellation } = useNoiseCancellationCallbacks();
  const { setStoredAudioVideoSettings } = useStoredAudioVideoSettings();

  return useCallback(
    async (trackOptions: CreateLocalTrackOptions) => {
      const prevLocalTrack = getLocalTrack();
      const targetDeviceId = trackOptions?.deviceId;
      const targetDeviceIdValue =
        targetDeviceId && constraintsToValue<string>(targetDeviceId);
      const storedAudioVideoSettings = getStoredAudioVideoSettings();
      setLocalTrack((prev) => ({
        ...prev,
        state: LocalTrackState.LOADING,
      }));
      let track: LocalAVTrack;
      try {
        switch (trackKind) {
          case "audio": {
            const deviceId =
              targetDeviceId ?? storedAudioVideoSettings?.inputAudioDeviceId;
            track = await createLocalAudioTrack({
              ...trackOptions,
              deviceId,
            });
            setStoredAudioVideoSettings((prev) => ({
              ...prev,
              inputAudioDeviceId: targetDeviceIdValue,
            }));
            await initNoiseCancellation(track);
            break;
          }
          case "video": {
            const deviceId =
              targetDeviceId ?? storedAudioVideoSettings?.inputVideoDeviceId;
            track = await createLocalVideoTrack({
              ...trackOptions,
              deviceId,
            });
            setStoredAudioVideoSettings((prev) => ({
              ...prev,
              inputVideoDeviceId: targetDeviceIdValue,
            }));
            break;
          }
          default:
            return;
        }
        if (!track) {
          setLocalTrack(prevLocalTrack);
          return;
        }
        const shouldEnableTrack =
          !prevLocalTrack?.state ||
          prevLocalTrack.state === LocalTrackState.READY;
        setLocalTrack((prev) => ({
          ...prev,
          state: shouldEnableTrack
            ? LocalTrackState.READY
            : LocalTrackState.DISABLED,
          track,
        }));
        return track;
      } catch (error) {
        handleTrackError(error as Error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackKind],
  );
}

/**
 * Returns the existing local track or creates a new one. If a device ID is
 * specified, then the existing track is unpublished and a new one is created.
 */
export function useGetOrCreateLocalTrackCallback(
  trackKind: AudioVideoTrackKind,
) {
  const getLocalTrack = useAtomCallback(
    useCallback(
      (get) => get(localAudioVideoTrackFamily(trackKind))?.track,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
  );
  const unpublishAudioTrack = useUnpublishLocalTrackCallback("audio");
  const unpublishVideoTrack = useUnpublishLocalTrackCallback("video");
  const gracefullyCreateLocalTrack =
    useGracefullyCreateLocalTrackCallback(trackKind);
  /**
   * Unpublishes the audio track if it exists. Returns true if the track was
   * successfully unpublished or if it didn't exist.
   */
  const hardUnpublishExistingTrack = useCallback(
    async () => {
      if (!getLocalTrack()) return true;
      if (trackKind === "audio") {
        return !!(await unpublishAudioTrack({ shouldReleaseTrack: true }));
      }
      if (trackKind === "video") {
        return !!(await unpublishVideoTrack({ shouldReleaseTrack: true }));
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackKind],
  );

  return useCallback(
    async (trackOptions: CreateLocalTrackOptions = {}) => {
      const targetDeviceId = trackOptions?.deviceId;
      const storedAudioVideoSettings = getStoredAudioVideoSettings();
      let track: LocalAVTrack | void;

      switch (trackKind) {
        case "audio": {
          if (targetDeviceId) {
            const unpublishedExistingTrackSuccessfully =
              await hardUnpublishExistingTrack();
            if (!unpublishedExistingTrackSuccessfully) return;
          }

          const deviceId =
            targetDeviceId ?? storedAudioVideoSettings?.inputAudioDeviceId;

          // It's important to get the local track again after potentially
          // unpublishing it
          track =
            getLocalTrack() ||
            (await gracefullyCreateLocalTrack({ ...trackOptions, deviceId }));
          break;
        }

        case "video": {
          if (targetDeviceId) {
            const unpublishedExistingTrackSuccessfully =
              await hardUnpublishExistingTrack();
            if (!unpublishedExistingTrackSuccessfully) return;
          }

          const deviceId =
            targetDeviceId ?? storedAudioVideoSettings?.inputVideoDeviceId;

          // It's important to get the local track again after potentially
          // unpublishing it
          track =
            getLocalTrack() ||
            (await gracefullyCreateLocalTrack({ ...trackOptions, deviceId }));
          break;
        }
        default:
          return;
      }
      return track;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackKind],
  );
}
