import { RefObject, useCallback, useEffect, useState } from "react";
import { usePrefersReducedMotion } from "../../../a11y/usePrefersReducedMotion";
import { secondsToMilliseconds } from "date-fns";

type KeyAnimations = {
  offset: { x: number; y: number };
  drawRipple: () => void;
};

export function useKeyAnimation(
  imageRef: RefObject<HTMLImageElement>,
): KeyAnimations {
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const disableMotion = usePrefersReducedMotion();

  // Creates a gentle drifting effect by randomly updating the blob offsets
  useEffect(
    function setBlobOffsets() {
      // Keep the blobs where they are if the user has reduced motion enabled
      if (disableMotion) {
        return;
      }
      let timeout: NodeJS.Timeout;
      const setDynamicOffset = () => {
        setOffset({
          x: (Math.random() - 0.5) * 50, // Random value between -10 and 10
          y: (Math.random() - 0.5) * 50, // Random value between -10 and 10
        });

        // Random interval between 3 and 8 seconds
        const randomInterval =
          Math.floor(Math.random() * secondsToMilliseconds(5)) +
          secondsToMilliseconds(3);
        timeout = setTimeout(setDynamicOffset, randomInterval);
      };

      // Call it once to set the initial offset
      setDynamicOffset();

      return function stopUpdatingOffsets() {
        clearTimeout(timeout);
      };
    },
    [disableMotion],
  );

  // Function to create a ripple effect when the blob is pressed
  const drawRipple = useCallback(() => {
    // Don't draw a ripple if the user prefers reduced motion
    if (disableMotion) {
      return;
    }
    const target: HTMLImageElement | null = imageRef.current;
    if (!target) return;
    const centerX = target.offsetLeft + target.offsetWidth / 2;
    const centerY = target.offsetTop + target.offsetHeight / 2;
    const rippleNode = imageRef.current;
    if (!rippleNode) return;
    // Clone the image of the selected key
    const newRippleNode = rippleNode.cloneNode(true) as HTMLImageElement;
    const rippleDimensions = getComputedStyle(rippleNode);
    const rippleWidthInPx = parseInt(rippleDimensions.width);
    const blobColor = getComputedStyle(target).color;
    // Apply some custom styling to the clone
    newRippleNode.style.color = blobColor;
    newRippleNode.style.left = centerX - rippleWidthInPx / 2 + "px";
    newRippleNode.style.top = centerY - rippleWidthInPx / 2 + "px";
    newRippleNode.classList.add("ripple-motion");
    // Prevent any interactivity
    newRippleNode.style.pointerEvents = "none";
    // Add the cloned element to the DOM for the duration of the animation
    rippleNode.parentElement?.appendChild(newRippleNode);
    newRippleNode.addEventListener("animationend", () => {
      newRippleNode.remove();
    });
  }, [imageRef, disableMotion]);

  return { offset, drawRipple };
}
