import { faMemo } from "@fortawesome/pro-solid-svg-icons";
import { Tag } from "@growtherapy/sprout-ui";
import { PUSH_DRAWER_ARIA_CONTROLS_ID } from "../constants";
import { useAtomValue } from "jotai";
import { drawerStateAtom } from "../state";
import { getIsClientInfoDrawerOpen } from "../utils";
import { useToggleClientInformationDrawerCallback } from "../client-information";
import { hasAnyNewFormsAtom } from "../client-information/state";
import { IconMenuItem } from "../../components";

export function ClientInformationDrawerControlMenuItem({
  isClientInfoDrawerOpen,
  hasNewClientInfo,
  onClick,
}: {
  isClientInfoDrawerOpen: boolean;
  hasNewClientInfo?: boolean;
  onClick: () => void;
}) {
  return (
    <IconMenuItem
      aria-controls={PUSH_DRAWER_ARIA_CONTROLS_ID}
      aria-expanded={isClientInfoDrawerOpen}
      onClick={onClick}
      icon={faMemo}
    >
      <span className="sr-only">
        {isClientInfoDrawerOpen ? "Close" : "Open"} client information
      </span>
      <span aria-hidden>Client info</span>
      {hasNewClientInfo && (
        <Tag aria-label="new info available" use="lilac">
          New
        </Tag>
      )}
    </IconMenuItem>
  );
}

export function ClientInformationDrawerControlMenuItemWrapper() {
  const isClientInfoDrawerOpen = getIsClientInfoDrawerOpen(
    useAtomValue(drawerStateAtom),
  );
  const toggleClientInformationDrawer =
    useToggleClientInformationDrawerCallback();
  const hasNewForms = useAtomValue(hasAnyNewFormsAtom);

  return (
    <ClientInformationDrawerControlMenuItem
      isClientInfoDrawerOpen={isClientInfoDrawerOpen}
      hasNewClientInfo={hasNewForms}
      onClick={toggleClientInformationDrawer}
    />
  );
}
