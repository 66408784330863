import { RefObject, useRef } from "react";
import { useKeyAnimation } from "./useKeyAnimation";
import { useKeySound } from "./useKeySound";

export type KeyProps = {
  letter: string;
  image: string;
  note: string;
  playNote: () => void;
  stopNote: () => void;
  offset: { x: number; y: number };
  imageRef: RefObject<HTMLImageElement>;
};

const SPACE_KEY = " ";
const ENTER = "ENTER";

export function Key({
  letter,
  note,
  image,
  imageRef,
  offset,
  playNote,
  stopNote,
}: KeyProps) {
  return (
    <button
      className="relative w-fit h-fit transition-all ease-linear duration-[4000ms] group outline-none"
      aria-label={`Play note ${note}. Pressing the ${letter} key will also play this note.`}
      onMouseDown={playNote}
      onMouseUp={stopNote}
      onKeyDown={(event) => {
        const normalizedEventKey = event.key.toUpperCase();
        if (normalizedEventKey === SPACE_KEY || normalizedEventKey === ENTER) {
          playNote();
        }
      }}
      onKeyUp={(event) => {
        const normalizedEventKey = event.key.toUpperCase();
        if (normalizedEventKey === SPACE_KEY || normalizedEventKey === ENTER) {
          stopNote();
        }
      }}
    >
      <span className="flex w-64 h-64 hover:scale-125 group-focus:scale-125 transition-transform ease-out duration-500 relative inline-block">
        <img
          alt=""
          src={image}
          className="h-full w-full block absolute top-0 transition-all ease-linear duration-[4000ms]"
          ref={imageRef}
          style={{
            transform: `translate(${offset.x}px, ${offset.y}px)`,
          }}
        />
      </span>
      {letter}
    </button>
  );
}

export function KeyWrapper({
  letter,
  note,
  ...rest
}: Omit<KeyProps, "offset" | "imageRef" | "playNote" | "stopNote">) {
  const imageRef = useRef<HTMLImageElement>(null);
  const { offset, drawRipple } = useKeyAnimation(imageRef);
  const { playNote, stopNote } = useKeySound(letter, note, drawRipple);

  return (
    <Key
      letter={letter}
      note={note}
      offset={offset}
      playNote={playNote}
      stopNote={stopNote}
      imageRef={imageRef}
      {...rest}
    />
  );
}
