import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import {
  drawerStateAtom,
  ProviderDrawerState,
  newSessionConfirmationDataAtom,
} from "./state";
import { ClientQueueToastWrapper as ClientQueueToast } from "./ClientQueueToast";
import { localScreenShareTrackAtom, twilioRoomAtom } from "../twilio";
import { useSetAtom } from "jotai";
import { useAdmitClientCallback } from "./clients-drawer/useAdmitClientCallback";
import { VisitorPresence } from "../types";
import { toast } from "../toasts";
import { CLIENT_QUEUE_NEW_VISITOR_TOAST_TAG } from "./client-information/constants";

const IN_SESSION_TOAST_DURATION = 30_000;

export function getToastKeyForPatient(patientShortId: string) {
  return `client-queue-toast-${patientShortId}`;
}

export function useShowNewVisitorToastCallback() {
  const getScreenShareTrack = useAtomCallback(
    useCallback((get) => get(localScreenShareTrackAtom), []),
  );
  const getTwilioRoom = useAtomCallback(
    useCallback((get) => get(twilioRoomAtom), []),
  );
  const getDrawerState = useAtomCallback(
    useCallback((get) => get(drawerStateAtom), []),
  );
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setNewSessionConfirmationData = useSetAtom(
    newSessionConfirmationDataAtom,
  );
  const [admitClient] = useAdmitClientCallback();

  function showVisitorToastCallback(
    shortId: VisitorPresence["patientShortId"],
    name: string,
    visitorUuid?: string,
  ) {
    const isInSession = !!getTwilioRoom();
    const toastDuration = isInSession ? IN_SESSION_TOAST_DURATION : undefined;
    const isSharingScreen = !!getScreenShareTrack();
    const clientDrawerIsOpen = getDrawerState() === ProviderDrawerState.CLIENTS;
    const onAdmitHandler = async () => {
      if (!visitorUuid) return;
      await admitClient(visitorUuid, name, shortId);
    };
    if (clientDrawerIsOpen || isSharingScreen) return;

    const onShowStartNewSessionConfirmation = () => {
      if (!visitorUuid) return;

      setNewSessionConfirmationData({
        visitorName: name,
        visitorUuid,
        patientShortId: shortId,
      });
    };

    const onOpenQueue = () => {
      setDrawerState(ProviderDrawerState.CLIENTS);
      // Close all toasts related to new visitors.
      toast.closeByTag(CLIENT_QUEUE_NEW_VISITOR_TOAST_TAG);
    };

    toast.enqueue(
      (ariaProps) => (
        <ClientQueueToast
          participantName={name}
          patientShortId={shortId}
          onAdmit={onAdmitHandler}
          onOpenQueue={onOpenQueue}
          onShowStartNewSessionConfirmation={onShowStartNewSessionConfirmation}
          visitorUuid={visitorUuid}
          {...ariaProps}
        />
      ),
      {
        timeout: toastDuration,
        position: "bottom-left",
        tags: new Set([CLIENT_QUEUE_NEW_VISITOR_TOAST_TAG]),
        key: getToastKeyForPatient(shortId),
      },
    );
  }
  return showVisitorToastCallback;
}
