import { faMessage } from "@fortawesome/pro-solid-svg-icons";
import { Tag } from "@growtherapy/sprout-ui";
import { PUSH_DRAWER_ARIA_CONTROLS_ID } from "../constants";
import { getIsAnyChatDrawerOpen } from "../../components/utils";
import { useAtomValue } from "jotai";
import { drawerStateAtom } from "../state";
import { useChatDrawerButtonUtils } from "../chat/useChatDrawerButtonUtils";
import { IconMenuItem } from "../../components";

export function ChatDrawerControlMenuItem({
  isChatDrawerOpen,
  onClick,
  unreadMessageCount,
}: {
  isChatDrawerOpen: boolean;
  isChatButtonDisabled: boolean;
  onClick: () => void;
  unreadMessageCount: number;
}) {
  return (
    <IconMenuItem
      aria-controls={PUSH_DRAWER_ARIA_CONTROLS_ID}
      aria-expanded={isChatDrawerOpen}
      className="flex items-center gap-2"
      onClick={onClick}
      icon={faMessage}
    >
      <span className="sr-only">
        {isChatDrawerOpen ? "Close" : "Open"} chat,
      </span>
      <span aria-hidden>Chat</span>
      {unreadMessageCount ? (
        <Tag use="lilac">{unreadMessageCount}</Tag>
      ) : (
        <span className="sr-only">0</span>
      )}
      <span className="sr-only">
        unread {unreadMessageCount === 1 ? "message" : "messages"}
      </span>
    </IconMenuItem>
  );
}

export function ChatDrawerControlMenuItemWrapper() {
  const { unreadMessageCount, toggleChatDrawer } = useChatDrawerButtonUtils();
  const isChatDrawerOpen = getIsAnyChatDrawerOpen(
    useAtomValue(drawerStateAtom),
  );

  return (
    <ChatDrawerControlMenuItem
      isChatDrawerOpen={isChatDrawerOpen}
      isChatButtonDisabled={false}
      onClick={toggleChatDrawer}
      unreadMessageCount={unreadMessageCount}
    />
  );
}
