export const ConsentModalText = {
  HEADING:
    "would like to use Grow Therapy's capability that provides AI-enabled summarization to deepen their focus on you in your sessions.",
  ENABLE_BUTTON: "Yes, I consent",
  DONT_ENABLE_BUTTON: "No, I don't consent",
  FOOTER: "You can opt out anytime from your settings.",
  TRANSCRIPTION_STORAGE_DISCLAIMER:
    "Session transcripts will be securely stored for up to 30 days for quality assurance purposes.",
  MULTI_PARTICIPANT_DISCLAIMER: "Consent will apply to all participants.",
  PROVIDER_NAME_FALLBACK: "Your provider",
};
