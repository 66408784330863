import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { twilioRoomAtom } from "../twilio/state";

// these correspond to the class names set in the root-asset index.css
const HIDE_UI_ELEMENTS_CLASS_NAME = "session-focused";
const HIDABLE_UI_ELEMENT_CLASS_NAME = "hideable";
const USER_EVENTS = ["mousemove", "keydown"];

const HIDE_ELEMENTS_TIMEOUT_DELAY_MS = 3000;

function hideNonEssentials() {
  document.body.classList.add(HIDE_UI_ELEMENTS_CLASS_NAME);
}

function showNonEssentials() {
  document.body.classList.remove(HIDE_UI_ELEMENTS_CLASS_NAME);
}

/**
 * Hides all non-essential elements, using the non-essential-elements props in
 * this file, when in a session.
 *
 * The non-essential elements will be hidden when the pointer is idle for 3
 * seconds, and shown when the pointer moves.
 */
export function useSessionFocus() {
  const [_hideElementsTimeout, setHideElementsTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const isInSession = !!useAtomValue(twilioRoomAtom);

  useEffect(
    function handleSessionFocus() {
      const resetHideElementsTimeout = (nullify = false) => {
        setHideElementsTimeout((prevTimeout) => {
          if (prevTimeout) clearTimeout(prevTimeout);
          return nullify
            ? null
            : setTimeout(hideNonEssentials, HIDE_ELEMENTS_TIMEOUT_DELAY_MS);
        });
        showNonEssentials();
      };

      if (!isInSession) {
        resetHideElementsTimeout(true);
        return;
      }

      const abortController = new AbortController();
      USER_EVENTS.forEach((eventName) => {
        document.addEventListener(eventName, () => resetHideElementsTimeout(), {
          signal: abortController.signal,
        });
      });

      return () => abortController.abort();
    },
    [isInSession],
  );
}

function blockPropagationUntilVisible(
  e:
    | React.PointerEvent<HTMLDivElement>
    | React.MouseEvent<HTMLDivElement>
    | React.TouchEvent<HTMLDivElement>,
) {
  const currentElement = e.currentTarget;

  if (!currentElement) return;

  if (parseInt(getComputedStyle(currentElement).opacity) < 1) {
    e.stopPropagation();
  }
}

export const nonEssentialElementProps = {
  className: HIDABLE_UI_ELEMENT_CLASS_NAME,
  onClickCapture: blockPropagationUntilVisible,
  onPointerDownCapture: blockPropagationUntilVisible,
  onPointerUpCapture: blockPropagationUntilVisible,
  onTouchStartCapture: blockPropagationUntilVisible,
  onTouchEndCapture: blockPropagationUntilVisible,
};
