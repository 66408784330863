import { Divider } from "@growtherapy/sprout-ui";

export function ToolbarSeparator() {
  return (
    <Divider
      className="hidden md:block md:border-neutral-900 md:h-11"
      orientation="vertical"
    />
  );
}
