export const dynamicConfig = "telehealth_config";
export const announcementConfig = "telehealth_announcement";
export const aiManualQaProviders = "ai_qa_opted_in_providers";

export const delayedEntryGate = "telehealth_delayed_entry";
export const disableTranscriptionForMultipleParticipants =
  "telehealth_disable_transcription_for_multiple_participants";
export const toastViewTransitionsGate = "telehealth_toast_view_transitions";
export const whiteboardEventualConsistencyGate =
  "telehealth_whiteboard_eventual_consistency";
export const preSessionVibesGate = "pre_session_vibes";
export const scribblesGate = "telehealth_scribbles";
export const wordpressUrlMigrationGate = "wordpress_url_migration";
