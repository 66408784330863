import { TagUseType } from "@growtherapy/sprout-ui";
import { AppointmentType } from "./types";

export const getAppointmentTagColor = (
  appointmentType?: AppointmentType | null,
): TagUseType => {
  switch (appointmentType) {
    case null:
    case undefined:
      return "neutral";
    case AppointmentType.Consultation:
      return "lilac";
    case AppointmentType.Intake:
      return "yellow";
    default:
      return "green";
  }
};

export const getAppointmentTypeText = (
  appointmentType?: AppointmentType | null,
): string => {
  switch (appointmentType) {
    case null:
    case undefined:
      return "";
    case AppointmentType.Intake:
      return "Intake";
    case AppointmentType.Consultation:
      return "Consultation";
    default:
      return "Follow-up";
  }
};
