import { useCallback } from "react";
import {
  DataTrackMessageType,
  DataTrackSender,
  DataTrackWhiteboardSnapshotMessage,
} from "../types";
import { useSendDataTrackMessageCallback } from "./useSendDataTrackMessageCallback";
import { loadWhiteboardStore } from "../../components/whiteboard/loadAppWhiteboardStore";

export const useSendWhiteboardSnapshotCallback = () => {
  const sendMessage = useSendDataTrackMessageCallback();
  return useCallback(
    async (senderIdentity: DataTrackSender) => {
      const store = await loadWhiteboardStore();
      const formattedMessage: DataTrackWhiteboardSnapshotMessage = {
        snapshot: store.getStoreSnapshot(),
        type: DataTrackMessageType.WHITEBOARD_SNAPSHOT,
        sender: senderIdentity,
      };

      sendMessage(formattedMessage);
    },
    [sendMessage],
  );
};
