import { useAtomValue } from "jotai";
import { ChatDrawerControlMenuItem } from "../../../twilio/controls/ChatDrawerControl";
import { ChatDrawerButtonWrapper as ChatDrawerButton } from "../../chat/ChatDrawerButton";
import { MEETING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID } from "../../constants";
import { VisitorDrawerState, visitorDrawerStateAtom } from "../../state";
import { useChatDrawerButtonUtils } from "../../chat/useChatDrawerButtonUtils";
import { DEFAULT_MEETING_ROOM_PAGE_TITLE } from "../metadata";

export function VisitorMeetingChatDrawerButton() {
  return (
    <ChatDrawerButton
      defaultTitle={DEFAULT_MEETING_ROOM_PAGE_TITLE}
      drawerId={MEETING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID}
    />
  );
}

export function VisitorMeetingChatDrawerMenuItemWrapper() {
  const drawerState = useAtomValue(visitorDrawerStateAtom);
  const { unreadMessagesCount, toggleChatDrawer, isChatDrawerDisabled } =
    useChatDrawerButtonUtils();

  return (
    <ChatDrawerControlMenuItem
      drawerId={MEETING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID}
      isChatDrawerOpen={drawerState === VisitorDrawerState.CHAT}
      onClickChatButton={toggleChatDrawer}
      isChatButtonDisabled={isChatDrawerDisabled}
      unreadMessageCount={unreadMessagesCount}
    />
  );
}
