import {
  Modal,
  ModalBody,
  Text,
  Button,
  Heading,
} from "@growtherapy/sprout-ui";
import HandHoldingPencil from "../../assets/images/hand-holding-pencil.svg?react";

type ConsentModalProps = {
  onClose: () => void;
  recordOptIn: () => void;
  isOpen: boolean;
};

export const ConsentModalText = {
  HEADING: "Enable transcription so you can focus on your clients.",
  SUBTITLE:
    "Enabling this feature will request transcription consent from your client. Please be aware that the transcription and summarization technology can make mistakes.",
  ENABLE_BUTTON: "Yes, enable",
  DONT_ENABLE_BUTTON: "No, don't enable",
  FOOTER: "You can change this setting any time in your dashboard.",
};

export function ConsentModal({
  onClose,
  recordOptIn,
  isOpen,
}: ConsentModalProps) {
  return (
    <Modal
      onClose={onClose}
      onOutsideClick={onClose}
      isOpen={isOpen}
      className="shadow-none sm:max-w-[31rem] md:max-w-[31rem] sm:top-[25%]"
    >
      <ModalBody className="flex flex-col items-center gap-8 p-8 text-center">
        <HandHoldingPencil />
        <div className="flex flex-col gap-2 items-center">
          <Heading as="h2" variant="md">
            {ConsentModalText.HEADING}
          </Heading>
          <Text variant="md">{ConsentModalText.SUBTITLE}</Text>
        </div>
        <div className="w-full flex  flex-col gap-4 items-center text-center">
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 w-full">
            <Button onClick={onClose} use="secondary" className="w-full">
              {ConsentModalText.DONT_ENABLE_BUTTON}
            </Button>
            <Button onClick={recordOptIn} use="primary" className="w-full m-0">
              {ConsentModalText.ENABLE_BUTTON}
            </Button>
          </div>
          <Text variant="sm" className="text-neutral-700">
            {ConsentModalText.FOOTER}
          </Text>
        </div>
      </ModalBody>
    </Modal>
  );
}
