import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Navbar,
  PageContainer,
  PageProviders,
  PushDrawerContainer,
  StageContainer,
} from "../../components";
import { logger } from "../../datadog";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import {
  TelehealthSessionInfoData_TelehealthSessionInfo_Appointment,
  useGetTelehealthSessionInfo,
} from "../../hooks";
import { useInactiveTabPerformanceOptimization } from "../../hooks/useInactiveTabPerformanceOptimization";
import { useOnInactiveMobileScreenDetected } from "../../hooks/useOnInactiveMobileScreenDetected";
import { useWaitingRoomTrackingEvent } from "../../hooks/useWaitingRoomTrackingEvent";
import { EventTypeEnum } from "../../segment/types";
import { Theme } from "../../state";
import { ExperimentLayers } from "../../statsig/experiments";
import { useHandleDeviceChanges, useInitLocalMediaTracks } from "../../twilio";
import { HelpModalWrapper as HelpModal } from "../HelpModal";
import { PushDrawerWrapper as PushDrawer } from "../PushDrawer";
import { SelfVideoThumbnail } from "../SelfVideoThumbnail";
import {
  VisitorDrawerState,
  visitorDrawerStateAtom,
  visitorIsHelpModalOpenAtom,
  visitorMeasuresAtom,
  preSessionVibesOpenAtom,
} from "../state";
import { useInitTwilioSyncClient } from "../sync/useInitTwilioSyncClient";
import {
  BackgroundAnimationContainerWrapper as BackgroundAnimationContainer,
  useAnimationState,
} from "./background-action";
import { ProviderInfoSectionWrapper as ProviderInfoSection } from "./ProviderInfoSection";
import { ProviderNotHereModalWrapper as ProviderNotHereModal } from "./ProviderNotHereModal";
import { RoomLoaderWrapper as RoomLoader } from "./RoomLoader";
import { SessionInfoSectionWrapper as SessionInfoSection } from "./SessionInfoSection";
import { useInitVisitorChatAccess, useWaitingRoomConversation } from "./chat";
import { useClearProviderNoShowTabTitleCallback } from "./useClearProviderNoShowTabTitle";
import { useResetUserStateCallback } from "./useResetUserStateCallback";
import { useUpdateTitleOnProviderPastNoShowThreshold } from "./useUpdateTitleOnProviderPastNoShowThreshold";
import { useUpdateVisitorPresenceInterval } from "./useUpdateVisitorPresenceInterval";
import { useWaitingRoomTimeout } from "./useWaitingRoomTimeout";
import { useWatchForTwilioRoomToken } from "./useWatchForTwilioRoomToken";
import { usePreSessionPromptState } from "./usePreSessionPromptState";
import { setDefaultMeetingRoomFaviconAndTitle } from "../meeting-room/metadata";
import { useLayer } from "@statsig/react-bindings";
import { WAITING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID } from "../constants";
import { useObscuredVisitorContentProps } from "../a11y";
import { VisitorWaitingSessionControlsWrapper as SessionControls } from "./controls";
import { PreSessionVibesWrapper as PreSessionVibes } from "./pre-session-vibes/PreSessionVibes";
import { PreSessionPromptVariant } from "./PreSessionPrompt";

function Stage({
  obscuredContentProps,
  pauseAnimation,
  appointment,
}: {
  obscuredContentProps: Partial<{
    "aria-hidden": boolean;
    inert: string;
  }>;
  pauseAnimation: () => void;
  appointment:
    | TelehealthSessionInfoData_TelehealthSessionInfo_Appointment
    | undefined;
}) {
  const setDrawerState = useSetAtom(visitorDrawerStateAtom);

  const visitorMeasures = useAtomValue(visitorMeasuresAtom);

  const hasFormData = !!visitorMeasures?.length;

  const preSessionVibesOpen = useAtomValue(preSessionVibesOpenAtom);
  const { preSessionPrompt } = usePreSessionPromptState();
  const showPreSessionVibes =
    preSessionPrompt === PreSessionPromptVariant.VIBES && preSessionVibesOpen;

  const openDrawer = useCallback(
    (drawer: VisitorDrawerState): void => {
      setDrawerState(drawer);
      // Pause the background animation when the drawer is opened.
      pauseAnimation();
    },
    [setDrawerState, pauseAnimation],
  );

  return (
    <StageContainer {...obscuredContentProps} className="gap-y-0 @container">
      <BackgroundAnimationContainer isPreSessionVibesOpen={showPreSessionVibes}>
        <ProviderInfoSection />
        <div className="flex flex-col flex-grow gap-y-8 justify-center items-center">
          {showPreSessionVibes ? (
            <PreSessionVibes />
          ) : (
            <SessionInfoSection
              appointment={appointment}
              openDrawer={openDrawer}
              hasForms={hasFormData}
            />
          )}
          <MobileSelfVideoThumbnail />
        </div>
      </BackgroundAnimationContainer>
      <DesktopSelfVideoThumbnail />
    </StageContainer>
  );
}

function PageEffects() {
  const [layerName, layerConfig] = ExperimentLayers.ClientWaitingRoom;
  const layer = useLayer(layerName, {
    disableExposureLog: true,
  });
  const shouldShowAVSetup = layer?.get<boolean>(
    layerConfig.ShouldShowAvSetup,
    false,
  );

  const resetUserState = useResetUserStateCallback();
  useUpdateVisitorPresenceInterval();
  useInitTwilioSyncClient();
  const { clearPreSessionPrompt } = usePreSessionPromptState();
  useWatchForTwilioRoomToken({
    onDone: () => {
      clearPreSessionPrompt();
      setDefaultMeetingRoomFaviconAndTitle();
    },
  });
  useInitLocalMediaTracks(!shouldShowAVSetup);
  useHandleDeviceChanges();
  useWaitingRoomTimeout();

  useInitVisitorChatAccess();
  useWaitingRoomConversation();
  useOnInactiveMobileScreenDetected(() => {
    resetUserState();
    logger.info("Returned to intro room due to inactive screen");
  });
  useUpdateTitleOnProviderPastNoShowThreshold();
  useInactiveTabPerformanceOptimization();
  const { providerShortId, patientShortId } = useParams();

  useWaitingRoomTrackingEvent(
    EventTypeEnum.CLIENT_WAITING_ROOM,
    providerShortId,
    patientShortId,
  );

  return null;
}

export function MobileSelfVideoThumbnail() {
  return (
    <SelfVideoThumbnail
      showExpandVideoToggle={false}
      shouldShowHideSelf={false}
      showSetPositionMenu={false}
      className="sm:hidden relative self-center mt-2 mb-6 sm:mt-10 sm:mb-10"
      videoClassName="min-h-[12rem] sm:min-h-[14rem] max-h-[18rem] min-w-[calc(100vw-2rem)] sm:min-w-[25rem] max-w-screen-sm"
    />
  );
}

export function DesktopSelfVideoThumbnail() {
  return (
    <SelfVideoThumbnail
      showExpandVideoToggle
      shouldShowHideSelf
      showSetPositionMenu
      className="hidden sm:inline absolute"
    />
  );
}

export function Page() {
  const obscuredContentProps = useObscuredVisitorContentProps();

  const [isHelpModalOpen, setIsHelpModalOpen] = useAtom(
    visitorIsHelpModalOpenAtom,
  );

  const clearProviderNoShowTabTitle = useClearProviderNoShowTabTitleCallback();
  const { providerShortId, patientShortId } = useParams();
  const { data } = useGetTelehealthSessionInfo(providerShortId, patientShortId);

  const appointment = data?.telehealthSessionInfo?.appointment;

  const { pause: pauseAnimation, play: playAnimation } = useAnimationState();

  const openHelpModal = useCallback(() => {
    sendLoggingEvents(TrackingEvents.ANY_HELP_REQUEST);
    sendLoggingEvents(TrackingEvents.CLIENT_HELP_REQUEST);
    setIsHelpModalOpen(true);
    // Pause the background animation when the help modal is opened.
    pauseAnimation();
  }, [setIsHelpModalOpen, pauseAnimation]);

  return (
    <>
      <PageEffects />
      <PageProviders theme={Theme.LIGHT}>
        <HelpModal
          isOpen={isHelpModalOpen}
          onClose={() => {
            setIsHelpModalOpen(false);
            clearProviderNoShowTabTitle();
            playAnimation();
          }}
          shouldShowProviderNoShowSection
          appointment={appointment}
        />
        <ProviderNotHereModal
          onOpenModal={pauseAnimation}
          onCloseModal={playAnimation}
        />
        <PageContainer>
          <Navbar
            {...obscuredContentProps}
            className="order-1"
            onClickNeedHelpButton={openHelpModal}
          />
          <SessionControls />
          <PushDrawerContainer
            // The push drawer must come _after_ the buttons that can trigger it
            // in the DOM. This is so that a keyboard user can tab forward from
            // the triggering button to the drawer. However, the drawer and
            // stage are meant to be visually positioned _before_ the control
            // buttons, so we reorder them within their flex container.
            className="order-2"
          >
            <Stage
              obscuredContentProps={obscuredContentProps}
              pauseAnimation={pauseAnimation}
              appointment={appointment}
            />
            <PushDrawer
              className="sm:my-2 sm:h-auto"
              id={WAITING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID}
              onCloseDrawer={playAnimation}
            />
          </PushDrawerContainer>
          <RoomLoader />
        </PageContainer>
      </PageProviders>
    </>
  );
}
