import { useAtomCallback } from "jotai/utils";
import { twilioRoomAtom } from "../../twilio/state";
import { useDisconnectTwilioParticipantMutation } from "../useDisconnectTwilioParticipantMutation";
import { useCallback } from "react";
import { toast } from "@growtherapy/sprout-ui";
import { logger } from "../../datadog/logger";
import { roomToDiagnosticInfo } from "../../twilio/utils";

export function useRemoveClientCallback() {
  const [disconnectTwilioParticipant, { loading }] =
    useDisconnectTwilioParticipantMutation();
  const getTwilioRoom = useAtomCallback(
    useCallback((get) => get(twilioRoomAtom), []),
  );
  const callback = useCallback(
    async (participantSid: string) => {
      const room = getTwilioRoom();
      if (!room) return;
      try {
        await disconnectTwilioParticipant({
          variables: { roomName: room.name, participantSid },
        });
      } catch (error) {
        // TODO: TOAST SPROUT
        toast.error(
          "Something went wrong when trying to remove the client from the session",
        );
        logger.error(
          "Unable to remove client from session",
          roomToDiagnosticInfo(room),
          error as Error,
        );
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return { callback, loading };
}
