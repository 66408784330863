import {
  Button,
  IconBadge,
  IconBadgeUse,
  Text,
  TextInput,
} from "@growtherapy/sprout-ui";
import { CannedMessages } from "../../components";
import { useState } from "react";
import { CANNED_MESSAGES } from "./constants";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { faMessage } from "@fortawesome/pro-solid-svg-icons";
import { ModifiedToastAria, Toast } from "../../toasts";
import { ToastCloseButton } from "../../toasts/ToastCloseButton";

export function NewWaitingRoomMessageToast({
  senderName,
  messageContent,
  onOpenChatDrawer,
  onSend,
  closeButtonProps,
  contentProps,
  descriptionProps,
  titleProps,
  ...toastAriaProps
}: {
  senderName: string;
  messageContent: string;
  onOpenChatDrawer: () => void;
  onSend: (message: string) => void;
} & ModifiedToastAria) {
  const [messageDraft, setMessageDraft] = useState("");
  const [showCustomReplyTextBox, setShowCustomReplyTextBox] = useState(false);

  return (
    <Toast {...toastAriaProps} className="sm:w-[30rem]">
      <div className="flex flex-col w-full gap-4">
        <div className="flex flex-row gap-4 justify-between">
          <button
            className="flex gap-4 w-full align-start fs-exclude min-w-0"
            data-dd-privacy="mask"
            data-dd-action-name="Click on new message toast button"
            onClick={onOpenChatDrawer}
            aria-label="Open message"
          >
            <IconBadge
              aria-hidden
              icon={faMessage}
              size="lg"
              use={IconBadgeUse.Lilac}
            />
            <div className="flex flex-col min-w-0" {...contentProps}>
              <Text
                {...titleProps}
                data-dd-privacy="mask"
                className="grow font-medium text-lilac-700 text-left fs-exclude"
              >
                <span className="sr-only">{`New message from ${senderName}:`}</span>
                <span aria-hidden>{senderName}</span>
              </Text>
              <Text
                {...descriptionProps}
                data-dd-privacy="mask"
                variant="sm"
                className="line-clamp-2 sm:line-clamp-3 text-neutral-800 text-left fs-exclude break-words"
              >
                {messageContent}
              </Text>
            </div>
          </button>
          <ToastCloseButton size="sm" {...closeButtonProps} />
        </div>
        <hr className="w-full text-neutral-500" />
        <Text variant="xs" className="uppercase text-neutral-700">
          Send a quick reply
        </Text>
        <div className="flex flex-row flex-wrap gap-2 w-full">
          <CannedMessages
            messages={CANNED_MESSAGES}
            onSelect={(cannedMessage: string) => {
              sendLoggingEvents(TrackingEvents.PROVIDER_TEMPLATE_MESSAGE_SEND);
              sendLoggingEvents(TrackingEvents.PROVIDER_QUICK_REPLY_CANNED);
              onSend(cannedMessage);
            }}
          />
          <Button
            disabled={showCustomReplyTextBox}
            size="sm"
            className="text-small"
            onClick={() => setShowCustomReplyTextBox(true)}
          >
            Custom reply
          </Button>
        </div>
        {showCustomReplyTextBox && (
          <form
            className="flex flex-row gap-2"
            onSubmit={(event) => event.preventDefault()}
          >
            <TextInput
              onChange={(event) => setMessageDraft(event.target.value)}
              // autoFocus is okay in this example because this element will only get focus right after
              // someone clicks on "Custom reply". This gives enough context to an individual
              // about where they are on the screen and why.
              autoFocus // eslint-disable-line  jsx-a11y/no-autofocus
              placeholder="Write a custom reply"
              className="bg-neutral-100 h-10"
              value={messageDraft}
            />
            <Button
              size="sm"
              onClick={() => {
                sendLoggingEvents(TrackingEvents.PROVIDER_MESSAGE_SEND);
                sendLoggingEvents(TrackingEvents.PROVIDER_QUICK_REPLY_CUSTOM);
                onSend(messageDraft);
                setMessageDraft("");
              }}
              type="submit"
              disabled={!messageDraft.trim()}
            >
              Send
            </Button>
          </form>
        )}
      </div>
    </Toast>
  );
}
