import { faGear, faVideoSlash } from "@fortawesome/pro-solid-svg-icons";
import {
  Card,
  IconBadge,
  IconButton,
  IconButtonProps,
  Tooltip,
} from "@growtherapy/sprout-ui";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect } from "react";
import { useTimeout } from "react-use";
import { twMerge } from "tailwind-merge";

import { TestSpeakersButton } from "../../../components/TestSpeakersButton";
import { useInactiveTabPerformanceOptimization } from "../../../hooks/useInactiveTabPerformanceOptimization";
import {
  LocalAudioVideoThumbnail,
  LocalTrackState,
  localAudioTrackStateAtom,
  localVideoTrackStateAtom,
  permissionDeniedAtom,
  useHandleDeviceChanges,
  useInitLocalMediaTracks,
  useListenForAvailableDeviceChanges,
} from "../../../twilio";
import { AudioControlWrapper as AudioControl } from "../../../twilio/controls/AudioControl";
import { VideoControlWrapper as VideoControl } from "../../../twilio/controls/VideoControl";
import { VisitorDrawerState, visitorDrawerStateAtom } from "../../state";
import { ProviderInfoSectionWrapper as ProviderInfoSection } from "../../waiting-room/ProviderInfoSection";
import { InputControlsWrapper as InputControls } from "../InputControls";
import { ProviderLoginWrapper as ProviderLogin } from "../ProviderLogin";
import { BlockedPermissionPageContent } from "./BlockedPermissionPageContent";
import { JoinWaitingRoomReminder } from "./JoinWaitingRoomReminder";
import { UpdatedUICallout } from "./UpdatedUICallout";
import { getIsSettingsDrawerOpen } from "../../../components/utils";
import { DrawerButton } from "../../../components/DrawerButton";
import { setTitle } from "../../../metadata";

const INACTIVITY_TIMEOUT = 10000; // 10 seconds

export function EnhancedPageContent({
  className,
  isAudioLoading = false,
  isAudioPermissionDenied = false,
  isVideoLoading = false,
  isVideoPermissionDenied = false,
  onClickSettingsButton,
  drawerId,
  isSettingsDrawerOpen,
}: {
  className?: string;
  isAudioLoading?: boolean;
  isAudioPermissionDenied?: boolean;
  isVideoLoading?: boolean;
  isVideoPermissionDenied?: boolean;
  onClickSettingsButton: IconButtonProps["onClick"];
  drawerId: string;
  isSettingsDrawerOpen: boolean;
}) {
  let content: JSX.Element;

  const [isInactive, _onCancel, onRestartTimeout] =
    useTimeout(INACTIVITY_TIMEOUT);

  // Show the blocked permissions page if either audio or video permissions are
  // denied. If showing V2 before we know for sure is confusing, consider a
  // loading state
  const showPermissionsDenied =
    (!isAudioLoading && isVideoPermissionDenied) ||
    (!isVideoLoading && isAudioPermissionDenied);

  if (showPermissionsDenied) {
    content = (
      <div data-testid="client-intro-state--blocked-permissions">
        <BlockedPermissionPageContent
          isAudioPermissionDenied={isAudioPermissionDenied}
          isVideoPermissionDenied={isVideoPermissionDenied}
        />
      </div>
    );
  } else {
    content = (
      <div
        aria-label="Session controls"
        className="flex flex-row gap-4 items-center justify-center flex-wrap"
      >
        <LocalAudioVideoThumbnail
          className="relative self-center w-full"
          name=""
          shouldShowHideSelf={false}
          showExpandVideoToggle={false}
          videoClassName="min-h-[12rem] sm:min-h-[14rem] max-h-[18rem] min-w-[calc(100vw-2rem)] sm:min-w-[25rem] max-w-screen-sm"
          videoOffContent={
            <IconBadge
              size="xl"
              className="bg-neutral-800"
              iconClassName="text-coral-600"
              icon={faVideoSlash}
            />
          }
        />

        <AudioControl showVolumeIndicator={false} />

        <VideoControl />

        <Tooltip childIsInteractive text="Open settings">
          <DrawerButton
            as={IconButton}
            forDrawerStates={VisitorDrawerState.SETTINGS}
            aria-label={
              isSettingsDrawerOpen ? "Close settings" : "Open settings"
            }
            aria-expanded={isSettingsDrawerOpen}
            aria-controls={drawerId}
            iconDefinition={faGear}
            onClick={onClickSettingsButton}
          />
        </Tooltip>

        <TestSpeakersButton />
      </div>
    );
  }

  return (
    <>
      {!showPermissionsDenied && (
        <UpdatedUICallout className="sm:hidden w-full" />
      )}

      <Card
        className={twMerge(
          "self-center w-full sm:w-[30rem] z-10 flex flex-col items-center p-4 sm:p-8 gap-8 border-0 sm:border",
          className,
        )}
        data-testid="client-intro-state--v2"
      >
        <ProviderInfoSection
          className="p-0 m-0"
          label="Let’s get ready for your session with"
          hideStatus
        />
        <Card className="w-full mobile:border-x mobile:rounded-lg">
          <InputControls
            buttonLabel="Continue"
            buttonClassName="w-full"
            className="flex flex-col gap-y-4"
            onChange={onRestartTimeout}
            showAVPermissionCallout={false}
            shouldDisableSubmitWhenInvalid={true}
          />
        </Card>
        {!showPermissionsDenied && (
          <JoinWaitingRoomReminder show={isInactive() ?? false} />
        )}
        {content}
        <ProviderLogin size="sm" />
      </Card>
    </>
  );
}

export function EnhancedPageContentWrapper({
  className,
  drawerId,
}: {
  className?: string;
  drawerId: string;
}) {
  const [drawerState, setDrawerState] = useAtom(visitorDrawerStateAtom);
  const { microphoneIsBlocked, videoIsBlocked } =
    useAtomValue(permissionDeniedAtom);

  const localAudioState = useAtomValue(localAudioTrackStateAtom);
  const isAudioLoading =
    !localAudioState || localAudioState === LocalTrackState.LOADING;
  const localVideoState = useAtomValue(localVideoTrackStateAtom);
  const isVideoLoading =
    !localVideoState || localVideoState === LocalTrackState.LOADING;

  useInitLocalMediaTracks();
  useHandleDeviceChanges();
  useListenForAvailableDeviceChanges();
  useInactiveTabPerformanceOptimization();

  const isSettingsDrawerOpen = getIsSettingsDrawerOpen(drawerState);
  const onClickSettingsButton = useCallback(() => {
    setDrawerState((drawerState) =>
      drawerState === VisitorDrawerState.SETTINGS
        ? null
        : VisitorDrawerState.SETTINGS,
    );
  }, [setDrawerState]);

  useEffect(() => {
    setTitle("👋 Action required");
  }, []);

  return (
    <EnhancedPageContent
      className={className}
      isAudioLoading={isAudioLoading}
      isAudioPermissionDenied={microphoneIsBlocked}
      isVideoLoading={isVideoLoading}
      isVideoPermissionDenied={videoIsBlocked}
      onClickSettingsButton={onClickSettingsButton}
      isSettingsDrawerOpen={isSettingsDrawerOpen}
      drawerId={drawerId}
    />
  );
}
