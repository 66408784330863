import { twMerge } from "tailwind-merge";
import { NoAccessVariant } from "../../twilio/controls/NoAccessToast";
import { deviceIsMobile } from "../../utils";
import { BlockedPermissionDescription } from "../BlockedPermissionDescription";

type WithPassThroughProps<P = unknown> = P & {
  className?: string;
  noAccessVariant?: NoAccessVariant;
};

export function BlockedPermissionMessage({
  className,
  isMobile,
  noAccessVariant,
}: WithPassThroughProps<{
  isMobile: boolean;
}>) {
  return (
    <div
      className={twMerge(
        "flex flex-col gap-6 w-full rounded border border-neutral-700 bg-neutral-200 p-4",
        className,
      )}
    >
      <BlockedPermissionDescription
        isMobile={isMobile}
        noAccessVariant={noAccessVariant}
      />
    </div>
  );
}

/**
 * When using this component, make sure any of the following hooks are executed
 * to be sure the application stays in-sync with the user's device permissions:
 * - useUpdateAvailableDevicesInterval
 * - useListenForAvailableDeviceChanges
 */
export function BlockedPermissionMessageWrapper(props: WithPassThroughProps) {
  const isMobile = deviceIsMobile();
  return <BlockedPermissionMessage {...props} isMobile={isMobile} />;
}
