import { Icon, Tag, Text } from "@growtherapy/sprout-ui";
import { faLightbulb } from "@fortawesome/pro-solid-svg-icons";
import { Toast, ToastRenderFnProps } from "../../toasts";

export function LeaveSessionNudgeToast({
  contentProps,
  descriptionProps,
  titleProps,
  ...toastAriaProps
}: ToastRenderFnProps) {
  return (
    <Toast
      className="sm:w-[30rem]"
      closeButtonPlacement="top"
      {...toastAriaProps}
    >
      <div className="flex gap-4 align-start flex-col" {...contentProps}>
        <div className="flex justify-between">
          <Tag
            aria-label="The current appointment will be ending soon"
            use="green"
            className="flex flex-row justify-start items-center gap-2 px-2 py-1"
          >
            <Icon
              aria-hidden
              icon={faLightbulb}
              className="text-neutral-800 font-medium"
            />
            <Text
              variant="xs"
              className="text-neutral-800 font-medium"
              {...titleProps}
            >
              Grow Tip
            </Text>
          </Tag>
        </div>
        <Text variant="sm" {...descriptionProps}>
          Nearing the end? Use the &ldquo;Leave Session&rdquo; button and
          we&apos;ll guide you through next steps.
        </Text>
      </div>
    </Toast>
  );
}
