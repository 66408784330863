import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@growtherapy/sprout-ui";
import { ComponentProps } from "react";
import { useStartNewClientSessionCallback } from "./useStartNewClientSessionCallback";
import { newSessionConfirmationDataAtom } from "../state";
import { useAtom } from "jotai";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { toast } from "../../toasts";
import { getToastKeyForPatient } from "../useShowNewVisitorToastCallback";

type StartNewSessionConfirmationModalProps = Pick<
  ComponentProps<typeof Modal>,
  "onClose" | "isOpen"
> & {
  onConfirm: () => void;
  loading?: boolean;
  visitorName?: string;
};

export function StartNewSessionConfirmationModal({
  loading,
  onClose,
  onConfirm,
  visitorName,
  ...props
}: StartNewSessionConfirmationModalProps) {
  return (
    <Modal {...{ onClose, ...props }}>
      <ModalHeader>
        <span className="fs-exclude" data-dd-privacy="mask">
          Start a new session with {visitorName}?
        </span>
      </ModalHeader>
      <ModalBody>
        <span className="fs-exclude" data-dd-privacy="mask">
          This will end your current session and create a new session with{" "}
          {visitorName}.
        </span>
      </ModalBody>
      <ModalFooter
        actions={[
          {
            key: "cancel",
            use: "secondary",
            onClick: onClose,
            actionContent: "Cancel",
          },
          {
            key: "confirm-new-session",
            use: "primary",
            onClick: onConfirm,
            loading,
            actionContent: "Yes, start new session",
          },
        ]}
      />
    </Modal>
  );
}

export function StartNewSessionConfirmationModalWrapper() {
  const [newSessionConfirmationData, setNewSessionConfirmationData] = useAtom(
    newSessionConfirmationDataAtom,
  );
  const [onStartNew, { loading: isCompletingSession }] =
    useStartNewClientSessionCallback();
  const { visitorName, visitorUuid, patientShortId } =
    newSessionConfirmationData ?? {};

  return (
    <StartNewSessionConfirmationModal
      isOpen={!!newSessionConfirmationData}
      loading={isCompletingSession}
      onClose={() => {
        setNewSessionConfirmationData(null);
      }}
      onConfirm={async () => {
        if (!visitorUuid || !visitorName || !patientShortId) return;
        await onStartNew(visitorUuid, visitorName, patientShortId);
        const toastId = getToastKeyForPatient(patientShortId);
        toast.close(toastId);
        sendLoggingEvents(TrackingEvents.PROVIDER_END_AND_START_NEW_SESSION);
        setNewSessionConfirmationData(null);
      }}
      visitorName={visitorName}
    />
  );
}
