import { useAtomValue } from "jotai";
import { providerShortIdAtom } from "./state";
import { UserType } from "../types";
import { LazyWhiteboardCanvas } from "../components/whiteboard/LazyWhiteboardCanvas";
import { usePeriodicallySendWhiteboardSnapshots } from "./messages/usePeriodicallySendWhiteboardSnapshots";

export function ProviderWhiteboard() {
  const providerShortId = useAtomValue(providerShortIdAtom);
  usePeriodicallySendWhiteboardSnapshots();

  return (
    <LazyWhiteboardCanvas
      senderIdentity={{
        entityType: UserType.PROVIDER,
        entityId: providerShortId ?? "",
      }}
    />
  );
}
