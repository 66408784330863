import { useCallback, useRef } from "react";
import { useKey } from "react-use";

export type KeySoundValues = {
  playNote: () => void;
  stopNote: () => void;
};

export function useKeySound(
  letter: string,
  _note: string,
  drawRipple: () => void,
): KeySoundValues {
  const isKeyPressed = useRef<boolean>(false);

  const playNote = useCallback(() => {
    if (!isKeyPressed.current) {
      // TODO CARED-2015 add audio
      drawRipple();
    }
    isKeyPressed.current = true;
  }, [drawRipple]);

  const stopNote = useCallback(() => {
    // TODO CARED-2015 handle audio if key pressed
    isKeyPressed.current = false;
  }, []);

  const keyFilter = useCallback(
    (event: KeyboardEvent) => {
      return event.key.toUpperCase() === letter.toUpperCase();
    },
    [letter],
  );

  useKey(keyFilter, stopNote, { event: "keyup" }, [keyFilter, stopNote]);
  useKey(keyFilter, playNote, { event: "keydown" }, [keyFilter, playNote]);

  return {
    playNote,
    stopNote,
  };
}
