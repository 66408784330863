import { useAtomValue } from "jotai";
import { PaginatedDrawer } from "../../../components";
import { PendingFormWrapper as PendingForm } from "./PendingForm";
import { sortedPendingFormsForSelectedClientAtom } from "../state";
import { PendingFormsContextProvider } from "./PendingFormsContextProvider";
import { PendingFormsDrawerActions } from "./PendingFormsDrawerActions";
import { PendingFormsDrawerHeader } from "../../../components/forms/PendingFormsDrawerHeader";
import { isInSessionWithSelectedClientAtom } from "../../state";
import { FormType } from "../types";

export function PendingFormsDrawer() {
  const pendingForms = useAtomValue(sortedPendingFormsForSelectedClientAtom);
  const isInSessionWithSelectedClient = useAtomValue(
    isInSessionWithSelectedClientAtom,
  );

  // defer non-measures to existing implementation when using MIC infra
  const forms = pendingForms.filter((form) =>
    [FormType.Intake, FormType.Safetyplan].includes(form.formType as FormType),
  );

  if (!pendingForms) return undefined;

  return (
    <PendingFormsContextProvider>
      <PaginatedDrawer
        contentComponent={PendingForm}
        drawerBodyProps={{ className: "bg-neutral-200" }}
        actionsComponent={PendingFormsDrawerActions}
        contentOnly
        pages={forms}
        backButtonDisabled={!isInSessionWithSelectedClient}
        headerComponent={PendingFormsDrawerHeader}
        drawerBodyKeyFn={({ page }) => `pending-forms-drawer.${page?.formId}`}
      />
    </PendingFormsContextProvider>
  );
}
