import { LoadingScreen, Modal, ModalHeader } from "@growtherapy/sprout-ui";
import { useAtomValue } from "jotai";
import { VisitorState, visitorStateAtom } from "../state";

export function RoomLoader({ shouldShow }: { shouldShow: boolean }) {
  return (
    <Modal
      isOpen={shouldShow}
      // The modal is pretty wonky; it's not centered and works weirdly on
      // mobile. Resorting to hacks here.
      className="rounded-lg bottom-[calc(50%-10rem)] h-[20rem] left-[calc(50%-9rem)] w-[18rem] p-12 flex-col items-center justify-center sm:self-center sm:mb-[10%] sm:w-[24rem] sm:left-auto"
    >
      <ModalHeader className="text-center p-0 sm:p-0 md:p-0 mb-8">
        Starting session...
      </ModalHeader>
      <LoadingScreen className="flex flex-grow w-full h-full scale-[0.65]" />
    </Modal>
  );
}

export function RoomLoaderWrapper() {
  const visitorState = useAtomValue(visitorStateAtom);
  return <RoomLoader shouldShow={visitorState === VisitorState.LOADING} />;
}
