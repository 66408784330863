import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { IconButton } from "@growtherapy/sprout-ui";
import { twMerge } from "tailwind-merge";
import { ToastRenderFnProps } from "./types";
import classNames from "classnames";

export type ToastCloseButtonProps = {
  placement?: "top" | "center";
  size?: "xs" | "sm";
} & ToastRenderFnProps["closeButtonProps"];

export function ToastCloseButton({
  placement,
  size = "xs",
  ...ariaProps
}: ToastCloseButtonProps) {
  return (
    <IconButton
      {...ariaProps}
      type="button"
      iconDefinition={faXmark}
      className={twMerge(
        "text-neutral-800 no-underline hover:text-neutral-800 flex justify-center items-center",
        classNames({
          "h-9 w-9": size === "sm",
          "h-6 w-6": size === "xs",
          "self-start": placement === "top",
        }),
      )}
      use="secondary"
      aria-label="Dismiss"
      size={size === "sm" ? "md" : "sm"}
    />
  );
}
