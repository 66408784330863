import { Button, Icon } from "@growtherapy/sprout-ui";
import { twMerge } from "tailwind-merge";

import { ActionType, ActionStateToggleButtonProps } from "./types";
import {
  MUSIC_ACTION_ICONS,
  useBackgroundMusicPlayback,
} from "./useBackgroundMusicPlayback";

export function MusicStateToggleButton({
  className,
  currentActionState,
  onClick,
}: {
  className?: string;
  currentActionState: ActionType;
  onClick: () => void;
}): JSX.Element {
  const nextActionIcon = MUSIC_ACTION_ICONS[currentActionState];

  return (
    <Button
      use="link"
      size="sm"
      className={twMerge(
        "flex gap-x-2 items-center text-yellow-700 no-underline hover:brightness-75 hover:text-yellow-700",
        className,
      )}
      onClick={() => onClick()}
      aria-label={
        currentActionState === ActionType.PLAY ? "Sound on" : "Sound off"
      }
    >
      <Icon icon={nextActionIcon} />
      {currentActionState === ActionType.PLAY ? "Sound on" : "Sound off"}
    </Button>
  );
}

export type ActionStateToggleButtonWrapperProps = Pick<
  ActionStateToggleButtonProps,
  "className"
>;

export function MusicStateToggleButtonWrapper(
  props: ActionStateToggleButtonWrapperProps,
) {
  const { currentMusicState, toggleMusic } = useBackgroundMusicPlayback();
  return (
    <MusicStateToggleButton
      {...props}
      currentActionState={currentMusicState}
      onClick={toggleMusic}
    />
  );
}
