import { useAtomValue } from "jotai";
import { useRecordingControl as useBaseRecordingControl } from "../../twilio/controls/useRecordingControl";
import { sessionAppointmentShortIdAtom } from "../state";
import { sessionInfoAtom } from "../../state";

export function useRecordingControl() {
  const sessionInfo = useAtomValue(sessionInfoAtom);
  const hasAppointment = !!useAtomValue(sessionAppointmentShortIdAtom);
  return useBaseRecordingControl(
    hasAppointment,
    sessionInfo?.provider?.isPrescriber,
  );
}
