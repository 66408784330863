import { Button, Text } from "@growtherapy/sprout-ui";
import { VibesComponentProps } from "./types";
import { GO_BACK_CTA, INSTRUCTIONS } from "./constants";
import { MusicStateToggleButtonWrapper as MusicStateToggleButton } from "../background-action/MusicStateToggleButton";
import { Keyboard } from "./Keyboard";

export function HorizontalVibes({ closePreSessionVibes }: VibesComponentProps) {
  return (
    <div className="h-full w-full flex flex-col justify-center items-center mt-8 gap-4">
      <div className="h-full w-full flex flex-col justify-center items-center gap-4">
        <Text>{INSTRUCTIONS}</Text>
        <Keyboard />
      </div>
      <Button
        onClick={closePreSessionVibes}
        className="absolute bottom-3"
        use="secondary"
      >
        {GO_BACK_CTA}
      </Button>
      <div className="absolute bottom-3 right-3">
        <MusicStateToggleButton />
      </div>
    </div>
  );
}
