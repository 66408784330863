import { NewMessageToast } from "../../components/NewMessageToast";
import { Message } from "@twilio/conversations";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import {
  chatParticipantsByIdAtom,
  twilioIdentityAtom,
  unreadMessageCountByConversationSidAtom,
  localScreenShareTrackAtom,
} from "../../twilio/state";
import { logger } from "../../datadog/logger";
import { chatParticipantToName } from "../../twilio/utils";
import { useOpenConversationCallback } from "./useOpenConversationCallback";
import { ChatDrawerState, ProviderDrawerState, drawerStateAtom } from "../state";
import { minutesToMilliseconds } from "date-fns";
import { NewWaitingRoomMessageToast } from "./NewWaitingRoomMessageToast";
import { useSetAtom } from "jotai";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import { toast } from "../../toasts";

const TOAST_TIMEOUT = minutesToMilliseconds(1);
const TOAST_POSITION = "bottom-left";

export function getToastTagForConversation(sid: string) {
  return `NEW_MESSAGE_TOAST:conversation:${sid}`;
}

export function useShowNewMessageToastCallback() {
  const setUnreadMessageCount = useSetAtom(
    unreadMessageCountByConversationSidAtom,
  );
  const getChatParticipants = useAtomCallback(
    useCallback((get) => get(chatParticipantsByIdAtom), []),
  );
  const openConversation = useOpenConversationCallback();
  const getDrawerState = useAtomCallback(
    useCallback((get) => get(drawerStateAtom), []),
  );
  const getTwilioIdentity = useAtomCallback(
    useCallback((get) => get(twilioIdentityAtom), []),
  );
  const getIsLocalScreenShareMode = useAtomCallback(
    useCallback((get) => get(localScreenShareTrackAtom), []),
  );

  return useCallback(
    (message: Message, conversationKind: ChatDrawerState) => {
      const isConversationOpen = getDrawerState() === conversationKind;
      const isSelf = message.author === getTwilioIdentity();
      const isLocalScreenShareMode = getIsLocalScreenShareMode();

      if (isSelf || isConversationOpen || !!isLocalScreenShareMode) return;

      const participants = getChatParticipants();
      const participant = message.author ? participants[message.author] : null;
      const conversationSid = message.conversation.sid;
      let senderName = "New message";
      if (participant) {
        senderName = chatParticipantToName(participant);
      } else {
        const { author, sid } = message;
        logger.error("Could not find participant for message", {
          author,
          messageSid: sid,
          conversationSid,
          chatParticipantSids: Object.keys(participants),
        });
      }

      const sendQuickReply = async (
        newMessage: string,
        closeToast: () => void,
      ) => {
        await message.conversation.sendMessage(newMessage);
        sendLoggingEvents(TrackingEvents.ANY_MESSAGE_SEND);
        sendLoggingEvents(TrackingEvents.ANY_WAITING_ROOM_MESSAGE_SEND);
        setUnreadMessageCount((prev) => ({
          ...prev,
          [conversationSid]: 0,
        }));
        closeToast();
        toast.success(`Message to ${senderName} sent!`, {
          position: TOAST_POSITION,
          isMasked: true,
        });
      };

      // Dismiss any existing toasts for this conversation first.
      toast.closeByTag(getToastTagForConversation(conversationSid));

      toast.enqueue(
        ({ closeToast, ...ariaProps }) => {
          const Toast =
            conversationKind === ProviderDrawerState.WAITING_ROOM_CHAT
              ? NewWaitingRoomMessageToast
              : NewMessageToast;

          return (
            <Toast
              {...ariaProps}
              senderName={senderName}
              messageContent={message.body ?? ""}
              onOpenChatDrawer={() => {
                closeToast();
                openConversation(conversationKind, message.conversation.sid);
              }}
              onSend={(message: string) => sendQuickReply(message, closeToast)}
            />
          );
        },
        {
          position: TOAST_POSITION,
          timeout: TOAST_TIMEOUT,
          tags: new Set([getToastTagForConversation(conversationSid)]),
        },
      );
    },
    [
      getChatParticipants,
      getDrawerState,
      getTwilioIdentity,
      getIsLocalScreenShareMode,
      openConversation,
      setUnreadMessageCount,
    ],
  );
}
