import {
  CentralSessionControls,
  RightsideSessionControls,
  SessionControl,
  SessionControlsLayout,
} from "../../../components";
import { ToolbarSeparator } from "../../../components/ToolbarSeparator";
import {
  ScreenShareControlWrapper as ScreenShareControl,
  ScreenShareMenuItemsWrapper as ScreenShareMenuItems,
} from "../../../twilio/controls/ScreenShareControl";
import { useObscuredVisitorContentProps } from "../../a11y";
import {
  VisitorAudioControlWrapper as VisitorAudioControl,
  VisitorVideoControlWrapper as VisitorVideoControl,
  VisitorAdditionalOptionsMenuWrapper as VisitorAdditionalOptionsMenu,
} from "../../controls";
import { RecordingControl } from "../../session-recording/RecordingControl";
import { LeaveSessionButtonWrapper as LeaveSessionButton } from "../LeaveSessionButton";
import {
  VisitorMeetingChatDrawerButton,
  VisitorMeetingChatDrawerMenuItemWrapper as VisitorMeetingChatDrawerMenuItem,
} from "./VisitorMeetingChatDrawerControls";
import {
  VisitorMeetingSettingsControlButton,
  VisitorMeetingSettingsDrawerControlMenuItem,
} from "./VisitorMeetingSettingsDrawerControls";

const centralControls: SessionControl[] = [
  {
    key: "audio",
    toolbarComponent: VisitorAudioControl,
  },
  {
    key: "video",
    toolbarComponent: VisitorVideoControl,
  },
  {
    key: "av-separator",
    toolbarComponent: ToolbarSeparator,
  },
  {
    key: "chat",
    toolbarComponent: VisitorMeetingChatDrawerButton,
    additionalOptionsMenuItemComponent: VisitorMeetingChatDrawerMenuItem,
  },

  {
    key: "screenshare",
    toolbarComponent: ScreenShareControl,
    additionalOptionsMenuItemComponent: ScreenShareMenuItems,
  },
  {
    key: "recording",
    toolbarComponent: RecordingControl,
  },
  {
    key: "session-tools-separator",
    toolbarComponent: ToolbarSeparator,
  },
  {
    key: "settings",
    toolbarComponent: VisitorMeetingSettingsControlButton,
    additionalOptionsMenuItemComponent:
      VisitorMeetingSettingsDrawerControlMenuItem,
  },
];

export function VisitorMeetingSessionControlsWrapper() {
  const obscuredContentProps = useObscuredVisitorContentProps();

  return (
    <SessionControlsLayout {...obscuredContentProps} className="order-3">
      <CentralSessionControls
        controls={centralControls}
        additionalOptionsMenuComponent={VisitorAdditionalOptionsMenu}
      />
      <RightsideSessionControls>
        <LeaveSessionButton />
      </RightsideSessionControls>
    </SessionControlsLayout>
  );
}
