import { useSetAtom } from "jotai";
import { useSendProviderParticipantStateCallback } from "../../provider/messages/useSendProviderParticipantStateCallback";
import { sendLoggingEvents, TrackingEvents } from "../../events";
import { isWhiteboardModeAtom } from "../messages/state";
import { useCallback } from "react";
import { useTrackEvent } from "../../segment/segment";
import { EventTypeEnum } from "../../segment/types";
import { useAtomCallback } from "jotai/utils";
import { sessionInfoAtom } from "../../state";

export const useWhiteboardCallbacks = () => {
  const setIsWhiteboardMode = useSetAtom(isWhiteboardModeAtom);
  const sendParticipantState = useSendProviderParticipantStateCallback();
  const { userClicked } = useTrackEvent();
  const getSessionInfo = useAtomCallback(
    useCallback((get) => get(sessionInfoAtom), []),
  );

  const startWhiteboard = useCallback(() => {
    setIsWhiteboardMode({
      value: true,
      lastUpdated: Date.now(),
    });
    sendLoggingEvents(TrackingEvents.PROVIDER_WHITEBOARD_SHARE);
    userClicked(EventTypeEnum.PROVIDER_START_WHITEBOARD, {
      appointmentShortId: getSessionInfo()?.appointment?.shortId ?? "",
    });
    //this sends updated state to the visitor, indicating that they should open the whiteboard
    sendParticipantState();
  }, [setIsWhiteboardMode, sendParticipantState, userClicked, getSessionInfo]);

  const stopWhiteboard = useCallback(() => {
    setIsWhiteboardMode({
      value: false,
      lastUpdated: Date.now(),
    });
    sendLoggingEvents(TrackingEvents.PROVIDER_WHITEBOARD_STOP_SHARE);
    userClicked(EventTypeEnum.PROVIDER_END_WHITEBOARD, {
      appointmentShortId: getSessionInfo()?.appointment?.shortId ?? "",
    });
    sendParticipantState();
  }, [setIsWhiteboardMode, sendParticipantState, userClicked, getSessionInfo]);

  return { startWhiteboard, stopWhiteboard };
};
