import { useCallback, useEffect, useRef } from "react";
import { useCreateVisitorAccessDocTokenCallback } from "../waiting-room/useCreateVisitorAccessDocTokenMutation";
import { useVisitorId } from "../useVisitorId";
import { useParams } from "react-router-dom";
import { useAtomValue } from "jotai";
import { visitorNameAtom } from "../state";
import { useInitTwilioSyncClient as useBaseInitTwilioSyncClient } from "../../twilio";
import { ConnectionErrorToast } from "./ConnectionErrorToast";
import toast from "react-hot-toast";

export function useInitTwilioSyncClient() {
  const showedConnectionErrorToastIdRef = useRef<string>();
  const [createTwilioVisitorAccessDocToken, { data }] =
    useCreateVisitorAccessDocTokenCallback();
  const visitorUuid = useVisitorId();
  const visitorName = useAtomValue(visitorNameAtom);
  const { providerShortId } = useParams() as { providerShortId: string };

  useEffect(
    function createAccessToken() {
      createTwilioVisitorAccessDocToken({
        visitorName,
        visitorUuid,
        providerShortId,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useBaseInitTwilioSyncClient({
    jwt: data?.createTwilioVisitorAccess?.jwt,
    onConnectionError: useCallback(() => {
      if (showedConnectionErrorToastIdRef.current) {
        return;
      }

      // TODO: TOAST HOT
      showedConnectionErrorToastIdRef.current = toast.custom(
        ({ id: toastId }) => {
          return (
            <ConnectionErrorToast
              onClose={() => {
                toast.remove(toastId);
              }}
            />
          );
        },
        {
          duration: Infinity,
          position: "top-center",
        },
      );
    }, []),
  });
}
