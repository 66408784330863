import { Link, Heading, Text, LinkUse } from "@growtherapy/sprout-ui";
import ChairAndArmchair from "../assets/chair-and-armchair.svg";
import { getAppConfig } from "../config";
import { APPOINTMENT_LINK_CHANGED } from "./constants";

export function AppointmentLinkChangedPage() {
  const clientPortalURI = `${getAppConfig().marketplaceOrigin}/client-dashboard`;
  const clientPortalMessagesURI = `${clientPortalURI}/messages`;
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-6 bg-neutral-300 px-16">
      <img src={ChairAndArmchair} alt="" />
      <Heading variant="3xl" className="font-normal text-center" as="h1">
        {APPOINTMENT_LINK_CHANGED.title}
      </Heading>
      <Text as="div" className="text-neutral-700 text-center">
        {APPOINTMENT_LINK_CHANGED.subtitle}
      </Text>
      <Link href={clientPortalMessagesURI} buttonUse="primary">
        {APPOINTMENT_LINK_CHANGED.messageProvider}
      </Link>
      <Text variant="sm" className="text-neutral-700 text-center">
        {APPOINTMENT_LINK_CHANGED.forAdditionalSupport}{" "}
        <Link
          className="text-small text-neutral-700"
          use={LinkUse.Neutral}
          href={clientPortalURI}
        >
          {APPOINTMENT_LINK_CHANGED.portal}
        </Link>
        .
      </Text>
    </div>
  );
}
