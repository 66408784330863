import { useAtomValue } from "jotai";
import { isMediaShareModeAtom } from "../state";
import { MediaShareLayoutWrapper as MediaShareLayout } from "./MediaShareLayout";
import {
  RemoteParticipantGridWrapper as RemoteAudioVideoGrid,
  RemoteParticipantGridProps,
} from "./RemoteParticipantGrid";
import { FC, ReactNode } from "react";
import { usePictureInPicture } from "../usePictureInPicture";

export function SessionGrid({
  thumbnail,
  remoteParticipantComponent: RemoteParticipantComponent,
  whiteboardComponent: WhiteboardComponent,
}: {
  thumbnail: ReactNode;
  whiteboardComponent: FC;
} & Pick<RemoteParticipantGridProps, "remoteParticipantComponent">) {
  const isMediaShareMode = useAtomValue(isMediaShareModeAtom);

  usePictureInPicture();

  return isMediaShareMode ? (
    <MediaShareLayout
      remoteParticipantComponent={RemoteParticipantComponent}
      thumbnail={thumbnail}
      whiteboardComponent={WhiteboardComponent}
    />
  ) : (
    <RemoteAudioVideoGrid
      remoteParticipantComponent={RemoteParticipantComponent}
    />
  );
}
