import { useCallback } from "react";
import {
  isRecordingAtom,
  isWhiteboardModeAtom,
  patientConsentAtom,
} from "../../twilio/messages/state";
import { useAtomCallback } from "jotai/utils";
import { ConsentStatus, DataTrackAppStateMessage } from "../../twilio/types";
import {
  DataTrackUpdateStatus,
  getNewestDataTrackState,
  resolveCorrectConsentState,
} from "../../twilio/messages/utils";
import { DataTrackAppState } from "../../twilio/messages/types";
import { useClientTranscriptionResponseToastCallback } from "../session-recording/useClientTranscriptionResponseToastCallback";
import { useRecordAudioCallbacks } from "../session-recording/useRecordAudioCallbacks";
import { useSetAtom } from "jotai";
import { useSendProviderParticipantStateCallback } from "./useSendProviderParticipantStateCallback";

function useHandleClientConsentCallback() {
  const getUserPatientConsent = useAtomCallback(
    useCallback((get) => get(patientConsentAtom), []),
  );
  const sendParticipantState = useSendProviderParticipantStateCallback();
  const popClientResponseTranscriptionToast =
    useClientTranscriptionResponseToastCallback();

  const setPatientConsent = useSetAtom(patientConsentAtom);

  return useCallback(
    async (receivedParticipantState: DataTrackAppState) => {
      const currentConsentState = getUserPatientConsent();
      const receivedConsentState = receivedParticipantState.patient.consent;
      const [correctConsentState, comparisonResult] =
        resolveCorrectConsentState({
          receivedState: receivedConsentState,
          userState: currentConsentState,
        });

      if (comparisonResult === DataTrackUpdateStatus.NO_UPDATE_NEEDED) {
        return;
      }

      setPatientConsent(correctConsentState);

      const receivedNewConsentValue =
        comparisonResult ===
        DataTrackUpdateStatus.VALUE_AND_LAST_UPDATED_CHANGED;

      if (!receivedNewConsentValue) {
        return;
      }
      /** If the patient has newly consented,
       * record the consent and tell the provider
       */
      if (correctConsentState.value === ConsentStatus.OPTED_IN) {
        popClientResponseTranscriptionToast(true);
      }
      /** If the patient has declined to consent,
       *  tell the provider
       */
      if (correctConsentState.value === ConsentStatus.SOFT_NO) {
        popClientResponseTranscriptionToast(false);
      }
      sendParticipantState();
    },
    [
      getUserPatientConsent,
      setPatientConsent,
      sendParticipantState,
      popClientResponseTranscriptionToast,
    ],
  );
}

function useHandleRecordingUpdateCallback() {
  const { startRecording, stopRecording } = useRecordAudioCallbacks();
  const getCurrentIsRecording = useAtomCallback(
    useCallback((get) => get(isRecordingAtom), []),
  );
  return useCallback(
    async (receivedState: DataTrackAppState) => {
      const [newestIsRecording, comparisonResult] = getNewestDataTrackState({
        receivedState: receivedState.isRecording,
        userState: getCurrentIsRecording(),
      });

      // Return early if no update is needed
      if (comparisonResult === DataTrackUpdateStatus.NO_UPDATE_NEEDED) {
        return;
      }

      if (newestIsRecording.value) {
        await startRecording({ lastUpdated: newestIsRecording.lastUpdated });
      } else {
        await stopRecording({ lastUpdated: newestIsRecording.lastUpdated });
      }
    },
    [startRecording, stopRecording, getCurrentIsRecording],
  );
}

function useHandleIsWhiteboardModeUpdateCallback() {
  const getCurrentIsWhiteboardMode = useAtomCallback(
    useCallback((get) => get(isWhiteboardModeAtom), []),
  );
  const setIsWhiteboardMode = useSetAtom(isWhiteboardModeAtom);

  return useCallback(
    (receivedState: DataTrackAppState) => {
      const [newestIsWhiteboardMode, comparisonResult] =
        getNewestDataTrackState({
          receivedState: receivedState.isWhiteboardMode,
          userState: getCurrentIsWhiteboardMode(),
        });

      if (comparisonResult === DataTrackUpdateStatus.NO_UPDATE_NEEDED) {
        return;
      }
      setIsWhiteboardMode(newestIsWhiteboardMode);
    },
    [getCurrentIsWhiteboardMode, setIsWhiteboardMode],
  );
}

export function useUpdateDataTrackAppStateCallback() {
  const handleRecordingUpdate = useHandleRecordingUpdateCallback();
  const handleClientConsentUpdate = useHandleClientConsentCallback();
  const handleIsWhiteboardModeUpdate =
    useHandleIsWhiteboardModeUpdateCallback();

  return useCallback(
    async (message: DataTrackAppStateMessage) => {
      const { participantState } = message;
      handleIsWhiteboardModeUpdate(participantState);
      handleClientConsentUpdate(participantState);
      await handleRecordingUpdate(participantState);
    },
    [
      handleClientConsentUpdate,
      handleRecordingUpdate,
      handleIsWhiteboardModeUpdate,
    ],
  );
}
