import "./assets/css/fonts.css";
import "./assets/css/index.css";
import "./assets/css/loading.css";
import "./assets/css/toasts.css";
import "./assets/css/tailwind_index.css";
import App from "./App.tsx";
import ReactDOM from "react-dom/client";
import { addAppIconsToLibrary } from "./icons.ts";
import { initDataDog } from "./datadog/init.ts";
import { testH264Support } from "./twilio/config.ts";

export async function init() {
  initDataDog();
  addAppIconsToLibrary();
  await testH264Support();
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <App />,
  );
}

init();
