export const INTRO_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID =
  "introduction-room-push-drawer";
export const MEETING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID =
  "meeting-room-push-drawer";
export const WAITING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID =
  "waiting-room-push-drawer";

export type DrawerId =
  | typeof INTRO_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID
  | typeof MEETING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID
  | typeof WAITING_ROOM_PUSH_DRAWER_ARIA_CONTROLS_ID;
