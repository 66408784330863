import {
  LoadingScreen as VideoThumbnailLoadingBackdrop,
  Text,
  InitialsAvatar,
  AvatarAccent,
} from "@growtherapy/sprout-ui";
import { twMerge } from "tailwind-merge";
import {
  DetailedHTMLProps,
  FC,
  MutableRefObject,
  VideoHTMLAttributes,
  useState,
} from "react";
import classNames from "classnames";
import { nameToInitials } from "../utils";
import { ActiveSpeakerOutline, NameBadge } from ".";
import { nonEssentialElementProps } from "../hooks";
import { useAtom } from "jotai";
import { thumbnailPositionAtom } from "../twilio";
import { SetPositionMenu } from "./SetPositionMenu";
import {
  faArrowDownLeftAndArrowUpRightToCenter,
  faArrowUpRightAndArrowDownLeftFromCenter,
} from "@fortawesome/pro-solid-svg-icons";
import { SelfViewControlButton } from "./SelfViewControlButton";

export type VideoThumbnailProps = {
  name: string;
  pronouns?: string[];
  className?: string;
  videoClassName?: string;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
  audioTransmissionEnabled?: boolean;
  videoComponent: FC<
    DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>
  >;
  videoTransmissionEnabled?: boolean;
  showExpandVideoToggle?: boolean;
  isSettingsThumbnail?: boolean;
  showSetPositionMenu?: boolean;
  loadingVideo?: boolean;
  isMirrored?: boolean;
  helpText?: string;
  hideSelfViewButton: React.ReactNode;
  isSelfViewHidden: boolean;
  isMobile: boolean;
  isSpeaking?: boolean;
  videoOffContent?: JSX.Element;
};

function ExpandVideoToggle({
  isExpanded,
  isMobile,
  onToggle,
}: {
  isExpanded: boolean;
  isMobile: boolean;
  onToggle: () => void;
}) {
  const label = `${isExpanded ? "Minimize" : "Maximize"} self view`;
  return (
    <SelfViewControlButton
      buttonClassName="group/expand-video-button absolute top-2 left-2 flex-row-reverse"
      icon={
        isExpanded
          ? faArrowDownLeftAndArrowUpRightToCenter
          : faArrowUpRightAndArrowDownLeftFromCenter
      }
      label={label}
      labelClassName="group-hover/expand-video-button:block group-focus/expand-video-button:block"
      labelIsVisible={!isMobile}
      onClick={onToggle}
      screenReaderLabel={label}
    />
  );
}

function VideoThumbnailContent({
  expandedVideo,
  name,
  setExpandedVideo,
  showExpandVideoToggle = true,
  videoComponent: VideoComponent,
  videoTransmissionEnabled,
  isMirrored,
  isMobile,
  helpText,
  videoOffContent,
}: Pick<
  VideoThumbnailProps,
  | "name"
  | "showExpandVideoToggle"
  | "videoComponent"
  | "videoTransmissionEnabled"
  | "isMirrored"
  | "isMobile"
  | "helpText"
  | "videoOffContent"
> & {
  expandedVideo: boolean;
  setExpandedVideo: (setter: (expanded: boolean) => boolean) => void;
}) {
  const displayInitials = nameToInitials(name);
  let videoOffText: JSX.Element | null = null;
  if (helpText) {
    videoOffText = (
      <Text
        variant="md"
        className="relative text-neutral-300 fs-exclude text-center p-4"
        data-dd-privacy="mask"
      >
        {helpText}
      </Text>
    );
  } else if (videoOffContent) {
    videoOffText = videoOffContent;
  } else if (displayInitials) {
    videoOffText = (
      <>
        <span className="sr-only">Your video is off.</span>
        <InitialsAvatar
          aria-hidden
          initials={displayInitials}
          size="sm"
          accent={AvatarAccent.Green}
        />
      </>
    );
  }

  return videoTransmissionEnabled ? (
    <>
      <VideoComponent
        className={twMerge(
          classNames("absolute w-full h-full fs-exclude", {
            "scale-x-[-1]": isMirrored,
          }),
        )}
        muted
      />
      {showExpandVideoToggle && (
        <ExpandVideoToggle
          isExpanded={expandedVideo}
          isMobile={isMobile}
          onToggle={() => {
            setExpandedVideo((current) => !current);
          }}
        />
      )}
    </>
  ) : (
    videoOffText
  );
}

export function VideoThumbnail({
  name,
  pronouns,
  className,
  videoClassName,
  audioTransmissionEnabled,
  containerRef,
  videoComponent,
  videoTransmissionEnabled,
  showExpandVideoToggle = true,
  showSetPositionMenu,
  loadingVideo,
  isMirrored,
  isSettingsThumbnail,
  helpText,
  isSelfViewHidden,
  hideSelfViewButton,
  isMobile,
  isSpeaking,
  videoOffContent,
}: VideoThumbnailProps) {
  const [expandedVideo, setExpandedVideo] = useState(false);
  const [thumbnailPosition, setThumbnailPosition] = useAtom(
    thumbnailPositionAtom,
  );

  const shouldExpandVideo = expandedVideo && videoTransmissionEnabled;

  return (
    <div
      className={twMerge(
        classNames(
          "group/video-thumbnail h-fit w-fit z-10 relative",
          className,
          {
            [thumbnailPosition]: showSetPositionMenu,
          },
        ),
      )}
      ref={containerRef}
    >
      {isSelfViewHidden ? null : (
        <div
          className={twMerge(
            classNames(
              "relative flex justify-center overflow-hidden rounded-2xl items-center transition-all border-0 bg-neutral-900",
              {
                "min-w-[14rem] min-h-[7.875rem] max-w-[14rem] max-h-[7.875rem]":
                  !shouldExpandVideo,
                "min-w-[40rem] min-h-[22rem] max-w-[40rem] max-h-[22rem]":
                  shouldExpandVideo,
                "border border-neutral-700 rounded-2xl bg-neutral-900":
                  !videoTransmissionEnabled,
              },
            ),
            videoClassName,
          )}
          /* 
          For accessibility: The aria-label and aria-labelledby attributes are not well supported
          on the HTML5 <audio> and <video> elements at this time. In the meantime, we can
          add the label to the parent element.
          */
          role="region"
          aria-label={`Live video thumbnail self view${
            videoTransmissionEnabled ? "" : " (Off)"
          }`}
          data-testid="video-thumbnail"
        >
          {loadingVideo ? (
            <VideoThumbnailLoadingBackdrop
              className="absolute w-full h-full"
              size="md"
            />
          ) : (
            <VideoThumbnailContent
              {...{
                expandedVideo,
                name,
                setExpandedVideo,
                showExpandVideoToggle,
                videoTransmissionEnabled,
                isMirrored,
                isMobile,
                helpText,
                videoComponent,
                videoOffContent,
              }}
            />
          )}
          {!audioTransmissionEnabled && !isSettingsThumbnail && (
            <NameBadge
              className={`absolute bottom-0 left-0 ${nonEssentialElementProps.className}`}
              audioMuted={!audioTransmissionEnabled}
              isSelf
            />
          )}
          {isSettingsThumbnail && name && (
            <NameBadge
              className={`absolute bottom-0 left-0 ${nonEssentialElementProps.className}`}
              name={name}
              pronouns={pronouns}
              audioMuted={!audioTransmissionEnabled}
              isSelf
            />
          )}
          <ActiveSpeakerOutline
            data-testid="video-thumbnail.active-speaker-outline"
            isOutlined={!!isSpeaking}
          />
        </div>
      )}
      {hideSelfViewButton}
      {showSetPositionMenu && !isSelfViewHidden && (
        <SetPositionMenu
          isMobile={!!isMobile}
          position={thumbnailPosition}
          setPosition={setThumbnailPosition}
        />
      )}
    </div>
  );
}
