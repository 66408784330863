import {
  Button,
  IconButton,
  InputControl,
  PhoneNumberInput,
  TextInput,
} from "@growtherapy/sprout-ui";
import classNames from "classnames";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { EmergencyContactValueQuestionProps } from "./types";
import { ValidationError, ContactValue } from "../types";
import { useState } from "react";

function getEmptyContactValue(): ContactValue {
  return { name: "", phoneNumber: "" };
}

/**
 * Ported over from
 * https://github.com/Grow-Therapy-Team/Grow-Dashboard/blob/main/grow-therapy-frontend/apps/csr-app/src/app/client-portal/Intake/components/EmergencyContactInput.tsx#L18
 */
export default function EmergencyContactInput({
  onChange: givenOnChange,
  question,
  validationErrors,
  value: initialValue,
}: EmergencyContactValueQuestionProps): JSX.Element {
  const [values, setValues] = useState<ContactValue[]>(
    initialValue ?? [getEmptyContactValue()],
  );
  const { name } = question;
  const isIncompleteForm = validationErrors?.has(
    ValidationError.INCOMPLETE_FORM,
  );

  const onChange = (newValues: ContactValue[]): void => {
    setValues(newValues);
    givenOnChange?.(question, newValues);
  };

  const onChangeContactProperty = (
    newValue: string,
    index: number,
    property: keyof ContactValue,
  ): void => {
    const updatedValue = { ...values?.[index] };
    updatedValue[property] = newValue;
    const updatedValues = [...values];
    updatedValues[index] = updatedValue;
    onChange(updatedValues);
  };

  const removeContact = (index: number): void => {
    onChange?.([...values.slice(0, index), ...values.slice(index + 1)]);
  };

  const shouldShowError = (value: string): string => {
    return isIncompleteForm && !value ? "This field is required" : "";
  };

  return (
    <>
      {values?.map((contact: ContactValue, index: number) => {
        const nameError = shouldShowError(contact.name);
        const phoneError = shouldShowError(contact.phoneNumber);
        const isLastContact = index === values.length - 1;

        return (
          <div
            className={classNames("flex gap-2 items-center", {
              "mb-4": !isLastContact,
            })}
            key={`${index}-contact-name`}
          >
            <InputControl
              error={nameError}
              label="Name"
              errorClassName="text-base"
              className="mb-0"
              labelClassName={classNames("text-base", {
                "sr-only": index !== 0,
              })}
            >
              <TextInput
                name={`${name}-name-${index}`}
                className="fs-exclude mb-0"
                value={contact.name}
                placeholder="e.g. John Smith"
                id={`contact-name-${index}`}
                onChange={(e): void => {
                  onChangeContactProperty(e.target.value, index, "name");
                }}
              />
            </InputControl>
            <InputControl
              error={phoneError}
              label="Phone number"
              className="mb-0"
              labelClassName={classNames("text-base", {
                "sr-only": index !== 0,
              })}
              errorClassName="text-base"
            >
              <PhoneNumberInput
                name={`${name}-phone-number-${index}`}
                className="fs-exclude"
                value={contact.phoneNumber}
                id={`contact-phone-${index}`}
                onChange={(phoneNumber): void => {
                  onChangeContactProperty(phoneNumber, index, "phoneNumber");
                }}
              />
            </InputControl>

            {values.length > 1 && (
              <IconButton
                aria-label="Remove contact"
                data-testid="remove-contact-button"
                iconDefinition={faXmark}
                onClick={(): void => removeContact(index)}
                size="sm"
                className={classNames("no-underline h-fit w-fit self-center", {
                  "mt-6": index === 0,
                })}
                use="link"
              />
            )}
          </div>
        );
      })}
      <Button
        data-testid="add-additional-emergency-contact-button"
        className="ml-0 mt-4"
        onClick={(): void => {
          onChange?.(values?.concat(getEmptyContactValue()));
        }}
        use="secondary"
      >
        Add person
      </Button>
    </>
  );
}
