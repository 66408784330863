import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { ProviderDrawerState, drawerStateAtom } from "../state";
import { useAtomCallback } from "jotai/utils";
import { toast } from "../../toasts";
import { CLIENT_QUEUE_NEW_VISITOR_TOAST_TAG } from "../client-information/constants";

export function useToggleProviderDrawerStateCallback(
  targetDrawerState: ProviderDrawerState,
) {
  const getDrawerState = useAtomCallback(
    useCallback((get) => get(drawerStateAtom), []),
  );
  const setDrawerState = useSetAtom(drawerStateAtom);

  const closeRelevantToasts = useCallback(() => {
    switch (targetDrawerState) {
      case ProviderDrawerState.CLIENTS:
        toast.closeByTag(CLIENT_QUEUE_NEW_VISITOR_TOAST_TAG);
        break;
    }
  }, [targetDrawerState]);

  return useCallback(() => {
    const shouldCloseDrawer = getDrawerState() === targetDrawerState;
    if (shouldCloseDrawer) {
      setDrawerState(null);
      return;
    }
    closeRelevantToasts();
    setDrawerState(targetDrawerState);
  }, [closeRelevantToasts, getDrawerState, setDrawerState, targetDrawerState]);
}
