import { useCallback, useEffect } from "react";
import { useInterval } from "usehooks-ts";
import { VisitorState, visitorStateAtom } from "../state";
import { toast } from "@growtherapy/sprout-ui";
import { VISITOR_ACCESS_DOC_TTL_MS } from "../../config";
import {
  millisecondsToSeconds,
  minutesToMilliseconds,
  secondsToMilliseconds,
} from "date-fns";
import { useAtomCallback } from "jotai/utils";
import { memoize } from "../../utils";
import { useResetUserStateCallback } from "./useResetUserStateCallback";

// Minutes before the access doc expires
const WAITING_ROOM_TIMEOUT_MS =
  VISITOR_ACCESS_DOC_TTL_MS - minutesToMilliseconds(5);

// Seconds until the user is shown an informational toast about the timeout
const MS_UNTIL_TIMEOUT_TOAST_IS_SHOWN = secondsToMilliseconds(10);

const WAITING_ROOM_ENTRY_TIME_KEY = "waiting-room-entry-time";

function getWaitingRoomEntryTime() {
  return parseInt(sessionStorage.getItem(WAITING_ROOM_ENTRY_TIME_KEY) ?? "0");
}

function setWaitingRoomEntryTime(timeMs: number) {
  sessionStorage.setItem(WAITING_ROOM_ENTRY_TIME_KEY, timeMs.toString());
}

function clearWaitingRoomEntryTime() {
  sessionStorage.removeItem(WAITING_ROOM_ENTRY_TIME_KEY);
}

function shouldTimeOut(entryTimeMs: number) {
  return Date.now() - entryTimeMs > WAITING_ROOM_TIMEOUT_MS;
}

function shouldShowTimeoutToast(entryTimeMs: number) {
  return (
    Date.now() - entryTimeMs >
    WAITING_ROOM_TIMEOUT_MS - MS_UNTIL_TIMEOUT_TOAST_IS_SHOWN
  );
}

const showTimeoutToast = memoize((_id: number) => {
  // TODO: TOAST SPROUT
  toast.celebrate(
    `You’ve reached the maximum allotted time for the waiting room. You will be redirected in ${millisecondsToSeconds(
      MS_UNTIL_TIMEOUT_TOAST_IS_SHOWN,
    )} seconds and can re-join the waiting room if desired.`,
    {
      duration: MS_UNTIL_TIMEOUT_TOAST_IS_SHOWN,
    },
  );
});

export function useWaitingRoomTimeout() {
  const getVisitorState = useAtomCallback(
    useCallback((get) => get(visitorStateAtom), []),
  );
  const resetUserState = useResetUserStateCallback();

  useEffect(function initEntryTime() {
    setWaitingRoomEntryTime(Date.now());
    return clearWaitingRoomEntryTime;
  }, []);

  useInterval(
    useCallback(
      function handleWaitingRoomTimeout() {
        const isLoadingIntoMeeting = getVisitorState() === VisitorState.LOADING;
        if (isLoadingIntoMeeting) return;

        const entryTimeMs = getWaitingRoomEntryTime();
        if (!entryTimeMs) return;

        if (shouldTimeOut(entryTimeMs)) {
          resetUserState();
          return;
        }

        if (shouldShowTimeoutToast(entryTimeMs)) {
          showTimeoutToast(entryTimeMs);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
    250,
  );
}
